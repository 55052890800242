import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select, { components } from 'react-select';

import { DropdownProps } from '../../types/types';
import { sortLabelsFromOptions } from '../../utils/formData';

interface MultiSelectDropdownProps extends DropdownProps {
  showPlaceholder?: boolean;
  control: any;
  additionalClassName?: string;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="uib-icon uib-icon--new" aria-hidden="true" />
    </components.DropdownIndicator>
  );
};

function MultiSelectDropdown({
  id,
  label,
  required = true,
  control,
  options,
  showPlaceholder,
  disabled,
  additionalClassName,
}: MultiSelectDropdownProps) {
  const intl = useIntl();

  const maxMenuHeight = 175;
  const placeholder = showPlaceholder
    ? intl.formatMessage({ id: 'general.pleaseChooseEllipsis' })
    : null;

  return (
    <div
      className={`uib-form__item ${
        required ? `uib-form-field--required` : null
      } ${additionalClassName}`}
    >
      <label htmlFor={id} className="uib-form__label">
        {label}
      </label>
      <Controller
        name={id}
        control={control}
        rules={{ required: required }}
        render={({ field }) => (
          <Select
            {...field}
            placeholder={placeholder}
            isMulti
            id={id}
            options={sortLabelsFromOptions(options)}
            className="multiselect"
            classNamePrefix="multiselect"
            components={{ DropdownIndicator }}
            isDisabled={disabled}
            maxMenuHeight={maxMenuHeight}
          />
        )}
      />
    </div>
  );
}

export { MultiSelectDropdown };
