import InfoIcon from '@mui/icons-material/Info';

import { ActionTableButton } from './ActionTableButton';

interface EditActionTableButtonProps {
  id: string;
  onClick: () => void;
}

export function DetailViewActionTableButton({ id, onClick }: EditActionTableButtonProps) {
  return <ActionTableButton id={id} onClick={onClick} icon={<InfoIcon />} />;
}
