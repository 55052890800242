import { useIsAuthenticated } from '@azure/msal-react';
import { ReactElement } from 'react';

import { AuthenticatedApp } from './AuthenticatedApp';
import { UnauthenticatedApp } from './UnauthenticatedApp';

function App(): ReactElement {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {isAuthenticated && <AuthenticatedApp />}
      {!isAuthenticated && <UnauthenticatedApp />}
    </>
  );
}

export { App };
