import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useLockers, useEditLocker, useOpenLocker } from '../../../../api/hooks/useStationApi';
import { LoadingIndicator } from '../../../../components/library/loading/LoadingIndicator';
import { TablePageLayout } from '../../../../components/table/TablePageLayout';
import { Cabinet, Locker, Station } from '../../../../types/types';
import { generateEditRequest } from '../../../../utils/formData';

import { LockersTable } from './LockersTable';
import { DraftLockerModal } from './manage/DraftLockerModal';
import { LockerFormData } from './manage/LockerForm';

interface LockersViewProps {
  station: Station;
  cabinet: Cabinet;
}

function LockersView({ station, cabinet }: LockersViewProps) {
  const {
    mutate: mutateOpenLocker,
    isLoading: isOpenLockerLoading,
    isError: isOpenLockerError,
  } = useOpenLocker();
  const intl = useIntl();
  const [selectedLockerForEditing, setSelectedLockerForEditing] = useState<Locker | undefined>(
    undefined
  );
  const [editLockerError, setEditLockerError] = useState<unknown>(undefined);
  const { isLoading, isError, lockers, remove } = useLockers(station._id, cabinet._id);
  const {
    mutateAsync: editLocker,
    isError: isEditLockerError,
    error: editLockerErrorResponse,
    reset: resetEditLocker,
  } = useEditLocker();

  // check if we need this
  useEffect(() => {
    return () => {
      remove();
    };
  }, []);

  const onCloseLockerForm = () => {
    setSelectedLockerForEditing(undefined);
  };

  const onSubmitLockerForm = async (data: LockerFormData) => {
    if (selectedLockerForEditing) onSubmitEditLocker(data);
  };

  const onSubmitEditLocker = async (data: LockerFormData) => {
    if (data.lockerFeature === 'none') data.lockerFeature = '';
    const editRequest = generateEditRequest(data);
    if (editRequest) {
      editLocker(editRequest).finally(() => {
        setSelectedLockerForEditing(undefined);
      });
    } else {
      setEditLockerError(intl.formatMessage({ id: 'lockers.edit.error' }));
      setSelectedLockerForEditing(undefined);
    }
  };

  useEffect(() => {
    if (isEditLockerError) {
      setEditLockerError(editLockerErrorResponse);
    }
  }, [isEditLockerError]);

  if (isLoading) return <LoadingIndicator />;

  if (isError) return <>{}</>;

  const isContentError = isOpenLockerError || isError;
  const openLockerErrorMessage = intl.formatMessage({
    id: 'lockers.error.open',
  });
  const generalErrorMessage = intl.formatMessage({ id: 'general.error' });
  const contentError = isOpenLockerError
    ? openLockerErrorMessage
    : isError
      ? generalErrorMessage
      : null;

  return (
    <>
      <TablePageLayout
        isContentLoading={isLoading}
        isContentError={isContentError}
        contentError={contentError}
        table={
          <LockersTable
            mutateOpenLocker={mutateOpenLocker}
            lockers={lockers}
            stationId={station._id}
            isOpenLockerLoading={isOpenLockerLoading}
            onSelectLockerForEditing={setSelectedLockerForEditing}
          />
        }
        hasDraftModal={true}
        hasHeaderButton={false}
        isDraftModalOpen={!!selectedLockerForEditing}
        draftModal={
          selectedLockerForEditing && (
            <DraftLockerModal
              locker={selectedLockerForEditing}
              onClose={onCloseLockerForm}
              onSubmit={onSubmitLockerForm}
            />
          )
        }
        isEditModalError={editLockerError !== undefined}
        editModalError={editLockerError}
        cleanup={remove}
        cleanupErrors={() => {
          resetEditLocker();
          setEditLockerError(undefined);
        }}
        pageName={intl.formatMessage({ id: 'lockers.self' })}
        pageNameSingular={intl.formatMessage({ id: 'lockers.singular' })}
      />
    </>
  );
}

export { LockersView };
