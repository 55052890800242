import { Dispatch, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ButtonVariant } from '../../types/components/Button';
import { OptionItem } from '../../types/types';
import { TooltipButton } from '../general/TooltipButton';
import Button from '../odx/Button';

import { DefaultButtonGroup } from './DefaultButtonGroup';
import { LabeledDropdown } from './LabeledDropdown';

export interface UploadFormProps {
  entityToUpload: string;
  dropDownOptions?: OptionItem[];
  dropDownLabel?: string;
  dropDownId?: string;
  uploadDropdownDefaultValue?: string;
  setUploadDropdownDefaultValue?: Dispatch<React.SetStateAction<string | undefined>>;
  uploadDataErrors: Record<string, any> | undefined;
  onSubmit: (data: UploadFormData) => void;
  onCancel: () => void;
  resetUpload: () => void;
  getUploadTemplate: () => void;
  setSelectedDropdownIdForUpload?: Dispatch<React.SetStateAction<string | undefined>>;
}

export interface UploadFormData {
  dropdownIdInput: string;
  File: FileList;
}

const MAX_FILE_SIZE = 10;

function UploadForm({
  entityToUpload,
  dropDownOptions,
  dropDownLabel,
  dropDownId,
  uploadDropdownDefaultValue,
  setUploadDropdownDefaultValue,
  uploadDataErrors,
  onSubmit,
  onCancel,
  resetUpload,
  getUploadTemplate,
  setSelectedDropdownIdForUpload,
}: UploadFormProps) {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    getValues,
    formState: { isValid, isSubmitting, dirtyFields, errors },
  } = useForm<UploadFormData>({
    mode: 'onChange',
  });
  const dropdownIdInputValue = getValues('dropdownIdInput');
  const getUploadTemplateDisabled = !dropdownIdInputValue && entityToUpload === 'items';
  const validateFile = (input: FileList) => {
    const imageSizeInMbs = input[0].size / 1024 / 1024;
    const imageNameLength = input[0].name.length;

    if (imageSizeInMbs > MAX_FILE_SIZE) {
      return intl.formatMessage({ id: 'general.fileSize.error' });
    }
    if (imageNameLength > 50) {
      return intl.formatMessage({ id: 'general.fileNameSize.error' });
    }
    return true;
  };

  const handleCopyClickBoard = (errorsData: Record<string, any>) => {
    const textToClipBoard = errorsData.join('\n');
    navigator.clipboard.writeText(textToClipBoard);
  };

  const isValidDropDownOption = (dropDownOptions: OptionItem[], dropDownValue: string) => {
    return dropDownOptions.some(d => d.value === dropDownValue);
  };

  const handleDropdownIdChange = (dropdowmIdValue: string) => {
    if (setSelectedDropdownIdForUpload) {
      setSelectedDropdownIdForUpload(dropdowmIdValue);
    }
  };

  // This useEffect() checks if the dropdownIdInput don't exists and if so reset the dropdown selection
  useEffect(() => {
    if (
      dropDownOptions &&
      uploadDropdownDefaultValue &&
      setUploadDropdownDefaultValue &&
      !isValidDropDownOption(dropDownOptions, uploadDropdownDefaultValue)
    ) {
      resetField('dropdownIdInput');
      setUploadDropdownDefaultValue('');
    }
  }, [dropDownOptions]);
  return (
    <form
      onSubmit={handleSubmit(data => {
        onSubmit(data);
      })}
    >
      <div className="uib-modal__content modal-form single-col">
        {dropDownOptions !== undefined &&
          dropDownId !== undefined &&
          dropDownLabel !== undefined && (
            <LabeledDropdown
              label={dropDownLabel}
              id={'dropdownIdInput'}
              options={dropDownOptions}
              placeholder={
                !uploadDropdownDefaultValue
                  ? intl.formatMessage({ id: 'general.pleaseChooseEllipsis' })
                  : undefined
              }
              selectedValue={uploadDropdownDefaultValue}
              setValue={setValue}
              onChange={handleDropdownIdChange}
              props={{
                ...register('dropdownIdInput', {
                  required: true,
                }),
              }}
            />
          )}
        <div className="uib-form__item upload-form-item">
          <label htmlFor="uploadedFile" className="uib-form__label">
            {intl.formatMessage({ id: `${entityToUpload}.uploadData` })}
          </label>
          <input
            hidden
            type="file"
            id="uploadedFile"
            accept=".xlsx"
            {...register('File', {
              required: true,
              validate: validateFile,
              onChange: resetUpload,
            })}
          />
          <label
            htmlFor="uploadedFile"
            style={{ maxWidth: '200px' }}
            className={'odx-button odx-button--primary'}
          >
            {intl.formatMessage({ id: 'general.chooseFile' })}
          </label>
        </div>
        {!errors.File?.message && !uploadDataErrors && (
          <div className="uib-textfield__input" style={{ marginTop: '15px' }}>
            <button
              onClick={() => {
                getUploadTemplate();
              }}
              type="button"
              disabled={getUploadTemplateDisabled}
            >
              <span className={`uib-link ${getUploadTemplateDisabled && 'uib-link--muted'}`}>
                <i className="uib-icon uib-icon--download" />
                {intl.formatMessage({
                  id: 'general.downloadTemplate',
                })}
              </span>
              {intl.formatMessage({
                id: `${entityToUpload}.uploadTemplate`,
              })}
            </button>
          </div>
        )}
        {uploadDataErrors && (
          <div className="form-error">
            {intl.formatMessage({
              id: `${entityToUpload}.uploadDataFailed.error`,
            })}
            <div className="border-box-form" data-testid="errorsBox">
              {uploadDataErrors?.map((uploadDataError: string, index: number) => (
                <pre key={index}> {uploadDataError} </pre>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="uib-modal__footer">
        {!uploadDataErrors ? (
          <DefaultButtonGroup
            isSubmitDisabled={isSubmitting || !isValid || !Object.keys(dirtyFields).length}
            onCancel={onCancel}
            actionButtonText={intl.formatMessage(
              {
                id: `${entityToUpload}.uploadButton`,
              },
              { entityToUpload: entityToUpload }
            )}
            secondButtonText={
              errors.File &&
              intl.formatMessage({
                id: 'general.close',
              })
            }
          />
        ) : (
          <div className="uib-button-group default-button-group">
            <div className="buttons-main-cluster">
              <TooltipButton
                id="copyReport"
                handleButtonAction={() => handleCopyClickBoard(uploadDataErrors)}
                actionButtonText={intl.formatMessage({
                  id: 'general.copyErrorReport',
                })}
                buttonTooltipMessage={intl.formatMessage({
                  id: 'general.errorsCopied',
                })}
                setTimeoutOnMessage={true}
              />
              <Button variant={ButtonVariant.GHOST} id={'abortButton'} onClick={onCancel}>
                {intl.formatMessage({ id: 'general.cancel' })}
              </Button>
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

export { UploadForm };
