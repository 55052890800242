interface SwitchProps {
  id: string;
  isSelected?: boolean;
  props?: unknown;
  disabled?: boolean;
  onChange?: () => void;
  onClick?: () => void;
}
const Switch = ({ id, isSelected, props, disabled, onChange, onClick }: SwitchProps) => {
  const getLabelState = () => {
    if (disabled) {
      return 'switch-disabled';
    }
    return isSelected ? 'switch-selected' : undefined;
  };

  const disabledButtonClassName = disabled ? 'switch-button-disabled' : '';

  return (
    <div className="switch-container">
      <input
        className="switch-checkbox"
        data-testid="switch"
        id={id}
        disabled={disabled}
        checked={isSelected}
        type="checkbox"
        onChange={onChange}
        onClick={onClick}
        {...(typeof props === 'object' ? props : {})}
      />
      <label className={`switch-ui-label ${getLabelState()}`} htmlFor={id}>
        <span className={`switch-button ${disabledButtonClassName}`} />
      </label>
    </div>
  );
};

export { Switch };
