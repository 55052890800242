import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { EditActionTableButton } from '../../library/table/action-buttons/EditActionTableButton';

export function entityEditButtonFactoryMethod(entity: any | undefined) {
  return <>{entity && <EditEntityButton entity={entity} />}</>;
}
interface EditEntityButtonProps {
  entity: any;
}
export function EditEntityButton({ entity }: EditEntityButtonProps) {
  const { setEntityToEdit } = useCrudPageUiStateContext();
  function handleEditEntity() {
    setEntityToEdit(entity);
  }
  return <EditActionTableButton id={`editEntity_${entity.id}`} onClick={handleEditEntity} />;
}
