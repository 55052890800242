import Icon from '../../library/icons/Icon';

interface InfoButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

function InfoButton({ onClick, disabled = false }: InfoButtonProps) {
  return (
    <button onClick={onClick} type="button" className="uib-iconbutton" disabled={disabled}>
      <Icon id={'infoButtonTable'} iconName={'info'} />
    </button>
  );
}

export { InfoButton };
