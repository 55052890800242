import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DefaultButtonGroup } from '../../../../components/form/DefaultButtonGroup';
import { LabeledDropdown } from '../../../../components/form/LabeledDropdown';
import { Cabinet, OptionItem } from '../../../../types/types';
import { getDirtyFormValues } from '../../../../utils/formData';

export interface CabinetFormProps {
  cabinet?: Cabinet;
  cabinetTypes: OptionItem[];
  lockerFeatures: OptionItem[];
  onCancel: () => void;
  onSubmit: (data: CabinetFormData) => void;
}
export interface CabinetFormData {
  _id?: string;
  type?: string;
  lockerFeatureId?: string;
}

function CabinetForm({
  cabinet,
  cabinetTypes,
  lockerFeatures,
  onCancel,
  onSubmit,
}: CabinetFormProps) {
  const intl = useIntl();
  const isEditForm = !!cabinet;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty, isSubmitting, dirtyFields },
  } = useForm<CabinetFormData>({
    mode: 'onChange',
  });

  return (
    <form
      onSubmit={handleSubmit(data => {
        if (cabinet) {
          data = getDirtyFormValues(data, dirtyFields);
          data._id = cabinet._id;
        }
        onSubmit(data);
      })}
    >
      <div className="uib-modal__content modal-form double-col">
        <LabeledDropdown
          label={intl.formatMessage({ id: 'cabinets.cabinetType' })}
          id="type"
          options={cabinetTypes}
          placeholder={
            !isEditForm
              ? intl.formatMessage({
                  id: 'general.pleaseChooseEllipsis',
                })
              : undefined
          }
          disabled={!!cabinet}
          selectedValue={
            cabinetTypes.find(cabinetType => cabinetType.label === cabinet?.cabinetType)?.value
          }
          setValue={setValue}
          props={{ ...register('type', { required: !isEditForm }) }}
          sortOptions={false}
        />
        <LabeledDropdown
          label={intl.formatMessage({ id: 'lockers.lockerFeature' })}
          id="lockerFeatureId"
          options={lockerFeatures}
          required={false}
          setValue={setValue}
          selectedValue={cabinet?.lockerFeatureId ? cabinet.lockerFeatureId : 'none'}
          props={{ ...register('lockerFeatureId') }}
        />
      </div>

      <div className="uib-modal__footer">
        <DefaultButtonGroup
          isSubmitDisabled={isSubmitting || !isDirty || !isValid}
          onCancel={onCancel}
        />
      </div>
    </form>
  );
}

export { CabinetForm };
