import { useIntl } from 'react-intl';

import { FormTextField } from '../../../library/form/FormTextField';

export function RenterFirstNameInput() {
  const intl = useIntl();
  return (
    <FormTextField
      id={'RenterFirstNameInput'}
      label={intl.formatMessage({ id: 'general.firstName' })}
      required={true}
      disabled={false}
      name={'firstName'}
    />
  );
}
