import { useIntl } from 'react-intl';

import { Modal } from '../../../components/modal/Modal';

import { BusinessFormData, BusinessReportForm } from './BusinessReportForm';

interface BusinessReportModalProps {
  onClick: (data: BusinessFormDataProcessed) => void;
  onClose: () => void;
}

export interface BusinessFormDataProcessed {
  startDate: string;
  endDate: string;
}

function BusinessReportModal({ onClose, onClick }: BusinessReportModalProps) {
  const intl = useIntl();

  const processBusinessReport = (formData: BusinessFormData) => {
    const startDate = new Date(formData.startDate).toISOString().split('T')[0];
    const endDate = new Date(formData.endDate).toISOString().split('T')[0];
    onClick({ startDate, endDate });
  };

  const header = (
    <h1 className="uib-modal__title">{intl.formatMessage({ id: 'reports.businessReport' })}</h1>
  );
  return (
    <>
      <Modal
        isOpen={true}
        hasForm={true}
        header={header}
        modalHasClosed={() => {
          onClose();
        }}
        content={
          <BusinessReportForm
            onCancel={onClose}
            onSubmit={data => {
              processBusinessReport(data);
              onClose();
            }}
          />
        }
      />
    </>
  );
}

export { BusinessReportModal };
