import dayjs from 'dayjs';
import { useState } from 'react';
import { useForm, Validate } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DefaultButtonGroup } from '../../../components/form/DefaultButtonGroup';
import { LabeledInput } from '../../../components/form/LabeledInput';

import { BusinessReportFormLegend } from './BusinessReportFormLegend';

export interface BusinessFormData {
  startDate: Date;
  endDate: Date;
}

export interface BusinessReportFormProps {
  onCancel: () => void;
  onSubmit: (data: BusinessFormData) => void;
}

function BusinessReportForm({ onCancel, onSubmit }: BusinessReportFormProps) {
  const intl = useIntl();
  const [dateErrorModalOpen, setDateErrorModalOpen] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isDirty, dirtyFields, isSubmitting },
  } = useForm<BusinessFormData>({
    mode: 'onChange',
  });

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  const validateStartDate: Validate<Date, any> = startDate => {
    if (!!watchEndDate && watchEndDate < startDate) {
      setDateErrorModalOpen(true);
      return false;
    }
    setDateErrorModalOpen(false);
    return true;
  };

  const validateEndDate: Validate<Date, any> = endDate => {
    if (watchStartDate && endDate < watchStartDate) {
      setDateErrorModalOpen(true);
      return false;
    }
    setDateErrorModalOpen(false);
    return true;
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(data => {
          onSubmit(data);
        })}
      >
        <div className="uib-modal__content modal-form double-col">
          <LabeledInput
            id="startDate"
            label={intl.formatMessage({
              id: 'reports.startDate',
            })}
            labelAdditionalClassName="increase-width"
            type="date"
            max={dayjs().format('YYYY-MM-DD')}
            props={{
              ...register('startDate', {
                required: true,
                validate: validateStartDate,
              }),
            }}
          />
          <LabeledInput
            id="endDate"
            label={intl.formatMessage({
              id: 'reports.endDate',
            })}
            labelAdditionalClassName="increase-width"
            type="date"
            max={dayjs().format('YYYY-MM-DD')}
            props={{
              ...register('endDate', {
                required: true,
                validate: validateEndDate,
              }),
            }}
          />
          {dateErrorModalOpen && (
            <span className="form-error">
              {intl.formatMessage({
                id: 'reports.endDateShorterThatStartDate.error',
              })}
            </span>
          )}
        </div>
        <BusinessReportFormLegend />
        <div className="uib-modal__footer">
          <DefaultButtonGroup
            isSubmitDisabled={
              isSubmitting || !isDirty || !isValid || !Object.keys(dirtyFields).length
            }
            onCancel={onCancel}
            actionButtonText="Download"
          />
        </div>
      </form>
    </>
  );
}

export { BusinessReportForm };
