import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useMenuItems } from '../../hooks/useMenuItems';
import { RentalHistory } from '../../modules/reports/RentalHistory';
import { ServiceHistory } from '../../modules/reports/ServiceHistory';
import { StationsPage } from '../../modules/stations/StationsPage';
import { UserManagementPage } from '../../modules/users/UserManagementPage';
import { ShopApiDocsPage } from '../../views/api-docs/ShopApiDocsPage';
import { ServiceTechnicianDashboard } from '../../views/dashboard/ServiceTechnicianDashboard';
import { CreateItemSetTemplatePage } from '../../views/item-set/CreateItemSetTemplatePage';
import { SystemsPage } from '../../views/systems/SystemsPage';

interface AppRoutesProps {
  rrSystemId: string;
}

function AppRoutes({ rrSystemId }: AppRoutesProps) {
  const { menuItems } = useMenuItems();
  const location = useLocation();
  const navigate = useNavigate();
  const RoutesFromMenu = menuItems.map(menuItem => {
    return (
      <Route
        path={menuItem.to}
        element={menuItem.component ? <menuItem.component /> : undefined}
        key={menuItem.to}
      />
    );
  });
  // if rrSystem is not set we need to redirect to the main page except when in the user page
  if (!rrSystemId && location.pathname != '/systems' && location.pathname != '/user-mgmt') {
    navigate('/systems');
  }
  return (
    <Routes>
      <Route path="/api/docs/shop" element={<ShopApiDocsPage />} />
      <Route path="/systems" element={<SystemsPage />} />
      <Route path="/user-mgmt" element={<UserManagementPage />} />
      {RoutesFromMenu}
      <Route path="/item-set-templates/create" element={<CreateItemSetTemplatePage />} />
      <Route path="/stations/:stationId/:cabinetId" element={<StationsPage />} />
      <Route path="/stations/:stationId" element={<StationsPage />} />
      <Route path="/reports/rental-history" element={<RentalHistory />} />
      <Route path="/reports/service-history" element={<ServiceHistory />} />
      <Route path="/dashboard" element={<ServiceTechnicianDashboard />} />
      <Route path="/*" element={<Navigate to="/systems" />} />
    </Routes>
  );
}

export { AppRoutes };
