import { useCreateRenter } from '../../../api/hooks/useRentingApi';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { RenterFormValues } from '../../../types/renter/RenterFormValues';
import { RenterParameter } from '../../../types/renter/RenterParameter';
import { ApiError } from '../../../types/types';
import { CreateEntityFormButtons } from '../../common/crud/CreateEntityFormButtons';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';
import { RenterForm } from '../form/RenterForm';

export function CreateRenterForm() {
  const { setNotificationMessage } = useNotificationMessageContext();
  const {
    mutateAsync: createRenter,
    reset: resetCreateRenter,
    isLoading: createRenterIsLoading,
  } = useCreateRenter();
  const isLoading = createRenterIsLoading;
  function handleSubmit(renterParam: RenterParameter) {
    createRenter(renterParam, {
      onSuccess: () => {
        resetCreateRenter();
        setNotificationMessage('renters.saved', NotificationType.Success);
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          const errorData = error.response.data as ApiError;
          if (errorData.code) {
            setNotificationMessage(`renters.${errorData.code}`, NotificationType.Error);
          } else {
            setNotificationMessage('renters.generic-create-error', NotificationType.Error);
          }
        }
      },
    });
  }
  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <RenterForm
          initialFormValues={initialFormValues()}
          handleSubmit={handleSubmit}
          buttons={<CreateEntityFormButtons />}
        />
      )}
    </>
  );
}
function initialFormValues(): RenterFormValues {
  return {
    firstName: '',
    lastName: '',
    barcode: '',
    humanRenterId: '',
    contractorId: '',
    phone: '',
    email: '',
    supervisorEmails: [],
    qualificationOptions: [],
    qualifications: [],
    isRenterAdmin: false,
    status: 'Confirmed',
  };
}
