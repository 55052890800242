import { ChangeEvent, useEffect, useState } from 'react';

import Chip from '../odx/Chip';

interface LabeledTagfieldProps {
  resetValue?: boolean;
  label: string;
  required?: boolean;
  placeholder?: string;
  defaultTags?: string[];
  validateTag: (tag: string, tagList: string[]) => Promise<boolean | undefined>;
  sendUpdatedTagList: (tagList: string[]) => void;
}

function LabeledTagfield({
  resetValue,
  label,
  required = false,
  placeholder,
  defaultTags = [],
  validateTag,
  sendUpdatedTagList,
}: LabeledTagfieldProps) {
  const [tagToAdd, setTagToAdd] = useState<string>('');
  const [tagList, setTagList] = useState<string[]>(defaultTags);

  useEffect(() => {
    if (resetValue) {
      setTagList(defaultTags);
    }
  }, [resetValue]);

  function updateTagToAdd(event: ChangeEvent<HTMLInputElement>) {
    setTagToAdd(event.target.value.trim());
  }

  async function evaluateKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' || event.key === 'NumEnter' || event.key === ' ') {
      addTag();
    }
  }

  async function addTag() {
    const isTagValid = tagToAdd !== '' ? await validateTag(tagToAdd, tagList) : false;
    if (isTagValid) {
      const updatedTagList = [...tagList];
      updatedTagList.push(tagToAdd);
      setTagList(updatedTagList);
    }
    setTagToAdd('');
  }

  function removeTag(tagName: string) {
    const updatedTagList = [...tagList];
    const tagIndex = updatedTagList.indexOf(tagName);
    if (tagIndex > -1) {
      updatedTagList.splice(tagIndex, 1);
      setTagList(updatedTagList);
      sendUpdatedTagList(updatedTagList);
    }
  }

  const tagfieldEmpty = tagList.length === 0;

  return (
    <div className={`uib-form__item ${required ? 'uib-form-field--required' : null} tag-section`}>
      <label htmlFor="tagfield" className="uib-form__label">
        {label}
      </label>
      <div id="tagfield" className="tagfield">
        {tagList.map(tag => {
          return (
            <Chip removable={true} onRemove={() => removeTag(tag)} key={tag}>
              {tag}
            </Chip>
          );
        })}
        <input
          type="text"
          value={tagToAdd}
          placeholder={tagfieldEmpty ? placeholder : undefined}
          onChange={updateTagToAdd}
          onKeyUp={evaluateKeyUp}
          onBlur={addTag}
          className={tagfieldEmpty ? 'empty-tagfield-input' : undefined}
        />
      </div>
    </div>
  );
}

export { LabeledTagfield };
