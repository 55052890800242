import { Button } from '@mui/material';
import { useIntl } from 'react-intl';

interface ConfirmButtonProps {
  onClick: () => void;
}

export function ConfirmButton({ onClick }: ConfirmButtonProps) {
  const intl = useIntl();
  return (
    <Button id={'confirmBtn'} onClick={onClick}>
      {intl.formatMessage({ id: 'general.confirm' })}
    </Button>
  );
}
