import { useIntl } from 'react-intl';
import { Column } from 'react-table';

import { VerticalTable } from '../../components/table/VerticalTable';
import { ServiceHistoryEntry } from '../../types/types';

interface ServiceHistoryTableProps {
  serviceHistory: ServiceHistoryEntry[];
}

function ServiceHistoryTable({ serviceHistory }: ServiceHistoryTableProps) {
  const intl = useIntl();

  const columns: Column[] = [
    {
      Header: intl.formatMessage({ id: 'item-types.singular' }),
      accessor: 'itemTypeName',
    },
    {
      Header: intl.formatMessage({ id: 'items.eID' }),
      accessor: 'eId',
    },
    {
      Header: intl.formatMessage({ id: 'service-types.singular' }),
      accessor: 'serviceTypeName',
    },
    {
      Header: intl.formatMessage({ id: 'general.date' }),
      accessor: 'date',
    },
    {
      Header: intl.formatMessage({ id: 'reports.source' }),
      accessor: 'source',
    },
  ];

  const sortBy = [
    {
      id: 'date',
      desc: true,
    },
  ];

  return (
    <VerticalTable
      sortBy={sortBy}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      columns={columns}
      data={serviceHistory}
    />
  );
}

export { ServiceHistoryTable };
