import { useCreateAlertRule } from '../../../api/hooks/useAlertRulesApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { AlertRuleFormValues } from '../../../types/alert-rules/AlertRuleFormValues';
import { AlertRuleParameter } from '../../../types/alert-rules/AlertRuleParameter';
import { AlertRuleType } from '../../../types/alert-rules/AlertRuleType';
import { AlertRuleUserInputUnit } from '../../../types/alert-rules/AlertRuleUserInputUnit';
import { ApiError } from '../../../types/types';
import { CreateEntityFormButtons } from '../../common/crud/CreateEntityFormButtons';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';
import { AlertRuleForm } from '../form/AlertRuleForm';

export function CreateAlertRuleForm() {
  const { setNotificationMessage } = useNotificationMessageContext();
  const {
    mutateAsync: createAlertRule,
    reset: resetCreateAlertRule,
    isLoading,
  } = useCreateAlertRule();
  const { setOpenCreateDialog } = useCrudPageUiStateContext();
  function handleSubmit(alertRuleParam: AlertRuleParameter) {
    createAlertRule(alertRuleParam, {
      onSuccess: () => {
        resetCreateAlertRule();
        setNotificationMessage('alert-rules.saved', NotificationType.Success);
        setOpenCreateDialog(false);
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          const errorData = error.response.data as ApiError;
          if (errorData.code) {
            setNotificationMessage(`alert-rules.${errorData.code}`, NotificationType.Error);
          } else {
            setNotificationMessage('alert-rules.generic-create-error', NotificationType.Error);
          }
        }
      },
    });
  }
  return (
    <>
      {isLoading && <LoadingIndicator />}
      <AlertRuleForm
        initialFormValues={initialCreateAlertRuleFormValues()}
        handleSubmit={handleSubmit}
        buttons={<CreateEntityFormButtons />}
      />
    </>
  );
}
function initialCreateAlertRuleFormValues(): AlertRuleFormValues {
  return {
    alertRuleType: AlertRuleType.ReturnOverdueWarning,
    name: '',
    threshold: 1,
    unit: AlertRuleUserInputUnit.Days,
    itemTypeId: '',
    stationId: '',
    receiverRoles: [],
    receiverEmails: [],
  };
}
