import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useDashboardContext } from '../../context/DashboardContext';
import { Renter } from '../../types/renter/Renter';
import { ChartCard } from '../library/charts/ChartCard';
import { CheckIcon } from '../library/icons/CheckIcon';
import { WarningIcon } from '../library/icons/WarningIcon';
import { ColumnType, DataTable } from '../library/table/DataTable';

export function ExpiringQualificationsChartCard() {
  const { renters, rentersIsLoading } = useDashboardContext();
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'dashboard.expiringQualificationsTitle',
  });
  const rows = transformToQualificationRow(renters);
  return (
    <ChartCard
      title={title}
      content={<ExpiringQualificationsTable rows={rows} />}
      isLoading={rentersIsLoading}
      isEmpty={rows.length == 0}
    />
  );
}
interface ExpiringQualificationRow {
  id: string;
  renterBarcode: string;
}
interface ExpiringQualificationsTableProps {
  rows: ExpiringQualificationRow[];
}
function ExpiringQualificationsTable({ rows }: ExpiringQualificationsTableProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const columns = [
    {
      field: 'renterName',
      headerName: intl.formatMessage({ id: 'renters.fullName' }),
    },
    {
      field: 'qualificationName',
      headerName: intl.formatMessage({
        id: 'qualifications.singular',
      }),
    },
    {
      field: 'isValid',
      headerName: intl.formatMessage({ id: 'qualifications.validity' }),
      renderCell: renderValidity,
    },
    {
      field: 'expiryDate',
      headerName: intl.formatMessage({ id: 'qualifications.expiryDate' }),
      type: ColumnType.Date,
    },
  ];
  function onRowClick(id: string) {
    const row = rows.find(r => r.id === id);
    navigate(`/renters?column=barcode&filter=${row?.renterBarcode}`);
  }
  return (
    <DataTable
      onRowClick={onRowClick}
      rows={rows}
      columns={columns}
      sort={[{ field: 'expiryDate', sort: 'asc' }]}
      initialPageSize={5}
      showToolBar={false}
      rowHeight={35}
    />
  );
}
function renderValidity(param: any) {
  if (param.value) {
    return <CheckIcon />;
  }
  return <WarningIcon />;
}
function transformToQualificationRow(renters: Renter[]): ExpiringQualificationRow[] {
  const qualifications = renters
    .filter(r => !!r.qualificationDetails)
    .map(renter => {
      return renter.qualificationDetails?.map(qualification => {
        const expiryDate = stringToDate(qualification.expiryDate);
        return {
          id: renter._id + '_' + qualification._id,
          renterName: renter.firstName + ' ' + renter.lastName,
          renterBarcode: renter.barcode,
          qualificationName: qualification.name,
          isValid: qualification.isValid,
          expiryDate: expiryDate,
        };
      });
    })
    .flat()
    .filter(q => !!q);
  return qualifications;
}

// expecting date format DD.MM.YYYY
function stringToDate(date: string): Date | undefined {
  if (date === 'Undefined') {
    return undefined;
  }
  const [day, month, year] = date.split('.');
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}
