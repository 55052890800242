import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { useClient } from '../../context/auth/tokenContext';
import { LockerFeature } from '../../types/types';

function useLockerFeatures() {
  const intl = useIntl();
  const client = useClient<LockerFeature[]>();
  const result = useQuery('lockerFeatures', () => client('/locker-features'));
  const noFeature: LockerFeature[] = [
    {
      _id: 'none',
      description: intl.formatMessage({ id: 'lockers.features.none' }),
    },
  ];
  return {
    ...result,
    lockerFeatures: noFeature.concat(result.data?.data ?? []),
  };
}

export { useLockerFeatures };
