import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { DropDownItem } from '../inputs/DropDown';

interface FormMultiDropdownProps {
  id: string;
  label: string;
  name: string;
  options: DropDownItem[];
  required?: boolean;
  fullWidth?: boolean;
}

export function FormMultiDropDown({
  id,
  label,
  name,
  options,
  required,
  fullWidth = true,
}: FormMultiDropdownProps) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field: { onChange, value } }) => (
        <FormControl sx={{ my: 1 }} fullWidth={fullWidth}>
          <InputLabel sx={{ bgcolor: 'white', px: 1 }}>{label}</InputLabel>
          <Select id={id} multiple value={value} onChange={onChange}>
            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
