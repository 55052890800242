function useRequestHeaders() {
  function contentDispositionFileName(headers: any) {
    const contentDisposition = headers['content-disposition'];
    const fileName = contentDisposition
      .replace('filename="', 'filename=')
      .replace('"; ', '; ')
      .split(';')[1]
      .split('filename=')[1]
      .split('; ')[0]
      .trim();
    return fileName;
  }
  return { contentDispositionFileName };
}

export { useRequestHeaders };
