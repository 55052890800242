import { useIntl } from 'react-intl';

import { useGetServiceHistoryExcel, useServiceHistory } from '../../api/hooks/useReportsApi';

import { HistoryPageLayout } from './components/HistoryPageLayout';
import { ServiceHistoryTable } from './ServiceHistoryTable';

function ServiceHistory() {
  const intl = useIntl();
  const {
    isLoading: isServiceHistoryLoading,
    isError: isServiceHistoryError,
    serviceHistory,
    remove: removeServiceHistory,
  } = useServiceHistory();
  const {
    serviceHistoryExcel,
    headers,
    isLoading: isExcelLoading,
    isSuccess: isExcelSuccess,
    isError: isExcelError,
    refetch,
    remove: removeExcel,
  } = useGetServiceHistoryExcel();

  return (
    <HistoryPageLayout
      pageName={intl.formatMessage({ id: 'reports.serviceHistory' })}
      pageNameSingular={intl.formatMessage({ id: 'reports.serviceHistory' })}
      historyTable={<ServiceHistoryTable serviceHistory={serviceHistory} />}
      isHistoryLoading={isServiceHistoryLoading}
      isHistoryError={isServiceHistoryError}
      historyExcel={serviceHistoryExcel}
      excelHeaders={headers}
      isExcelLoading={isExcelLoading}
      isExcelSuccess={isExcelSuccess}
      isExcelError={isExcelError}
      fetchExcel={refetch}
      removeExcel={removeExcel}
      cleanup={removeServiceHistory}
    />
  );
}

export { ServiceHistory };
