import { Station } from '../../../types/types';
import { DropDown, DropDownItem } from '../../library/inputs/DropDown';

interface StationDropDownProps {
  currentStation: string;
  onChange: (stationId: string) => void;
  style?: any;
  fullWidth?: boolean;
  stations: Station[];
}
export function StationDropDown({
  currentStation,
  onChange,
  style,
  fullWidth = true,
  stations,
}: StationDropDownProps) {
  const stationDropDownItems = getDropDownItems(stations);
  return (
    <DropDown
      fullWidth={fullWidth}
      style={style}
      id={'stationsDropDown'}
      value={currentStation}
      dropDownItems={stationDropDownItems}
      onChange={onChange}
    />
  );
}

function getDropDownItems(stations: Station[]): DropDownItem[] {
  const result = stations.map(station => ({
    value: station._id,
    label: station.location,
  }));
  result.push({ value: '', label: '-' });
  return result;
}
