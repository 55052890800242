export interface QuantityRestrictionRow {
  id: string;
  itemTypes: string[];
  renters: string[];
  contractors: string[];
  quantity: number;
}

export enum QuantityRestrictionRowFieldName {
  ItemTypes = 'itemTypes',
  Renters = 'renters',
  Contractors = 'contractors',
  Quantity = 'quantity',
}
