import { ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Column } from 'react-table';

import { DetailedItemType } from '../../types/item-types/ItemType';
import { DetailedItem, DetailedUser, DetailedRenter } from '../../types/types';
import { LoadingIndicator } from '../library/loading/LoadingIndicator';
import { Modal } from '../modal/Modal';

import { HorizontalTable } from './HorizontalTable';
import { VerticalTable } from './VerticalTable';

interface DetailViewProps {
  header: string;
  headerAdditionalInfo?: ReactNode;
  mainSectionHeader?: string;
  isDetailDataLoading: boolean;
  detailData: DetailedUser | DetailedItemType | DetailedItem | DetailedRenter | null;
  detailDataError: string;
  isMainSectionHorizontal?: boolean;
  mainColumns: Column<{ [key: string]: unknown }>[];
  aside?: ReactNode;
  additionalTableLayer?: Column<{ [key: string]: unknown }>[];
  hasSecondSection: boolean;
  secondSectionHeader: string;
  secondaryData?: { [key: string]: unknown }[];
  secondarySectionHasTable?: boolean;
  isSecondSectionHorizontal?: boolean;
  secondaryColumns: Column<{ [key: string]: unknown }>[];
  hiddenColumns?: string[];
  onClose: () => void;
  cleanup: () => void;
}

function DetailView({
  header,
  headerAdditionalInfo,
  isDetailDataLoading,
  detailData,
  mainSectionHeader,
  isMainSectionHorizontal = false,
  mainColumns,
  aside,
  additionalTableLayer,
  hasSecondSection,
  secondSectionHeader,
  secondaryData,
  secondarySectionHasTable = true,
  isSecondSectionHorizontal = true,
  secondaryColumns,
  detailDataError,
  hiddenColumns = [],
  onClose,
  cleanup,
}: DetailViewProps) {
  const intl = useIntl();
  if (!mainSectionHeader) mainSectionHeader = intl.formatMessage({ id: 'general.metadata' });

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  const detailViewHeader = () => {
    if (!detailData) return <></>;

    return (
      <>
        <h2 className="uib-modal__title">{header}</h2>
        {headerAdditionalInfo && (
          <div className="header-additional-info">{headerAdditionalInfo}</div>
        )}
      </>
    );
  };

  const detailViewContent = () => {
    if (isDetailDataLoading) return <LoadingIndicator />;
    if (!detailData)
      return (
        <p>
          {detailDataError} {intl.formatMessage({ id: 'general.contactAdmin' })}
        </p>
      );
    return (
      <>
        <section>
          <h3>{mainSectionHeader}</h3>
          <div
            className={`detail-layer first-layer ${additionalTableLayer ? '' : 'is-item-detail'}`}
          >
            {isMainSectionHorizontal ? (
              <div className="table-container">
                <HorizontalTable
                  // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
                  columns={mainColumns}
                  data={[detailData]}
                />
              </div>
            ) : (
              <div className={`table-container col-count-${mainColumns.length}`}>
                <VerticalTable
                  // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
                  columns={mainColumns}
                  data={[detailData]}
                  canSearchEntries={false}
                />
              </div>
            )}
            {aside}
          </div>
          <div
            className={`detail-layer second-layer ${
              additionalTableLayer ? '' : 'no-layer-content'
            }`}
          >
            {additionalTableLayer && (
              <div className={`table-container col-count-${additionalTableLayer.length}`}>
                <VerticalTable
                  // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
                  columns={additionalTableLayer}
                  data={[detailData]}
                  canSearchEntries={false}
                  hiddenColumns={hiddenColumns}
                />
              </div>
            )}
          </div>
        </section>
        {hasSecondSection && secondaryData && (
          <section>
            <h3>{secondSectionHeader}</h3>
            <div className="detail-layer third-layer">
              {secondarySectionHasTable &&
                (isSecondSectionHorizontal ? (
                  <div className="table-container">
                    <HorizontalTable
                      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
                      columns={secondaryColumns}
                      data={secondaryData}
                    />
                  </div>
                ) : (
                  <div className={`table-container col-count-${secondaryColumns.length}`}>
                    <VerticalTable
                      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
                      columns={secondaryColumns}
                      data={secondaryData}
                      canSearchEntries={false}
                      dropdownPages={[3, 5, 10, 20]}
                      defaultPageSize={3}
                    />
                  </div>
                ))}
            </div>
          </section>
        )}
      </>
    );
  };

  const fullModalContent = () => {
    return (
      <div className="detail-view-modal">
        <div className="detail-view-header">{detailViewHeader()}</div>
        <div className="detail-view-content">{detailViewContent()}</div>
      </div>
    );
  };

  return (
    <Modal
      isOpen
      additionalClassNames={additionalTableLayer || hasSecondSection ? 'wide-modal' : ''}
      hasButtons={true}
      content={fullModalContent()}
      modalHasClosed={onClose}
    />
  );
}

export { DetailView };
