import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/tokenContext';
import { QualificationFormData } from '../../modules/renting/qualifications/create-qualification/QualificationForm';
import { EditRequest, Qualification } from '../../types/types';

const QUALIFICATIONS_URL = '/qualifications';

function useQualifications() {
  const client = useClient<Qualification[]>();
  const result = useQuery('qualifications', () => client(QUALIFICATIONS_URL));
  return { ...result, qualifications: result.data?.data ?? [] };
}

function useCreateQualification() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<QualificationFormData>) =>
      client(`${QUALIFICATIONS_URL}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('qualifications');
      },
    }
  );
}

function useEditQualification() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: EditRequest) =>
      client(QUALIFICATIONS_URL, {
        method: 'PATCH',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('qualifications');
      },
    }
  );
}

function useDeleteQualification(qualificationId: string) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`${QUALIFICATIONS_URL}/${qualificationId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('qualifications');
      },
    }
  );
}

export { useQualifications, useCreateQualification, useDeleteQualification, useEditQualification };
