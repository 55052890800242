import { useEffect, useRef, forwardRef } from 'react';

type Props = {
  indeterminate?: boolean;
  isHeaderCell?: boolean;
};

// eslint-disable-next-line react/display-name
export const IndeterminateCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ indeterminate, isHeaderCell = false, ...rest }, ref) => {
    const defaultRef = useRef<HTMLInputElement>();
    const resolvedRef = (ref || defaultRef) as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
      resolvedRef.current.indeterminate = !!indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className={`checkboxContainer ${isHeaderCell ? 'checkboxHeaderContainer' : ''}`}>
        <input type="checkbox" ref={resolvedRef} {...rest} className="checkbox" />
      </div>
    );
  }
);
