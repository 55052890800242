import { useIntl } from 'react-intl';

export const ErrorBoxLowerDescription = () => {
  const intl = useIntl();
  return (
    <>
      {intl.formatMessage({
        id: 'items.updateServices.lowerErrorDescription1',
      })}
      <ul className="errorBoxLowerDescription">
        <li>
          {intl.formatMessage({
            id: 'items.updateServices.lowerErrorDescription2',
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: 'items.updateServices.lowerErrorDescription3',
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: 'items.updateServices.lowerErrorDescription4',
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: 'items.updateServices.lowerErrorDescription5',
          })}
        </li>
      </ul>
      <div style={{ marginTop: 20 }}>
        {intl.formatMessage({
          id: 'items.updateServices.lowerErrorDescription6',
        })}
      </div>
    </>
  );
};
