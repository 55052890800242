import { useIntl } from 'react-intl';

import { useItemTypes } from '../../../api/hooks/useRentingApi';
import { ItemType } from '../../../types/item-types/ItemType';
import { FormDropDown } from '../../library/form/FormDropdown';
import { DropDownItem } from '../../library/inputs/DropDown';

interface ItemTypeDropDownProps {
  required?: boolean;
}
export function ItemTypeDropDown({ required }: ItemTypeDropDownProps) {
  const intl = useIntl();
  const { itemTypes } = useItemTypes();
  const itemTypeDropDownItems = getItemTypeDropDownItems(itemTypes);
  return (
    <FormDropDown
      id={'itemTypeDropDown'}
      label={intl.formatMessage({ id: 'item-types.singular' })}
      name={'itemTypeId'}
      dropDownItems={itemTypeDropDownItems}
      required={required}
    />
  );
}

function getItemTypeDropDownItems(itemTypes: ItemType[]): DropDownItem[] {
  const result = itemTypes.map(itemType => ({
    value: itemType._id,
    label: itemType.name,
  }));
  result.push({ value: '', label: '-' });
  return result;
}
