import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { CellProps } from 'react-table';

import { DeleteButton } from '../../components/table/table-buttons/DeleteButton';
import { EditButton } from '../../components/table/table-buttons/EditButton';
import { InfoButton } from '../../components/table/table-buttons/InfoButton';
import { TableButton } from '../../components/table/table-buttons/TableButtons';
import { VerticalTable } from '../../components/table/VerticalTable';
import { User, UserRole } from '../../types/types';
import { bindestrich } from '../../utils/bindestrich';

type SelectedUser = User | null | undefined;

interface UserTableProps {
  users: User[];
  isUsersBySystemTable: boolean;
  onSelectUserForDetailView?: Dispatch<SetStateAction<SelectedUser>>;
  onSelectUserForPatchView?: Dispatch<SetStateAction<SelectedUser>>;
  onSelectedUserForDeletion?: Dispatch<SetStateAction<SelectedUser>>;
}

function UsersTable({
  users,
  onSelectedUserForDeletion,
  isUsersBySystemTable,
  onSelectUserForDetailView,
  onSelectUserForPatchView,
}: UserTableProps) {
  const intl = useIntl();

  const coreColumns = [
    {
      Header: intl.formatMessage({ id: 'general.firstName' }),

      Cell: ({ row: { original } }: CellProps<User>) => {
        return bindestrich(original.firstName);
      },
    },
    {
      Header: intl.formatMessage({ id: 'general.lastName' }),
      Cell: ({ row: { original } }: CellProps<User>) => {
        return bindestrich(original.lastName);
      },
    },
    {
      Header: intl.formatMessage({ id: 'general.email' }),
      accessor: 'email',
    },
  ];

  const userManagementSpecificColumns = [
    {
      id: 'action-button-group',
      Header: intl.formatMessage({ id: 'general.actions' }),
      Cell: ({ row: { original: user } }: CellProps<User>) => {
        if (!onSelectUserForDetailView) return <></>;
        if (!onSelectUserForPatchView) return <></>;
        return (
          <TableButton>
            <InfoButton onClick={() => onSelectUserForDetailView(user)} />
            <EditButton onClick={() => onSelectUserForPatchView(user)} />
            <DeleteButton
              onClick={e => {
                e.stopPropagation();
                onSelectedUserForDeletion?.(user);
              }}
            />
          </TableButton>
        );
      },
    },
  ];

  const userBySystemSpecificColumns = [
    {
      Header: intl.formatMessage({ id: 'users.role' }),
      Cell: ({ row: { original } }: CellProps<User>) => {
        return original.role === UserRole.ADMIN ? 'Admin' : 'Read Only';
      },
    },
    {
      Header: intl.formatMessage({ id: 'general.actions' }),
      id: 'actions',
      Cell: ({ row }: CellProps<User>) => {
        const user = row.original;
        return (
          <DeleteButton
            onClick={e => {
              e.stopPropagation();
              onSelectedUserForDeletion?.(user);
            }}
          />
        );
      },
    },
  ];

  const columns = isUsersBySystemTable
    ? [...coreColumns, ...userBySystemSpecificColumns]
    : [...coreColumns, ...userManagementSpecificColumns];

  const sortBy = [
    {
      id: 'name',
      desc: false,
    },
  ];

  return (
    <VerticalTable
      sortBy={sortBy}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      columns={columns}
      data={users}
    />
  );
}

export { UsersTable };
