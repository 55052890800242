import { useIntl } from 'react-intl';

import { FormTextField } from '../../../library/form/FormTextField';

export function RenterEmailInput() {
  const intl = useIntl();
  return (
    <FormTextField
      id={'RenterEmailInput'}
      label={intl.formatMessage({ id: 'general.email' })}
      required={false}
      disabled={false}
      name={'email'}
      type={'email'}
    />
  );
}
