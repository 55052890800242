import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useQualifications,
  useCreateQualification,
  useDeleteQualification,
  useEditQualification,
} from '../../../api/hooks/useQualificationApi';
import { RequestErrorResponse } from '../../../components/modal/ErrorModal';
import { TablePageLayout } from '../../../components/table/TablePageLayout';
import { Qualification } from '../../../types/types';
import { generateEditRequest, validateErrorStructure } from '../../../utils/formData';

import { DraftQualificationModal } from './create-qualification/DraftQualificationModal';
import { QualificationFormData } from './create-qualification/QualificationForm';
import { QualificationsTable } from './QualificationsTable';

function QualificationsPage() {
  const intl = useIntl();
  type errorType = RequestErrorResponse | string | null;

  const [isCreatingQualification, toggleIsCreatingQualification] = useState<boolean>(false);
  const [selectedQualificationForDeletion, setSelectedQualificationForDeletion] = useState<
    Qualification | undefined
  >();
  const [selectQualificationsForEditing, setSelectQualificationsForEditing] = useState<
    Qualification | undefined
  >();
  const [createQualificationsError, setCreateQualificationError] = useState<errorType>();
  const [editQualificationError, setEditQualificationError] = useState<errorType>();
  const [deleteQualificationsError, setDeleteQualificationError] = useState<errorType>();

  const {
    isLoading,
    isError: isGetQualificationsError,
    error: getQualificationsError,
    qualifications,
    remove,
  } = useQualifications();

  const {
    mutateAsync: deleteQualification,
    isError: isDeleteQualificationError,
    error: deleteQualificationErrorResponse,
  } = useDeleteQualification(selectedQualificationForDeletion?._id ?? '');
  const {
    mutateAsync: createQualification,
    isError: isCreateQualificationError,
    error: createQualificationError,
    reset: createQualificationReset,
  } = useCreateQualification();

  const {
    mutateAsync: editQualification,
    isError: isEditQualificationError,
    error: editQualificationErrorResponse,
    reset: resetEditQualification,
  } = useEditQualification();

  const onSubmitQualificationForm = (data: QualificationFormData | Record<string, any>) => {
    if (isCreatingQualification) onSubmitCreateQualification(data);
    if (selectQualificationsForEditing) onSubmitEditQualification(data);
  };

  const onSubmitCreateQualification = async (data: QualificationFormData | Record<string, any>) => {
    createQualification(data).finally(() => {
      toggleIsCreatingQualification(false);
    });
  };

  const onSubmitEditQualification = async (data: QualificationFormData | Record<string, any>) => {
    const editRequest = generateEditRequest(data);

    if (editRequest) {
      editQualification(editRequest).finally(() => {
        setSelectQualificationsForEditing(undefined);
      });
    } else {
      setEditQualificationError(intl.formatMessage({ id: 'qualifications.edit.error' }));
      setSelectQualificationsForEditing(undefined);
    }
  };

  const onConfirmDeleteQualifications = () => {
    deleteQualification().finally(() => {
      setSelectedQualificationForDeletion(undefined);
    });
  };

  const cleanAllErrors = () => {
    createQualificationReset();
    setDeleteQualificationError(null);
    resetEditQualification();
    setEditQualificationError(undefined);
  };

  const checkQualificationCreateError = (createError: RequestErrorResponse) => {
    const createErrorStatus = createError.response.status;
    const createErrorCode = createError.response.data.codes[0];

    if (createErrorStatus === 409 && createErrorCode === '0210') {
      setCreateQualificationError(
        intl.formatMessage({
          id: 'qualifications.create.BAD_REQUEST_DUPLICATE_QUALIFICATION_NAME',
        })
      );

      return;
    }
    setDeleteQualificationError(createError);
  };

  const checkQualificationEditError = (editError: any) => {
    if (
      validateErrorStructure(editError.response.data) &&
      editError.response.status === 400 &&
      editError.response.data.code === 'BAD_REQUEST_DUPLICATE_QUALIFICATION_NAME'
    ) {
      setEditQualificationError(
        intl.formatMessage({
          id: 'qualifications.create.BAD_REQUEST_DUPLICATE_QUALIFICATION_NAME',
        })
      );
    }
    return [intl.formatMessage({ id: 'qualifications.edit.error' })];
  };

  const checkQualificationDeleteError = (deleteError: RequestErrorResponse) => {
    const deleteErrorStatus = deleteError.response.status;
    if (deleteErrorStatus === 400) {
      setDeleteQualificationError(
        intl.formatMessage(
          { id: 'qualifications.delete.stillRequiredError' },
          {
            qualificationName: `${selectedQualificationForDeletion?.name}`,
          }
        )
      );
      return;
    }
    setDeleteQualificationError(deleteError);
  };

  useEffect(() => {
    if (isCreateQualificationError) {
      checkQualificationCreateError(createQualificationError as RequestErrorResponse);
    }
  }, [isCreateQualificationError]);

  useEffect(() => {
    if (isDeleteQualificationError) {
      checkQualificationDeleteError(deleteQualificationErrorResponse as RequestErrorResponse);
    }
  }, [isDeleteQualificationError]);

  useEffect(() => {
    if (isEditQualificationError) {
      checkQualificationEditError(editQualificationErrorResponse as RequestErrorResponse);
    }
  }, [isEditQualificationError]);

  useEffect(() => {
    if (isDeleteQualificationError) {
      checkQualificationDeleteError(deleteQualificationErrorResponse as RequestErrorResponse);
    }
  }, [isDeleteQualificationError]);

  return (
    <TablePageLayout
      isContentLoading={isLoading}
      isContentError={isGetQualificationsError}
      contentError={getQualificationsError}
      pageName={intl.formatMessage({ id: 'qualifications.self' })}
      pageNameSingular={intl.formatMessage({
        id: 'qualifications.singular',
      })}
      table={
        <QualificationsTable
          qualifications={qualifications}
          onSelectQualificationForDeletion={setSelectedQualificationForDeletion}
          onSelectQualificationForEditing={setSelectQualificationsForEditing}
        />
      }
      hasDraftModal={true}
      openCreateModal={() => toggleIsCreatingQualification(true)}
      isDraftModalOpen={isCreatingQualification || !!selectQualificationsForEditing}
      draftModal={
        <DraftQualificationModal
          qualification={selectQualificationsForEditing}
          onClose={() => {
            toggleIsCreatingQualification(false);
            setSelectQualificationsForEditing(undefined);
          }}
          onSubmit={onSubmitQualificationForm}
        />
      }
      isCreateModalError={!!createQualificationsError}
      isDeleteModalError={!!deleteQualificationsError}
      isEditModalError={!!editQualificationError}
      editModalError={editQualificationError}
      deleteModalError={deleteQualificationsError}
      createModalError={createQualificationsError}
      cleanup={remove}
      cleanupErrors={cleanAllErrors}
      isDeleteModalOpen={!!selectedQualificationForDeletion}
      onConfirmDelete={onConfirmDeleteQualifications}
      onCloseDelete={setSelectedQualificationForDeletion}
      confirmDeleteMessage={
        <p>
          {intl.formatMessage(
            { id: 'qualifications.delete.confirmation' },
            {
              qualificationName: selectedQualificationForDeletion?.name,
            }
          )}
        </p>
      }
    />
  );
}
export { QualificationsPage };
