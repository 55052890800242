import { Button } from '@mui/material';

import Icon from '../icons/Icon';

interface AddIconButtonProps {
  id: string;
  onClick: () => void;
  disabled?: boolean;
}
export function MultiDeleteIconButton({ id, onClick, disabled }: AddIconButtonProps) {
  return (
    <Button id={id} variant="contained" onClick={onClick} color={'secondary'} disabled={disabled}>
      <Icon iconName={'delete'} />
    </Button>
  );
}
