import { useIntl } from 'react-intl';

import { FormTextField } from '../../../library/form/FormTextField';

export function SystemSiteInput() {
  const intl = useIntl();
  return (
    <FormTextField
      id={'SystemSiteInput'}
      label={intl.formatMessage({ id: 'systems.site' })}
      required={true}
      disabled={false}
      name={'site'}
    />
  );
}
