import { useIntl } from 'react-intl';

import {
  ServiceForecastUiStateProvider,
  useServiceForecastUiStateContext,
} from '../../../../context/ServiceForecastUiStateContext';
import { OnClickReportCard } from '../OnClickReportCard';

export function ServiceForeCastCard() {
  const intl = useIntl();
  const { setOpenDialog } = useServiceForecastUiStateContext();
  return (
    <ServiceForecastUiStateProvider>
      <OnClickReportCard
        id={'btnServiceForecast'}
        name={intl.formatMessage({ id: 'reports.serviceForecast' })}
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        <i className="uib-icon uib-icon--configuration-view card-icon" />
      </OnClickReportCard>
    </ServiceForecastUiStateProvider>
  );
}
