import { useIntl } from 'react-intl';

import { Modal } from '../../../components/modal/Modal';
import { Station } from '../../../types/types';

import { StationForm } from './StationForm';

export interface DraftStationModalProps {
  station?: Station;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

function DraftStationModal({ station, onClose, onSubmit }: DraftStationModalProps) {
  const intl = useIntl();
  const isCreateModal = !station;
  const header = (
    <h1 className="uib-modal__title">
      {intl.formatMessage({
        id: `stations.${isCreateModal ? 'create' : 'edit'}`,
      })}
    </h1>
  );

  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      content={<StationForm station={station} onCancel={onClose} onSubmit={onSubmit} />}
      modalHasClosed={onClose}
    />
  );
}

export { DraftStationModal };
