import { useIntl } from 'react-intl';

export const BusinessReportFormLegend = () => {
  const intl = useIntl();
  return (
    <div className="form-legend">
      <span>
        {intl.formatMessage({
          id: 'reports.legendTitle',
        })}
      </span>
      <ul>
        <li>
          <b>
            {intl.formatMessage({
              id: 'reports.legendTitle.closedRents',
            })}
          </b>
          <ul>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescription.closedRents',
              })}
            </li>
          </ul>
        </li>
        <li>
          <b>
            {intl.formatMessage({
              id: 'reports.legendTitle.openRents',
            })}
          </b>
          <ul>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescription.openRents',
              })}
            </li>
          </ul>
        </li>
        <li>
          <b>
            {intl.formatMessage({
              id: 'reports.legendTitle.rentingFrequency',
            })}
          </b>
          <ul>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescriptionA.rentingFrequency',
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescriptionB.rentingFrequency',
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescriptionC.rentingFrequency',
              })}
            </li>
          </ul>
        </li>
        <li>
          <b>
            {intl.formatMessage({
              id: 'reports.legendTitle.usageSumPerDay',
            })}
          </b>
          <ul>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescriptionA.usageSumPerDay',
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescriptionB.usageSumPerDay',
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescriptionC.usageSumPerDay',
              })}
            </li>
          </ul>
        </li>
        <li>
          <b>
            {intl.formatMessage({
              id: 'reports.legendTitle.stockHistory',
            })}
          </b>
          <ul>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescriptionA.stockHistory',
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescriptionB.stockHistory',
              })}
            </li>
          </ul>
        </li>
        <li>
          <b>
            {intl.formatMessage({
              id: 'reports.legendTitle.usagePercentagePerDay',
            })}
          </b>
          <ul>
            <li>
              {intl.formatMessage({
                id: 'reports.legendDescription.usagePercentagePerDay',
              })}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
