import { IntlShape, useIntl } from 'react-intl';

import { AlertRuleUserInputUnit } from '../../../../types/alert-rules/AlertRuleUserInputUnit';
import { FormNumberAndUnitInput } from '../../../library/form/FormNumberAndUnitInput';
import { DropDownItem } from '../../../library/inputs/DropDown';

export function AlertRuleThresholdInput() {
  const intl = useIntl();
  const thresholdPlaceholder = intl.formatMessage({
    id: 'alert-rules.threshold',
  });
  return (
    <FormNumberAndUnitInput
      numberId={'alertRuleThresholdInput'}
      numberName={'threshold'}
      numberPlaceHolder={thresholdPlaceholder}
      unitId={'alertRuleUnitInput'}
      unitName={'unit'}
      unitDropDownItems={alertTypeUnitDrowDownItems(intl)}
      required={true}
    />
  );
}
function alertTypeUnitDrowDownItems(intl: IntlShape): DropDownItem[] {
  return [
    AlertRuleUserInputUnit.Days,
    AlertRuleUserInputUnit.Hours,
    AlertRuleUserInputUnit.Minutes,
  ].map(unit => ({
    value: unit.toString(),
    label: intl.formatMessage({ id: `alert-rules.unit-${unit.toString()}` }),
  }));
}
