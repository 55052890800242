import update from 'immutability-helper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useIntl } from 'react-intl';
import { Column, useTable, useFilters } from 'react-table';

import { DragItem } from './DragItem';

export interface DragAndDropTableProps<T extends { [key: string]: unknown }> {
  columns: Column<{ [key: string]: unknown }>[];
  data: T[];
  canHighlightSelectedRow: boolean;
  canSearchEntries?: boolean;
  selectedRowId?: string;
  onClickRow?: (row: { [key: string]: unknown }) => void;
  onItemDropped: (newPosition: number, row: { [key: string]: unknown }) => void;
}

interface DefaultColumnFilterProps {
  column: {
    filterValue: string;
    preFilteredRows: string[];
    setFilter: (event: string | undefined) => void;
  };
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: DefaultColumnFilterProps) {
  const intl = useIntl();
  const count = preFilteredRows.length;

  return (
    <>
      <span className="uib-search__icon" />
      <input
        className="uib-search__input"
        type="search"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={intl.formatMessage({ id: 'components.table.searchEntries' }, { count })}
      />
    </>
  );
}

function DragAndDropTable<T extends Record<string, unknown>>({
  columns,
  data,
  canHighlightSelectedRow = true,
  canSearchEntries = true,
  selectedRowId,
  onClickRow,
  onItemDropped,
}: DragAndDropTableProps<T>) {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const memoizedData = useMemo(() => tableData, [tableData]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  const textFilter = (
    rows: { values: { [key: string]: string } }[],
    id: string,
    filterValue: string
  ) => {
    return rows.filter(row => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
        : true;
    });
  };

  const filterTypes = useMemo(
    () => ({
      text: textFilter,
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, rows, state, prepareRow, headerGroups } = useTable(
    {
      data: memoizedData,
      columns: memoizedColumns,
      defaultColumn,
      filterTypes,
    },
    useFilters
  );

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRecord = tableData[dragIndex];
      setTableData(
        update(tableData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
          ],
        })
      );
    },
    [tableData, setTableData]
  );

  const isRowSelected = (row: any) => {
    return canHighlightSelectedRow && row.original._id && row.original._id === selectedRowId;
  };

  return (
    <div className="drag-and-drop-table">
      <DndProvider backend={HTML5Backend}>
        <table className="uib-table uib-table--plain" {...getTableProps()}>
          <thead className="uib-table__header">
            {headerGroups.map((headerGroup, i) => (
              <tr
                className="uib-table__row uib-table__row--header"
                {...headerGroup.getHeaderGroupProps()}
                key={i}
              >
                <th className="drag-column-header" />
                {headerGroup.headers.map((column, j) => (
                  <th {...column.getHeaderProps()} key={j}>
                    <div className="header-text">{<pre>{column.render('Header')}</pre>}</div>
                    {canSearchEntries && (
                      <div className="uib-search">
                        {column.canFilter && column.render('Filter')}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="uib-table__body" {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <DragItem
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  key={row.id}
                  isSelected={isRowSelected(row)}
                  onRowClick={() => onClickRow?.(row.original)}
                  onItemDropped={index => onItemDropped?.(index, row.original)}
                  isFiltering={state.filters.length > 0}
                />
              );
            })}
          </tbody>
        </table>
      </DndProvider>
    </div>
  );
}

export { DragAndDropTable };
