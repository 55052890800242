import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface FormSubmitButtonProps {
  id?: string;
}

export function FormSubmitButton({ id = 'formSubmitBtn' }: FormSubmitButtonProps) {
  const intl = useIntl();
  const {
    formState: { isSubmitting, isDirty, isValid },
  } = useFormContext();
  return (
    <Button
      variant={'contained'}
      type="submit"
      id={id}
      disabled={isSubmitting || !isDirty || !isValid}
    >
      {intl.formatMessage({ id: 'general.save' })}
    </Button>
  );
}
