import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ButtonVariant } from '../../types/components/Button';
import { XtraHeaderButtonObject } from '../../types/types';
import Icon from '../library/icons/Icon';
import { LoadingIndicator } from '../library/loading/LoadingIndicator';
import { DeleteModal } from '../modal/DeleteModal';
import { ErrorBoxModal } from '../modal/ErrorBoxModal';
import { ErrorModal, RequestErrorResponse } from '../modal/ErrorModal';
import Button from '../odx/Button';

import { TablePageHeader } from './TablePageHeader';
interface TablePageLayoutProps {
  isContentLoading?: boolean;
  isContentError: boolean;
  contentError?: unknown;
  permissionsErrorCode?: number;
  pageName: string;
  pageNameSingular: string;
  pageNavigationLink?: ReactNode;
  table: ReactNode;
  hasDraftModal?: boolean;
  hasHeaderButton?: boolean;
  xtraHeaderButtons?: XtraHeaderButtonObject[];
  isHeaderButtonDisabled?: boolean;
  openCreateModal?: () => void;
  isDraftModalOpen?: boolean;
  draftModal?: ReactNode;
  isCreateModalError?: boolean;
  createModalError?: unknown;
  isEditModalError?: boolean;
  editModalError?: unknown;
  isDetailViewOpen?: boolean;
  detailView?: ReactNode;
  confirmDeleteMessage?: ReactNode;
  isDeleteModalOpen?: boolean;
  onConfirmDelete?: () => void;
  onCloseDelete?: Dispatch<SetStateAction<any>>;
  isDeleteModalError?: boolean;
  deleteModalAdditionalMessage?: string;
  deleteModalError?: unknown;
  errorBoxDescription?: string;
  isErrorBoxLowerDescription?: boolean;
  errorModalBoxContent?: string[];
  children?: ReactNode;
  cleanup: () => void;
  cleanupErrors?: () => void;
}

function TablePageLayout({
  isContentLoading = false,
  isContentError,
  contentError,
  permissionsErrorCode = 403,
  pageName,
  pageNameSingular,
  pageNavigationLink,
  table,
  hasDraftModal = false,
  hasHeaderButton = true,
  isHeaderButtonDisabled = false,
  xtraHeaderButtons,
  openCreateModal,
  isDraftModalOpen,
  draftModal,
  isCreateModalError,
  createModalError,
  isEditModalError,
  editModalError,
  isDetailViewOpen = false,
  detailView,
  confirmDeleteMessage,
  isDeleteModalOpen,
  deleteModalAdditionalMessage,
  onConfirmDelete,
  onCloseDelete,
  isDeleteModalError,
  deleteModalError,
  errorBoxDescription,
  isErrorBoxLowerDescription,
  errorModalBoxContent,
  children,
  cleanup,
  cleanupErrors,
}: TablePageLayoutProps) {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const isPermissionError = (error: RequestErrorResponse) => {
    if (error === undefined || error === null) return false;
    const {
      response: { status },
    } = error;
    return status === permissionsErrorCode;
  };
  const closeModal = () => {
    setIsModalOpen(false);
    if (cleanupErrors) cleanupErrors();
  };
  const generateErrorProps = (): {
    error: string | RequestErrorResponse | undefined;
    dismissable?: boolean;
  } => {
    if (isContentError || isCreateModalError || isEditModalError || isDeleteModalError) {
      const error = isContentError
        ? contentError
        : isCreateModalError
          ? createModalError
          : isEditModalError
            ? editModalError
            : isDeleteModalError
              ? deleteModalError
              : null;
      if (typeof error === 'string') {
        return {
          error: error,
        };
      } else if (isContentError && isPermissionError(error as RequestErrorResponse)) {
        return {
          error: intl.formatMessage({ id: 'general.permissionsError' }),
          dismissable: false,
        };
      } else if (isPermissionError(error as RequestErrorResponse)) {
        return {
          error: intl.formatMessage({ id: 'general.permissionsError' }),
        };
      } else if (isCreateModalError || isEditModalError || isDeleteModalError) {
        return {
          error: error as RequestErrorResponse,
        };
      } else {
        return {
          error: intl.formatMessage({ id: 'general.error' }),
        };
      }
    }
    return {
      error: undefined,
    };
  };

  const errorModalProps = useMemo(generateErrorProps, [
    isContentError,
    isCreateModalError,
    isDeleteModalError,
    isEditModalError,
  ]);

  useEffect(() => {
    return () => {
      if (errorModalProps.error === undefined) {
        setIsModalOpen(true);
      }
    };
  }, [errorModalProps]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  if (isContentLoading) return <LoadingIndicator />;

  return (
    <>
      <div className="uib-board__box uib-board__box__full-width draeger_header">
        <div className="uib-card">
          <div className="uib-card__header">
            <TablePageHeader>
              {pageNavigationLink}
              {pageName}
            </TablePageHeader>
            {hasHeaderButton && (
              <div className="header-button-container">
                {hasDraftModal && (
                  <Button
                    id={'tableHeaderAddButton'}
                    variant={
                      isHeaderButtonDisabled ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY
                    }
                    onClick={openCreateModal}
                    disabled={isHeaderButtonDisabled}
                  >
                    <Icon iconName={'plus'} id={'addButton'} />
                  </Button>
                )}
                {xtraHeaderButtons &&
                  xtraHeaderButtons.map(xtraButton => {
                    return (
                      <Button
                        id={'tableHeader' + xtraButton.iconName + 'Button'}
                        variant={
                          xtraButton.isDisabled ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY
                        }
                        key={xtraButton.iconName}
                        onClick={xtraButton.onClick}
                        disabled={xtraButton.isDisabled}
                      >
                        <Icon
                          iconName={xtraButton.iconName}
                          additionalClasses={xtraButton.additionalClassName}
                        />
                      </Button>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="uib-board__box">
        {table}
        <div className="sub-table-container">{children}</div>
      </div>
      {isDraftModalOpen && draftModal}
      {isDetailViewOpen && detailView}
      {isDeleteModalOpen && onConfirmDelete && onCloseDelete && (
        <DeleteModal
          entry={pageNameSingular}
          additionalMessage={deleteModalAdditionalMessage}
          content={confirmDeleteMessage}
          onConfirm={onConfirmDelete}
          onClose={() => onCloseDelete(undefined)}
        />
      )}
      {errorModalBoxContent && errorBoxDescription && (
        <ErrorBoxModal
          isOpen={true}
          errorDescription={errorBoxDescription}
          content={errorModalBoxContent}
          onClose={closeModal}
          isErrorBoxLowerDescription={isErrorBoxLowerDescription}
        />
      )}
      <ErrorModal
        onClose={closeModal}
        isOpen={errorModalProps.error !== undefined && isModalOpen}
        error={errorModalProps.error}
        dismissable={errorModalProps?.dismissable}
      />
    </>
  );
}

export { TablePageLayout };
