import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useItemTypes } from '../../api/hooks/useRentingApi';
import { Notification } from '../../components/common/notification/Notification';
import { PageHeader } from '../../components/common/PageHeader';
import { useCreateItemSetTemplate } from '../../integrations/itemSetTemplate/useItemSetTemplate';
import { ApiError } from '../../types/types';

function CreateItemSetTemplatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [values, setValues] = useState({ name: '', shortName: '', lockerSize: '' });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setIsFormValid(
      Object.values(values).every(x => x !== null && x !== '') && selectedRows.length >= 2
    );
  }, [values, selectedRows]);

  const handleChange = (event: any) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    const itemTypeIds = selectedRows.map(row => row._id);
    const data = { ...values, itemTypeIds };
    createItemSetTemplate(data, {
      onSuccess: () => {
        resetCreateItemSetTemplate();
        navigate('/item-set-templates');
        setIsSubmitting(false);
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          const errorData = error.response.data as ApiError;
          setErrorCode(errorData.code);
          setShowErrorSnackbar(true);
          setIsSubmitting(false);
        }
        setIsSubmitting(false);
      },
    });
  };

  const { itemTypes, isLoading } = useItemTypes();

  useEffect(() => {
    if (itemTypes) {
      setLoading(isLoading);
    }
  }, [itemTypes]);

  const { mutateAsync: createItemSetTemplate, reset: resetCreateItemSetTemplate } =
    useCreateItemSetTemplate();

  const handleRowClick = (param: GridRowParams) => {
    setSelectedRows(prevState => [...prevState, param.row]);
  };

  const handleDeleteClick = (index: number) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows.splice(index, 1);
    setSelectedRows(newSelectedRows);
  };

  const columns: GridColDef[] = [
    {
      field: 'imageUrl',
      headerName: intl.formatMessage({ id: 'item-set-template.image' }),
      renderCell: (params: GridRenderCellParams) => (
        <img src={params.value as string} style={{ height: '50px' }} alt="Item" />
      ),
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'item-types.itemTypeName' }),
      editable: false,
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'lockerFeature',
      headerName: intl.formatMessage({ id: 'lockers.lockerFeature' }),
      editable: false,
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'lockerSize',
      headerName: intl.formatMessage({ id: 'lockers.lockerSize' }),
      editable: false,
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
  ];

  return (
    <>
      <PageHeader
        breadCrumbs={[
          {
            label: intl.formatMessage({ id: 'systems.self' }),
            route: '#',
          },
          {
            label: intl.formatMessage({ id: 'item-set-template.self' }),
            route: '#/item-set-templates',
          },
          {
            label: intl.formatMessage({ id: 'item-set-template.create' }),
            route: '#/item-set-templates/create',
          },
        ]}
      />
      <Box sx={{ bgcolor: 'white', display: 'flex', width: '100%', p: '20px' }}>
        <DataGridPremium
          loading={loading}
          autoHeight={true}
          getRowId={row => row._id}
          sx={{ bgcolor: 'white', width: '50%' }}
          rows={itemTypes}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          disableDensitySelector
          disableColumnSelector
          disableColumnFilter
          pageSizeOptions={[10, 20, 50]}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
        />
        <Box sx={{ width: '50%', bgcolor: 'white', p: '20px', pt: '0px' }}>
          <Typography variant="h5" gutterBottom>
            {intl.formatMessage({ id: 'item-set-template.create' })}
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              id={'name'}
              label={intl.formatMessage({ id: 'item-set-template.name' })}
              value={values.name}
              onChange={handleChange}
              required
              disabled={isSubmitting}
              autoComplete={'new-password'}
              fullWidth={true}
            />

            <TextField
              name="shortName"
              id={'shortName'}
              label={intl.formatMessage({ id: 'item-set-template.shortName' })}
              value={values.shortName}
              onChange={handleChange}
              required
              disabled={isSubmitting}
              autoComplete={'new-password'}
              fullWidth={true}
              sx={{ my: 2 }}
            />

            <FormControl fullWidth={true} required>
              <InputLabel id="lockerSize-label" sx={{ bgcolor: 'white' }}>
                {intl.formatMessage({ id: 'lockers.lockerSize' })}
              </InputLabel>
              <Select
                fullWidth={true}
                labelId="lockerSize-label"
                name="lockerSize"
                id={'lockerSizeSelect'}
                value={values.lockerSize}
                disabled={isSubmitting}
                onChange={handleChange}
              >
                <MenuItem value="S">S</MenuItem>
                <MenuItem value="M">M</MenuItem>
                <MenuItem value="L">L</MenuItem>
                <MenuItem value="XL">XL</MenuItem>
              </Select>
            </FormControl>

            <Box display={'flex'} sx={{ mt: 2 }} justifyContent={'space-between'}>
              <Button
                variant={'contained'}
                color={'secondary'}
                id={'cancelCreateItemSetTemplateBtn'}
                onClick={() => navigate('/item-set-templates')}
              >
                {intl.formatMessage({ id: 'general.cancel' })}
              </Button>

              <Button
                variant={'contained'}
                type="submit"
                id={'createItemSetTemplateBtn'}
                disabled={!isFormValid && !isSubmitting}
              >
                {intl.formatMessage({ id: 'general.save' })}
              </Button>
            </Box>
          </form>

          <Notification
            showNotification={showErrorSnackbar}
            setShowNotification={setShowErrorSnackbar}
            message={errorCode || 'general.errorMessage'}
          />

          <TableContainer sx={{ mt: 8 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{intl.formatMessage({ id: 'item-set-template.image' })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: 'item-set-template.name' })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: 'item-set-template.actions' })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRows.length ? (
                  selectedRows.map((row, index) => (
                    <TableRow key={row._id + index}>
                      <TableCell>
                        <img src={row.imageUrl} style={{ height: '50px' }} alt="Item" />
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeleteClick(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography variant="body1" align="center">
                        {intl.formatMessage({
                          id: 'item-set-template.noItemTypeSelected',
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}

export { CreateItemSetTemplatePage };
