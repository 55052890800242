import debounceFn from 'debounce-fn';
import { saveAs } from 'file-saver';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useCreateItem,
  useDeleteItem,
  useEditItem,
  useItems,
  useServiceItems,
  useUploadItems,
  useUploadTemplate,
  useDeleteMultiItem,
} from '../../../api/hooks/useItemApi';
import { useItemTypes } from '../../../api/hooks/useRentingApi';
import { useOpenLocker } from '../../../api/hooks/useStationApi';
import { handleDeleteError } from '../../../components/form/HandleDeleteErrors';
import { handleUploadError } from '../../../components/form/HandleUploadErrors';
import { UploadFormData } from '../../../components/form/UploadForm';
import { Modal } from '../../../components/modal/Modal';
import { UploadModal } from '../../../components/modal/UploadModal';
import { TablePageLayout } from '../../../components/table/TablePageLayout';
import { useRequestHeaders } from '../../../hooks/useRequestHeaders';
import { ItemType } from '../../../types/item-types/ItemType';
import {
  EntitiesToUpload,
  Item,
  OptionItem,
  Status,
  ServiceItemsFormResponseType,
} from '../../../types/types';
import {
  cleanFormData,
  generateEditRequest,
  validateErrorStructure,
} from '../../../utils/formData';

import { ItemDetailView } from './ItemDetailView';
import { ItemsTable } from './ItemsTable';
import { DraftItemModal } from './manage/DraftItemModal';
import { ItemFormData } from './manage/ItemForm';
import { ServiceItemsFormData } from './service-items/ServiceItemsForm';
import { ServiceItemsModal } from './service-items/ServiceItemsModal';

function itemTypeToOptionItem(itemType: ItemType): OptionItem {
  return {
    value: itemType._id,
    label: itemType.name,
  };
}

function ItemsPage() {
  const intl = useIntl();

  const [isCreatingItem, toggleIsCreatingItem] = useState<boolean>(false);
  const [createItemError, setCreateItemError] = useState<unknown>(undefined);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean | null>();
  const [uploadDropdownDefaultValue, setUploadDropdownDefaultValue] = useState<string>();
  const [selectedItemForEditing, setSelectedItemForEditing] = useState<Item | undefined>();
  const [editItemError, setEditItemError] = useState<unknown>(undefined);
  const [selectedItemForDetailView, setSelectedItemForDetailView] = useState<Item | undefined>();
  const [selectedItemForDeletion, setSelectedItemForDeletion] = useState<Item | undefined>(
    undefined
  );
  const [selectedItemsForMultiDelete, setSelectedItemsForMultiDelete] = useState<string[]>([]);
  const [isMultiDeleteModalOpen, setIsMultiDeleteModalOpen] = useState<boolean | undefined>(
    undefined
  );
  const [selectedItemTypeForUpload, setSelectedItemTypeForUpload] = useState<string | undefined>(
    undefined
  );
  const [isServiceItemsModalOpen, toggleServiceItemsModal] = useState<boolean>(false);
  const [itemsEIdsNotUpdated, setItemEIdsNotUpdated] = useState<string[] | undefined>(undefined);

  const formatSelectedItemsForMultiDelete = () => ({
    itemIds: selectedItemsForMultiDelete,
  });

  const isItemRented =
    selectedItemForDeletion?.renter !== null && selectedItemForDeletion?.status === Status.RENTED;

  const { isLoading, isError: isItemsError, error: getItemsError, items, remove } = useItems();
  const {
    mutateAsync: createItem,
    isError: isCreateItemError,
    error: createItemErrorResponse,
    reset: resetCreateItem,
  } = useCreateItem();
  const {
    headers,
    isSuccess: isTemplateSuccess,
    refetch: getUploadTemplate,
    uploadTemplate,
    remove: removeUploadTemplate,
  } = useUploadTemplate(selectedItemTypeForUpload);
  const {
    mutateAsync: uploadItems,
    data: uploadItemsData,
    reset: resetUploadItems,
    status: uploadItemsStatus,
    isError: isUploadFailed,
    error: uploadError,
  } = useUploadItems();
  const {
    mutateAsync: editItem,
    isError: isEditItemError,
    error: editItemErrorResponse,
    reset: resetEditItem,
  } = useEditItem();
  const {
    mutateAsync: deleteItem,
    isError: isDeleteItemError,
    error: deleteItemError,
    reset: resetDeleteItem,
  } = useDeleteItem(selectedItemForDeletion?._id ?? '');
  const {
    mutateAsync: serviceItems,
    isError: isServiceItemsError,
    error: getServiceItemsError,
    reset: resetServiceItems,
  } = useServiceItems();
  const {
    mutateAsync: deleteMultiItem,
    isError: isDeleteMultiItemError,
    error: deleteMultiItemErrorResponse,
    reset: resetDeleteMultiItem,
  } = useDeleteMultiItem();

  const { contentDispositionFileName } = useRequestHeaders();

  const onCloseDraftItemModal = () => {
    toggleIsCreatingItem(false);
    setSelectedItemForEditing(undefined);
  };
  const { itemTypes, isLoading: isLoadingItemTypes } = useItemTypes(!!isUploadModalOpen);

  const {
    mutate: mutateOpenLocker,
    isError: isOpenLockerError,
    isLoading: isLoadingFromUseOpenLocker,
    reset: resetOpenLocker,
  } = useOpenLocker();

  const checkDeleteMultiItemError = (multiDeleteError: any) => {
    if (validateErrorStructure(multiDeleteError.response.data)) {
      return handleDeleteError(multiDeleteError.response.data);
    }
    return [intl.formatMessage({ id: 'items.multiDeleteFailed.error.generic' })];
  };

  const errorBoxDescriptionContent = () => {
    if (isDeleteMultiItemError) {
      return intl.formatMessage({
        id: 'items.multiDeleteFailed',
      });
    } else {
      return intl.formatMessage({
        id: 'items.updateServices.errorDescription',
      });
    }
  };

  // We need dd/mm/yyyy format with leading zero's 01/01/2023
  const formatDateAddLeadingZero = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${month <= 9 ? '0' + month : month}/${day <= 9 ? '0' + day : day}/${year}`;
  };

  const formatServiceDates = (data: ItemFormData): ItemFormData => {
    data.lastServiceDates?.forEach(service => {
      if (service.lastServiceDate) {
        service.lastServiceDate = formatDateAddLeadingZero(new Date(service.lastServiceDate));
      }
    });

    if (data.lastGdcCalibrationDate)
      data.lastGdcCalibrationDate = formatDateAddLeadingZero(new Date(data.lastGdcCalibrationDate));

    return data;
  };

  const onSubmitItemForm = (data: ItemFormData, shouldFormModalStayOpen: boolean) => {
    const dataServiceDatesFormated = formatServiceDates(data);
    if (isCreatingItem) onSubmitCreateItem(dataServiceDatesFormated, shouldFormModalStayOpen);
    if (selectedItemForEditing) onSubmitEditItem(dataServiceDatesFormated);
  };

  const onSubmitCreateItem = async (data: ItemFormData, shouldFormModalStayOpen: boolean) => {
    const cleanedData = cleanFormData(data);
    createItem(cleanedData).finally(() => {
      if (!shouldFormModalStayOpen) toggleIsCreatingItem(false);
    });
  };

  const onSubmitEditItem = async (data: ItemFormData) => {
    const editRequest = generateEditRequest(data);
    if (editRequest) {
      editItem(editRequest).finally(() => {
        setSelectedItemForEditing(undefined);
      });
    } else {
      setEditItemError(intl.formatMessage({ id: 'items.edit.error' }));
      setSelectedItemForEditing(undefined);
    }
  };

  const onConfirmDeleteItem = () => {
    if (selectedItemForDeletion) {
      deleteItem().finally(() => setSelectedItemForDeletion(undefined));
    } else {
      deleteMultiItem(formatSelectedItemsForMultiDelete())
        .then(() => {
          setSelectedItemsForMultiDelete([]);
        })
        .finally(() => setIsMultiDeleteModalOpen(false));
    }
  };

  const onSubmitServiceItemsForm = async (data: ServiceItemsFormData) => {
    serviceItems(data)
      .then(response => {
        const responseData = response.data as ServiceItemsFormResponseType;

        if (responseData.itemEIdsNotUpdated) {
          setItemEIdsNotUpdated(Object.keys(responseData.itemEIdsNotUpdated));
        }
      })
      .finally(() => {
        toggleServiceItemsModal(false);
      });
  };

  const onCloseUploadItemsModal = () => {
    setUploadDropdownDefaultValue('');
    setIsUploadModalOpen(false);
    resetUploadItems();
  };

  const checkItemsUploadError = (uploadError: any) => {
    if (validateErrorStructure(uploadError.response.data)) {
      return handleUploadError(intl, uploadError.response.data);
    }
    return [intl.formatMessage({ id: 'renters.uploadDataFailed.error.generic' })];
  };

  const getUploadedItemsAmount = (uploadRentersResponse: any) => {
    return uploadRentersResponse?.data.itemCount;
  };

  const processSubmitData = (data: UploadFormData) => {
    setUploadDropdownDefaultValue(data.dropdownIdInput);
    const formData = new FormData();
    formData.append('file', data.File[0], data.File[0].name);
    uploadItems({ formData, itemTypeId: data.dropdownIdInput });
  };

  const checkItemsCreateError = (createError: any) => {
    const createErrorStatus = createError.response.status;
    const createErrorCode = createError.response.data.codes[0];

    if (createErrorStatus === 409) {
      if (createErrorCode === '0010' || createErrorCode === '0012') {
        setCreateItemError(intl.formatMessage({ id: 'rental-robot-systems.error.eId' }));
      } else if (createErrorCode === '0017') {
        setCreateItemError(
          intl.formatMessage({
            id: 'rental-robot-systems.error.draegerSerialNo',
          })
        );
      } else if (createErrorCode === '0013') {
        setCreateItemError(
          intl.formatMessage({
            id: 'rental-robot-systems.error.customId',
          })
        );
      }
    } else {
      setCreateItemError(createItemErrorResponse);
    }
  };

  useEffect(() => {
    if (isCreateItemError) {
      checkItemsCreateError(createItemErrorResponse);
    }
  }, [isCreateItemError]);

  const debouncedRefetch = useMemo(
    () => debounceFn(getUploadTemplate, { wait: 500 }),
    [getUploadTemplate]
  );

  useEffect(() => {
    if (uploadTemplate) {
      const fileName = contentDispositionFileName(headers);
      saveAs(new Blob([uploadTemplate]), fileName);
    }
    return () => {
      removeUploadTemplate();
    };
  }, [isTemplateSuccess]);

  const checkItemsEditError = (editError: any) => {
    const editErrorStatus = editError.response.status;
    const editErrorCode = editError.response.data.codes[0];

    if (editErrorStatus === 409) {
      if (editErrorCode === '0011') {
        setCreateItemError(
          intl.formatMessage({
            id: 'rental-robot-systems.error.eId',
          })
        );
      } else if (editErrorCode === '0016') {
        setEditItemError(
          intl.formatMessage({
            id: 'rental-robot-systems.error.eId.thisOrAnother',
          })
        );
      } else if (editErrorCode === '0017') {
        setEditItemError(
          intl.formatMessage({
            id: 'rental-robot-systems.error.draegerSerialNo.thisOrAnother',
          })
        );
      } else if (editErrorCode === '0018') {
        setCreateItemError(
          intl.formatMessage({
            id: 'rental-robot-systems.error.customId',
          })
        );
      }
    } else {
      setEditItemError(createItemErrorResponse);
    }
  };
  useEffect(() => {
    if (isEditItemError) {
      checkItemsEditError(editItemErrorResponse);
    }
  }, [isEditItemError]);

  const isContentError = isItemsError || isServiceItemsError || isOpenLockerError;
  const openLockerError = intl.formatMessage({ id: 'lockers.error.open' });
  const contentError = getItemsError || getServiceItemsError || openLockerError || null;

  const xtraHeaderButtons = [
    {
      onClick: () => setIsUploadModalOpen(true),
      iconName: 'upload',
      isDisabled: !!selectedItemsForMultiDelete.length,
    },
    {
      onClick: () => toggleServiceItemsModal(true),
      iconName: 'service',
      isDisabled: !!selectedItemsForMultiDelete.length,
    },
    {
      onClick: () => {
        setIsMultiDeleteModalOpen(true);
      },
      iconName: 'delete',
      isDisabled: !selectedItemsForMultiDelete.length,
    },
  ];

  return (
    <>
      <TablePageLayout
        isContentLoading={isLoading}
        isContentError={isContentError}
        contentError={contentError}
        pageName={intl.formatMessage({ id: 'items.self' })}
        pageNameSingular={intl.formatMessage({
          id: 'items.singular',
        })}
        table={
          <ItemsTable
            mutateOpenLocker={mutateOpenLocker}
            isOpenLockerLoading={isLoadingFromUseOpenLocker}
            items={items}
            onSelectItemForEditing={setSelectedItemForEditing}
            onSelectItemForDetailView={setSelectedItemForDetailView}
            onSelectItemForDeletion={setSelectedItemForDeletion}
            setSelectedItemsForMultiDelete={setSelectedItemsForMultiDelete}
            actionButtonsDisabled={!!selectedItemsForMultiDelete.length}
            isMultiDeleteModalOpen={isMultiDeleteModalOpen}
          />
        }
        hasDraftModal={true}
        openCreateModal={() => toggleIsCreatingItem(true)}
        isDraftModalOpen={isCreatingItem || !!selectedItemForEditing}
        draftModal={
          <DraftItemModal
            item={selectedItemForEditing}
            onClose={onCloseDraftItemModal}
            onSubmit={onSubmitItemForm}
          />
        }
        isCreateModalError={createItemError !== undefined}
        createModalError={createItemError}
        isEditModalError={editItemError !== undefined}
        editModalError={editItemError}
        isDeleteModalOpen={
          (Boolean(selectedItemForDeletion) && !selectedItemForDeletion?.station) ||
          isMultiDeleteModalOpen
        }
        onCloseDelete={
          isMultiDeleteModalOpen ? setIsMultiDeleteModalOpen : setSelectedItemForDeletion
        }
        onConfirmDelete={onConfirmDeleteItem}
        confirmDeleteMessage={
          isMultiDeleteModalOpen ? (
            <>
              <span>{intl.formatMessage({ id: 'items.multiDelete.confirm1' })}</span>
              <span style={{ fontWeight: 'bold' }}>
                {intl.formatMessage(
                  { id: 'items.multiDelete.confirm2' },
                  {
                    numberOfItems: selectedItemsForMultiDelete.length,
                  }
                )}
              </span>
              <span>{intl.formatMessage({ id: 'items.multiDelete.confirm3' })}</span>
            </>
          ) : (
            <p>
              {intl.formatMessage(
                {
                  id: `items.${isItemRented ? 'deleteWarning' : 'delete'}`,
                },
                {
                  itemTypeName: selectedItemForDeletion?.itemType.name,
                  itemEid: selectedItemForDeletion?.itemBarcode,
                }
              )}
            </p>
          )
        }
        errorBoxDescription={errorBoxDescriptionContent()}
        isErrorBoxLowerDescription={!!itemsEIdsNotUpdated}
        errorModalBoxContent={
          isDeleteMultiItemError
            ? checkDeleteMultiItemError(deleteMultiItemErrorResponse)
            : itemsEIdsNotUpdated
        }
        isDetailViewOpen={!!selectedItemForDetailView}
        detailView={
          selectedItemForDetailView && (
            <ItemDetailView
              itemId={selectedItemForDetailView._id}
              onClose={() => setSelectedItemForDetailView(undefined)}
            />
          )
        }
        isHeaderButtonDisabled={!!selectedItemsForMultiDelete.length}
        isDeleteModalError={isDeleteItemError}
        deleteModalError={deleteItemError}
        xtraHeaderButtons={xtraHeaderButtons}
        cleanup={remove}
        cleanupErrors={() => {
          resetCreateItem();
          resetEditItem();
          resetDeleteItem();
          resetUploadItems();
          setCreateItemError(undefined);
          setEditItemError(undefined);
          setItemEIdsNotUpdated(undefined);
          resetServiceItems();
          resetOpenLocker();
          resetDeleteMultiItem();
        }}
      ></TablePageLayout>
      {selectedItemForDeletion?.station && (
        <Modal
          isOpen={true}
          header={
            <h1 className="uib-modal__title">
              {intl.formatMessage({ id: 'items.deleteError.Header' })}
            </h1>
          }
          content={
            <div className="uib-modal__content">
              {intl.formatMessage({ id: 'items.deleteError.Content' })}
            </div>
          }
          closeButtonLabel={intl.formatMessage({ id: 'general.okay' })}
          modalHasClosed={() => setSelectedItemForDeletion(undefined)}
        />
      )}
      {isServiceItemsModalOpen && (
        <ServiceItemsModal
          onClose={() => toggleServiceItemsModal(false)}
          onSubmit={onSubmitServiceItemsForm}
        />
      )}
      {isUploadModalOpen && (
        <UploadModal
          entityToUpload={EntitiesToUpload.ITEMS}
          dropDownOptions={itemTypes.map(itemTypeToOptionItem)}
          dropDownLabel={intl.formatMessage({ id: 'item-types.singular' })}
          dropDownId="itemTypeId"
          uploadDropdownDefaultValue={uploadDropdownDefaultValue}
          setUploadDropdownDefaultValue={setUploadDropdownDefaultValue}
          isLoadingDropdownOptions={isLoadingItemTypes}
          uploadStatus={uploadItemsStatus}
          uploadDataErrors={isUploadFailed ? checkItemsUploadError(uploadError) : undefined}
          uploadedAmount={getUploadedItemsAmount(uploadItemsData)}
          resetUpload={resetUploadItems}
          submitFormData={data => processSubmitData(data)}
          onClose={onCloseUploadItemsModal}
          getUploadTemplate={debouncedRefetch}
          setSelectedDropdownIdForUpload={setSelectedItemTypeForUpload}
        />
      )}
    </>
  );
}

export { ItemsPage };
