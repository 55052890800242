import { useIntl } from 'react-intl';

import { Modal } from '../../../../components/modal/Modal';
import { Qualification } from '../../../../types/types';

import {
  QualificationFormData,
  QualificationForm,
  RawQualificationFormData,
} from './QualificationForm';

export interface QualificationFormModalProps {
  onClose: () => void;
  onSubmit: (data: QualificationFormData | Record<string, any>) => void;
  qualification?: Qualification;
}

function DraftQualificationModal({
  qualification,
  onClose,
  onSubmit,
}: QualificationFormModalProps) {
  const intl = useIntl();
  const isQualificationEditForm = !!qualification;
  const header = (
    <h1 className="uib-modal__title">
      {isQualificationEditForm
        ? intl.formatMessage({ id: 'qualifications.edit' })
        : intl.formatMessage({ id: 'qualifications.create' })}
    </h1>
  );

  const processFormData = async (formData: Record<keyof RawQualificationFormData, any>) => {
    const processedData: QualificationFormData = {
      _id: qualification?._id,
      name: formData.name,
      validityYears: formData.validityYears,
      validityMonths: formData.validityMonths,
      validityWeeks: formData.validityWeeks,
    };

    onSubmit(processedData);
  };

  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      modalHasClosed={onClose}
      content={
        <QualificationForm
          qualification={qualification}
          onCancel={onClose}
          onSubmit={processFormData}
        />
      }
    />
  );
}

export { DraftQualificationModal };
