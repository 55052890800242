import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm, Validate } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DefaultButtonGroup } from '../../../components/form/DefaultButtonGroup';
import { LabeledInput } from '../../../components/form/LabeledInput';
import { LabeledSwitch } from '../../../components/form/LabeledSwitch';
import { ServiceType } from '../../../types/types';
import { getDirtyFormValues } from '../../../utils/formData';

export interface ServiceTypeFormProps {
  serviceType?: ServiceType;
  onCancel: () => void;
  onSubmit: (data: ServiceTypeFormData) => void;
  toggleBlockPeriodError: Dispatch<SetStateAction<boolean>>;
  toggleIntervalInDaysError: Dispatch<SetStateAction<boolean>>;
}

export interface ServiceTypeFormData {
  _id?: string;
  name?: string;
  intervalInDays?: string;
  blockPeriodInDaysBeforeIntervalEnds?: string;
  isBlockAfterUse?: boolean;
  description?: string;
}

const twentyYearsInDays = '8035';

function ServiceTypeForm({
  serviceType,
  onCancel,
  onSubmit,
  toggleBlockPeriodError,
  toggleIntervalInDaysError,
}: ServiceTypeFormProps) {
  const intl = useIntl();
  const isEditForm = !!serviceType;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, isDirty, isSubmitting, dirtyFields, errors },
  } = useForm<ServiceTypeFormData>({
    mode: 'onChange',
    defaultValues: serviceType
      ? {
          name: serviceType.name,
          intervalInDays: serviceType.intervalInDays,
          blockPeriodInDaysBeforeIntervalEnds: serviceType.blockPeriodInDaysBeforeIntervalEnds,
          isBlockAfterUse: serviceType.isBlockAfterUse,
          description: serviceType.description,
        }
      : undefined,
  });

  const isBlockAfterUseWatcher = serviceType?.isBlockAfterUse || watch('isBlockAfterUse');

  const intervalInDaysWatcher = watch('intervalInDays');

  useEffect(() => {
    if (!serviceType) {
      setValue('intervalInDays', '', { shouldValidate: true });
      setValue('blockPeriodInDaysBeforeIntervalEnds', '', {
        shouldValidate: true,
      });
    }
  }, [isBlockAfterUseWatcher]);

  useEffect(() => {
    if (errors.blockPeriodInDaysBeforeIntervalEnds) toggleBlockPeriodError(true);
    else toggleBlockPeriodError(false);
  }, [errors.blockPeriodInDaysBeforeIntervalEnds]);

  useEffect(() => {
    if (errors.intervalInDays?.type === 'validate') {
      toggleIntervalInDaysError(true);
    } else toggleIntervalInDaysError(false);
  }, [errors.intervalInDays]);

  const validateBlockPeriodInDays: Validate<string | undefined, any> = blockPeriodInDays => {
    if (isBlockAfterUseWatcher) return true;
    if (intervalInDaysWatcher && blockPeriodInDays)
      return parseInt(blockPeriodInDays) < parseInt(intervalInDaysWatcher);
    return true;
  };

  const validateIntervalInDays: Validate<string | undefined, any> = intervalInDays => {
    if (isBlockAfterUseWatcher) return true;
    if (intervalInDays === '') return true;
    return intervalInDays ? parseInt(intervalInDays) < parseInt(twentyYearsInDays) : false;
  };

  return (
    <form
      onSubmit={handleSubmit(data => {
        if (serviceType) {
          data = getDirtyFormValues(data, dirtyFields);
          data._id = serviceType._id;
        }
        onSubmit(data);
      })}
    >
      <div className="uib-modal__content modal-form double-col">
        <LabeledSwitch
          id="isBlockAfterUse"
          label={intl.formatMessage({ id: 'service-types.isBlockAfterUse' })}
          isSelected={isBlockAfterUseWatcher}
          disabled={isEditForm}
          props={{
            ...register('isBlockAfterUse', {
              required: false,
              disabled: isEditForm,
            }),
          }}
        />
      </div>
      <div className="uib-modal__content modal-form double-col">
        <LabeledInput
          id="name"
          label={intl.formatMessage({ id: 'service-types.name' })}
          placeholder={intl.formatMessage({ id: 'service-types.serviceName' })}
          props={{ ...register('name', { required: true }) }}
        />

        <LabeledInput
          id="blockPeriodInDaysBeforeIntervalEnds"
          label={intl.formatMessage({
            id: 'service-types.blockPeriodInDaysBeforeIntervalEnds',
          })}
          placeholder={intl.formatMessage({
            id: 'service-types.blockPeriodInDaysBeforeIntervalEnds',
          })}
          required={false}
          disabled={isBlockAfterUseWatcher}
          type="number"
          min="0"
          props={{
            ...register('blockPeriodInDaysBeforeIntervalEnds', {
              required: false,
              validate: validateBlockPeriodInDays,
            }),
          }}
        />

        <LabeledInput
          id="intervalInDays"
          label={intl.formatMessage({ id: 'service-types.intervalInDays' })}
          placeholder={intl.formatMessage({
            id: 'service-types.intervalInDays',
          })}
          required={!isBlockAfterUseWatcher}
          disabled={isBlockAfterUseWatcher}
          type="number"
          min="0"
          max={twentyYearsInDays}
          props={{
            ...register('intervalInDays', {
              required: !isBlockAfterUseWatcher,
              validate: validateIntervalInDays,
            }),
          }}
        />
        <LabeledInput
          id="description"
          label={intl.formatMessage({ id: 'service-types.description' })}
          placeholder={intl.formatMessage({
            id: 'service-types.description',
          })}
          required={false}
          props={{ ...register('description', { required: false }) }}
        />
      </div>

      <div className="uib-modal__footer">
        <DefaultButtonGroup
          isSubmitDisabled={isSubmitting || !isDirty || !isValid}
          onCancel={onCancel}
        />
      </div>
    </form>
  );
}

export { ServiceTypeForm };
