import { Qualification } from '../types';

export interface Renter extends Record<string, unknown> {
  _id: string;
  lastName: string;
  firstName: string;
  barcode: string;
  humanRenterId: string;
  email: string;
  phone: string;
  contractor: {
    name: string;
    _id: string;
  };
  supervisorEmails: string[];
  isRenterAdmin: boolean;
  status: string;
  qualificationNames: string[];
  qualificationDetails: Qualification[];
}
export function findRenterNames(renterIds: string[], renters: Renter[]): string[] {
  const renterNames = [];
  for (const renterId of renterIds) {
    const renterName = findRenterName(renterId, renters);
    if (renterName !== '') {
      renterNames.push(renterName);
    }
  }
  return renterNames;
}
export function findRenterName(renterId: string, renters: Renter[]): string {
  const renter = renters.find(r => r._id === renterId);
  return getRenterName(renter);
}
export function getRenterName(renter?: Renter): string {
  if (renter?.firstName && renter?.lastName)
    return renter?.firstName + ' ' + renter?.lastName + ' (' + renter?.barcode + ')';
  return renter?.barcode ?? '';
}
