import { useMemo } from 'react';
import { Column, usePagination, useTable } from 'react-table';

interface HorizontalTableProps<T extends { [key: string]: unknown }> {
  columns: Column<{ [key: string]: unknown }>[];
  data: T[];
}

function HorizontalTable<T extends Record<string, unknown>>({
  columns,
  data,
}: HorizontalTableProps<T>) {
  const memoizedData = useMemo(() => data, [data]);

  const memoizedColumns = useMemo(() => columns, [columns]);

  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: {
        pageIndex: 0,
        pageSize: 3,
      },
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageSize },
  } = tableInstance;

  const paging = () => {
    return (
      <>
        {rows.length > 3 && (
          <div className="pagination">
            <div className="pagination-buttons">
              {rows.length > pageSize && (
                <>
                  <button
                    className="uib-iconbutton"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    <i className="uib-icon  uib-icon--arrow-switch-up uib-icon--size-xsmall narrow" />
                    <i className="uib-icon  uib-icon--arrow-switch-up uib-icon--size-xsmall" />
                  </button>
                  <button
                    className="uib-iconbutton"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <i className="uib-icon  uib-icon--arrow-switch-up uib-icon--size-xsmall" />
                  </button>
                  <button
                    className="uib-iconbutton"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <i className="uib-icon uib-icon--arrow-level-down uib-icon--size-xsmall" />
                  </button>
                  <button
                    className="uib-iconbutton"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    <i className="uib-icon uib-icon--arrow-level-down uib-icon--size-xsmall narrow" />
                    <i className="uib-icon uib-icon--arrow-level-down uib-icon--size-xsmall" />
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const headers = headerGroups[0].headers.map(column => column);

  return (
    <>
      <table
        className="uib-table uib-table--plain horizontal-table"
        style={canNextPage ? undefined : { maxWidth: `${25 + page.length * 25}%` }}
        {...getTableProps()}
      >
        <tbody className="uib-table__body" {...getTableBodyProps()}>
          {headers.map((header, i) => {
            return (
              <tr key={i}>
                <th className="uib-table__row--header" {...header.getHeaderProps()}>
                  <div className="header-text">{header.render('Header')}</div>
                </th>
                {page.map((row, j) => {
                  prepareRow(row);
                  return (
                    <td
                      {...row.cells[i].getCellProps([
                        { className: (row.cells[i].column as any).className },
                      ])}
                      key={j}
                    >
                      {row.cells[i].render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {paging()}
    </>
  );
}

export { HorizontalTable };
