import { Grid as MuiGrid } from '@mui/material';
import { ReactNode } from 'react';

export interface GridProps {
  children?: ReactNode;
  style?: any;
  container?: boolean;
  item?: boolean;
  md?: number;
  lg?: number;
  xl?: number;
  columns?: number;
  sx?: any;
  xs?: number;
  spacing?: number;
}

export function Grid({
  children,
  style,
  container,
  item,
  md,
  lg,
  xl,
  columns,
  sx,
  xs,
  spacing = 1,
}: GridProps) {
  return (
    <MuiGrid
      style={style}
      columns={columns}
      md={md}
      lg={lg}
      xl={xl}
      sx={sx}
      container={container}
      item={item}
      xs={xs}
      spacing={container ? spacing : undefined}
    >
      {children}
    </MuiGrid>
  );
}
