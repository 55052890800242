import React from 'react';

import { CircularProgressSize } from '../../../types/components/CircularProgress';

import { CircularProgress } from './CircularProgress';

export interface LoadingSpinnerProps {
  isLoading: boolean;
  size: CircularProgressSize;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ isLoading, size }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={
        'ng-tns-c2-0 ng-trigger ng-trigger-hostAnimation odx-loading-spinner--auto-color ng-star-inserted odx-loading-spinner'
      }
    >
      <CircularProgress
        size={size}
        value={-1}
        additionalClasses={
          'ng-tns-c2-0 odx-circular-progress--auto odx-circular-progress--indeterminate odx-circular-progress'
        }
      />
    </div>
  );
};

export default LoadingSpinner;
