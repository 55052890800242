import { createContext, ReactNode, useContext } from 'react';

import { useSystems } from '../../../api/hooks/useSystemsApi';
import { System } from '../../../types/systems/System';

interface SystemsPageContextApi {
  systems: System[];
  systemsIsLoading: boolean;
}

const SystemsPageContext = createContext<SystemsPageContextApi | undefined>(undefined);

interface SystemsPageProviderProps {
  children: ReactNode;
}

export function SystemsPageProvider({ children }: SystemsPageProviderProps) {
  const { systems, isLoading: systemsIsLoading } = useSystems();
  const value: SystemsPageContextApi = {
    systems: systems,
    systemsIsLoading,
  };
  return <SystemsPageContext.Provider value={value}>{children}</SystemsPageContext.Provider>;
}

export function useSystemsPageContext() {
  const context = useContext(SystemsPageContext);
  if (context === undefined) {
    throw new Error('useSystemsPageContext must be used within an SystemsPageProvider');
  }
  return context;
}
