import { useIntl } from 'react-intl';

import { FormMultiEmailInput } from '../../../library/form/FormMultiEmailInput';

export function AlertRuleReceiverEmailsInput() {
  const intl = useIntl();
  const thresholdPlaceholder = intl.formatMessage({
    id: 'alert-rules.receiverEmails',
  });
  return (
    <FormMultiEmailInput
      id={'alertRuleReceiversInput'}
      label={thresholdPlaceholder}
      name={'receiverEmails'}
    />
  );
}
