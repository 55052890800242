import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { CellProps } from 'react-table';

import { DragAndDropTable } from '../../../components/table/DragAndDropTable';
import { DeleteButton } from '../../../components/table/table-buttons/DeleteButton';
import { EditButton } from '../../../components/table/table-buttons/EditButton';
import { TableButtons } from '../../../components/table/table-buttons/TableButtons';
import { Cabinet } from '../../../types/types';
export interface CabinetsTableProps {
  cabinets: Cabinet[];
  clickCabinet?: (cabinets: Cabinet) => void;
  selectedCabinetId: string;
  onSelectCabinetForEditing: Dispatch<SetStateAction<Cabinet | undefined>>;
  onSelectCabinetForDeletion: Dispatch<SetStateAction<Cabinet | undefined>>;
  onSubmitDnD: (newPosition: number, row: { [key: string]: unknown }) => void;
}

function CabinetsTable({
  cabinets,
  clickCabinet,
  selectedCabinetId,
  onSelectCabinetForEditing,
  onSelectCabinetForDeletion,
  onSubmitDnD,
}: CabinetsTableProps) {
  const intl = useIntl();

  const columns = [
    {
      Header: intl.formatMessage({ id: 'cabinets.cabinetPos' }),
      accessor: 'position',
    },
    {
      Header: intl.formatMessage({ id: 'cabinets.cabinetType' }),
      accessor: 'cabinetType',
    },
    {
      Header: intl.formatMessage({ id: 'general.actions' }),
      id: 'actions',
      Cell: ({ row: { original: cabinet } }: CellProps<Cabinet>) => {
        return (
          <TableButtons>
            <EditButton
              onClick={e => {
                onSelectCabinetForEditing(cabinet);
                e.stopPropagation();
              }}
            />
            <DeleteButton
              onClick={e => {
                onSelectCabinetForDeletion(cabinet);
                e.stopPropagation();
              }}
            />
          </TableButtons>
        );
      },
    },
  ];

  return (
    <DragAndDropTable
      columns={columns}
      data={cabinets}
      onClickRow={row => clickCabinet?.(row as unknown as Cabinet)}
      canHighlightSelectedRow
      selectedRowId={selectedCabinetId}
      onItemDropped={onSubmitDnD}
    />
  );
}

export { CabinetsTable };
