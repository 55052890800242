import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DefaultButtonGroup } from '../../../../components/form/DefaultButtonGroup';
import { LabeledInput, VerticalLabelledInput } from '../../../../components/form/LabeledInput';
import { LabeledSwitch } from '../../../../components/form/LabeledSwitch';
import { Qualification } from '../../../../types/types';
import { getDirtyFormValues } from '../../../../utils/formData';

export interface QualificationFormProps {
  onCancel: () => void;
  onSubmit: (data: QualificationFormData | Record<string, any>) => void;
  qualification?: Qualification;
}

export interface QualificationFormData {
  _id?: string;
  name: string;
  validityYears: number;
  validityMonths: number;
  validityWeeks: number;
}
export interface RawQualificationFormData {
  name?: string;
  validityYears?: number;
  validityMonths?: number;
  validityWeeks?: number;
}

function QualificationForm({ qualification, onCancel, onSubmit }: QualificationFormProps) {
  const intl = useIntl();
  const isEditForm = !!qualification;
  const isPeriodOfValidity =
    !!qualification?.validityYears ||
    !!qualification?.validityMonths ||
    !!qualification?.validityWeeks;

  const [switchSelected, toggleSwitchSelected] = useState<boolean>(isPeriodOfValidity);

  const {
    register,
    resetField,
    handleSubmit,
    watch,
    formState: { isValid, isDirty, dirtyFields, isSubmitting },
  } = useForm<QualificationFormData>({
    mode: 'onChange',
    defaultValues: qualification
      ? {
          name: qualification.name,
          validityMonths: qualification.validityMonths,
          validityWeeks: qualification.validityWeeks,
          validityYears: qualification.validityYears,
        }
      : undefined,
  });

  const yearsWatch = watch('validityYears');
  const monthsWatch = watch('validityMonths');
  const weeksWatch = watch('validityWeeks');

  const checkAllThreeTimeValuesZero = () => {
    if (yearsWatch == 0 && monthsWatch == 0 && weeksWatch == 0) {
      return true;
    }
    return false;
  };

  return (
    <form
      onSubmit={handleSubmit(data => {
        if (isEditForm) {
          const dataDirty = getDirtyFormValues(data, dirtyFields);
          onSubmit(dataDirty);
        } else {
          onSubmit(data);
        }
      })}
    >
      <div className="uib-modal__content modal-form single-col">
        <LabeledSwitch
          id="validitySwitch"
          isSelected={switchSelected}
          onChange={() => {
            if (switchSelected) {
              resetField('validityYears');
              resetField('validityMonths');
              resetField('validityWeeks');
            }
            toggleSwitchSelected(!switchSelected);
          }}
          disabled={isEditForm}
          label={intl.formatMessage({
            id: 'qualification.usePeriodOfValidity',
          })}
        />
        <LabeledInput
          id="name"
          label={intl.formatMessage({ id: 'qualifications.qualificationName' })}
          placeholder={intl.formatMessage({
            id: 'qualifications.qualificationName',
          })}
          props={{ ...register('name', { required: true }) }}
        />
        {switchSelected && (
          <div className={`uib-form__item uib-form-field--required`}>
            <label htmlFor="expirylabel" className="uib-form__label">
              {intl.formatMessage({ id: 'qualifications.periodOfValidity' })}
            </label>
            <div className="multiple-num-pickers">
              <VerticalLabelledInput
                id="years"
                label={intl.formatMessage({ id: 'general.years' })}
                defaultValue="0"
                disabled={!switchSelected}
                type="number"
                min="0"
                max="99"
                size={2}
                props={{
                  ...register('validityYears', {
                    required: switchSelected,
                  }),
                }}
              />
              <VerticalLabelledInput
                id="months"
                label={intl.formatMessage({ id: 'general.months' })}
                defaultValue="0"
                disabled={!switchSelected}
                type="number"
                min="0"
                max="11"
                size={2}
                props={{
                  ...register('validityMonths', {
                    required: switchSelected,
                  }),
                }}
              />
              <VerticalLabelledInput
                id="weeks"
                label={intl.formatMessage({ id: 'general.weeks' })}
                defaultValue="0"
                disabled={!switchSelected}
                type="number"
                min="0"
                max="3"
                size={2}
                props={{
                  ...register('validityWeeks', {
                    required: switchSelected,
                  }),
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div className="uib-modal__footer">
        <DefaultButtonGroup
          isSubmitDisabled={isSubmitting || !isDirty || !isValid || checkAllThreeTimeValuesZero()}
          onCancel={onCancel}
        />
      </div>
    </form>
  );
}
export { QualificationForm };
