import { chartOdxYellow } from '../../../context/themeContext';
import { IconSize } from '../../../types/components/Icon';
import { OdxIconSet } from '../../../types/types';

import { Icon } from './Icon';

export function WarningIcon() {
  return (
    <Icon
      iconName={'warning'}
      size={IconSize.LARGE}
      color={chartOdxYellow}
      iconSet={OdxIconSet.CORE}
    />
  );
}
