import { useCreateSystem } from '../../../api/hooks/useSystemsApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { SystemStatus } from '../../../types/systems/System';
import { SystemFormValues } from '../../../types/systems/SystemFormValues';
import { ApiError } from '../../../types/types';
import { CreateEntityFormButtons } from '../../common/crud/CreateEntityFormButtons';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';
import { SystemForm } from '../form/SystemForm';

export function CreateSystemsForm() {
  const { setNotificationMessage } = useNotificationMessageContext();
  const { setOpenCreateDialog } = useCrudPageUiStateContext();
  const { mutateAsync: createSystem, reset: resetCreateSystem, isLoading } = useCreateSystem();

  function handleSubmit(param: any) {
    createSystem(param, {
      onSuccess: () => {
        resetCreateSystem();
        setOpenCreateDialog(false);
        setNotificationMessage('systems.saved', NotificationType.Success);
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          const errorData = error.response.data as ApiError;
          if (errorData.code) {
            setNotificationMessage(`systems.${errorData.code}`, NotificationType.Error);
          } else {
            setNotificationMessage('systems.generic-create-error', NotificationType.Error);
          }
        }
      },
    });
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <SystemForm
          initialFormValues={initialFormValues()}
          handleSubmit={handleSubmit}
          buttons={<CreateEntityFormButtons />}
        />
      )}
    </>
  );
}

function initialFormValues(): SystemFormValues {
  return {
    client: '',
    country: '',
    city: '',
    site: '',
    notificationLanguage: '',
    adminEmails: [],
    status: SystemStatus.UNDEFINED,
    gdcTenantId: '',
  };
}
