import React, { useRef, useState, useEffect } from 'react';

import { CircularProgressSize } from '../../../types/components/CircularProgress';

export interface CircularProgressProps {
  stroke?: number;
  size?: CircularProgressSize;
  value?: number;
  additionalClasses?: string;
}

export const CircularProgress: React.FC<CircularProgressProps> = ({
  stroke = 4,
  size = CircularProgressSize.MEDIUM,
  value: initialValue = 0,
  additionalClasses,
}) => {
  const [value, setValue] = useState<number>(initialValue);
  const [indeterminate, setIndeterminate] = useState<boolean>(initialValue === -1);
  const [radius, setRadius] = useState<number>(0);
  const [normalizedStroke, setNormalizedStroke] = useState<number>(0);
  const [circumference, setCircumference] = useState<number>(283);

  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    updateDimensions();
    return () => window.removeEventListener('resize', updateDimensions);
  });

  useEffect(() => {
    const coercedValue = Number(initialValue);
    if (coercedValue <= 100 && coercedValue >= -1) {
      setValue(coercedValue);
      setIndeterminate(coercedValue === -1);
    }
  }, [initialValue]);

  const updateDimensions = (): void => {
    if (stroke < 0 || !elementRef.current) return;
    const normalizeFactor = 100 / (elementRef.current.offsetWidth || 1);

    let newNormalizedStroke = normalizeFactor * stroke;
    if (normalizeFactor >= 3) {
      newNormalizedStroke = Math.max(0, newNormalizedStroke - normalizeFactor);
    }
    setNormalizedStroke(newNormalizedStroke);
    setRadius(Math.max(0, 100 / 2 - newNormalizedStroke / 2));
    setCircumference(Math.floor(2 * radius * Math.PI));
  };

  const progressValue = indeterminate
    ? circumference
    : Math.floor((1 - value / 100) * circumference);
  const ariaValueNow = indeterminate ? null : value;

  return (
    // @ts-ignore
    <div
      data-testid={'circular-progress'}
      className={
        `odx-circular-progress odx-circular-progress--${size} ${
          value === -1 ? 'odx-circular-progress--indeterminate ' : ''
        }` + additionalClasses
      }
      ref={elementRef}
      role="meter"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={ariaValueNow}
    >
      <svg className={'odx-circular-progress__inner'} viewBox={'0 0 100 100'}>
        <circle
          className="odx-circular-progress__track"
          cx="50"
          cy="50"
          r={radius}
          strokeWidth={normalizedStroke}
        />
        <circle
          className="odx-circular-progress__indicator"
          strokeDasharray={`${circumference}`}
          strokeDashoffset={`${progressValue}`}
          cx="50"
          cy="50"
          r={radius}
          strokeWidth={normalizedStroke}
        />
      </svg>
    </div>
  );
};

export default CircularProgress;
