import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useAppContext } from '../../../context/appContext';
import { useLanguageContext } from '../../../context/languageContext';
import { useTimeZoneContext } from '../../../context/timeZoneContext';
import { OptionItem } from '../../../types/types';
import { DefaultButtonGroup } from '../../form/DefaultButtonGroup';
import { LabeledDropdown } from '../../form/LabeledDropdown';
import { LabeledInput } from '../../form/LabeledInput';

dayjs.extend(utc);
dayjs.extend(timezone);

interface SettingsFromData {
  chosenlanguage: string;
  timeZone: string;
  currentTime: string;
}

interface SettingsFromProps {
  languagesAsOptionItems: OptionItem[];
  timeZonesAsOptionItems: OptionItem[];
}

const getDateTimeFormat = (chosenlanguageWatch: string) =>
  chosenlanguageWatch === 'de' ? 'DD.MM.YYYY HH:mm:ss' : 'MM/DD/YYYY hh:mm:ss A';

function SettingsForm({ languagesAsOptionItems, timeZonesAsOptionItems }: SettingsFromProps) {
  const intl = useIntl();

  const { currentLanguage, selectLanguage } = useLanguageContext();
  const { timeZone, setTimeZone } = useTimeZoneContext();
  const { closeSettingsModal } = useAppContext();

  const [currentTime, setCurrentTime] = useState<string>(
    dayjs().tz(timeZone).format(getDateTimeFormat(currentLanguage.value))
  );

  const { register, handleSubmit, setValue, getValues } = useForm<SettingsFromData>({
    mode: 'onChange',
  });

  const timeZoneWatch = getValues('timeZone');
  const chosenlanguageWatch = getValues('chosenlanguage');

  useEffect(() => {
    const currentTimeInterval = setInterval(() => {
      setCurrentTime(dayjs().tz(timeZoneWatch).format(getDateTimeFormat(chosenlanguageWatch)));
    }, 1000);

    return () => {
      clearInterval(currentTimeInterval);
    };
  }, [timeZoneWatch, chosenlanguageWatch]);

  return (
    <form
      onSubmit={handleSubmit(data => {
        selectLanguage(data.chosenlanguage);
        setTimeZone(data.timeZone);
        closeSettingsModal();
      })}
    >
      <div className="uib-modal__content modal-form single-col">
        <LabeledDropdown
          label={intl.formatMessage({ id: 'app.settings.language' })}
          required={false}
          id="chosenlanguage"
          options={languagesAsOptionItems}
          selectedValue={currentLanguage.value}
          setValue={setValue}
          props={{ ...register('chosenlanguage') }}
        />
        <LabeledDropdown
          label={intl.formatMessage({ id: 'app.settings.timeZone' })}
          required={false}
          id="timeZone"
          options={timeZonesAsOptionItems}
          selectedValue={timeZone}
          setValue={setValue}
          sortOptions={false}
          props={{
            ...register('timeZone'),
          }}
        />
        <LabeledInput
          id="currentTime"
          label={intl.formatMessage({
            id: 'app.settings.currentTime',
          })}
          required={false}
          props={{
            ...register('currentTime', { disabled: true }),
            value: currentTime,
          }}
        />
      </div>
      <div className="uib-modal__footer">
        <DefaultButtonGroup onCancel={closeSettingsModal} isSubmitDisabled={false} />
      </div>
    </form>
  );
}

export { SettingsForm };
