import saveAs from 'file-saver';
import { useMutation, useQuery } from 'react-query';

import { useClient } from '../../context/auth/tokenContext';
import { BusinessFormDataProcessed } from '../../modules/reports/components/BusinessReportModal';
import { RentalHistoryEntry, ServiceHistoryEntry } from '../../types/types';

const RENTING_HISTORY_URL = 'rental-history';
const SERVICE_HISTORY_URL = 'service-history';
const BUSINESS_REPORT_URL = 'business-report';

function useRentalHistory() {
  const client = useClient<RentalHistoryEntry[]>();
  const result = useQuery('rental-history', () => client(RENTING_HISTORY_URL));
  return { ...result, rentalHistory: result.data?.data ?? [] };
}

function useGetRentalHistoryExcel() {
  const client = useClient<BlobPart>();
  const result = useQuery(
    'export-rental-history',
    () => client(`${RENTING_HISTORY_URL}/export`, { responseType: 'blob' }),
    { enabled: false }
  );
  return {
    ...result,
    rentingHistoryExcel: result.data?.data ?? null,
    headers: result.data?.headers ?? null,
  };
}

function useServiceHistory() {
  const client = useClient<ServiceHistoryEntry[]>();
  const result = useQuery('service-history', () => client(SERVICE_HISTORY_URL));
  return { ...result, serviceHistory: result.data?.data ?? [] };
}

function useGetServiceHistoryExcel() {
  const client = useClient<BlobPart>();
  const result = useQuery(
    'export-service-history',
    () => client(`${SERVICE_HISTORY_URL}/export`, { responseType: 'blob' }),
    { enabled: false }
  );
  return {
    ...result,
    serviceHistoryExcel: result.data?.data ?? null,
    headers: result.data?.headers ?? null,
  };
}

export function useDownloadBusinessReport() {
  const client = useClient<BlobPart>();
  return useMutation<any, Error, BusinessFormDataProcessed>(async data => {
    try {
      const response = await client(
        `${BUSINESS_REPORT_URL}/export?startDate=${data.startDate}&endDate=${data.endDate}`,
        { responseType: 'blob' }
      );
      const blob = new Blob([response.data]);
      saveAs(blob, `business-report-${data.startDate}-${data.endDate}.xlsx`);
    } catch (error) {
      throw new Error('An error occurred while downloading the file');
    }
  });
}

export function useDownloadServiceForeCast() {
  const client = useClient<BlobPart>();
  return useMutation<any, Error, string>(async endDate => {
    try {
      const response = await client(`/items/service-forecast?endDate=${endDate}`, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data]);
      saveAs(blob, `service-forecast-${endDate}.xlsx`);
    } catch (error) {
      throw new Error('An error occurred while downloading the file');
    }
  });
}

export { useRentalHistory, useGetRentalHistoryExcel, useServiceHistory, useGetServiceHistoryExcel };
