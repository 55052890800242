import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
interface ClientParameters extends AxiosRequestConfig {
  data?: Record<string, never>;
  apiUrl?: string;
  rrSystemId?: string;
  token?: string;
  headers?: Record<string, never>;
}

function client<T>(
  endpoint: string,
  {
    data,
    token,
    apiUrl,
    rrSystemId,
    headers: customHeaders,
    ...customConfig
  }: ClientParameters = {}
): AxiosPromise<T> {
  const params: AxiosRequestConfig = {
    method: data ? 'POST' : 'GET',
    url: `${apiUrl}/${endpoint}`,
    data,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...customHeaders,
    },
    params: {
      ...(rrSystemId ? { rr_system_id: rrSystemId } : {}),
    },
    ...customConfig,
  };

  return axios(params);
}

export { client };
