import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useIntl } from 'react-intl';
import { CellProps } from 'react-table';

import { VerticalTable } from '../../components/table/VerticalTable';
import { useTimeZoneContext } from '../../context/timeZoneContext';
import { RentalHistoryEntry } from '../../types/types';
import { bindestrich } from '../../utils/bindestrich';

dayjs.extend(utc);
dayjs.extend(timezone);

interface RentalHistoryTableProps {
  rentalHistory: RentalHistoryEntry[];
}

function RentalHistoryTable({ rentalHistory }: RentalHistoryTableProps) {
  const intl = useIntl();

  const { timeZone } = useTimeZoneContext();

  const getDateByTimeZone = (date: string) => {
    if (!date) return date;
    return dayjs(new Date(date)).utc().tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
  };

  function defectIcon(defectReported: boolean) {
    const icon = defectReported ? (
      <i className="uib-icon uib-icon--feedback-warning" />
    ) : (
      <i className="uib-icon uib-icon--remove" />
    );
    return <div className="cell-icon-container">{icon}</div>;
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'renters.eId' }),
      accessor: 'renterEId',
    },
    {
      Header: intl.formatMessage({ id: 'renters.fullName' }),
      accessor: 'renterName',
    },
    {
      Header: intl.formatMessage({ id: 'renters.contractor' }),
      accessor: 'contractor',
    },
    {
      Header: intl.formatMessage({ id: 'item-types.singular' }),
      accessor: 'itemTypeName',
    },
    {
      Header: intl.formatMessage({ id: 'items.eID' }),
      accessor: 'itemEId',
    },
    {
      Header: intl.formatMessage({ id: 'items.itemSetId' }),
      accessor: 'itemSetId',
    },
    {
      Header: intl.formatMessage({ id: 'reports.rentedOn' }),
      accessor: (row: RentalHistoryEntry) => {
        return getDateByTimeZone(row.rentedOn);
      },
      id: 'rentedOn',
    },
    {
      Header: intl.formatMessage({ id: 'reports.returnedOn' }),

      Cell: ({ row: { original } }: CellProps<RentalHistoryEntry>) => {
        return bindestrich(getDateByTimeZone(original.returnedOn));
      },
      id: 'returnedOn',
    },
    {
      Header: intl.formatMessage({ id: 'reports.rentedDuration' }),
      accessor: 'rentedDuration',
    },
    {
      Header: intl.formatMessage({ id: 'stations.singular' }),
      accessor: 'stationName',
    },
    {
      Header: intl.formatMessage({ id: 'reports.defectReported' }),
      id: 'defectReported',
      Cell: ({ row: { original } }: CellProps<RentalHistoryEntry>) =>
        defectIcon(original.defectReported),
    },
    {
      Header: intl.formatMessage({ id: 'reports.xDockResult' }),
      accessor: 'xDockResult',
    },
  ];

  const sortBy = [
    {
      id: 'rentedOn',
      desc: true,
    },
  ];

  return (
    <VerticalTable
      sortBy={sortBy}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      columns={columns}
      data={rentalHistory}
    />
  );
}

export { RentalHistoryTable };
