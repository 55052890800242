import { Renter } from './renter/Renter';

// every interface that is used in react-table needs to extend Record<string, unknown>
export enum Manufacturer {
  DRAEGER = 'Draeger',
  OTHER = 'Other',
}

export enum Status {
  AVAILABLE = 'Available',
  CHARGING = 'Charging',
  IN_SERVICE = 'InService',
  RENTED = 'Rented',
  REQUIRES_SERVICE = 'RequiresService',
  OVERDUE = 'Overdue',
}

export enum Connectivity {
  UNKNOWN = 'Unknown',
  OFFLINE = 'Offline',
  ONLINE = 'Online',
}

export enum EntitiesToUpload {
  ITEMS = 'items',
  RENTERS = 'renters',
}

export enum EditOperation {
  remove = 'Remove',
  replace = 'Replace',
}

export enum RrSystemStatus {
  LIVE = 'Live',
  NOT_LIVE = 'NotLive',
  UNDEFINED = 'Undefined',
}

export enum GeneralErrorCodes {
  NOT_FOUND_RRSYSTEM,
  BAD_REQUEST_FILE_CONVERTION,
  BAD_REQUEST_FILE_FORMAT,
  INTERNAL_SERVER_ERROR,
  BAD_REQUEST_MISSING_FILE,
  BAD_REQUEST_MISSING_RRSYSTEM,
}

export enum FormatErrorCodes {
  BAD_REQUEST_FILE_NAME_LENGTH,
  BAD_REQUEST_FILE_INVALID_EXTENSION,
  BAD_REQUEST_FILE_MAX_SIZE,
  BAD_REQUEST_FILE_MIN_NUMBER_ROWS,
}

export enum HeadersErrorCodes {
  BAD_REQUEST_HEADERS,
  BAD_REQUEST_HEADERS_MISING,
  BAD_REQUEST_HEADERS_UNKNOWN,
}

export enum ItemMultiDeleteErrorCodes {
  BAD_REQUEST_ITEM_IN_LOCKER,
}

export enum EntriesErrorCodes {
  BAD_REQUEST_CONTENT_MISSING,
  BAD_REQUEST_CONTENT_UNKNOWN,
  BAD_REQUEST_CONTENT_TOO_LONG,
  BAD_REQUEST_CONTENT_DUPLICATED,
  BAD_REQUEST_CONTENT_INVALID_EMAIL,
  BAD_REQUEST_CONTENT_FORMAT,
  BAD_REQUEST_CONTENT_DUPLICATED_IN_RR_SYSTEM_OR_OTHER,
  BAD_REQUEST_CONTENT_DUPLICATED_IN_THIS_RR_SYSTEM,
  BAD_REQUEST_CONTENT_DUPLICATED_IN_OTHER_RR_SYSTEM,
  BAD_REQUEST_CONTENT_DATE,
  BAD_REQUEST_CONTENT_DATE_FORMAT,
}

export enum OdxIconSet {
  CORE = 'core',
  SAFETY = 'safety',
  MEDICAL = 'medical',
}

export interface Client {
  name: string;
}

export interface RRSystem extends Record<string, unknown> {
  _id: string;
  client: string;
  country: string;
  city: string;
  site: string;
  notificationLanguage: string;
  adminEmails: string[];
  status: RrSystemStatus;
  gdcTenantId: string;
}

export interface Station extends Record<string, unknown> {
  _id: string;
  location: string;
  supervisorEmails: string[];
  iotAdapterId: string;
  terminalId: string;
  terminalSerialNo?: string;
  stationHealth?: StationHealth;
}

export interface StationHealth {
  scannerModuleState: string;
  lockerControllerModuleState: string;
  terminalModuleState: string;
  scannerPlugState: string;
  lockerControllerPlugState: string;
}

export interface Stock extends Record<string, unknown> {
  itemType: {
    id: string;
    name: string;
    isConsumable: boolean;
  };
  station: {
    id: string;
    name: string;
  };
  stock?: {
    rented: number;
    overdue: number;
    requiresService: number;
    available: number;
  };
  contractStock?: { id: string; stock: number; alertStock?: number };
}

export interface Cabinet extends Record<string, unknown> {
  _id: string;
  position: number;
  cabinetType: string;
  lockerFeatureId: string;
}

export interface Locker extends Record<string, unknown> {
  _id: string;
  position: number;
  lockerFeature: string;
  itemTypeName: string;
  item: {
    _id?: string;
    itemBarcode?: string;
  };
  items?: Item[];
  isDefective: boolean;
}

export interface CabinetType {
  displayNameForItem: string;
  displayNameForCabinet: string;
  name: string;
}

export interface LockerFeature {
  _id: string;
  description: string;
}

export interface MessageObject {
  messages: string[];
}

export interface PairIotAdapterParams {
  stationId: string;
  iotAdapterId: string;
}
export interface TerminalPairingCode {
  oneTimePairingCode: string;
  creationDateUtc: string;
}

export interface Item extends Record<string, unknown> {
  _id: string;
  draegerSerialNo: string;
  itemType: {
    _id: string;
    name: string;
    imageUrl: string;
  };
  itemBarcode: string;
  customId?: string;
  lockerId: string;
  cabinetPosition: number;
  lockerPosition: number;
  renter: {
    barcode?: string;
  };
  renterEId: string;
  station: {
    _id?: string;
    location?: string;
  };
  status: Status;
  serviceNames: string[];
  manufacturer: string;
  itemSet?: {
    id: string;
    itemSetTemplateId: string;
  };
}

export interface ItemServiceDetails extends Record<string, unknown> {
  serviceTypeName: string;
  isBlockAfterUse: boolean;
  nextServiceDate: string;
  itemNeedsServicingNow: boolean;
}

export interface DetailedItem extends Item {
  serviceDetails: ItemServiceDetails[];
  hasGdcCalibration: boolean;
}

export interface User extends Record<string, unknown> {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  globalRole: string;
  role: UserRole;
}

export enum UserRole {
  ADMIN = 'Admin',
  READONLY = 'ReadOnly',
}

export enum UserGlobalRole {
  NONE = 'None',
  SERVICE_TECHNICIAN = 'ServiceTechnician',
  GLOBAL_ADMIN = 'SuperAdmin',
}

export interface PatchUserFormData {
  _id: string;
  globalRole: string;
}

export interface UserSystemRole extends Record<string, string> {
  rrSystemName: string;
  role: UserRole;
}

export interface DetailedUser extends User {
  roles: UserSystemRole[];
}

export interface DetailedRenter extends Renter {
  qualifications: Qualification[];
}
export interface Qualification extends Record<string, unknown> {
  _id: string;
  name: string;
  validityYears: number;
  validityMonths: number;
  validityWeeks: number;
  isValid: boolean;
  expiryDate: string;
  issueDate: string;
}
export function hasPeriodOfValidity(qualification: Qualification | undefined): boolean {
  return (
    !!qualification?.validityWeeks ||
    !!qualification?.validityMonths ||
    !!qualification?.validityYears
  );
}

export interface IntervalServiceType extends Record<string, unknown> {
  _id: string;
  name: string;
  intervalInDays: string;
  isBlockAfterUse: boolean;
}

export interface ServiceType extends IntervalServiceType {
  blockPeriodInDaysBeforeIntervalEnds: string;
  description: string;
}

export interface BasicServiceType {
  _id: string | null;
  name: string;
}

export interface ServiceTypeReferences extends Record<string, any> {
  itemTypeReferences: string[];
  itemReferences: string[];
}

export interface RentalHistoryEntry extends Record<string, unknown> {
  renterEId: string;
  renterName: string;
  contractor: string;
  itemTypeName: string;
  itemEId: string;
  rentedOn: string;
  returnedOn: string;
  rentedDuration: string;
  stationName: string;
  defectReported: boolean;
  xDockResult: string;
  itemSetId?: string;
}

export interface ServiceHistoryEntry extends Record<string, unknown> {
  itemTypeName: string;
  eId: string;
  serviceTypeName: string;
  date: string;
  source: string;
}
interface EditOperationWithData {
  operation: EditOperation;
  field: string;
  value?: any;
}

export interface EditRequest {
  _id: string;
  patchFields: EditOperationWithData[];
}

export interface DropdownProps {
  id: string;
  options: OptionItem[];
  label: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  props: unknown;
}

export interface OptionItem {
  value: string;
  label: string;
}

export interface ServiceItemsFormResponseType {
  itemEIdsNotUpdated: ItemEIdsNotUpdatedObject | undefined;
  itemEIdsUpdated: Array<string>;
}
export interface ItemEIdsNotUpdatedObject {
  [key: number]: string;
}

export interface XtraHeaderButtonObject {
  onClick: () => void;
  additionalClassName?: string;
  iconName: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export interface ErrorDetails {
  code: string;
  message: string;
  parameters?: string[];
}

export interface ApiError {
  status: number;
  message: string;
  code: string;
  details?: ErrorDetails[];
}
