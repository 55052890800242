import { ServiceForecastUiStateProvider } from '../../../../context/ServiceForecastUiStateContext';

import { ServiceForeCastCard } from './ServiceForeCastCard';
import { ServiceForeCastDialog } from './ServiceForeCastDialog';

export function ServiceForeCast() {
  return (
    <ServiceForecastUiStateProvider>
      <ServiceForeCastCard />
      <ServiceForeCastDialog />
    </ServiceForecastUiStateProvider>
  );
}
