import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DataGridPremium, DataGridPremiumProps } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Notification } from '../../components/common/notification/Notification';
import { PageHeader } from '../../components/common/PageHeader';
import {
  useDeleteItemSetTemplate,
  useItemSetTemplates,
} from '../../integrations/itemSetTemplate/useItemSetTemplate';
import { ApiError } from '../../types/types';

interface ItemSetTemplateForDeletion {
  id: string;
  name: string;
  shortName: string;
  lockerSize: string;
  imageUrl: null;
}

function ItemSetTemplatePage() {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [selectedItemForDeletion, setSelectedItemForDeletion] =
    useState<ItemSetTemplateForDeletion | null>(null);
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'shortName',
      headerName: intl.formatMessage({ id: 'item-set-template.shortName' }),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'imageUrl',
      headerName: intl.formatMessage({ id: 'item-set-template.image' }),
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.row.parentName ? null : (
          <img src={params.value as string} style={{ width: '50px' }} alt="Item" />
        ),
      flex: 1,
    },
    {
      field: 'lockerSize',
      headerName: intl.formatMessage({ id: 'lockers.lockerSize' }),
      filterable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'action',
      headerName: intl.formatMessage({ id: 'item-set-template.actions' }),
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: params => {
        return params.row.parentName ? null : (
          <Stack direction="row" spacing={2}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setOpen(true);
                setSelectedItemForDeletion(params.row);
              }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const { itemSetTemplates, isLoading } = useItemSetTemplates();

  const deleteItemSetTemplate = useDeleteItemSetTemplate();

  interface ItemSetTemplateItemType {
    _id: string;
    name: string;
    shortName: string;
    lockerSize: string;
  }

  const flattenedItemSetTemplates = itemSetTemplates.reduce((acc: any[], itemSetTemplate) => {
    const itemTypes = itemSetTemplate.itemTypes as ItemSetTemplateItemType[];
    const parent = {
      id: itemSetTemplate._id,
      name: itemSetTemplate.name,
      shortName: itemSetTemplate.shortName,
      lockerSize: itemSetTemplate.lockerSize,
      imageUrl: itemSetTemplate.imageUrl,
    };
    const children =
      itemTypes && itemTypes.length > 0
        ? itemTypes.map((itemType: ItemSetTemplateItemType) => ({
            id: `${itemSetTemplate._id}-${itemType._id}`,
            name: itemType.name,
            shortName: itemType.shortName,
            lockerSize: itemType.lockerSize,
            parentId: itemSetTemplate._id,
            parentName: itemSetTemplate.name,
          }))
        : [];
    return [...acc, parent, ...children];
  }, []);

  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = row => {
    if (row.parentName) {
      return [row.parentName, row.name];
    }
    return [row.name];
  };

  return (
    <>
      <PageHeader
        breadCrumbs={[
          {
            label: intl.formatMessage({ id: 'systems.self' }),
            route: '#',
          },
          {
            label: intl.formatMessage({ id: 'item-set-template.self' }),
            route: '#/item-set-templates',
          },
        ]}
      >
        <Button
          id={'createItemSetTemplateBtn'}
          component={Link}
          to={'/item-set-templates/create'}
          variant="contained"
        >
          <AddIcon />
        </Button>
      </PageHeader>
      <Box sx={{ width: '100%' }}>
        <DataGridPremium
          autosizeOnMount={true}
          loading={isLoading}
          autoHeight={true}
          sx={{ bgcolor: 'white' }}
          getRowId={row => row.id}
          rows={flattenedItemSetTemplates}
          getTreeDataPath={getTreeDataPath}
          treeData
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          disableRowSelectionOnClick
          disableColumnSelector={true}
        />
      </Box>
      <Dialog
        id={'deleteItemSetTemplateDialog'}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {intl.formatMessage({ id: 'item-set-template.delete' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage(
              { id: 'item-set-template.delete.confirm' },
              { itemSetName: selectedItemForDeletion?.name }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id={'cancelDeleteItemSetTemplateBtn'}
            onClick={() => setOpen(false)}
            color="primary"
          >
            {intl.formatMessage({ id: 'general.cancel' })}
          </Button>
          <Button
            id={'confirmDeleteItemSetTemplateBtn'}
            onClick={() => {
              if (selectedItemForDeletion && selectedItemForDeletion.id) {
                deleteItemSetTemplate.mutate(
                  { _id: selectedItemForDeletion.id },
                  {
                    onError: (error: any) => {
                      if (error.response && (error.response.data as ApiError)) {
                        setOpen(false);
                        setErrorCode(error.response.data.code);
                        setShowErrorSnackbar(true);
                      }
                    },
                    onSuccess: () => {
                      setShowSuccessSnackbar(true);
                    },
                  }
                );
              }
              setOpen(false);
            }}
            color="error"
            variant={'contained'}
            autoFocus
            startIcon={deleteItemSetTemplate.isLoading ? <CircularProgress size={20} /> : null}
          >
            {intl.formatMessage({ id: 'general.confirm' })}
          </Button>
        </DialogActions>
      </Dialog>

      <Notification
        showNotification={showErrorSnackbar}
        setShowNotification={setShowErrorSnackbar}
        message={errorCode || 'general.errorMessage'}
      />

      <Notification
        showNotification={showSuccessSnackbar}
        setShowNotification={setShowSuccessSnackbar}
        type={'success'}
        message={'item-set-template.deleted'}
      />
    </>
  );
}

export { ItemSetTemplatePage };
