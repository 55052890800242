import { ReactNode } from 'react';

interface CardProps {
  cardName: string;
  children: ReactNode;
}

function Card({ cardName, children }: CardProps) {
  return (
    <div className="uib-card card">
      <div className="card-content">{children}</div>
      <p className="card-title">{cardName}</p>
    </div>
  );
}

export { Card };
