import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useDashboardContext } from '../../context/DashboardContext';
import { RentalHistoryEntry } from '../../types/types';
import { ChartCard } from '../library/charts/ChartCard';
import { CheckIcon } from '../library/icons/CheckIcon';
import { WarningIcon } from '../library/icons/WarningIcon';

export function GasDetectionDeviceMisuseChartCard() {
  const { rentalHistory, rentalHistoryIsLoading } = useDashboardContext();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'dashboard.deviceMisuseTitle' });
  return (
    <ChartCard
      title={title}
      content={<GasDetectionMisuseContent rentalHistory={rentalHistory} />}
      isLoading={rentalHistoryIsLoading}
      isEmpty={rentalHistory.length == 0}
    />
  );
}

interface GasDetectionMisuseContentProps {
  rentalHistory: RentalHistoryEntry[];
}
function GasDetectionMisuseContent({ rentalHistory }: GasDetectionMisuseContentProps) {
  const intl = useIntl();
  const bumpTestOverdueLabel = intl.formatMessage({
    id: 'dashboard.bumpTestOverdueLabel',
  });
  const bumpTestFailedLabel = intl.formatMessage({
    id: 'dashboard.bumpTestFailedLabel',
  });
  const testOverdueExists = getTestOverdue(rentalHistory);
  const testFailedExists = getTestFailed(rentalHistory);
  return (
    <>
      <div style={{ display: 'block' }}>
        {!testOverdueExists && !testFailedExists && <GasDetectionSafetySucess />}
        {testOverdueExists && (
          <GasDetectionSafetyWarning
            text={bumpTestOverdueLabel}
            rentingHistoryFilter="Test%20Overdue"
          />
        )}
        {testFailedExists && (
          <GasDetectionSafetyWarning
            text={bumpTestFailedLabel}
            rentingHistoryFilter="Failed%20and%20not%20returned"
          />
        )}
      </div>
    </>
  );
}
function getTestOverdue(rentalHistory: RentalHistoryEntry[]) {
  return rentalHistory.some(r => !r.returnedOn && r.xDockResult === 'Test overdue');
}
function getTestFailed(rentalHistory: RentalHistoryEntry[]) {
  return rentalHistory.some(r => !r.returnedOn && r.xDockResult === 'Failed and not returned');
}

interface GasDetectionSafetyWarningProps {
  text: string;
  rentingHistoryFilter: string;
}
function GasDetectionSafetyWarning({ text, rentingHistoryFilter }: GasDetectionSafetyWarningProps) {
  const navigate = useNavigate();
  function gotoRentingHistory() {
    navigate(`/reports/rental-history?column=xDockResult&filter=${rentingHistoryFilter}`);
  }
  return (
    <div style={{ cursor: 'pointer' }} onClick={gotoRentingHistory}>
      <IconWithText icon={<WarningIcon />} text={text} />
    </div>
  );
}
function GasDetectionSafetySucess() {
  const intl = useIntl();
  return (
    <IconWithText
      icon={<CheckIcon />}
      text={intl.formatMessage({
        id: 'dashboard.gasDetectionSafetySucessLabel',
      })}
    />
  );
}
interface IconWithTextProps {
  text: string;
  icon: ReactNode;
}
export function IconWithText({ icon, text }: IconWithTextProps) {
  return (
    <div style={{ marginBottom: '5px' }}>
      {icon} {text}
    </div>
  );
}
