import { IntlShape, useIntl } from 'react-intl';

import { useDashboardContext } from '../../context/DashboardContext';
import { IconSize } from '../../types/components/Icon';
import { OdxIconSet, RentalHistoryEntry } from '../../types/types';
import { ChartCard } from '../library/charts/ChartCard';
import Icon from '../library/icons/Icon';

import { IconWithText } from './GasDetectionDeviceMisuseChartCard';

export function RentCountChartCard() {
  const { rentalHistory, rentalHistoryIsLoading } = useDashboardContext();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'dashboard.rentCountTitle' });
  return (
    <ChartCard
      title={title}
      content={<RentCountContent rentalHistory={rentalHistory} />}
      isLoading={rentalHistoryIsLoading}
      isEmpty={rentalHistory.length == 0}
    />
  );
}
interface RentCountContentProps {
  rentalHistory: RentalHistoryEntry[];
}
function RentCountContent({ rentalHistory }: RentCountContentProps) {
  const intl = useIntl();
  const closedRentCount = formatNumber(intl, getClosedRentCount(rentalHistory));
  const openRentCount = formatNumber(intl, getOpenRentCount(rentalHistory));
  const avgRentingTime = formatNumber(intl, getAaverageRentingTime(rentalHistory));
  const overdueQuota = formatNumber(intl, getOverdueQuota(rentalHistory));

  const totalClosedRentsText = intl.formatMessage({
    id: 'dashboard.totalClosedRentsText',
  });
  const totalOpenRentsText = intl.formatMessage({
    id: 'dashboard.totalOpenRentsText',
  });
  const averageRentingTimeText = intl.formatMessage({
    id: 'dashboard.averageRentingTimeText',
  });
  const days = intl.formatMessage({ id: 'general.days' });
  const overdueRentsText = intl.formatMessage({
    id: 'dashboard.overdueRentQuotaText',
  });
  return (
    <>
      <IconWithText icon={<ReturnIcon />} text={`${totalClosedRentsText} ${closedRentCount}`} />
      <IconWithText icon={<RentIcon />} text={`${totalOpenRentsText} ${openRentCount}`} />
      <IconWithText
        icon={<TimerIcon />}
        text={`${averageRentingTimeText} ${avgRentingTime} ${days}`}
      />
      <IconWithText icon={<EvacuationTimerIcon />} text={`${overdueRentsText} ${overdueQuota} %`} />
    </>
  );
}
function formatNumber(intl: IntlShape, number: number) {
  return intl.formatNumber(number, { maximumFractionDigits: 2 });
}
function getOpenRentCount(rentalHistory: RentalHistoryEntry[]) {
  return rentalHistory.filter(r => !r.returnedOn).length;
}

function getClosedRentCount(rentalHistory: RentalHistoryEntry[]) {
  return rentalHistory.filter(r => !!r.returnedOn).length;
}

function getAaverageRentingTime(rentalHistory: RentalHistoryEntry[]) {
  return getAverage(
    rentalHistory
      .filter(r => !!r.returnedOn)
      .map(r => {
        return Number(r.rentedDuration);
      })
  );
}
function getOverdueQuota(rentalHistory: RentalHistoryEntry[]) {
  const overdueRentsCount = rentalHistory
    .filter(r => !r.returnedOn)
    .filter(r => Number(r.rentedDuration) >= 30).length;
  const allRentscount = rentalHistory.length;
  return (overdueRentsCount / allRentscount) * 100;
}
function ReturnIcon() {
  return <Icon iconName={'articles-return'} size={IconSize.LARGE} iconSet={OdxIconSet.SAFETY} />;
}
function RentIcon() {
  return <Icon iconName={'articles-issue'} size={IconSize.LARGE} iconSet={OdxIconSet.SAFETY} />;
}
function TimerIcon() {
  return <Icon iconName={'timer'} size={IconSize.LARGE} iconSet={OdxIconSet.SAFETY} />;
}
function EvacuationTimerIcon() {
  return <Icon iconName={'evacuation-timer'} size={IconSize.LARGE} iconSet={OdxIconSet.SAFETY} />;
}
function getAverage(array: number[]): number {
  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    sum += array[i];
  }
  return sum / array.length;
}
