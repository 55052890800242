import { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { CellProps, Row } from 'react-table';

import Icon from '../../../components/library/icons/Icon';
import { DeleteButton } from '../../../components/table/table-buttons/DeleteButton';
import { EditButton } from '../../../components/table/table-buttons/EditButton';
import { InfoButton } from '../../../components/table/table-buttons/InfoButton';
import { TableButtons } from '../../../components/table/table-buttons/TableButtons';
import { VerticalTable } from '../../../components/table/VerticalTable';
import { ItemType } from '../../../types/item-types/ItemType';
import { bindestrich } from '../../../utils/bindestrich';
import { defaultItemTypeImage } from '../../../utils/encodedImages';

interface ItemTypesTableProps {
  itemTypes: ItemType[];
  onSelectItemTypeForEditing: Dispatch<SetStateAction<ItemType | undefined>>;
  onSelectItemTypeForDetailView: Dispatch<SetStateAction<ItemType | undefined>>;
  onSelectItemTypeForDeletion: Dispatch<SetStateAction<ItemType | undefined>>;
}

function ItemTypesTable({
  itemTypes,
  onSelectItemTypeForEditing,
  onSelectItemTypeForDetailView,
  onSelectItemTypeForDeletion,
}: ItemTypesTableProps) {
  const intl = useIntl();

  const [overlayImage, setOverlayImage] = useState<ItemType | null>(null);

  const itemTypeImage = (row: Row<ItemType>) => {
    function openImageOverlay() {
      setOverlayImage(row.original);
    }

    const imageUrl = row.original.imageUrl ? row.original.imageUrl : defaultItemTypeImage;

    return (
      <img
        alt={row.original.name}
        src={imageUrl}
        onClick={row.original.imageUrl ? openImageOverlay : undefined}
      />
    );
  };

  const xDockIcon = (itemType: ItemType) => {
    const icon = itemType.xDockTestable ? (
      <Icon iconName={'check'} additionalClasses={'odx-blue-text'} />
    ) : (
      <Icon iconName={'blocked'} />
    );
    return <div className="cell-icon-container">{icon}</div>;
  };

  const columns = [
    {
      Header: intl.formatMessage({ id: 'item-types.image' }),
      Cell: ({ row }: CellProps<ItemType>) => itemTypeImage(row),
      className: 'table-image-cell',
    },
    {
      Header: intl.formatMessage({ id: 'item-types.itemTypeName' }),
      accessor: 'name',
    },
    {
      Header: intl.formatMessage({ id: 'item-types.rentalErpId' }),
      accessor: (row: ItemType) => {
        const data = row;
        return bindestrich(data.rentalErpId);
      },
    },
    {
      Header: intl.formatMessage({ id: 'item-types.mainErpId' }),
      accessor: (row: ItemType) => {
        const data = row;
        return bindestrich(data.mainErpId);
      },
    },
    {
      Header: intl.formatMessage({ id: 'lockers.lockerSize' }),
      accessor: 'lockerSize',
    },
    {
      Header: intl.formatMessage({ id: 'lockers.lockerFeature' }),
      id: 'lockerFeature',
      accessor: (row: ItemType) => {
        const data = row;
        return bindestrich(data.lockerFeature);
      },
    },
    {
      Header: intl.formatMessage({ id: 'item-types.qualifications' }),
      accessor: (row: ItemType) => {
        const data = row;
        if (!data.qualificationNames || data.qualificationNames.length === 0) {
          return '-';
        }
        return data.qualificationNames.join(', ');
      },
    },
    {
      Header: intl.formatMessage({ id: 'item-types.chargingDuration' }),
      id: 'chargingDurationInHours',
      accessor: (row: ItemType) => {
        const data = row;
        return bindestrich(data.chargingDurationInHours);
      },
    },
    {
      Header: intl.formatMessage({ id: 'item-types.xDockTestable' }),
      id: 'xDockTestable',
      Cell: ({ row: { original } }: CellProps<ItemType>) => xDockIcon(original),
    },
    {
      Header: intl.formatMessage({ id: 'item-types.xDockAlertingTime' }),
      id: 'xDockAlertingTime',
      accessor: (row: ItemType) => {
        const data = row as ItemType;
        return bindestrich(data.xDockAlertingTime);
      },
    },
    {
      Header: intl.formatMessage({ id: 'general.actions' }),
      id: 'actions',
      Cell: ({ row }: CellProps<ItemType>) => {
        const itemType = row.original;
        return (
          <TableButtons>
            <InfoButton onClick={() => onSelectItemTypeForDetailView(itemType)} />
            <EditButton
              onClick={e => {
                e.stopPropagation();
                onSelectItemTypeForEditing(itemType);
              }}
            />
            <DeleteButton
              onClick={e => {
                e.stopPropagation();
                onSelectItemTypeForDeletion(itemType);
              }}
            />
          </TableButtons>
        );
      },
    },
  ];

  const sortBy = [
    {
      id: 'name',
      desc: false,
    },
  ];

  function closeImageOverlay() {
    setOverlayImage(null);
  }

  return (
    <>
      {overlayImage && (
        <div className="overlay" onClick={closeImageOverlay}>
          <img className="image" alt={overlayImage.name} src={overlayImage.imageUrl} />
        </div>
      )}
      <VerticalTable
        sortBy={sortBy}
        // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
        columns={columns}
        data={itemTypes}
      />
    </>
  );
}

export { ItemTypesTable };
