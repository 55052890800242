import { CreateEntityButton } from '../../components/common/crud/CreateEntityButton';
import { DeleteEntitiesButton } from '../../components/common/crud/DeleteEntitiesButton';
import { ImportEntitiesButton } from '../../components/common/crud/ImportEntitiesButton';
import { SimpleNotification } from '../../components/common/notification/SimpleNotification';
import { RentersPageProvider } from '../../components/renters/contexts/RentersPageContext';
import { CreateRenterDialog } from '../../components/renters/create/CreateRenterDialog';
import { DeleteRentersDialog } from '../../components/renters/delete/DeleteRentersDialog';
import { PatchRenterDialog } from '../../components/renters/edit/PatchRenterDialog';
import { ImportRentersDialog } from '../../components/renters/import/ImportRentersDialog';
import { RenterDetailView } from '../../components/renters/RenterDetailView';
import { RentersPageHeader } from '../../components/renters/RentersPageHeader';
import { RentersTable } from '../../components/renters/RentersTable';
import { CrudPageUiStateProvider } from '../../context/CrudPageUiContext';
import { NotificationMessageProvider } from '../../context/notificationMessageContext';

function RentersPage() {
  return (
    <NotificationMessageProvider>
      <CrudPageUiStateProvider>
        <RentersPageProvider>
          <RentersPageHeader>
            <CreateEntityButton />
            <ImportEntitiesButton />
            <DeleteEntitiesButton />
          </RentersPageHeader>
          <RentersTable />
          <CreateRenterDialog />
          <RenterDetailView />
          <PatchRenterDialog />
          <DeleteRentersDialog />
          <ImportRentersDialog />
          <SimpleNotification />
        </RentersPageProvider>
      </CrudPageUiStateProvider>
    </NotificationMessageProvider>
  );
}
export { RentersPage };
