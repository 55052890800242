import { useIntl } from 'react-intl';

import { useLockerFeatures } from '../../../../api/hooks/useLockerFeaturesApi';
import { useLockerSizeOptions } from '../../../../api/hooks/useStationApi';
import { LoadingIndicator } from '../../../../components/library/loading/LoadingIndicator';
import { Modal } from '../../../../components/modal/Modal';
import { Cabinet, OptionItem, CabinetType, LockerFeature } from '../../../../types/types';

import { CabinetForm, CabinetFormData } from './CabinetForm';

export interface CabinetFormModalProps {
  cabinet?: Cabinet;
  onClose: () => void;
  onSubmit: (data: CabinetFormData) => void;
}

function lockerFeatureToOptionItem(feature: LockerFeature): OptionItem {
  return {
    value: feature._id,
    label: feature.description,
  };
}

function cabinetTypeToOptionItem(cabinetType: CabinetType): OptionItem {
  return {
    value: cabinetType.name,
    label: cabinetType.displayNameForCabinet,
  };
}

function DraftCabinetModal({ cabinet, onClose, onSubmit }: CabinetFormModalProps) {
  const intl = useIntl();
  const isCreateModal = !cabinet;
  const { lockerFeatures, isLoading: isLoadingLockerFeatures } = useLockerFeatures();
  const { lockerSizeOptions, isLoading: isLoadingCabinetTypes } = useLockerSizeOptions();
  const header = (
    <h1 className="uib-modal__title">
      {intl.formatMessage({
        id: `cabinets.${isCreateModal ? 'create' : 'edit'}`,
      })}
    </h1>
  );
  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      additionalClassNames="overflow-visible"
      content={
        isLoadingCabinetTypes || isLoadingLockerFeatures ? (
          <LoadingIndicator />
        ) : (
          <CabinetForm
            cabinet={cabinet}
            onSubmit={onSubmit}
            onCancel={onClose}
            cabinetTypes={lockerSizeOptions.map(cabinetTypeToOptionItem).reverse()}
            lockerFeatures={lockerFeatures.map(lockerFeatureToOptionItem)}
          />
        )
      }
    />
  );
}

export { DraftCabinetModal };
