import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { CellProps } from 'react-table';

import { DeleteButton } from '../../../components/table/table-buttons/DeleteButton';
import { EditButton } from '../../../components/table/table-buttons/EditButton';
import { TableButtons } from '../../../components/table/table-buttons/TableButtons';
import { VerticalTable } from '../../../components/table/VerticalTable';
import { useTimePeriod } from '../../../hooks/useTimePeriod';
import { Qualification } from '../../../types/types';
import { bindestrich } from '../../../utils/bindestrich';

interface QualificationsTableProps {
  qualifications: Qualification[];
  onSelectQualificationForDeletion: Dispatch<SetStateAction<Qualification | undefined>>;
  onSelectQualificationForEditing: React.Dispatch<React.SetStateAction<Qualification | undefined>>;
}

function QualificationsTable({
  qualifications,
  onSelectQualificationForDeletion,
  onSelectQualificationForEditing,
}: QualificationsTableProps) {
  const intl = useIntl();
  const { yearsMonthsWeeks } = useTimePeriod();
  const columns = [
    {
      Header: intl.formatMessage({ id: 'general.name' }),
      accessor: 'name',
    },
    {
      Header: intl.formatMessage({ id: 'qualifications.periodOfValidity' }),
      id: 'validity',
      accessor: (row: Qualification) => {
        const data = row as Qualification;
        return bindestrich(
          yearsMonthsWeeks(data.validityYears, data.validityMonths, data.validityWeeks),
          intl.formatMessage({ id: 'qualifications.targetDate' })
        );
      },
    },
    {
      Header: intl.formatMessage({ id: 'general.actions' }),
      id: 'actions',
      Cell: ({ row }: CellProps<Qualification>) => {
        const qualification = row.original;
        return (
          <TableButtons>
            <EditButton
              onClick={() => {
                onSelectQualificationForEditing(qualification);
              }}
            />
            <DeleteButton
              onClick={e => {
                e.stopPropagation();
                onSelectQualificationForDeletion(qualification);
              }}
            />
          </TableButtons>
        );
      },
    },
  ];

  const sortBy = [
    {
      id: 'name',
      desc: false,
    },
  ];

  return (
    <VerticalTable
      sortBy={sortBy}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      columns={columns}
      data={qualifications}
    />
  );
}

export { QualificationsTable };
