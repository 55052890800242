import { CreateEntityButton } from '../../components/common/crud/CreateEntityButton';
import { SimpleNotification } from '../../components/common/notification/SimpleNotification';
import { CreateQuantityRestrictionDialog } from '../../components/quantity-restrictions/create/CreateQuantityRestrictionDialog';
import { EditQuantityRestrictionDialog } from '../../components/quantity-restrictions/edit/EditQuantityRestrictionDialog';
import { QuantityRestrictionsPageHeader } from '../../components/quantity-restrictions/QuantityRestrictionsPageHeader';
import { QuantityRestrictionsTable } from '../../components/quantity-restrictions/QuantityRestrictionsTable';
import { CrudPageUiStateProvider } from '../../context/CrudPageUiContext';
import { NotificationMessageProvider } from '../../context/notificationMessageContext';

function QuantityRestrictionsPage() {
  return (
    <NotificationMessageProvider>
      <CrudPageUiStateProvider>
        <QuantityRestrictionsPageHeader>
          <CreateEntityButton />
        </QuantityRestrictionsPageHeader>
        <CreateQuantityRestrictionDialog />
        <QuantityRestrictionsTable />
        <EditQuantityRestrictionDialog />
        <SimpleNotification />
      </CrudPageUiStateProvider>
    </NotificationMessageProvider>
  );
}
export { QuantityRestrictionsPage };
