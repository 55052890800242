import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { CellProps } from 'react-table';

import { DeleteButton } from '../../../components/table/table-buttons/DeleteButton';
import { EditButton } from '../../../components/table/table-buttons/EditButton';
import { TableButtons } from '../../../components/table/table-buttons/TableButtons';
import { VerticalTable } from '../../../components/table/VerticalTable';
import { Contractor } from '../../../types/contractors/Contractor';
import { bindestrich } from '../../../utils/bindestrich';

interface ContractorsTableProps {
  contractors: Contractor[];
  onSelectContractorForEditing: Dispatch<SetStateAction<Contractor | undefined>>;
  onSelectContractorForDeletion: Dispatch<SetStateAction<Contractor | undefined>>;
}

function ContractorsTable({
  contractors,
  onSelectContractorForEditing,
  onSelectContractorForDeletion,
}: ContractorsTableProps) {
  const intl = useIntl();

  const columns = [
    {
      Header: intl.formatMessage({ id: 'contractors.name' }),
      accessor: 'name',
    },
    {
      Header: intl.formatMessage({ id: 'contractors.contactPerson' }),
      accessor: (row: Contractor) => {
        const data = row as Contractor;
        return bindestrich(data.contactPerson);
      },
    },
    {
      Header: intl.formatMessage({ id: 'contractors.contactEmail' }),
      accessor: (row: Contractor) => {
        const data = row as Contractor;
        return bindestrich(data.contactEmail);
      },
    },
    {
      Header: intl.formatMessage({ id: 'general.actions' }),
      id: 'actions',
      Cell: ({ row }: CellProps<Contractor>) => {
        const contractor = row.original;
        return (
          <TableButtons>
            <EditButton
              onClick={() => {
                onSelectContractorForEditing(contractor);
              }}
            />
            <DeleteButton
              onClick={() => {
                onSelectContractorForDeletion(contractor);
              }}
            />
          </TableButtons>
        );
      },
    },
  ];

  const sortBy = [
    {
      id: 'name',
      desc: false,
    },
  ];

  return (
    <VerticalTable
      sortBy={sortBy}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      columns={columns}
      data={contractors}
    />
  );
}

export { ContractorsTable };
