import { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { OdxIconSet } from '../../types/components/Icon';
import { Icon } from '../library/icons/Icon';

import { Logo } from './Logo';

export interface MenuItem {
  label: string;
  to: string;
  component?: () => ReactElement;
}

export interface BurgerMenuProps {
  isCollapsed?: boolean;
  onCloseMenu: () => void;
  menuItems: MenuItem[];
}

function BurgerMenu({ isCollapsed, onCloseMenu, menuItems }: BurgerMenuProps) {
  const className = `uib-main-menu ${isCollapsed ? 'is-collapsed' : ''}`;

  const location = useLocation();
  const navLinkItems = menuItems.map(item => {
    const isActive = location.pathname === item.to;
    const itemClassName = isActive ? 'uib-main-menu__item is-active' : 'uib-main-menu__item';

    return (
      <NavLink to={item.to} key={item.to} className={itemClassName} onClick={onCloseMenu}>
        {item.label}
      </NavLink>
    );
  });

  return (
    <aside className={className}>
      <div className="uib-main-menu__inner">
        <div className="uib-main-menu__header">
          <div className="uib-main-menu__logo">
            <Logo />
          </div>
          <button className="uib-main-menu__close-button" onClick={onCloseMenu}>
            <i className="uib-icon uib-icon--close" />
          </button>
        </div>
        <div className="uib-main-menu__body">
          <NavLink onClick={onCloseMenu} to="/systems">
            <div className="home-button">
              <span>RR Systems Overview</span>
              <Icon iconName={'system'} iconSet={OdxIconSet.SAFETY} />
            </div>
          </NavLink>
          <div className="burger-list-scrollable">{navLinkItems}</div>
        </div>
      </div>
      <div className="uib-main-menu__backdrop" onClick={onCloseMenu} />
    </aside>
  );
}

export { BurgerMenu };
