import { deDE } from '@mui/x-data-grid/locales';
import { enUS } from '@mui/x-data-grid/locales';
import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import Cookies from 'universal-cookie';

import translations_de from '../language/de.json';
import translations_en from '../language/en.json';

export interface LanguageOption {
  value: LanguageOptionValue;
  label: string;
  translations: any;
  dataGridLocale: any;
}

enum LanguageOptionValue {
  en = 'en',
  de = 'de',
}

const languageOptions: LanguageOption[] = [
  {
    value: LanguageOptionValue.en,
    label: 'English',
    translations: translations_en,
    dataGridLocale: enUS,
  },
  {
    value: LanguageOptionValue.de,
    label: 'Deutsch',
    translations: translations_de,
    dataGridLocale: deDE,
  },
];

interface LanguageContextApi {
  currentLanguage: LanguageOption;
  selectLanguage: (language: string) => void;
}

const LanguageContext = createContext<LanguageContextApi | undefined>(undefined);

interface LanguageProviderProps {
  children: ReactNode;
}

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const cookies = new Cookies();

  const [currentLanguage, setCurrentLanguage] = useState<LanguageOption>(languageOptions[0]);

  useEffect(() => {
    const languageCookie = cookies.get('backoffice-language');
    const savedLanguage = languageOptions.find(option => option.value === languageCookie);
    if (savedLanguage) {
      setCurrentLanguage(savedLanguage);
    }
  }, []);

  const selectLanguage = (language: string) => {
    const selectedLanguage = languageOptions.find(option => option.value === language);
    if (selectedLanguage) {
      setCurrentLanguage(selectedLanguage);
      cookies.set('backoffice-language', selectedLanguage.value, {
        path: '/',
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      });
    }
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, selectLanguage }}>
      <IntlProvider locale={currentLanguage.value} messages={currentLanguage.translations}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

const useLanguageContext = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguageContext must be used within a LanguageProvider');
  }
  return context;
};

export { LanguageProvider, useLanguageContext, languageOptions, LanguageOptionValue };
