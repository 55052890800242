import { usePutSystem } from '../../../api/hooks/useSystemsApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { System } from '../../../types/systems/System';
import { SystemFormValues } from '../../../types/systems/SystemFormValues';
import { SystemParameter } from '../../../types/systems/SystemParameter';
import { ApiError } from '../../../types/types';
import { EditEntityDialogCloseButton } from '../../common/crud/EditEntityDialogCloseButton';
import { FormSubmitButton } from '../../library/buttons/FormSubmitButton';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';
import { SystemForm } from '../form/SystemForm';

export function EditSystemForm() {
  const { entityToEdit, setEntityToEdit } = useCrudPageUiStateContext<System>();
  const { setNotificationMessage } = useNotificationMessageContext();
  const formValues = getInitialFormValues(entityToEdit);
  const {
    mutateAsync: putSystem,
    reset: resetPutSystem,
    isLoading,
  } = usePutSystem(entityToEdit?.id ?? '');

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <SystemForm
          initialFormValues={formValues}
          handleSubmit={handleSubmit}
          buttons={EditSystemFormButtons()}
        />
      )}
    </>
  );

  function handleSubmit(formValues: SystemFormValues) {
    putSystem(toParam(formValues), {
      onSuccess: () => {
        resetPutSystem();
        setNotificationMessage('crud.edited', NotificationType.Success);
        setEntityToEdit(undefined);
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          const errorData = error.response.data as ApiError;
          if (errorData.code) {
            setNotificationMessage(`${errorData.code}`, NotificationType.Error);
          } else {
            setNotificationMessage('crud.generic-edit-error', NotificationType.Error);
          }
        }
      },
    });
  }
  function toParam(formValues: SystemFormValues): SystemParameter {
    return formValues;
  }
}

function getInitialFormValues(system: System | undefined): SystemFormValues {
  if (!system) throw new Error();
  return {
    client: system.client,
    country: system.country,
    city: system.city,
    site: system.site,
    notificationLanguage: system.notificationLanguage,
    adminEmails: system.adminEmails,
    status: system.status,
    gdcTenantId: system.gdcTenantId,
  };
}

function EditSystemFormButtons() {
  return (
    <>
      <EditEntityDialogCloseButton />
      <FormSubmitButton />
    </>
  );
}
