import { usePutAlertRule } from '../../../api/hooks/useAlertRulesApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { AlertRule } from '../../../types/alert-rules/AlertRule';
import { AlertRuleFormValues } from '../../../types/alert-rules/AlertRuleFormValues';
import { AlertRuleParameter } from '../../../types/alert-rules/AlertRuleParameter';
import { AlertRuleType } from '../../../types/alert-rules/AlertRuleType';
import { AlertRuleUnit } from '../../../types/alert-rules/AlertRuleUnit';
import { ApiError } from '../../../types/types';
import { EditEntityDialogCloseButton } from '../../common/crud/EditEntityDialogCloseButton';
import { FormSubmitButton } from '../../library/buttons/FormSubmitButton';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';
import { AlertRuleForm } from '../form/AlertRuleForm';
import { calculateThreshold, calculateUserInputUnit } from '../logic/AlertRuleUnitCalculator';

export function EditAlertRuleForm() {
  const { setNotificationMessage } = useNotificationMessageContext();
  const { entityToEdit: alertRuleToEdit } = useCrudPageUiStateContext<AlertRule>();
  const {
    mutateAsync: editAlertRule,
    reset: resetEditAlertRule,
    isLoading,
  } = usePutAlertRule(alertRuleToEdit?.id ?? '');
  const { setEntityToEdit: setAlertRuleToEdit } = useCrudPageUiStateContext();
  const formValues = getInitialFormValues(alertRuleToEdit);
  const buttons = CreateAlertRuleFormButtons();
  function handleSubmit(alertRuleParam: AlertRuleParameter) {
    editAlertRule(alertRuleParam, {
      onSuccess: () => {
        resetEditAlertRule();
        setNotificationMessage('alert-rules.edited', NotificationType.Success);
        setAlertRuleToEdit(undefined);
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          const errorData = error.response.data as ApiError;
          if (errorData.code) {
            setNotificationMessage(`alert-rules.${errorData.code}`, NotificationType.Error);
          } else {
            setNotificationMessage('alert-rules.generic-edit-error', NotificationType.Error);
          }
        }
      },
    });
  }
  return (
    <>
      {isLoading && <LoadingIndicator />}
      <AlertRuleForm initialFormValues={formValues} handleSubmit={handleSubmit} buttons={buttons} />
    </>
  );
}
function CreateAlertRuleFormButtons() {
  return (
    <>
      <EditEntityDialogCloseButton />
      <FormSubmitButton />
    </>
  );
}
function getInitialFormValues(alertRule: AlertRule | undefined): AlertRuleFormValues {
  const threshold = alertRule?.threshold ?? 0;
  const unit = alertRule?.alertRuleUnit ?? AlertRuleUnit.Minutes;
  return {
    alertRuleType: alertRule?.alertRuleType ?? AlertRuleType.ReturnOverdueWarning,
    name: alertRule?.name ?? '',
    threshold: calculateThreshold(threshold, unit),
    unit: calculateUserInputUnit(threshold, unit),
    itemTypeId: alertRule?.itemTypeId ?? '',
    stationId: alertRule?.stationId ?? '',
    receiverRoles: alertRule?.receiverRoles ?? [],
    receiverEmails: alertRule?.receiverEmails ?? [],
  };
}
