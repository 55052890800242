import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { ImportIconButton } from '../../library/buttons/ImportIconButton';

export function ImportEntitiesButton() {
  const { setOpenImportDialog } = useCrudPageUiStateContext();
  return (
    <ImportIconButton
      id={'ImportEntityButton'}
      onClick={() => {
        setOpenImportDialog(true);
      }}
    />
  );
}
