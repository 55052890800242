import { useIntl } from 'react-intl';

import { useContractors } from '../../../api/hooks/useContractorApi';
import { Contractor } from '../../../types/contractors/Contractor';
import {
  AutoCompleteOption,
  FormMultiAutocomplete,
} from '../../library/form/FormMultiAutocomplete';

export function MultiContractorsDropDown() {
  const intl = useIntl();
  const { contractors } = useContractors();
  const contractorDropDownItems = getOptions(contractors);
  const label = intl.formatMessage({ id: 'contractors.self' });
  return (
    <FormMultiAutocomplete
      id={'contractorDropdown'}
      label={label}
      name={'contractorIds'}
      options={contractorDropDownItems}
    />
  );
}

function getOptions(contractors: Contractor[]): AutoCompleteOption[] {
  const result = contractors.map(contractor => ({
    id: contractor._id,
    label: contractor.name,
  }));
  return result;
}
