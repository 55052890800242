import { ReactNode } from 'react';

import { BumpTestQuotaPieChartCard } from '../../components/dashboard/BumpTestQuotaPieChartCard';
import { ContractStockChartCard } from '../../components/dashboard/ContractStockChartCard';
import { ExpiringQualificationsChartCard } from '../../components/dashboard/ExpiringQualificationsChartCard';
import { GasDetectionDeviceMisuseChartCard } from '../../components/dashboard/GasDetectionDeviceMisuseChartCard';
import { ItemStatusChartCard } from '../../components/dashboard/ItemStatusChartCard';
import { PendingRentersChartCard } from '../../components/dashboard/PendingRentersChartCard';
import { RentCountChartCard } from '../../components/dashboard/RentCountChartCard';
import { StationHealthChartCard } from '../../components/dashboard/StationHealthChartCard';
import { Grid } from '../../components/library/containers/Grid';
import { DashboardProvider } from '../../context/DashboardContext';

export function ServiceTechnicianDashboard() {
  return (
    <DashboardProvider>
      <DashboardBox>
        <DashboardColumn>
          <StationHealthChartCard />
          <ContractStockChartCard />
        </DashboardColumn>
        <DashboardColumn>
          <GasDetectionDeviceMisuseChartCard />
          <BumpTestQuotaPieChartCard />
          <ItemStatusChartCard />
        </DashboardColumn>
        <DashboardColumn>
          <PendingRentersChartCard />
          <ExpiringQualificationsChartCard />
          <RentCountChartCard />
        </DashboardColumn>
      </DashboardBox>
    </DashboardProvider>
  );
}
interface DashboardBoxProps {
  children?: ReactNode;
}
function DashboardBox({ children }: DashboardBoxProps) {
  return (
    <Grid
      container
      style={{
        backgroundColor: 'white',
        width: '100%',
        minWidth: '600px',
        padding: '20px',
        maxWidth: '100%',
        justifyContent: 'center',
      }}
    >
      {children}
    </Grid>
  );
}
interface DashboardColumnProps {
  children?: ReactNode;
}
function DashboardColumn({ children }: DashboardColumnProps) {
  return (
    <Grid item lg={6} xl={4}>
      {children}
    </Grid>
  );
}
