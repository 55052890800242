import { TextField as MuiTextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

interface TextFieldProps {
  id: string;
  label: string;
  name: string;
  required: boolean;
  disabled: boolean;
  fullWidth?: boolean;
  type?: string;
}
export function FormTextField({
  id,
  label,
  name,
  required,
  disabled,
  fullWidth = true,
  type,
}: TextFieldProps) {
  const { register } = useFormContext();
  return (
    <MuiTextField
      id={id}
      label={label}
      autoComplete={'new-password'}
      fullWidth={fullWidth}
      required={required}
      disabled={disabled}
      type={type}
      sx={{ my: 1 }}
      {...register(name, {
        required: required,
        disabled: disabled,
      })}
    />
  );
}
