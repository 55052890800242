import type { XYCoord, Identifier } from 'dnd-core';
import React, { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { Row } from 'react-table';

interface DragItemProps {
  row: Row;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  isSelected: boolean;
  onRowClick?: (row: { [key: string]: unknown }) => void;
  onItemDropped?: (index: number) => void;
  isFiltering?: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function DragItem({
  row,
  index,
  moveRow,
  isSelected = false,
  onRowClick,
  onItemDropped,
  isFiltering = false,
}: DragItemProps) {
  const dropRef = useRef<HTMLTableRowElement>(null);
  const dragRef = useRef(null);

  const DND_ITEM_TYPE = 'row';

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: DND_ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop(item: DragItem) {
      onItemDropped?.(item.index);
    },
  });

  const [, drag, preview] = useDrag(() => ({
    type: DND_ITEM_TYPE,
    item: () => {
      return { type: DND_ITEM_TYPE, index };
    },
    canDrag: !isFiltering,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  preview(drop(dropRef));
  drag(dragRef);

  const rowClassName = (isRowSelected: boolean) => {
    return `uib-table__row ${isRowSelected ? 'uib-table__row--selected' : ''}`;
  };

  return (
    <tr
      className={rowClassName(isSelected)}
      ref={dropRef}
      onClick={() => onRowClick?.(row.original)}
    >
      <td className="drag-cell">
        <div className="drag-icon-container">
          {!isFiltering ? (
            <i className={`uib-icon uib-icon--menu`} ref={dragRef} />
          ) : (
            <i className={`uib-icon uib-icon--menu is-filtering`} />
          )}
        </div>
        <div className={isFiltering ? 'drag-cover' : undefined} />
      </td>
      {row.cells.map((cell, i) => {
        return (
          <td {...cell.getCellProps()} key={i}>
            {cell.render('Cell')}
          </td>
        );
      })}
    </tr>
  );
}
