import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  Theme,
  ThemeOptions,
} from '@mui/material/styles';
import React, { createContext, useState, useEffect, ReactNode } from 'react';

import { useLanguageContext } from './languageContext';

interface ThemeContextProps {
  theme: Theme;
}

interface ThemeProviderProps {
  children: ReactNode;
}

export const chartOdxBlue = '#355FAD';
export const chartOdxLightBlue = '#0091F7';
export const chartOdxGray = '#C7D3E0';
export const chartOdxGreen = '#09A491';
export const chartOdxSoftBlue = '#7AA6FC';
export const chartOdxDarkGray = '#495E6E';
export const chartOdxYellow = '#FFED72';
export const chartOdxRed = '#DE599A';
export const chartOdxPurple = '#A662E7';
export const chartOdxUltraBlue = '#35EED8';

export const chartOdxColors = [
  chartOdxBlue,
  chartOdxLightBlue,
  chartOdxGray,
  chartOdxGreen,
  chartOdxSoftBlue,
  chartOdxDarkGray,
  chartOdxYellow,
  chartOdxRed,
  chartOdxPurple,
  chartOdxUltraBlue,
];

export const odxPrimaryColor = '#002766';
export const odxSecondaryColor = '#c7d3e0';
export const odxSuccessColor = '#00dd31';
export const odxErrorColor = '#f30303';
export const odxInfoColor = '#002766';
export const odxWarningColor = '#ffe300';
export const odxAirBlue = '#0091F7';

export const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const { currentLanguage } = useLanguageContext();
  const [theme, setTheme] = useState(createTheme());

  const themeOptions: ThemeOptions = {
    palette: {
      primary: {
        main: odxPrimaryColor,
        contrastText: '#fff',
      },
      secondary: {
        main: odxSecondaryColor,
      },
      success: {
        main: odxSuccessColor,
        contrastText: '#009f26',
      },
      warning: {
        main: odxWarningColor,
        contrastText: '#b5a400',
      },
      error: {
        main: odxErrorColor,
      },
      info: {
        main: odxInfoColor,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1850,
      },
    },
  };

  useEffect(() => {
    setTheme(createTheme(themeOptions, currentLanguage.dataGridLocale));
  }, [currentLanguage]);

  return (
    <ThemeContext.Provider value={{ theme }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
