import React from 'react';

import { ChipSize, ChipVariant } from '../../types/components/Chip';
import { Icon } from '../library/icons/Icon';

import Button from './Button';

export interface ChipProps {
  removable?: boolean;
  size?: ChipSize;
  variant?: ChipVariant;
  onRemove?: () => void;
  onClick?: () => void;
  children: React.ReactNode;
}

export function Chip({
  removable = false,
  size = ChipSize.SMALL,
  variant = ChipVariant.SECONDARY,
  onRemove,
  onClick,
  children,
}: ChipProps) {
  const handleRemove = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRemove && onRemove();
  };

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <div className={`odx-chip odx-chip--${size} odx-chip--${variant}`} onClick={handleClick}>
      <div className={'odx-chip__content'}>{children}</div>
      {removable && (
        <Button
          type={'button'}
          id={'test'}
          additionalClasses={`odx-chip__action odx-button--${size} odx-button--${variant} odx-button`}
          onClick={handleRemove}
        >
          <Icon
            additionalClasses={`odx-icon--${size} odx-icon notranslate`}
            iconName={'close'}
          ></Icon>
        </Button>
      )}
    </div>
  );
}

export default Chip;
