import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

export function UploadFileButton() {
  const intl = useIntl();
  const {
    formState: { isSubmitting, isDirty, isValid },
  } = useFormContext();
  return (
    <Button
      id="uploadFileButton"
      type="submit"
      variant={'contained'}
      disabled={isSubmitting || !isDirty || !isValid}
    >
      {intl.formatMessage({ id: 'general.upload' })}
    </Button>
  );
}
