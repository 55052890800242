import { Autocomplete, TextField as MuiTextField } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';

export interface FormMultiTextFieldProps {
  id: string;
  label: string;
  name: string;
  placeholder?: string;
  validateInput?: (value: string) => boolean;
  inputIncorrectLabel: string;
  required?: boolean;
  disabled?: boolean;
}

export function FormMultiTextField({
  id,
  label,
  name,
  placeholder,
  validateInput,
  inputIncorrectLabel,
  required,
  disabled,
}: FormMultiTextFieldProps) {
  const intl = useIntl();
  const { getValues: getFormValues, setValue: setFormValue } = useFormContext();
  const [inputCorrect, setInputCorrect] = useState(true);
  const [ownValue, setOwnValue] = useState<string[]>(getFormValues(name));
  const [inputValue, setInputValue] = useState<string>();
  return (
    <Autocomplete
      multiple
      id={id}
      freeSolo
      value={ownValue}
      onChange={(event, value) => {
        onChange(value);
      }}
      autoSelect={true}
      options={[]}
      filterSelectedOptions
      renderInput={params => (
        <MuiTextField
          {...params}
          label={label}
          value={inputValue}
          onBlur={onInputBlur}
          onChange={event => {
            onInputChange(event.target.value);
          }}
          autoComplete={'new-password'}
          placeholder={placeholder}
          helperText={getHelperText(inputCorrect, inputIncorrectLabel, intl)}
          error={!inputCorrect}
          required={required && ownValue?.length === 0}
          disabled={disabled}
          sx={{ my: 1 }}
        />
      )}
    />
  );
  function onChange(newValue: string[]) {
    if (inputCorrect) {
      setFormValue(name, newValue, { shouldDirty: true, shouldValidate: true });
      setOwnValue(newValue);
    }
  }
  function onInputChange(value: string) {
    if (validateInput) {
      setInputCorrect(validateInput(value));
    }
    setInputValue(value);
  }
  function onInputBlur() {
    setInputValue('');
    setInputCorrect(true);
  }
}
function getHelperText(inputCorrect: boolean, label: string, intl: IntlShape): string {
  return inputCorrect ? '' : intl.formatMessage({ id: label });
}
