import { useIntl } from 'react-intl';

import { useDashboardContext } from '../../context/DashboardContext';
import { Connectivity, Station } from '../../types/types';
import { ChartCard } from '../library/charts/ChartCard';
import { Grid } from '../library/containers/Grid';
import { GridItem } from '../library/containers/GridItem';
import { Paper } from '../library/containers/Paper';
import { CheckIcon } from '../library/icons/CheckIcon';
import { WarningIcon } from '../library/icons/WarningIcon';

export function StationHealthChartCard() {
  const { stations, stationsIsLoading } = useDashboardContext();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'dashboard.stationHealthTitle' });
  const stationHealthDatas: StationHealthData[] = transformToStationHealthData(stations);
  return (
    <ChartCard
      title={title}
      content={<StationHealthOverview stationHealthDatas={stationHealthDatas} />}
      isLoading={stationsIsLoading}
      isEmpty={stationHealthDatas.length == 0}
    />
  );
}
interface StationHealthOverviewProps {
  stationHealthDatas: StationHealthData[];
}
function StationHealthOverview({ stationHealthDatas }: StationHealthOverviewProps) {
  return (
    <div style={{ overflow: 'hidden' }}>
      <div style={{ maxHeight: '385px', overflowY: 'scroll' }}>
        {stationHealthDatas.map(stationHealthData => {
          return (
            <StationHealthBar
              key={stationHealthData.stationId}
              stationHealthData={stationHealthData}
            />
          );
        })}
      </div>
    </div>
  );
}
interface StationHealthData {
  stationId: string;
  stationLocation: string;
  terminalOnline: boolean;
  iotAdapterOnline: boolean;
  scannerPluggedIn: boolean;
  lockerPluggedIn: boolean;
}
interface StationHealthBarProps {
  stationHealthData: StationHealthData;
}
function StationHealthBar({ stationHealthData }: StationHealthBarProps) {
  const intl = useIntl();
  return (
    <Paper style={{ padding: '15px', marginBottom: '5px', minWidth: '500px' }}>
      <Grid container>
        <StationLocationGirdCell stationLocation={stationHealthData.stationLocation} />
        <HealtHCheckGridCell
          isHealthy={stationHealthData.terminalOnline}
          label={intl.formatMessage({
            id: 'dashboard.stationHealth.terminalOnline',
          })}
        />
        <HealtHCheckGridCell
          isHealthy={stationHealthData.iotAdapterOnline}
          label={intl.formatMessage({
            id: 'dashboard.stationHealth.iotAdapterOnline',
          })}
        />
        <HealtHCheckGridCell
          isHealthy={stationHealthData.scannerPluggedIn}
          label={intl.formatMessage({
            id: 'dashboard.stationHealth.scannerPluggedIn',
          })}
        />
        <HealtHCheckGridCell
          isHealthy={stationHealthData.lockerPluggedIn}
          label={intl.formatMessage({
            id: 'dashboard.stationHealth.lockerPluggedIn',
          })}
        />
      </Grid>
    </Paper>
  );
}
interface StationLocationGirdCellProps {
  stationLocation: string;
}
function StationLocationGirdCell({ stationLocation }: StationLocationGirdCellProps) {
  return (
    <GridItem xs={3} style={{ textAlign: 'center' }}>
      <p>{stationLocation}</p>
    </GridItem>
  );
}
interface HealtHCheckGridCellProps {
  isHealthy: boolean;
  label: string;
}
function HealtHCheckGridCell({ isHealthy, label }: HealtHCheckGridCellProps) {
  return (
    <GridItem style={{ display: 'block' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isHealthy ? <CheckIcon /> : <WarningIcon />}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {label}
      </div>
    </GridItem>
  );
}

function transformToStationHealthData(stations: Station[]) {
  const stationHealthDatas = stations
    .filter(station => !!station.iotAdapterId)
    .map(station => {
      return {
        stationId: station._id,
        stationLocation: station.location,
        terminalOnline: station.stationHealth?.terminalModuleState == Connectivity.ONLINE,
        iotAdapterOnline:
          station.stationHealth?.scannerModuleState == Connectivity.ONLINE &&
          station.stationHealth?.lockerControllerModuleState == Connectivity.ONLINE,
        scannerPluggedIn: station.stationHealth?.scannerPlugState == 'Plugged',
        lockerPluggedIn: station.stationHealth?.lockerControllerPlugState == 'Plugged',
      };
    })
    .sort((a, b) => {
      const aCondition =
        a.terminalOnline || a.iotAdapterOnline || a.scannerPluggedIn || a.lockerPluggedIn;
      const bCondition =
        b.terminalOnline || b.iotAdapterOnline || b.scannerPluggedIn || b.lockerPluggedIn;
      if (!aCondition && bCondition) {
        return -1;
      }
      if (aCondition && !bCondition) {
        return 1;
      }
      return 0;
    });
  return stationHealthDatas;
}
