import React from 'react';

import { SystemFormValues } from '../../../types/systems/SystemFormValues';
import { Form } from '../../library/form/Form';

import { SystemAdminEmails } from './inputs/SystemAdminEmails';
import { SystemCityInput } from './inputs/SystemCityInput';
import { SystemClientInput } from './inputs/SystemClientInput';
import { SystemCountryInput } from './inputs/SystemCountryInput';
import { SystemGdcTenantIdInput } from './inputs/SystemGdcTenantIdInput';
import { SystemNotificationLanguageDropdown } from './inputs/SystemNotificationLanguageDropdown';
import { SystemSiteInput } from './inputs/SystemSiteInput';
import { SystemStatusDropdown } from './inputs/SystemStatusDropdown';

export interface SystemFormProps {
  initialFormValues: SystemFormValues;
  handleSubmit: (formInputValues: SystemFormValues, dirtyFields: string[]) => void;
  buttons: React.ReactNode;
}

export function SystemForm({ initialFormValues, handleSubmit, buttons }: SystemFormProps) {
  function onSubmit(formInputValues: SystemFormValues, dirtyFields: string[]) {
    handleSubmit(formInputValues, dirtyFields);
  }

  return (
    <Form initialFormValues={initialFormValues} onSubmit={onSubmit} buttons={buttons}>
      <SystemCountryInput />
      <SystemCityInput />
      <SystemClientInput />
      <SystemSiteInput />
      <SystemStatusDropdown />
      <SystemNotificationLanguageDropdown />
      <SystemAdminEmails />
      <SystemGdcTenantIdInput />
    </Form>
  );
}
