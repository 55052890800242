import { useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { LabeledDropdown } from '../../../../components/form/LabeledDropdown';
import { LabeledInput } from '../../../../components/form/LabeledInput';
import { LabeledSwitch } from '../../../../components/form/LabeledSwitch';
import { ItemType, XDockTestWorkflowPolicy } from '../../../../types/item-types/ItemType';

import { RawItemTypeData } from './ItemTypeForm';

interface GdcSpecificationsFormProps {
  register: UseFormRegister<RawItemTypeData>;
  watch: (data: string) => undefined | boolean;
  itemType?: ItemType;
  setValue: UseFormSetValue<RawItemTypeData>;
}

function GdcSpecificationsForm({
  register,
  watch,
  itemType,
  setValue,
}: GdcSpecificationsFormProps) {
  const intl = useIntl();
  const isEditForm = !!itemType;

  const xDockPolicyOptions = [
    {
      value: XDockTestWorkflowPolicy.INTERVAL,
      label: intl.formatMessage({
        id: 'item-types.xDockTestWorkflowPolicyInterval',
      }),
    },
    {
      value: XDockTestWorkflowPolicy.PER_RENT,
      label: intl.formatMessage({
        id: 'item-types.xDockTestWorkflowPolicyPerRent',
      }),
    },
  ];

  const [validateIntervalErrorModalOpen, setValidateIntervalErrorModalOpen] =
    useState<boolean>(false);

  const [gdcCalibrationSwitch, setGdcCalibrationSwitch] = useState<boolean>(
    itemType ? itemType.gdcCalibration : false
  );
  const watchGdcCalibrationInterval =
    itemType?.gdcCalibrationInterval || watch('gdcCalibrationInterval');
  const watchGdcCalibrationBlockPeriod =
    itemType?.gdcCalibrationBlockPeriod || watch('gdcCalibrationBlockPeriod');
  const [checkXDockTestWorkflowPolicy, setCheckXDockTestWorkflowPolicy] = useState<
    XDockTestWorkflowPolicy | undefined
  >(itemType ? itemType.xDockTestWorkflowPolicy : undefined);

  const validateGdcCalibrationInterval = (calibrationInterval: number) => {
    if (
      typeof watchGdcCalibrationBlockPeriod === 'number' &&
      calibrationInterval < watchGdcCalibrationBlockPeriod &&
      !isEditForm
    ) {
      setValidateIntervalErrorModalOpen(true);
      return false;
    }
    setValidateIntervalErrorModalOpen(false);
    return true;
  };

  const validateGdcCalibrationBlockPeriod = (calibrationBlockPeriod: number) => {
    if (
      typeof watchGdcCalibrationInterval === 'number' &&
      calibrationBlockPeriod > watchGdcCalibrationInterval &&
      !isEditForm
    ) {
      setValidateIntervalErrorModalOpen(true);
      return false;
    }
    setValidateIntervalErrorModalOpen(false);
    return true;
  };

  return (
    <div className="modal-form single-col expiry-date-form">
      <label className="uib-form__label expiry-date-form-label">
        {intl.formatMessage({ id: 'item-types.GdcSpecifications' })}
      </label>
      <div className="modal-form double-col">
        <LabeledDropdown
          label={intl.formatMessage({
            id: 'item-types.xDockTestWorkflowPolicy',
          })}
          additionalClassName="x-dock-time-input increase-width expiry-date-labeled-input"
          id="xDockTestWorkflowPolicy"
          options={xDockPolicyOptions}
          placeholder={
            itemType?.xDockTestable !== false && itemType?.xDockTestable !== undefined
              ? intl.formatMessage({
                  id: `item-types.xDockTestWorkflowPolicy${itemType?.xDockTestWorkflowPolicy}`,
                })
              : intl.formatMessage({ id: 'general.pleaseChooseEllipsis' })
          }
          onChange={e => {
            setCheckXDockTestWorkflowPolicy(e as XDockTestWorkflowPolicy);
          }}
          setValue={setValue}
          props={{
            ...register('xDockTestWorkflowPolicy', { required: !isEditForm }),
          }}
          dataTestId="xDockPolicyDropDown"
        />
        {checkXDockTestWorkflowPolicy === XDockTestWorkflowPolicy.INTERVAL && (
          <LabeledInput
            id="xDockTestExpirationTime"
            label={intl.formatMessage({
              id: 'item-types.xDockTestExpirationTime',
            })}
            type="number"
            min="1"
            max="120"
            props={{
              ...register('xDockTestExpirationTime', {
                required: checkXDockTestWorkflowPolicy === XDockTestWorkflowPolicy.INTERVAL,
              }),
            }}
            className="x-dock-time-input increase-width expiry-date-labeled-input tooltip-message"
            inputSiblings={<span>{intl.formatMessage({ id: 'general.days' })}</span>}
          />
        )}
      </div>
      <div className="modal-form double-col">
        <LabeledInput
          id="xDockAlertingTime"
          label={intl.formatMessage({ id: 'item-types.xDockAlertingTime' })}
          type="number"
          min="1"
          max="60"
          placeholder={'10'}
          props={{
            ...register('xDockAlertingTime', { required: true }),
          }}
          className="x-dock-time-input increase-width expiry-date-labeled-input"
          inputSiblings={<span>{intl.formatMessage({ id: 'general.minutes' })}</span>}
        />
        <LabeledInput
          id="xDockTestExpirationTimeInStorage"
          label={intl.formatMessage({
            id: 'item-types.xDockTestExpirationTimeInStorage',
          })}
          type="number"
          min="1"
          max="120"
          placeholder="7"
          props={{
            ...register('xDockTestExpirationTimeInStorage', {
              required: true,
            }),
          }}
          className="x-dock-time-input increase-width expiry-date-labeled-input tooltip-message"
          inputSiblings={<span>{intl.formatMessage({ id: 'general.days' })}</span>}
          tooltipMessage={intl.formatMessage({
            id: 'item-types.xDockTestExpirationTimeInStorageMessage',
          })}
        />
      </div>
      <div className="modal-form double-col">
        <LabeledSwitch
          id="gdcCalibration"
          label={intl.formatMessage({ id: 'item-types.gdcCalibration' })}
          isSelected={gdcCalibrationSwitch}
          additionalClasses="extra-margin-space x-dock-time-input increase-width"
          props={{
            ...register('gdcCalibration', { required: false }),
          }}
          onClick={() => {
            setGdcCalibrationSwitch(!gdcCalibrationSwitch);
          }}
        />
      </div>
      {gdcCalibrationSwitch && (
        <div className="modal-form double-col">
          <LabeledInput
            id="gdcCalibrationInterval"
            label={intl.formatMessage({
              id: 'item-types.gdcCalibrationInterval',
            })}
            type="number"
            min="1"
            max="360"
            placeholder="120"
            props={{
              ...register('gdcCalibrationInterval', {
                required: true,
                validate: validateGdcCalibrationInterval,
                valueAsNumber: true,
              }),
            }}
            className="x-dock-time-input increase-width expiry-date-labeled-input"
            inputSiblings={<span>{intl.formatMessage({ id: 'general.days' })}</span>}
          />
          <LabeledInput
            id="gdcCalibrationBlockPeriod"
            label={intl.formatMessage({
              id: 'item-types.gdcCalibrationBlockPeriod',
            })}
            type="number"
            min="0"
            max="30"
            placeholder="0"
            props={{
              ...register('gdcCalibrationBlockPeriod', {
                validate: validateGdcCalibrationBlockPeriod,
                required: true,
                valueAsNumber: true,
              }),
            }}
            required={true}
            className="x-dock-time-input increase-width expiry-date-labeled-input tooltip-message"
            inputSiblings={<span>{intl.formatMessage({ id: 'general.days' })}</span>}
          />
          {validateIntervalErrorModalOpen && (
            <span className="form-error">
              {intl.formatMessage({
                id: 'item-types.blockPeriodGreaterThanInterval.error',
              })}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export { GdcSpecificationsForm };
