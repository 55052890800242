import { IntlShape, useIntl } from 'react-intl';

import { ALERT_RULES_QUERY_KEY, useGetAlertRules } from '../../api/hooks/useAlertRulesApi';
import { useItemTypes } from '../../api/hooks/useRentingApi';
import { useStations } from '../../api/hooks/useStationApi';
import { AlertRule } from '../../types/alert-rules/AlertRule';
import { AlertRuleRow, AlertRuleRowFieldName } from '../../types/alert-rules/AlertRuleRow';
import { AlertRuleType } from '../../types/alert-rules/AlertRuleType';
import { AlertRuleUnit } from '../../types/alert-rules/AlertRuleUnit';
import { getItemTypeName, ItemType } from '../../types/item-types/ItemType';
import { Station } from '../../types/types';
import { entityDeleteButtonFactoryMethod } from '../common/crud/DeleteEntityButton';
import { entityEditButtonFactoryMethod } from '../common/crud/EditEntityButton';
import { DataTableColumn, DataTable, ColumnType } from '../library/table/DataTable';

import { calculateThreshold, calculateUserInputUnit } from './logic/AlertRuleUnitCalculator';

function AlertRulesTable() {
  const { alertRules, isLoading: alertRuleLoading } = useGetAlertRules();
  const { itemTypes, isLoading: itemTypesLoading } = useItemTypes();
  const { stations, isLoading: stationsLoading } = useStations();
  const isLoading = alertRuleLoading || itemTypesLoading || stationsLoading;
  const intl = useIntl();
  const alertRuleColumns = createAlertRuleColumns(intl);
  const alertRuleRows = createRows(alertRules, intl, itemTypes, stations);
  function actionButtonsFactorymethod(alertRuleId: string) {
    const alertRule = alertRules.find(a => a.id == alertRuleId);
    return (
      <>
        {entityEditButtonFactoryMethod(alertRule)}
        {deleteButtonFactoryMethod(alertRule)}
      </>
    );
  }
  return (
    <DataTable
      rows={alertRuleRows}
      columns={alertRuleColumns}
      actionButtonsFactoryMethod={actionButtonsFactorymethod}
      loading={isLoading}
    />
  );
}
function deleteButtonFactoryMethod(alertRule: AlertRule | undefined) {
  return entityDeleteButtonFactoryMethod(alertRule?.id ?? '', 'alert-rule', ALERT_RULES_QUERY_KEY);
}
function createRows(
  alertRules: AlertRule[],
  intl: IntlShape,
  itemTypes: ItemType[],
  stations: Station[]
): AlertRuleRow[] {
  return alertRules.map((alertRule: AlertRule): AlertRuleRow => {
    return {
      id: alertRule.id,
      alertRuleType: translateAlertRuleType(alertRule.alertRuleType, intl),
      name: alertRule.name,
      threshold: calculateThreshold(alertRule.threshold, alertRule.alertRuleUnit),
      unit: calculateUnit(alertRule.threshold, alertRule.alertRuleUnit, intl),
      receiverEmails: alertRule.receiverEmails,
      receiverRoles: translateRoles(alertRule.receiverRoles, intl),
      itemType: getItemTypeName(alertRule.itemTypeId, itemTypes),
      station: getStationName(alertRule.stationId, stations),
    };
  });
}
function createAlertRuleColumns(intl: IntlShape) {
  const alertRuleColumns: DataTableColumn[] = [
    {
      field: AlertRuleRowFieldName.Name,
      headerName: intl.formatMessage({ id: 'alert-rules.name' }),
    },
    {
      field: AlertRuleRowFieldName.AlertRuleType,
      headerName: intl.formatMessage({ id: 'alert-rules.type' }),
    },
    {
      field: AlertRuleRowFieldName.Threshold,
      headerName: intl.formatMessage({ id: 'alert-rules.threshold' }),
    },
    {
      field: AlertRuleRowFieldName.Unit,
      headerName: intl.formatMessage({ id: 'alert-rules.unit' }),
    },
    {
      field: AlertRuleRowFieldName.ReceiverRoles,
      headerName: intl.formatMessage({ id: 'alert-rules.receiverRoles' }),
      type: ColumnType.List,
    },
    {
      field: AlertRuleRowFieldName.ReceiverEmails,
      headerName: intl.formatMessage({ id: 'alert-rules.receiverEmails' }),
      type: ColumnType.List,
    },
    {
      field: AlertRuleRowFieldName.ItemType,
      headerName: intl.formatMessage({ id: 'item-types.singular' }),
    },
    {
      field: AlertRuleRowFieldName.Station,
      headerName: intl.formatMessage({ id: 'stations.singular' }),
    },
  ];
  return alertRuleColumns;
}
function translateAlertRuleType(alertRuleType: AlertRuleType, intl: IntlShape): string {
  return intl.formatMessage({ id: `alert-rules.${alertRuleType}` });
}
function translateRoles(roles: string[], intl: IntlShape): string[] {
  return roles.map(r => intl.formatMessage({ id: `alert-rules.${r}` }));
}
function getStationName(stationId: string, stations: Station[]): string {
  return stations.find(s => s._id === stationId)?.location ?? '';
}

function calculateUnit(threshold: number, unit: AlertRuleUnit, intl: IntlShape): string {
  return intl.formatMessage({
    id: `alert-rules.unit-${calculateUserInputUnit(threshold, unit)}`,
  });
}

export { AlertRulesTable };
