export enum ChipVariant {
  SECONDARY = 'secondary',
  HIGHLIGHT = 'highlight',
  SUCCESS = 'success',
  DANGER = 'danger',
}

export enum ChipSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}
