import { useIntl } from 'react-intl';

import { FormTextField } from '../../../library/form/FormTextField';

export function RenterEmployeeNumberInput() {
  const intl = useIntl();
  return (
    <FormTextField
      id={'RenterEmployeeNumberInput'}
      label={intl.formatMessage({ id: 'renters.employeeNo' })}
      required={false}
      disabled={false}
      name={'humanRenterId'}
    />
  );
}
