import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { UseFormRegister, UseFormSetValue, UseFormUnregister } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { LabeledInput } from '../../../../components/form/LabeledInput';
import { GdcCalibrationResult } from '../../../../types/item-types/ItemType';

import { ItemFormData } from './ItemForm';

interface ItemFormOptionalFieldsProps {
  register: UseFormRegister<ItemFormData>;
  gdcCalibrationTestResponse?: GdcCalibrationResult | null;
  setValue: UseFormSetValue<ItemFormData>;
  unregister: UseFormUnregister<ItemFormData>;
  itemIdGdcCalibrationTest: string | undefined;
}

function ItemFormGdcOptionalFields({
  register,
  gdcCalibrationTestResponse,
  setValue,
  unregister,
  itemIdGdcCalibrationTest,
}: ItemFormOptionalFieldsProps) {
  const intl = useIntl();
  const [labelInputDisabled, setLabelInputDisabled] = useState<boolean>(true);
  const [overwriteManually, setOverwriteManually] = useState<boolean>(false);
  const [overwriteManuallyDisabled, setOverwriteManuallyDisabled] = useState<boolean>(false);

  const gdcCalibrationDateDefault = gdcCalibrationTestResponse?.testDateTime
    ? dayjs(gdcCalibrationTestResponse.testDateTime).format('YYYY-MM-DD')
    : undefined;
  const hasGdcCalibrationSuccessResult =
    gdcCalibrationTestResponse && gdcCalibrationTestResponse.testResult === 'Passed';

  const handleOverwriteCheckBox = () => {
    if (hasGdcCalibrationSuccessResult) {
      if (overwriteManually) {
        setValue('lastGdcCalibrationDate', gdcCalibrationDateDefault);
        unregister('lastGdcCalibrationDate');
      }
      setOverwriteManually(!overwriteManually);
      setLabelInputDisabled(!labelInputDisabled);
    }
  };

  useEffect(() => {
    if (itemIdGdcCalibrationTest) {
      if (hasGdcCalibrationSuccessResult) {
        setValue('lastGdcCalibrationDate', gdcCalibrationDateDefault);
        setLabelInputDisabled(true);
        setOverwriteManually(false);
        setOverwriteManuallyDisabled(false);
      } else {
        setValue('lastGdcCalibrationDate', undefined);
        setLabelInputDisabled(false);
        setOverwriteManually(true);
        setOverwriteManuallyDisabled(true);
      }
    } else {
      setLabelInputDisabled(true);
    }
  }, [gdcCalibrationTestResponse]);

  return (
    <>
      <LabeledInput
        type="date"
        id="dateId"
        dataTestid="lastGdcCalibrationDate"
        defaultValue={gdcCalibrationDateDefault}
        label={intl.formatMessage({ id: 'items.lastGdcCalibrationDate' })}
        required={true}
        disabled={labelInputDisabled}
        max={dayjs().format('YYYY-MM-DD')}
        subContent={
          itemIdGdcCalibrationTest && (
            <div style={{ marginLeft: '35%', marginTop: -10 }}>
              <input
                type="checkbox"
                id="overwriteManually"
                onChange={handleOverwriteCheckBox}
                checked={overwriteManually}
                disabled={overwriteManuallyDisabled}
              />
              <label htmlFor="overwriteManually"> Overwrite manually</label>
            </div>
          )
        }
        props={{
          ...register('lastGdcCalibrationDate', {
            required: !hasGdcCalibrationSuccessResult,
            valueAsDate: true,
          }),
        }}
      />
    </>
  );
}

export { ItemFormGdcOptionalFields };
