import { useIntl } from 'react-intl';

import { FormTextField } from '../../../library/form/FormTextField';

export function SystemClientInput() {
  const intl = useIntl();
  return (
    <FormTextField
      id={'SystemClientInput'}
      label={intl.formatMessage({ id: 'systems.client' })}
      required={true}
      disabled={false}
      name={'client'}
    />
  );
}
