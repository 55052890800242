import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  useItemTypeServiceTypes,
  useGdcCalibrationDate,
} from '../../../../api/hooks/useRentingApi';
import { DefaultButtonGroup } from '../../../../components/form/DefaultButtonGroup';
import { LabeledDropdown } from '../../../../components/form/LabeledDropdown';
import { LabeledInput } from '../../../../components/form/LabeledInput';
import Icon from '../../../../components/library/icons/Icon';
import { LoadingIndicator } from '../../../../components/library/loading/LoadingIndicator';
import { ItemType } from '../../../../types/item-types/ItemType';
import { Item, OptionItem, Manufacturer } from '../../../../types/types';
import { getDirtyFormValues } from '../../../../utils/formData';

import { ItemFormGdcOptionalFields } from './ItemFormGdcOptionalFields';

export interface ItemFormProps {
  onCancel: () => void;
  onSubmit: (data: ItemFormData, saveAndNext: boolean) => void;
  itemTypes: ItemType[];
  item?: Item;
}

interface ItemServiceType {
  _id: string;
  lastServiceDate: string;
}

export interface ItemFormData {
  _id?: string;
  barcode?: string;
  customId?: string;
  draegerSerialNo?: string;
  itemTypeId?: string;
  lastServiceDates?: ItemServiceType[];
  lastGdcCalibrationDate?: string;
}

function ItemForm({ onCancel, onSubmit, itemTypes, item }: ItemFormProps) {
  const intl = useIntl();
  const isEditItemForm = !!item;

  const {
    register,
    handleSubmit,
    control,
    reset,
    resetField,
    setValue,
    unregister,
    getValues,
    watch,
    formState: { isValid, isDirty, isSubmitting, isSubmitSuccessful, dirtyFields },
  } = useForm<ItemFormData>({
    mode: 'onChange',
    defaultValues: item
      ? {
          barcode: item.itemBarcode,
          draegerSerialNo: item.draegerSerialNo,
          lastServiceDates: undefined,
          customId: item.customId,
        }
      : undefined,
  });
  useFieldArray({
    control,
    name: 'lastServiceDates',
  });

  const draegerSerialNoWatch = watch('draegerSerialNo');

  const [shouldFormModalStayOpen, setShouldFormModalStayOpen] = useState<boolean>(false);

  const [gdcCalibrationDraegerSerialNo, setGdcCalibrationDraegerSerialNo] = useState<
    string | undefined
  >();

  const [selectedItemTypeId, setSelectedItemTypeId] = useState<string | undefined>(
    item?.itemType._id
  );

  const {
    serviceTypes,
    isLoading: isServiceTypesLoading,
    isSuccess: isGetServiceTypesSuccess,
    remove: removeItemTypesServiceTypes,
  } = useItemTypeServiceTypes(selectedItemTypeId);

  const { gdcCalibrationTestResponse, isLoading: gdcCalibrationTestLoading } =
    useGdcCalibrationDate(gdcCalibrationDraegerSerialNo);

  const selectedItemTypeIsConsumable = !!(
    selectedItemTypeId && findSelectedItemType(selectedItemTypeId)?.isConsumable
  );

  function handleItemTypeChanged(selectedItemTypeIdInDropdown: string) {
    if (isGdcCalibrationItemType(selectedItemTypeIdInDropdown)) {
      setGdcCalibrationDraegerSerialNo(getValues('draegerSerialNo'));
    } else {
      setGdcCalibrationDraegerSerialNo(undefined);
    }
    resetField('lastServiceDates');
    resetField('lastGdcCalibrationDate');
    setSelectedItemTypeId(selectedItemTypeIdInDropdown);
  }

  function handleDraegerSerialNoChange(selectedDraegerSerialNo: string) {
    if (
      !isEditItemForm &&
      selectedItemTypeId &&
      isGdcCalibrationItemType(selectedItemTypeId) &&
      validateDraegerSerialNoItemId(selectedDraegerSerialNo)
    ) {
      setGdcCalibrationDraegerSerialNo(selectedDraegerSerialNo);
    } else {
      setGdcCalibrationDraegerSerialNo(undefined);
    }
  }

  function itemTypeToOptionItem(itemType: ItemType): OptionItem {
    return {
      value: itemType._id,
      label: itemType.name,
    };
  }

  function findSelectedItemType(selectedItemTypeId: string) {
    return itemTypes.find(x => x._id === selectedItemTypeId);
  }

  function isGdcItemType(selectedItemType: string | undefined) {
    if (!selectedItemType) return false;
    const itemType = findSelectedItemType(selectedItemType);
    return itemType ? itemType.xDockTestable : false;
  }

  function isGdcCalibrationItemType(selectedItemType: string | undefined) {
    if (!selectedItemType) return false;
    const itemType = findSelectedItemType(selectedItemType);
    return itemType ? itemType.gdcCalibration && itemType.xDockTestable : false;
  }

  function isDraegerManufacturer(selectedItemType: string | undefined) {
    if (!selectedItemType) return false;
    const itemType = findSelectedItemType(selectedItemType);
    return itemType?.manufacturer === Manufacturer.DRAEGER;
  }

  function setDraegerSerialNoInfoMessage() {
    if (isGdcItemType(selectedItemTypeId)) {
      if (
        !validateDraegerSerialNoItemId(draegerSerialNoWatch) &&
        !validateDraegerSerialNoItemId(gdcCalibrationDraegerSerialNo)
      ) {
        return getErrorMessage('items.wrongDraegerSerialNoFormat');
      }
      if (validateDraegerSerialNoItemId(gdcCalibrationDraegerSerialNo)) {
        switch (gdcCalibrationTestResponse?.testResult) {
          case 'Passed':
            return getSuccessMessage('items.lastGdcCalibrationPassed');
          case 'ItemUnknown':
            return getErrorMessage('items.unknownDraegerSerialNo');
          default:
            return getErrorMessage('items.gdcCalibrationTestNotFound');
        }
      }
      return;
    }

    return;

    function getErrorMessage(id: string) {
      return <div className="form-error">{intl.formatMessage({ id })}</div>;
    }

    function getSuccessMessage(id: string) {
      return <div className="form-success">{intl.formatMessage({ id })}</div>;
    }
  }
  function getDefaultLastServiceDate(): string {
    return dayjs().subtract(14, 'day').format('YYYY-MM-DD');
  }

  function getServiceDateFields() {
    if (isServiceTypesLoading) {
      return (
        <div className="center-content-with-flex">
          <LoadingIndicator />
        </div>
      );
    }
    if (!isEditItemForm && selectedItemTypeId && isGetServiceTypesSuccess) {
      return serviceTypes.map((serviceType, index) => (
        <div
          key={serviceType._id}
          style={{ display: serviceType.isBlockAfterUse ? 'none' : undefined }}
        >
          <input
            value={serviceType._id}
            {...register(`lastServiceDates.${index}._id` as const)}
            required={false}
            style={{ display: 'none' }}
          />
          {!serviceType.isBlockAfterUse && (
            <LabeledInput
              defaultValue={getDefaultLastServiceDate()}
              type="date"
              max={dayjs().format('YYYY-MM-DD')}
              id={serviceType._id}
              label={intl.formatMessage(
                { id: 'items.lastServiceDate' },
                { name: serviceType.name }
              )}
              required={false}
              props={{
                ...register(`lastServiceDates.${index}.lastServiceDate` as const, {
                  valueAsDate: true,
                }),
              }}
            />
          )}
        </div>
      ));
    }
  }

  function validateDraegerSerialNoItemId(draegerSerialNo: string | undefined) {
    if (isGdcItemType(selectedItemTypeId)) {
      return !!(
        draegerSerialNo &&
        draegerSerialNo.length === 16 &&
        /^[0-9]*$/.test(draegerSerialNo.slice(0, 7)) &&
        /^[A-Za-z]*$/.test(draegerSerialNo.slice(7, 11)) &&
        draegerSerialNo.slice(11, 12) === '-' &&
        /^[0-9]*$/.test(draegerSerialNo.slice(12, 16))
      );
    }
    return true;
  }

  function getInputSibling() {
    if (
      isGdcCalibrationItemType(selectedItemTypeId) &&
      gdcCalibrationDraegerSerialNo &&
      validateDraegerSerialNoItemId(gdcCalibrationDraegerSerialNo)
    ) {
      if (gdcCalibrationTestLoading) {
        return <LoadingIndicator />;
      } else {
        if (gdcCalibrationTestResponse?.gdcCalibrationTestResult === 'Passed') {
          return (
            <Icon
              id={'dreagerSnCheckFailed'}
              iconName={'check'}
              additionalClasses={'dreagerSnCheckIcon'}
            />
          );
        } else {
          return (
            <Icon
              id={'dreagerSnCheckSuccess'}
              iconName={'cancel'}
              additionalClasses={'dreagerSnCheckIcon'}
            />
          );
        }
      }
    }
  }
  useEffect(() => {
    if (isSubmitSuccessful && shouldFormModalStayOpen) {
      reset({
        itemTypeId: '',
        barcode: '',
        draegerSerialNo: '',
        customId: '',
        lastServiceDates: [],
        lastGdcCalibrationDate: '',
      });
    }
    setShouldFormModalStayOpen(false);
    removeItemTypesServiceTypes();
    setSelectedItemTypeId(undefined);
  }, [isSubmitSuccessful]);

  return (
    <form
      className="modal-form"
      onSubmit={handleSubmit(data => {
        if (item) {
          data = getDirtyFormValues(data, dirtyFields);
          data._id = item._id;
        }
        if (data.draegerSerialNo) data.draegerSerialNo = data.draegerSerialNo.trim();
        onSubmit(data, shouldFormModalStayOpen);
      })}
    >
      <div className="uib-modal__content modal-form double-col">
        <LabeledDropdown
          label={intl.formatMessage({ id: 'item-types.singular' })}
          id="itemTypeId"
          options={itemTypes.map(itemTypeToOptionItem)}
          placeholder={
            !isEditItemForm ? intl.formatMessage({ id: 'general.pleaseChooseEllipsis' }) : undefined
          }
          disabled={isEditItemForm}
          selectedValue={item?.itemType._id}
          setValue={setValue}
          onChange={handleItemTypeChanged}
          dataTestId="itemTypeIdTestId"
          props={{
            ...register('itemTypeId', {
              required: !isEditItemForm,
            }),
          }}
          resetSelectedValue={isSubmitSuccessful}
        />

        <LabeledInput
          id="barcode"
          label={intl.formatMessage({ id: 'items.eID' })}
          disabled={selectedItemTypeIsConsumable}
          placeholder={intl.formatMessage({ id: 'items.eID' })}
          props={{ ...register('barcode', { required: true }) }}
        />
        <LabeledInput
          id="draegerSerialNo"
          label={intl.formatMessage({ id: 'items.draegerSerialNo' })}
          disabled={selectedItemTypeIsConsumable || isEditItemForm}
          placeholder={intl.formatMessage({
            id: 'items.draegerSerialNoPlaceholder',
          })}
          feedbackIcon={
            validateDraegerSerialNoItemId(gdcCalibrationDraegerSerialNo) &&
            isGdcCalibrationItemType(selectedItemTypeId)
          }
          required={
            !isEditItemForm &&
            (isGdcItemType(selectedItemTypeId) || isDraegerManufacturer(selectedItemTypeId))
          }
          inputSiblings={getInputSibling()}
          tooltipMessage={
            isGdcItemType(selectedItemTypeId)
              ? intl.formatMessage({
                  id: 'items.lastGdcCalibrationDateTooltip',
                })
              : undefined
          }
          props={{
            ...register('draegerSerialNo', {
              required:
                isGdcItemType(selectedItemTypeId) || isDraegerManufacturer(selectedItemTypeId),
              validate: validateDraegerSerialNoItemId,
              onChange: e => handleDraegerSerialNoChange(e.target.value),
            }),
          }}
          subContent={draegerSerialNoWatch ? setDraegerSerialNoInfoMessage() : null}
        />
        <LabeledInput
          id="customId"
          label={intl.formatMessage({ id: 'items.customId' })}
          placeholder={intl.formatMessage({ id: 'items.customId' })}
          disabled={selectedItemTypeIsConsumable}
          props={{ ...register('customId') }}
          required={false}
        />
        {!isEditItemForm &&
          selectedItemTypeId &&
          isGdcCalibrationItemType(selectedItemTypeId) &&
          gdcCalibrationDraegerSerialNo && (
            <ItemFormGdcOptionalFields
              register={register}
              gdcCalibrationTestResponse={gdcCalibrationTestResponse}
              setValue={setValue}
              unregister={unregister}
              itemIdGdcCalibrationTest={gdcCalibrationDraegerSerialNo}
            />
          )}
        {!selectedItemTypeIsConsumable && getServiceDateFields()}
      </div>
      {selectedItemTypeIsConsumable && (
        <div className="form-error" data-testid="itemTypeIsConsumableWarning">
          {intl.formatMessage({ id: 'items.itemTypeIsConsumable.warning' })}
        </div>
      )}
      <div className="uib-modal__footer">
        <DefaultButtonGroup
          isSubmitDisabled={isSubmitting || !isDirty || !isValid || selectedItemTypeIsConsumable}
          onCancel={onCancel}
          hasSaveAndNextOption={!isEditItemForm}
          clickSaveAndNext={() => setShouldFormModalStayOpen(true)}
        />
      </div>
    </form>
  );
}

export { ItemForm };
