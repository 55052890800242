import { useIntl } from 'react-intl';

import { ButtonVariant } from '../../types/components/Button';
import Button from '../odx/Button';

interface DefaultButtonGroupProps {
  isSubmitDisabled: boolean;
  onSubmit?: () => void;
  onCancel: () => void;
  actionButtonText?: string;
  secondButtonText?: string;
  hasSaveAndNextOption?: boolean;
  subContent?: string;
  clickSaveAndNext?: () => void;
}

function DefaultButtonGroup({
  isSubmitDisabled,
  onSubmit,
  onCancel,
  actionButtonText,
  secondButtonText,
  hasSaveAndNextOption = false,
  subContent,
  clickSaveAndNext,
}: DefaultButtonGroupProps) {
  const intl = useIntl();
  actionButtonText = actionButtonText
    ? actionButtonText
    : intl.formatMessage({ id: 'general.save' });
  return (
    <div
      className={
        subContent ? 'button-group-with-subContent' : 'uib-button-group default-button-group'
      }
    >
      {hasSaveAndNextOption && (
        <div>
          <Button
            id={'saveNextButton'}
            type="submit"
            disabled={isSubmitDisabled}
            onClick={clickSaveAndNext}
          >
            {intl.formatMessage({ id: 'general.saveAndNext' })}
          </Button>
        </div>
      )}
      <div className="buttons-main-cluster">
        <Button id={'abortButton'} variant={ButtonVariant.GHOST} onClick={onCancel}>
          {!secondButtonText ? intl.formatMessage({ id: 'general.cancel' }) : secondButtonText}
        </Button>
        <Button
          type="submit"
          id={'submitButton'}
          variant={isSubmitDisabled ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY}
          disabled={isSubmitDisabled}
          onClick={onSubmit}
        >
          {actionButtonText}
        </Button>
      </div>
      <div className="subContent">{subContent}</div>
    </div>
  );
}
export { DefaultButtonGroup };
