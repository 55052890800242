import { useIntl } from 'react-intl';

import { useItemTypes } from '../../../api/hooks/useRentingApi';
import { useStations } from '../../../api/hooks/useStationApi';
import { LoadingIndicator } from '../../../components/library/loading/LoadingIndicator';
import { Modal } from '../../../components/modal/Modal';
import { Stock } from '../../../types/types';

import { StockForm, StockFormData } from './StockForm';

export interface DraftStockModalProps {
  selectedStock?: Stock;
  itemsStock: Stock[];
  onClose: () => void;
  submitFormData: (data: StockFormData, isConsumableItemTypeSelected: boolean) => void;
}

export const DraftStockModal = ({
  selectedStock,
  itemsStock,
  onClose,
  submitFormData,
}: DraftStockModalProps) => {
  const intl = useIntl();
  const iseditform = !!selectedStock;

  const { itemTypes, isLoading: isLoadingItemTypes } = useItemTypes();
  const { stations, isLoading: isLoadingStations } = useStations();

  const header = (
    <h1 className="uib-modal__title">
      {iseditform
        ? intl.formatMessage({ id: 'stockPage.editModalHeader' })
        : intl.formatMessage({ id: 'stockPage.createModalHeader' })}
    </h1>
  );

  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      modalHasClosed={onClose}
      additionalClassNames="overflow-visible"
      content={
        isLoadingItemTypes || isLoadingStations ? (
          <LoadingIndicator />
        ) : (
          <StockForm
            onCancel={onClose}
            onSubmit={submitFormData}
            selectedStock={selectedStock}
            itemTypes={itemTypes}
            stations={stations}
            itemsStock={itemsStock}
          />
        )
      }
    />
  );
};
