import { ReactNode } from 'react';
import { DefaultValues, FieldValues, FormProvider, useForm } from 'react-hook-form';

export interface FormProps<T extends FieldValues> {
  initialFormValues?: DefaultValues<T>;
  onSubmit: (formInputValues: T, dirtyFields: string[]) => void;
  children?: ReactNode;
  buttons?: ReactNode;
}

export function Form<T extends FieldValues>({
  initialFormValues,
  onSubmit,
  children,
  buttons,
}: FormProps<T>) {
  const methods = useForm<T>({
    mode: 'onChange',
    defaultValues: initialFormValues,
  });
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(data => {
          onSubmit(data, mapToFieldNames(methods.formState.dirtyFields));
        })}
      >
        {children}
        {buttons && (
          <div style={{ marginTop: '25px', float: 'right', display: 'flex', gap: '10px' }}>
            {buttons}
          </div>
        )}
      </form>
    </FormProvider>
  );
}
function mapToFieldNames(dirtyFields: any): string[] {
  return Object.entries(dirtyFields).map(([field]) => {
    return field;
  });
}
