import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DefaultButtonGroup } from '../../../../components/form/DefaultButtonGroup';
import { LabeledInput } from '../../../../components/form/LabeledInput';
import { Contractor } from '../../../../types/contractors/Contractor';
import { getDirtyFormValues } from '../../../../utils/formData';

export interface ContractorFormProps {
  contractor?: Contractor;
  onCancel: () => void;
  onSubmit: (data: ContractorFormData) => void;
}

export interface ContractorFormData {
  _id?: string;
  name?: string;
  contactPerson?: string;
  contactEmail?: string;
}

function ContractorForm({ contractor, onCancel, onSubmit }: ContractorFormProps) {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting, dirtyFields },
  } = useForm<ContractorFormData>({
    mode: 'onChange',
    defaultValues: contractor
      ? {
          name: contractor.name,
          contactPerson: contractor.contactPerson,
          contactEmail: contractor.contactEmail,
        }
      : undefined,
  });

  return (
    <form
      onSubmit={handleSubmit(data => {
        if (contractor) {
          data = getDirtyFormValues(data, dirtyFields);
          data._id = contractor._id;
        }
        onSubmit(data);
      })}
    >
      <div className="uib-modal__content modal-form double-col">
        <LabeledInput
          id="name"
          label={intl.formatMessage({ id: 'contractors.name' })}
          placeholder={intl.formatMessage({ id: 'contractors.name' })}
          props={{ ...register('name', { required: true }) }}
        />
        <LabeledInput
          id="contactPerson"
          label={intl.formatMessage({ id: 'contractors.contactPerson' })}
          placeholder={intl.formatMessage({ id: 'contractors.contactPerson' })}
          required={false}
          props={{ ...register('contactPerson', { required: false }) }}
        />
        <LabeledInput
          id="contactEmail"
          label={intl.formatMessage({ id: 'contractors.contactEmail' })}
          placeholder={intl.formatMessage({ id: 'contractors.contactEmail' })}
          type="email"
          required={false}
          props={{ ...register('contactEmail', { required: false }) }}
        />
      </div>

      <div className="uib-modal__footer">
        <DefaultButtonGroup
          isSubmitDisabled={isSubmitting || !isDirty || !isValid}
          onCancel={onCancel}
        />
      </div>
    </form>
  );
}
export { ContractorForm };
