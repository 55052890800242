import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DefaultButtonGroup } from '../../../components/form/DefaultButtonGroup';
import { LabeledDropdown } from '../../../components/form/LabeledDropdown';
import { LabeledInput } from '../../../components/form/LabeledInput';
import { ItemType } from '../../../types/item-types/ItemType';
import { Stock, Station, OptionItem } from '../../../types/types';

export interface StockFormProps {
  onCancel: () => void;
  onSubmit: (data: StockFormData, isConsumableItemTypeSelected: boolean) => void;
  selectedStock?: Stock;
  itemTypes: ItemType[];
  stations: Station[];
  itemsStock: Stock[];
}

export interface EditStockFormData {
  stock: number;
  alertStock: number;
}

export interface StockFormData extends EditStockFormData {
  stationId: string;
  itemTypeId: string;
}

const stationToOptionItem = (station: Station): OptionItem => {
  return {
    value: station._id,
    label: station.location,
  };
};

const itemTypeToOptionItem = (itemType: ItemType): OptionItem => {
  return {
    value: itemType._id,
    label: itemType.name,
  };
};

export const StockForm = ({
  onCancel,
  onSubmit,
  selectedStock,
  itemTypes,
  stations,
  itemsStock,
}: StockFormProps) => {
  const intl = useIntl();
  const isEditForm = !!selectedStock;

  const [showValidateError, setShowValidateError] = useState<boolean>(false);
  const [showStockValidateError, setShowStockValidateError] = useState<string | null>();

  const [isConsumableItemTypeSelected, setIsConsumableItemTypeSelected] = useState<boolean>(
    !!selectedStock?.itemType.isConsumable
  );

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    getValues,
    resetField,
    formState: { isValid, isDirty, dirtyFields, isSubmitting, isSubmitSuccessful },
  } = useForm<StockFormData>({
    mode: 'onChange',
    defaultValues: {
      stock: selectedStock?.contractStock?.stock,
      alertStock: selectedStock?.contractStock?.alertStock,
    },
  });

  const contractStockWatch = watch('stock');
  const alertStockWatch = watch('alertStock');

  const getAlertStockSubcontent = () => {
    if (!showStockValidateError && !showValidateError) {
      return alertStockWatch > 0 ? (
        <p>
          {intl.formatMessage({ id: 'stockPage.alertEmail' })}{' '}
          <b>
            {intl.formatMessage(
              { id: 'stockPage.alertEmail.highlighted' },
              {
                numberOfItems: alertStockWatch,
              }
            )}
          </b>
        </p>
      ) : (
        intl.formatMessage({ id: 'stockPage.noAlertEmail' })
      );
    }
  };

  const validateStationLocation = (value: string) => {
    if (isEditForm) return true;

    const isValid = itemsStock.some(
      (itemStock: Stock) =>
        itemStock.station.id === value &&
        itemStock.itemType.id === getValues('itemTypeId') &&
        itemStock.contractStock?.stock
    );
    setShowValidateError(isValid);
    return !isValid;
  };

  const validateItemTypeId = (value: string) => {
    if (isEditForm) return true;

    const isValid = itemsStock.some(
      (itemStock: Stock) =>
        itemStock.itemType.id === value &&
        itemStock.station.id === getValues('stationId') &&
        itemStock.contractStock?.stock
    );
    setShowValidateError(isValid);
    return !isValid;
  };

  const validateStockContent = (contractStock: number, alertStock: number) => {
    if (!isEditForm && !contractStock && !alertStock) {
      setShowStockValidateError(
        intl.formatMessage({
          id: 'stockPage.contractAndAlertStockCanNotBeEmpty',
        })
      );
      return false;
    }

    if (contractStock && alertStock && contractStock < alertStock) {
      setShowStockValidateError(intl.formatMessage({ id: 'stockPage.invalidAlertStock' }));
      return false;
    }
    setShowStockValidateError(null);
    return true;
  };

  const onChangeItemType = (itemTypeId: string) => {
    const isConsumable = itemTypes.some(
      itemType => itemType._id === itemTypeId && itemType.isConsumable
    );
    setIsConsumableItemTypeSelected(isConsumable);
    if (isConsumable) {
      resetField('alertStock');
    }
  };

  const validateMaxLength = (inputValue: number, field: 'stock' | 'alertStock') => {
    const value = inputValue;
    const maxLength = 4;
    const newValue =
      String(value).length - 1 < maxLength ? value : String(value).substring(0, maxLength);
    setValue(field, Number(newValue));
  };

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data, isConsumableItemTypeSelected))}>
      <div className="uib-modal__content modal-form double-col">
        <LabeledDropdown
          id="stationId"
          label={intl.formatMessage({ id: 'stations.singular' })}
          options={stations.map(stationToOptionItem)}
          placeholder={
            !isEditForm ? intl.formatMessage({ id: 'general.pleaseChooseEllipsis' }) : undefined
          }
          disabled={isEditForm}
          selectedValue={selectedStock?.station.id}
          setValue={setValue}
          dataTestId="stationLocationTestId"
          props={{
            ...register('stationId', {
              required: true,
              validate: validateStationLocation,
            }),
          }}
          resetSelectedValue={isSubmitSuccessful}
        />
        <LabeledDropdown
          label={intl.formatMessage({ id: 'item-types.singular' })}
          id="itemTypeId"
          options={itemTypes.map(itemTypeToOptionItem)}
          placeholder={
            !isEditForm ? intl.formatMessage({ id: 'general.pleaseChooseEllipsis' }) : undefined
          }
          disabled={isEditForm}
          selectedValue={selectedStock?.itemType.id}
          setValue={setValue}
          onChange={itemTypeId => {
            onChangeItemType(itemTypeId);
          }}
          dataTestId="itemTypeIdTestId"
          props={{
            ...register('itemTypeId', {
              required: true,
              validate: validateItemTypeId,
            }),
          }}
          resetSelectedValue={isSubmitSuccessful}
        />
        <LabeledInput
          id="stock"
          label={intl.formatMessage({ id: 'stockPage.contractStock' })}
          className="x-dock-time-input"
          tooltipMessage={intl.formatMessage({
            id: 'stockPage.contractStockTooltip',
          })}
          type="number"
          min="0"
          max="1000"
          props={{
            ...register('stock', {
              valueAsNumber: true,
              onChange: e => validateMaxLength(e.target.value, 'stock'),
              validate: value => validateStockContent(value, alertStockWatch),
            }),
          }}
          required={false}
        />
        <LabeledInput
          id="alertStock"
          label={intl.formatMessage({ id: 'stockPage.alertStock' })}
          type="number"
          min="0"
          max="1000"
          subContent={getAlertStockSubcontent()}
          className="x-dock-time-input"
          props={{
            ...register('alertStock', {
              onChange: e => validateMaxLength(e.target.value, 'alertStock'),
              valueAsNumber: true,
              validate: value => validateStockContent(contractStockWatch, value),
            }),
          }}
          disabled={isConsumableItemTypeSelected}
          required={false}
        />
      </div>
      {showValidateError && (
        <span className="form-error">
          {intl.formatMessage({ id: 'stockPage.stationItemTypeNoValid' })}{' '}
        </span>
      )}
      {showStockValidateError && <span className="form-error">{showStockValidateError} </span>}
      <div className="uib-modal__footer">
        <DefaultButtonGroup
          isSubmitDisabled={
            isSubmitting || !isDirty || !isValid || !Object.keys(dirtyFields).length
          }
          onCancel={onCancel}
        />
      </div>
    </form>
  );
};
