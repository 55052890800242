import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DefaultButtonGroup } from '../../../../../components/form/DefaultButtonGroup';
import { LabeledDropdown } from '../../../../../components/form/LabeledDropdown';
import { LabeledInput } from '../../../../../components/form/LabeledInput';
import { LabeledSwitch } from '../../../../../components/form/LabeledSwitch';
import { Locker, OptionItem } from '../../../../../types/types';
import { getDirtyFormValues, getValueFromOptions } from '../../../../../utils/formData';

export interface LockerFormProps {
  locker: Locker;
  lockerFeatures: OptionItem[];
  onCancel: () => void;
  onSubmit: (data: LockerFormData) => void;
}

export interface LockerFormData {
  _id?: string;
  lockerPosition?: number;
  lockerFeature?: string;
  isDefective?: boolean;
}

function LockerForm({ locker, lockerFeatures, onCancel, onSubmit }: LockerFormProps) {
  const intl = useIntl();
  const [switchSelected, toggleSwitchSelected] = useState<boolean>(locker?.isDefective);

  const defaultLockerEditValues = (locker: Locker) => ({
    lockerPosition: locker.position,
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty, isSubmitting, dirtyFields },
  } = useForm<LockerFormData>({
    mode: 'onChange',
    defaultValues: defaultLockerEditValues(locker),
  });

  return (
    <form
      onSubmit={handleSubmit(data => {
        if (locker) {
          data = getDirtyFormValues(data, dirtyFields);
          data._id = locker._id;
        }
        onSubmit(data);
      })}
    >
      <div className="uib-modal__content modal-form double-col">
        <LabeledInput
          id="lockerPosition"
          label={intl.formatMessage({ id: 'lockers.lockerPos' })}
          placeholder={intl.formatMessage({ id: 'lockers.lockerPos' })}
          type="number"
          disabled
          props={{ ...register('lockerPosition') }}
        />
        <LabeledDropdown
          label={intl.formatMessage({ id: 'lockers.lockerFeature' })}
          id="lockerFeature"
          options={lockerFeatures}
          required={false}
          setValue={setValue}
          selectedValue={getValueFromOptions(lockerFeatures, locker.lockerFeature)}
          props={{ ...register('lockerFeature', { required: false }) }}
        />
        <LabeledSwitch
          id="isDefective"
          label={intl.formatMessage({ id: 'lockers.defect' })}
          isSelected={switchSelected}
          props={{
            ...register('isDefective', {
              required: false,
              onChange: () => toggleSwitchSelected(!switchSelected),
            }),
          }}
        />
      </div>

      <div className="uib-modal_footer">
        <DefaultButtonGroup
          isSubmitDisabled={isSubmitting || !isDirty || !isValid}
          onCancel={onCancel}
        />
      </div>
    </form>
  );
}
export { LockerForm };
