import { useIntl } from 'react-intl';

import { Modal } from '../../../components/modal/Modal';
import Button from '../../../components/odx/Button';
import { ButtonVariant } from '../../../types/components/Button';
import { Station } from '../../../types/types';

interface IotUnpairingModalProps {
  station: Station;
  unpairIotAdapter: (stationId: string) => void;
  onClose: () => void;
}

function IotUnpairingModal({ station, onClose, unpairIotAdapter }: IotUnpairingModalProps) {
  const intl = useIntl();
  const getUnpairModalContent = () => {
    return (
      <div>
        {station.terminalId
          ? intl.formatMessage({ id: 'stations.iot.unpairingDoubleConfirm' })
          : intl.formatMessage({ id: 'stations.iot.unpairingConfirm' })}
      </div>
    );
  };

  const getUnpairActionButton = () => {
    return (
      <Button
        onClick={() => {
          unpairIotAdapter(station._id);
          onClose();
        }}
        variant={ButtonVariant.DANGER}
        id={'confirmIotUnpair'}
      >
        {intl.formatMessage({ id: 'general.yes' })}
      </Button>
    );
  };

  return (
    <Modal
      isOpen={true}
      header={
        <h1 className="uib-modal__title">
          {intl.formatMessage({ id: 'stations.iot.unpairing.Header' })}
        </h1>
      }
      content={getUnpairModalContent()}
      closeButtonLabel={intl.formatMessage({ id: 'general.no' })}
      modalHasClosed={onClose}
      actionButton={getUnpairActionButton()}
    />
  );
}

export { IotUnpairingModal };
