import { TextField as MuiTextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

interface NumberInputProps {
  id: string;
  name: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  style?: any;
}
export function FormNumberInput({
  id,
  name,
  placeholder,
  required,
  disabled,
  fullWidth = false,
  style,
}: NumberInputProps) {
  const { register } = useFormContext();
  return (
    <MuiTextField
      type="number"
      label={placeholder}
      id={id}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      style={style}
      {...register(name, {
        required: required,
        disabled: disabled,
      })}
    />
  );
}
