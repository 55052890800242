import { FormMultiTextField } from './FormMultiTextField';

interface MultiEmailInputProps {
  id: string;
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
}
export function FormMultiEmailInput({
  id,
  label,
  name,
  placeholder,
  required,
  disabled,
}: MultiEmailInputProps) {
  return (
    <FormMultiTextField
      id={id}
      label={label}
      name={name}
      inputIncorrectLabel={'general.email.format.error'}
      validateInput={validateEmail}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
    />
  );
}

function validateEmail(email: string): boolean {
  if (email === '') return true;
  return Boolean(
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  );
}
