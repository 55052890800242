import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DefaultButtonGroup } from '../../../components/form/DefaultButtonGroup';
import { LabeledInput } from '../../../components/form/LabeledInput';
import { LabeledTagfield } from '../../../components/form/LabeledTagfield';
import { Station } from '../../../types/types';
import { getDirtyFormValues, validateEmailFormat } from '../../../utils/formData';

export interface StationFormProps {
  station?: Station;
  onCancel: () => void;
  onSubmit: (data: StationFormData) => void;
}

export interface StationFormData {
  _id?: string;
  location?: string;
  supervisorEmails?: string[];
  terminalSerialNo?: string;
}

function StationForm({ station, onCancel, onSubmit }: StationFormProps) {
  const intl = useIntl();
  const isEditForm = !!station;

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting, dirtyFields },
  } = useForm<StationFormData>({
    mode: 'onChange',
    defaultValues: station
      ? {
          location: station.location,
          terminalSerialNo: station.terminalSerialNo,
        }
      : undefined,
  });

  const [supervisorEmailsForSending, setSupervisorEmailsForSending] = useState<string[]>(
    station ? station.supervisorEmails : []
  );
  const [invalidEmailFormatError, toggleInvalidEmailFormatError] = useState<boolean>(false);

  async function validateEmail(email: string, currentTagList: string[]) {
    toggleInvalidEmailFormatError(false);
    if (validateEmailFormat(email)) {
      const newTagList = currentTagList.concat(email);
      setSupervisorEmailsForSending(newTagList);
      return true;
    }
    toggleInvalidEmailFormatError(true);
    return false;
  }

  function updateEmailList(newList: string[]) {
    setSupervisorEmailsForSending(newList);
  }

  return (
    <>
      <form>
        <div className="uib-modal__content modal-form double-col">
          <LabeledInput
            id="location"
            label={intl.formatMessage({ id: 'stations.location' })}
            placeholder={intl.formatMessage({ id: 'stations.location' })}
            props={{ ...register('location', { required: true }) }}
          />
          {isEditForm && (
            <LabeledInput
              id="terminalSerialNo"
              label={intl.formatMessage({ id: 'stations.terminalSerialNo' })}
              placeholder={intl.formatMessage({
                id: 'stations.terminalSerialNo',
              })}
              props={{ ...register('terminalSerialNo') }}
              required={false}
            />
          )}
        </div>
      </form>
      <div className="uib-modal__content modal-form single-col">
        <LabeledTagfield
          label={intl.formatMessage({ id: 'stations.supervisors' })}
          required={true}
          placeholder={intl.formatMessage({
            id: 'stations.supervisors.placeholder',
          })}
          defaultTags={station ? station.supervisorEmails : undefined}
          validateTag={validateEmail}
          sendUpdatedTagList={updateEmailList}
        />

        {invalidEmailFormatError && (
          <span className="form-error">
            {intl.formatMessage({
              id: 'stations.supervisorsEmail.error',
            })}
          </span>
        )}
      </div>
      <div className="uib-modal__footer">
        <DefaultButtonGroup
          isSubmitDisabled={
            isSubmitting ||
            (!isDirty && station?.supervisorEmails === supervisorEmailsForSending) ||
            !isValid ||
            supervisorEmailsForSending.length < 1
          }
          onCancel={onCancel}
          onSubmit={handleSubmit(data => {
            if (isEditForm) {
              data = getDirtyFormValues(data, dirtyFields);
              data._id = station._id;
            }
            data.supervisorEmails = supervisorEmailsForSending;
            onSubmit(data);
          })}
        />
      </div>
    </>
  );
}

export { StationForm };
