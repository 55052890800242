import { useMutation, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/tokenContext';

export function useDelete() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({ _id, endpoint }: { _id: string; endpoint: string; invalidateQuery: string }) =>
      client(`${endpoint}/${_id}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: (_, { invalidateQuery }) => {
        queryClient.invalidateQueries(invalidateQuery);
      },
    }
  );
}
