import { ReactNode } from 'react';

import { QuantityRestrictionParameter } from '../../../types/quantity-restrictions/CreateQuantityRestrictionParameter';
import { QuantityRestrictionFormValues } from '../../../types/quantity-restrictions/QuantityRestrictionFormValues';
import { MultiContractorsDropDown } from '../../common/inputs/MultiContractorsDropDown';
import { MultiItemTypeDropDown } from '../../common/inputs/MultiItemTypeDropDown';
import { MultiRentersDropDown } from '../../common/inputs/MultiRentersDropDown';
import { Form } from '../../library/form/Form';

import { QuantityInput } from './inputs/QuantityInput';

export interface QuantityRestrictionFormProps {
  initialFormValues: QuantityRestrictionFormValues;
  handleSubmit: (formInputValues: QuantityRestrictionParameter) => void;
  buttons: ReactNode;
}
export function QuantityRestrictionForm({
  initialFormValues,
  handleSubmit,
  buttons,
}: QuantityRestrictionFormProps) {
  function onSubmit(formInputValues: QuantityRestrictionFormValues) {
    handleSubmit(toParam(formInputValues));
  }

  return (
    <Form initialFormValues={initialFormValues} onSubmit={onSubmit} buttons={buttons}>
      <QuantityInput />
      <MultiRentersDropDown />
      <MultiContractorsDropDown />
      <MultiItemTypeDropDown />
    </Form>
  );
}

function toParam(formInputValues: QuantityRestrictionFormValues): QuantityRestrictionParameter {
  return {
    quantity: formInputValues.quantity ?? 0,
    renterIds: formInputValues.renterIds.length == 0 ? undefined : formInputValues.renterIds,
    contractorIds:
      formInputValues.contractorIds.length == 0 ? undefined : formInputValues.contractorIds,
    itemTypeIds: formInputValues.itemTypeIds.length == 0 ? undefined : formInputValues.itemTypeIds,
  };
}
