import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/tokenContext';
import { ContractorFormData } from '../../modules/renting/contractors/manage/ContractorForm';
import { Contractor } from '../../types/contractors/Contractor';
import { EditRequest, MessageObject } from '../../types/types';

const CONTRACTORS_URL = '/contractors';

function useContractors() {
  const client = useClient<Contractor[]>();
  const result = useQuery('contractors', () => client(CONTRACTORS_URL));
  return { ...result, contractors: result.data?.data ?? [] };
}

function useCreateContractor() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: ContractorFormData) =>
      client(`${CONTRACTORS_URL}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contractors');
      },
    }
  );
}

function useEditContractor() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: EditRequest) =>
      client(`${CONTRACTORS_URL}`, {
        method: 'PATCH',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contractors');
      },
    }
  );
}

function useDeleteContractor(contractorId: string) {
  const client = useClient<MessageObject>();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`${CONTRACTORS_URL}/${contractorId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contractors');
      },
    }
  );
}

export { useContractors, useCreateContractor, useEditContractor, useDeleteContractor };
