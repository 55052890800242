import { useFormContext } from 'react-hook-form';

import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { CancelButton } from '../../library/buttons/CancelButton';

export function EditEntityDialogCloseButton() {
  const { setEntityToEdit } = useCrudPageUiStateContext();
  const { reset } = useFormContext();
  return (
    <CancelButton
      onClick={() => {
        setEntityToEdit(undefined);
        reset();
      }}
    />
  );
}
