import { Box, Typography } from '@mui/material';

interface TitleProps {
  title: string;
}
export function ChartCardTitle({ title }: TitleProps) {
  return (
    <Box sx={{ width: '100%', paddingBottom: '15px' }}>
      <Typography fontSize={20}>{title}</Typography>
    </Box>
  );
}
