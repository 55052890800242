import { useIntl } from 'react-intl';

import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { Dialog } from '../../library/dialog/Dialog';

import { CreateRenterForm } from './CreateRenterForm';

function CreateRenterDialog() {
  const intl = useIntl();
  const { openCreateDialog } = useCrudPageUiStateContext();
  return (
    <>
      {openCreateDialog && (
        <Dialog
          width="md"
          id={'CreateRenterDialog'}
          isOpen={openCreateDialog}
          title={intl.formatMessage({ id: 'renters.create' })}
          content={<CreateRenterForm />}
        />
      )}
    </>
  );
}

export { CreateRenterDialog };
