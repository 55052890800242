import { ReactNode, createContext, useContext, useState } from 'react';

interface CrudPageUiStateContextApi<T> {
  openCreateDialog: boolean;
  setOpenCreateDialog: (value: boolean) => void;
  openDeleteDialog: boolean;
  setOpenDeleteDialog: (value: boolean) => void;
  entityToEdit: T | undefined;
  setEntityToEdit: (entity: T | undefined) => void;
  entityForDetailView: T | undefined;
  setEntityForDetailView: (entity: T | undefined) => void;
  entitieIdsToDelete: string[] | undefined;
  setEntitieIdsToDelete: (entities: string[] | undefined) => void;
  openImportDialog: boolean;
  setOpenImportDialog: (value: boolean) => void;
}

interface CrudPageUiStateProviderProps {
  children: ReactNode;
}

function CrudPageUiStateProvider<T>({ children }: CrudPageUiStateProviderProps) {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
  const [entityToEdit, setEntityToEdit] = useState<T>();
  const [entityForDetailView, setEntityForDetailView] = useState<T>();
  const [entitieIdsToDelete, setEntitieIdsToDelete] = useState<string[]>();
  const value: CrudPageUiStateContextApi<T> = {
    openCreateDialog,
    setOpenCreateDialog,
    entityToEdit,
    setEntityToEdit,
    openDeleteDialog,
    setOpenDeleteDialog,
    entitieIdsToDelete,
    setEntitieIdsToDelete,
    entityForDetailView,
    setEntityForDetailView,
    openImportDialog,
    setOpenImportDialog,
  };
  return (
    <CrudPageUiStateContext.Provider value={value}>{children}</CrudPageUiStateContext.Provider>
  );
}
const CrudPageUiStateContext = createContext<CrudPageUiStateContextApi<any> | undefined>(undefined);

function useCrudPageUiStateContext<T>() {
  const context = useContext<CrudPageUiStateContextApi<T> | undefined>(CrudPageUiStateContext);

  if (context === undefined) {
    throw new Error('useCrudPageUiStateContext must be used within an CrudPageUiStateProvider');
  }
  return context;
}

export { CrudPageUiStateProvider, useCrudPageUiStateContext };
