import Icon from '../library/icons/Icon';

import { Switch } from './Switch';

interface LabeledSwitchProps {
  id: string;
  label: string;
  isSelected?: boolean;
  disabled?: boolean;
  props?: unknown;
  additionalClasses?: string;
  onChange?: () => void;
  onClick?: () => void;
}

const LabeledSwitch = ({
  id,
  label,
  isSelected = false,
  disabled,
  props,
  additionalClasses = '',
  onChange,
  onClick,
}: LabeledSwitchProps) => {
  return (
    <>
      {disabled ? (
        <div className={`uib-form__item checkbox-container ${additionalClasses}`}>
          <label className="uib-form__label">{label}</label>
          {isSelected ? (
            <Icon iconName={'check'} additionalClasses={'odx-blue-text'} />
          ) : (
            <Icon iconName={'blocked'} />
          )}
        </div>
      ) : (
        <div className={`uib-form__item checkbox-container ${additionalClasses}`}>
          <label className="uib-form__label">{label}</label>
          <Switch
            id={id}
            isSelected={isSelected}
            props={props}
            disabled={disabled}
            onChange={onChange}
            onClick={onClick}
          />
        </div>
      )}
    </>
  );
};

export { LabeledSwitch };
