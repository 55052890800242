import { useIntl } from 'react-intl';

import { useStations } from '../../../api/hooks/useStationApi';
import { Station } from '../../../types/types';
import { FormDropDown } from '../../library/form/FormDropdown';
import { DropDownItem } from '../../library/inputs/DropDown';

interface StationDropDownProps {
  required?: boolean;
}
export function FormStationDropDown({ required }: StationDropDownProps) {
  const intl = useIntl();
  const { stations } = useStations();
  const stationDropDownItems = getDropDownItems(stations);
  return (
    <FormDropDown
      id={'stationsDropDown'}
      label={intl.formatMessage({ id: 'stations.singular' })}
      name={'stationId'}
      dropDownItems={stationDropDownItems}
      required={required}
    />
  );
}

function getDropDownItems(stations: Station[]): DropDownItem[] {
  const result = stations.map(station => ({
    value: station._id,
    label: station.location,
  }));
  result.push({ value: '', label: '-' });
  return result;
}
