import useIntl from 'react-intl/src/components/useIntl';

import { useDeleteMultiRenter } from '../../../api/hooks/useRentingApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { ApiError } from '../../../types/types';
import { CancelButton } from '../../library/buttons/CancelButton';
import { ConfirmButton } from '../../library/buttons/ConfirmButton';
import { Dialog } from '../../library/dialog/Dialog';

export function DeleteRentersDialog() {
  const intl = useIntl();
  const { setNotificationMessage } = useNotificationMessageContext();
  const { openDeleteDialog, entitieIdsToDelete, setOpenDeleteDialog } = useCrudPageUiStateContext();

  const { mutateAsync: deleteMultiRenter } = useDeleteMultiRenter();

  return (
    <>
      {openDeleteDialog && (
        <Dialog
          id={'DeleteRentersDialog'}
          isOpen={openDeleteDialog}
          title={intl.formatMessage({ id: 'renter.multiDelete.confirm' })}
          content={
            <>
              <p>
                {intl.formatMessage(
                  { id: 'renter.multiDelete.confirm1' },
                  {
                    numberOfRenters: entitieIdsToDelete?.length,
                  }
                )}
              </p>
              <p>{intl.formatMessage({ id: 'renter.multiDelete.confirm2' })}</p>
            </>
          }
          buttons={
            <>
              <CancelButton
                onClick={() => {
                  setOpenDeleteDialog(false);
                }}
              />
              <ConfirmButton
                onClick={() => {
                  handleDeleteEntity();
                  setOpenDeleteDialog(false);
                }}
              />
            </>
          }
        />
      )}
    </>
  );

  function handleDeleteEntity() {
    deleteMultiRenter(
      {
        rentersId:
          entitieIdsToDelete?.map(e => {
            return { _id: e };
          }) ?? [],
      },
      {
        onError: (error: any) => {
          if (error.response && error.response.data) {
            const errorData = error.response.data as ApiError;
            if (errorData.code) {
              // TODO spezific error message
              setNotificationMessage(`alert-rules.${errorData.code}`, NotificationType.Success);
            } else {
              setNotificationMessage(`renters.generic-delete-error`, NotificationType.Error);
            }
          }
        },
        onSuccess: () => {
          setNotificationMessage('renters.deleted', NotificationType.Success);
        },
      }
    );
  }
}
