import { Breadcrumbs, Container, Link } from '@mui/material';
import { ReactNode } from 'react';

export interface BreadcrumbItem {
  label: string;
  route: string;
}

export interface PageHeaderProps {
  breadCrumbs: BreadcrumbItem[];
  children?: ReactNode;
}

function PageHeader({ breadCrumbs, children }: PageHeaderProps) {
  return (
    <Container
      sx={{
        bgcolor: 'white',
        py: '15px',
        mb: '20px',
        display: 'flex',
        alignItems: 'center',
      }}
      maxWidth={false}
    >
      <Breadcrumbs
        style={{
          width: '30%',
        }}
        aria-label={'breadcrumb'}
      >
        {breadCrumbs.map((breadcrumb, index) => (
          <Link
            key={breadcrumb.label}
            underline={'hover'}
            color={index === breadCrumbs.length - 1 ? 'text.primary' : 'inherit'}
            href={breadcrumb.route}
          >
            {breadcrumb.label}
          </Link>
        ))}
      </Breadcrumbs>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'right',
          width: '70%',
        }}
      >
        {children}
      </div>
    </Container>
  );
}

export { PageHeader };
