import debounceFn from 'debounce-fn';
import { saveAs } from 'file-saver';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ErrorModal } from '../../../components/modal/ErrorModal';
import { TablePageLayout } from '../../../components/table/TablePageLayout';
import { useRequestHeaders } from '../../../hooks/useRequestHeaders';

interface HistoryPageLayoutProps {
  pageName: string;
  pageNameSingular: string;
  historyTable: ReactNode;
  isHistoryLoading: boolean;
  isHistoryError: boolean;
  historyExcel: BlobPart | null;
  excelHeaders: any;
  isExcelLoading: boolean;
  isExcelSuccess: boolean;
  isExcelError: boolean;
  fetchExcel: () => void;
  removeExcel: () => void;
  cleanup: () => void;
}

function HistoryPageLayout({
  pageName,
  pageNameSingular,
  historyTable,
  isHistoryLoading,
  isHistoryError,
  historyExcel,
  excelHeaders,
  isExcelLoading,
  isExcelSuccess,
  isExcelError,
  fetchExcel,
  removeExcel,
  cleanup,
}: HistoryPageLayoutProps) {
  const intl = useIntl();
  const { contentDispositionFileName } = useRequestHeaders();

  const debouncedRefetch = useMemo(() => debounceFn(fetchExcel, { wait: 500 }), [fetchExcel]);

  const [isModalopen, setIsModalOpen] = useState<boolean>(false);

  const xtraHeaderButtons = [
    {
      onClick: () => debouncedRefetch(),
      iconName: 'download',
      isDisabled: isExcelLoading,
    },
  ];

  useEffect(() => {
    if (historyExcel !== null) {
      const fileName = contentDispositionFileName(excelHeaders);
      saveAs(new Blob([historyExcel]), fileName);
    }
    return () => {
      removeExcel();
    };
  }, [isExcelSuccess]);

  const reportsPageLink = () => {
    return (
      <>
        <Link className="uib-link" to="/reports">
          {intl.formatMessage({ id: 'reports.self' })}
        </Link>
        {' > '}
      </>
    );
  };

  return (
    <>
      <TablePageLayout
        isContentLoading={isHistoryLoading}
        isContentError={isHistoryError}
        pageName={pageName}
        pageNameSingular={pageNameSingular}
        pageNavigationLink={reportsPageLink()}
        table={historyTable}
        cleanup={cleanup}
        xtraHeaderButtons={xtraHeaderButtons}
        isHeaderButtonDisabled={false}
        hasHeaderButton={true}
      ></TablePageLayout>
      {isExcelError && (
        <ErrorModal
          error={`${intl.formatMessage({
            id: 'reports.excelError',
          })} ${intl.formatMessage({ id: 'general.contactAdmin' })}`}
          isOpen={isModalopen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
}

export { HistoryPageLayout };
