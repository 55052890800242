import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { LanguageOptionValue, useLanguageContext } from '../../context/languageContext';
import { ButtonVariant } from '../../types/components/Button';
import Button from '../odx/Button';

import { Modal } from './Modal';

export interface DeleteModalProps {
  onClose: () => void;
  onConfirm: () => void;
  entry: string;
  content?: ReactNode;
  additionalMessage?: string;
}

function DeleteModal({ onClose, onConfirm, entry, content, additionalMessage }: DeleteModalProps) {
  const intl = useIntl();
  const { currentLanguage } = useLanguageContext();
  const thingToBeDeleted =
    currentLanguage.value === LanguageOptionValue.de ? entry : entry.toLowerCase();
  content = content ? (
    content
  ) : (
    <p>
      {intl.formatMessage({ id: 'components.delete.confirmation' }, { entry: thingToBeDeleted })}{' '}
      {additionalMessage}
    </p>
  );

  return (
    <Modal
      isOpen={true}
      header={
        <h1 className="uib-modal__title">
          {intl.formatMessage({ id: 'general.delete' }, { entry: entry })}
        </h1>
      }
      content={content}
      closeButtonLabel={intl.formatMessage({ id: 'general.cancel' })}
      modalHasClosed={onClose}
      actionButton={
        <Button id={'deleteConfirmButton'} onClick={onConfirm} variant={ButtonVariant.DANGER}>
          {intl.formatMessage({ id: 'general.confirm' })}
        </Button>
      }
    />
  );
}

export { DeleteModal };
