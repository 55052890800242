import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import {
  Column,
  ColumnInstance,
  HeaderGroup,
  HeaderProps,
  SortingRule,
  TableToggleCommonProps,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { DefaultColumnFilter } from './DefaultColumnFilter';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';

export interface StockColumn extends ColumnInstance {
  applyStyle: boolean;
}
export interface ColumnFilter {
  columnAccessor: string | null;
  filterValue: string | null;
}
export interface VerticalTableProps<T extends { [key: string]: unknown }> {
  columns: Column<{ [key: string]: unknown }>[];
  data: T[];
  updateData?: Dispatch<SetStateAction<T[]>>;
  sortBy?: SortingRule<{ [key: string]: unknown }>[];
  canSearchEntries?: boolean;
  onClickRow?: (row: { [key: string]: unknown }) => void;
  canHighlightSelectedRow?: boolean;
  selectedRowId?: string;
  selectedColumnHeader?: string;
  dropdownPages?: number[];
  defaultPageSize?: number;
  isScrollableInX?: boolean;
  getCellProps?: (row: any, applyStyle: boolean) => any;
  hiddenColumns?: string[];
  setMultiSelectedElements?: React.Dispatch<React.SetStateAction<string[]>>;
  isMultiSelectModalOpen?: boolean;
}

const maxMediaBreakWidth = 920;
const defaultPropGetter = () => ({});

function VerticalTable<T extends Record<string, unknown>>({
  columns,
  data,
  updateData,
  sortBy: sortBy = [],
  canSearchEntries = true,
  onClickRow,
  canHighlightSelectedRow = false,
  selectedRowId,
  dropdownPages = [20, 50, 100],
  defaultPageSize = 20,
  isScrollableInX = false,
  getCellProps = defaultPropGetter,
  hiddenColumns = [],
  setMultiSelectedElements,
  isMultiSelectModalOpen,
}: VerticalTableProps<T>) {
  const intl = useIntl();
  const memoizedData = useMemo(() => data, [data]);

  const memoizedColumns = useMemo(() => columns, [columns]);

  const textFilter = (
    rows: { values: { [key: string]: string } }[],
    id: string,
    filterValue: string
  ) => {
    return rows.filter(row => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
        : true;
    });
  };

  const filterTypes = useMemo(
    () => ({
      text: textFilter,
    }),
    []
  );

  const updateCurrentState = (rowIndex: number, columnId: string, value: string | number) => {
    if (updateData)
      updateData(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        })
      );
  };

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      defaultColumn: defaultColumn,
      filterTypes,
      initialState: {
        sortBy,
        pageIndex: 0,
        pageSize: defaultPageSize,
        hiddenColumns: hiddenColumns,
      },
      updateCurrentState,
      autoResetPage: false,
      autoResetFilters: false,
      enableRowSelection: true,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (setMultiSelectedElements)
        hooks.visibleColumns.push((columns: any) => [
          {
            id: 'selection',
            Header: ({
              getToggleAllPageRowsSelectedProps,
            }: HeaderProps<Record<string, unknown>>) => (
              <IndeterminateCheckbox isHeaderCell={true} {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({
              row,
            }: {
              row: { getToggleRowSelectedProps: () => TableToggleCommonProps };
            }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    rows,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const getSumById = (id: string) => {
    const initialValue = 0;
    return Array.from(rows)
      .filter(row => typeof row.values[id] === 'number')
      .reduce((accumulator, row) => accumulator + row.values[id], initialValue);
  };

  useEffect(() => {
    if (!page.length && pageCount) {
      previousPage();
    }
    if (rows.length <= defaultPageSize) {
      gotoPage(0);
    }
  }, [rows.length]);

  useEffect(() => {
    setMultiSelectedElements &&
      setMultiSelectedElements(
        tableInstance.selectedFlatRows.map(
          selectedFlatRow => selectedFlatRow.original._id
        ) as string[]
      );
  }, [tableInstance.selectedFlatRows.length]);

  useEffect(() => {
    if (isMultiSelectModalOpen === false) {
      toggleAllRowsSelected(false);
    }
  }, [isMultiSelectModalOpen]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const column = query.get('column');
  const filter = query.get('filter');
  const columnFilter: ColumnFilter = {
    columnAccessor: column,
    filterValue: filter,
  };
  useEffect(() => {
    if (
      columnFilter &&
      columnFilter.columnAccessor &&
      columnFilter.filterValue &&
      columns.some(c => columnFilter.columnAccessor === c.accessor)
    ) {
      tableInstance.setFilter(columnFilter.columnAccessor, columnFilter.filterValue);
    }
  }, []);

  const paging = () => {
    return (
      <>
        {rows.length >= dropdownPages[0] && (
          <div className="pagination">
            <div className="pagination-buttons">
              {rows.length > pageSize && (
                <>
                  <button
                    className="uib-iconbutton"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    <i className="uib-icon  uib-icon--arrow-switch-up uib-icon--size-xsmall narrow" />
                    <i className="uib-icon  uib-icon--arrow-switch-up uib-icon--size-xsmall" />
                  </button>
                  <button
                    className="uib-iconbutton"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <i className="uib-icon  uib-icon--arrow-switch-up uib-icon--size-xsmall" />
                  </button>
                  <button
                    className="uib-iconbutton"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <i className="uib-icon uib-icon--arrow-level-down uib-icon--size-xsmall" />
                  </button>
                  <button
                    className="uib-iconbutton"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    <i className="uib-icon uib-icon--arrow-level-down uib-icon--size-xsmall narrow" />
                    <i className="uib-icon uib-icon--arrow-level-down uib-icon--size-xsmall" />
                  </button>
                </>
              )}
            </div>
            <div className="pagination-label">
              {intl.formatMessage({
                id: 'components.table.page',
              })}{' '}
              <strong>
                {pageIndex + 1}{' '}
                {intl.formatMessage({
                  id: 'components.table.of',
                })}{' '}
                {pageOptions.length}
              </strong>{' '}
            </div>

            {dropdownPages.length > 1 && (
              <div className="uib-dropdown">
                <select
                  className="uib-dropdown__select"
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {' '}
                  {dropdownPages.map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {intl.formatMessage({
                        id: 'components.table.show',
                      })}{' '}
                      {pageSize}
                    </option>
                  ))}
                </select>
                <div className="uib-dropdown__arrow" />
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const rowClassName = (row: any) => {
    const isRowSelected =
      canHighlightSelectedRow && row.original._id && row.original._id === selectedRowId;

    return `uib-table__row ${isRowSelected ? 'uib-table__row--selected' : ''} ${
      tableInstance.selectedFlatRows.some(
        selectedRow => selectedRow.original._id === row.original._id
      )
        ? 'uib-table__row--checked'
        : ''
    }`;
  };

  return (
    <>
      <table
        className={`uib-table uib-table--plain vertical-table ${
          isScrollableInX && 'vertical-table-scrollable-x'
        }`}
        {...getTableProps()}
      >
        <thead className="uib-table__header">
          {headerGroups.map((headerGroup, i) => (
            <tr
              className="uib-table__row uib-table__row--header"
              {...headerGroup.getHeaderGroupProps()}
              key={i}
            >
              {headerGroup.headers.map(
                (
                  column: HeaderGroup<{
                    [key: string]: unknown;
                  }>,
                  j
                ) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`${column.reducedWidth && 'reduce-width'}`}
                    key={j}
                  >
                    {column.canSort ? (
                      <div className="header-text" {...column.getSortByToggleProps()}>
                        {<span>{column.render('Header')}</span>}

                        {column.isSorted && (
                          <i
                            className={`uib-icon  ${
                              column.isSortedDesc
                                ? 'uib-icon--level-up'
                                : 'uib-icon--arrow-dropdown'
                            }`}
                          />
                        )}
                      </div>
                    ) : (
                      <div className="header-text">{column.render('Header')}</div>
                    )}
                    {canSearchEntries && (
                      <div className="uib-search">
                        {column.canFilter && column.render('Filter')}

                        {column.addSum && (
                          <span>
                            {`${intl.formatMessage({
                              id: 'components.table.sum',
                            })} ${getSumById(column.id)}`}
                          </span>
                        )}
                      </div>
                    )}
                  </th>
                )
              )}
            </tr>
          ))}
        </thead>
        <tbody className="uib-table__body" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className={rowClassName(row)}
                {...row.getRowProps()}
                key={i}
                onClick={() => onClickRow?.(row.original)}
              >
                {row.cells.map((cell, j) =>
                  window.innerWidth < maxMediaBreakWidth && cell.column.hideColumn ? (
                    cell.column.toggleHidden(true)
                  ) : (
                    <td
                      style={{
                        cursor: onClickRow ? 'pointer' : 'default',
                      }}
                      {...cell.getCellProps([
                        { className: (cell.column as any).className },
                        getCellProps(row.original, (cell.column as StockColumn).applyStyle),
                      ])}
                      key={j}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {paging()}
    </>
  );
}

export { VerticalTable };
