import { ReactNode, createContext, useContext, useState } from 'react';

interface BusinessReportUiStateContextApi {
  openDownloadStartedDialog: boolean;
  setOpenDownloadStartedDialog: (value: boolean) => void;
}

const BusinessReportUiStateContext = createContext<BusinessReportUiStateContextApi | undefined>(
  undefined
);

interface BusinessReportUiStateProviderProps {
  children: ReactNode;
}

function BusinessReportUiStateProvider({ children }: BusinessReportUiStateProviderProps) {
  const [openDownloadStartedDialog, setOpenDownloadStartedDialog] = useState<boolean>(false);

  const value: BusinessReportUiStateContextApi = {
    openDownloadStartedDialog: openDownloadStartedDialog,
    setOpenDownloadStartedDialog: setOpenDownloadStartedDialog,
  };
  return (
    <BusinessReportUiStateContext.Provider value={value}>
      {children}
    </BusinessReportUiStateContext.Provider>
  );
}

function useBusinessReportUiStateContext() {
  const context = useContext(BusinessReportUiStateContext);
  if (context === undefined) {
    throw new Error(
      'useBusinessReportUiStateContext must be used within an BusinessReportUiStateProvider'
    );
  }
  return context;
}

export { BusinessReportUiStateProvider, useBusinessReportUiStateContext };
