import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useContractors,
  useCreateContractor,
  useDeleteContractor,
  useEditContractor,
} from '../../../api/hooks/useContractorApi';
import { RequestErrorResponse } from '../../../components/modal/ErrorModal';
import { TablePageLayout } from '../../../components/table/TablePageLayout';
import { Contractor } from '../../../types/contractors/Contractor';
import { generateEditRequest } from '../../../utils/formData';

import { ContractorsTable } from './ContractorsTable';
import { ContractorFormData } from './manage/ContractorForm';
import { DraftContractorModal } from './manage/DraftContractorModal';

function ContractorsPage() {
  const intl = useIntl();

  const [isCreatingContractor, toggleIsCreatingContractor] = useState<boolean>(false);
  const [selectedContractorForEditing, setSelectedContractorForEditing] = useState<
    Contractor | undefined
  >();
  const [editContractorError, setEditContractorError] = useState<unknown>();
  const [selectedContractorForDeletion, setSelectedContractorForDeletion] = useState<
    Contractor | undefined
  >();
  const [deleteContractorError, setDeleteContractorError] = useState<unknown>();

  const { isLoading, isError, error: getContractorsError, contractors, remove } = useContractors();
  const {
    mutateAsync: createContractor,
    isError: isCreateContractorError,
    error: createContractorError,
    reset: resetCreateContractor,
  } = useCreateContractor();
  const {
    mutateAsync: editContractor,
    isError: isEditContractorError,
    error: editContractorErrorResponse,
    reset: resetEditContractor,
  } = useEditContractor();
  const {
    mutateAsync: deleteContractor,
    isError: isDeleteContractorError,
    error: deleteContractorErrorResponse,
    reset: resetDeleteContractor,
  } = useDeleteContractor(selectedContractorForDeletion?._id ?? '');

  const onCloseDraftContractorModal = () => {
    toggleIsCreatingContractor(false);
    setSelectedContractorForEditing(undefined);
  };

  const onSubmitContractorForm = async (data: ContractorFormData) => {
    if (isCreatingContractor) onSubmitCreateContractor(data);
    if (selectedContractorForEditing) onSubmitEditContractor(data);
  };

  const onSubmitCreateContractor = async (data: ContractorFormData) => {
    createContractor(data).finally(() => {
      toggleIsCreatingContractor(false);
    });
  };

  const onSubmitEditContractor = async (data: ContractorFormData) => {
    const editRequest = generateEditRequest(data);
    if (editRequest) {
      editContractor(editRequest).finally(() => {
        // Always set to null as error case will trigger new modal
        setSelectedContractorForEditing(undefined);
      });
    } else {
      setEditContractorError(intl.formatMessage({ id: 'items.edit.error' }));
      setSelectedContractorForEditing(undefined);
    }
  };

  useEffect(() => {
    if (isEditContractorError) {
      setEditContractorError(editContractorErrorResponse);
    }
  }, [isEditContractorError]);

  const onConfirmDeleteContractor = () => {
    deleteContractor().finally(() => {
      setSelectedContractorForDeletion(undefined);
    });
  };

  useEffect(() => {
    if (isDeleteContractorError) {
      const {
        response: {
          status,
          data: { messages },
        },
      } = deleteContractorErrorResponse as RequestErrorResponse;
      if (status === 409) {
        setDeleteContractorError(
          intl.formatMessage(
            { id: 'contractors.delete.referenceError' },
            { referenceCount: messages[0] }
          )
        );
      } else {
        setDeleteContractorError(deleteContractorErrorResponse);
      }
    }
  }, [isDeleteContractorError]);

  return (
    <TablePageLayout
      isContentLoading={isLoading}
      isContentError={isError}
      contentError={getContractorsError}
      pageName={intl.formatMessage({ id: 'contractors.self' })}
      pageNameSingular={intl.formatMessage({ id: 'contractors.singular' })}
      table={
        <ContractorsTable
          contractors={contractors}
          onSelectContractorForEditing={setSelectedContractorForEditing}
          onSelectContractorForDeletion={setSelectedContractorForDeletion}
        />
      }
      hasDraftModal={true}
      openCreateModal={() => toggleIsCreatingContractor(true)}
      isDraftModalOpen={isCreatingContractor || !!selectedContractorForEditing}
      draftModal={
        <DraftContractorModal
          contractor={selectedContractorForEditing}
          onClose={onCloseDraftContractorModal}
          onSubmit={onSubmitContractorForm}
        />
      }
      isCreateModalError={isCreateContractorError}
      createModalError={createContractorError}
      isEditModalError={editContractorError !== undefined}
      editModalError={editContractorError}
      isDeleteModalOpen={!!selectedContractorForDeletion}
      onCloseDelete={setSelectedContractorForDeletion}
      onConfirmDelete={onConfirmDeleteContractor}
      confirmDeleteMessage={
        <p>
          {intl.formatMessage(
            { id: 'contractors.delete.confirm' },
            {
              contractorName: selectedContractorForDeletion?.name,
            }
          )}
        </p>
      }
      isDeleteModalError={deleteContractorError !== undefined}
      deleteModalError={deleteContractorError}
      cleanup={remove}
      cleanupErrors={() => {
        resetCreateContractor();
        resetEditContractor();
        setEditContractorError(undefined);
        resetDeleteContractor();
        setDeleteContractorError(undefined);
      }}
    />
  );
}
export { ContractorsPage };
