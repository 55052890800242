import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  useCabinets,
  useCreateCabinet,
  useDeleteCabinet,
  useEditCabinet,
} from '../../../api/hooks/useStationApi';
import { Grid } from '../../../components/library/containers/Grid';
import { TablePageLayout } from '../../../components/table/TablePageLayout';
import { Cabinet, Station } from '../../../types/types';
import { generateEditRequest } from '../../../utils/formData';

import { CabinetsTable } from './CabinetsTable';
import { LockersView } from './lockers/LockersView';
import { CabinetFormData } from './manage/CabinetForm';
import { DraftCabinetModal } from './manage/DraftCabinetModal';

interface CabinetsPageProps {
  station: Station;
}

function CabinetsPage({ station }: CabinetsPageProps) {
  const intl = useIntl();

  const stationId = station?._id;
  const [selectedCabinetId, setSelectedCabinetId] = useState('');
  const [isCreatingCabinet, toggleIsCreatingCabinet] = useState<boolean>(false);
  const [selectedCabinetForEditing, setSelectedCabinetForEditing] = useState<Cabinet | undefined>();
  const [editCabinetError, setEditCabinetError] = useState<unknown>(undefined);
  const [selectedCabinetForDeletion, setSelectedCabinetForDeletion] = useState<
    Cabinet | undefined
  >();

  const { isLoading, isError, error: getCabinetsError, cabinets, remove } = useCabinets(stationId);
  const {
    mutateAsync: createCabinet,
    isError: isCreateCabinetError,
    error: createCabinetError,
    reset: resetCreateCabinet,
  } = useCreateCabinet(stationId);
  const {
    mutateAsync: editCabinet,
    isError: isEditCabinetError,
    error: editCabinetErrorResponse,
    reset: resetEditCabinet,
  } = useEditCabinet(stationId);
  const {
    mutateAsync: deleteCabinet,
    isError: isDeleteCabinetError,
    error: deleteCabinetError,
    reset: resetDeleteCabinet,
  } = useDeleteCabinet(stationId, selectedCabinetForDeletion?._id ?? '');

  const { cabinetId } = useParams<{ cabinetId: string }>();
  const cabinet = useMemo(
    () => cabinets.find((cabinet: { _id: string }) => cabinet._id === cabinetId),
    [cabinets, cabinetId]
  );
  useEffect(() => {
    if (cabinet) {
      setSelectedCabinetId(cabinet._id);
    }
  }, [cabinet]);

  const navigate = useNavigate();
  const selectCabinet = ({ _id }: Cabinet) => {
    setSelectedCabinetId(_id);
    const url = '/stations/' + stationId + '/' + _id;
    navigate(url);
  };

  const onCloseCabinetForm = () => {
    toggleIsCreatingCabinet(false);
    setSelectedCabinetForEditing(undefined);
  };

  const onSubmitCabinetForm = async (data: CabinetFormData) => {
    if (isCreatingCabinet) onSubmitCreateCabinet(data);
    if (selectedCabinetForEditing) onSubmitEditCabinet(data);
  };

  const onSubmitCreateCabinet = async (data: CabinetFormData) => {
    data.lockerFeatureId = data.lockerFeatureId != 'none' ? data.lockerFeatureId : undefined;
    createCabinet(data).finally(() => {
      toggleIsCreatingCabinet(false);
    });
  };

  const onSubmitEditCabinet = async (data: CabinetFormData) => {
    if (data.lockerFeatureId === 'none') data.lockerFeatureId = '';
    const editRequest = generateEditRequest(data);
    if (editRequest) {
      editCabinet(editRequest).finally(() => {
        setSelectedCabinetForEditing(undefined);
      });
    } else {
      setEditCabinetError(intl.formatMessage({ id: 'cabinets.edit.error' }));
      setSelectedCabinetForEditing(undefined);
    }
  };

  const onSubmitDnDItem = async (newPosition: number, row: { [key: string]: unknown }) => {
    const data = JSON.parse(`{"_id": "${row._id}", "position": "${newPosition}"}`);
    const editRequest = generateEditRequest(data);
    if (editRequest) {
      editCabinet(editRequest).finally(() => {
        setSelectedCabinetForEditing(undefined);
      });
    }
  };

  useEffect(() => {
    if (isEditCabinetError) {
      setEditCabinetError(editCabinetErrorResponse);
    }
  }, [isEditCabinetError]);

  const onConfirmDeleteCabinet = () => {
    deleteCabinet().finally(() => {
      setSelectedCabinetForDeletion(undefined);
    });
  };

  return (
    <TablePageLayout
      isContentLoading={isLoading}
      isContentError={isError}
      contentError={getCabinetsError}
      pageName={intl.formatMessage({ id: 'cabinets.self' })}
      pageNameSingular={intl.formatMessage({ id: 'cabinets.singular' })}
      pageNavigationLink={
        <>
          <Link className="uib-link" to="/stations">
            {intl.formatMessage({ id: 'stations.self' })}
          </Link>
          {' > '} {station.location} {' > '}
        </>
      }
      table={
        <Grid columns={2} container>
          <Grid item sx={{ width: { xs: '100%', lg: '30%' } }}>
            <CabinetsTable
              cabinets={cabinets}
              clickCabinet={selectCabinet}
              selectedCabinetId={selectedCabinetId}
              onSelectCabinetForEditing={setSelectedCabinetForEditing}
              onSelectCabinetForDeletion={setSelectedCabinetForDeletion}
              onSubmitDnD={onSubmitDnDItem}
            />
          </Grid>
          <Grid item sx={{ width: { xs: '100%', lg: '70%' } }}>
            {!isLoading && (
              <div className="uib-board__box uib-board__box--stretch">
                {cabinet ? (
                  <LockersView station={station} cabinet={cabinet} />
                ) : (
                  <div className="uib-card">
                    <div className="uib-card__header">
                      <p className="uib-card__headline">
                        {intl.formatMessage({ id: 'cabinets.selectPrompt' })}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      }
      hasDraftModal={true}
      openCreateModal={() => toggleIsCreatingCabinet(true)}
      isDraftModalOpen={isCreatingCabinet || !!selectedCabinetForEditing}
      draftModal={
        <DraftCabinetModal
          cabinet={selectedCabinetForEditing}
          onClose={onCloseCabinetForm}
          onSubmit={onSubmitCabinetForm}
        />
      }
      isCreateModalError={isCreateCabinetError}
      createModalError={createCabinetError}
      isEditModalError={editCabinetError !== undefined}
      editModalError={editCabinetError}
      isDeleteModalOpen={!!selectedCabinetForDeletion}
      onCloseDelete={setSelectedCabinetForDeletion}
      onConfirmDelete={onConfirmDeleteCabinet}
      isDeleteModalError={isDeleteCabinetError}
      deleteModalError={deleteCabinetError}
      cleanup={remove}
      cleanupErrors={() => {
        resetCreateCabinet();
        resetEditCabinet();
        resetDeleteCabinet();
        setEditCabinetError(undefined);
      }}
    />
  );
}

export { CabinetsPage };
