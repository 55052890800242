import { useIntl } from 'react-intl';

interface DefaultColumnFilterProps {
  column: {
    filterValue: string;
    preFilteredRows: string[];
    setFilter: (event: string | undefined) => void;
  };
}

export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}: DefaultColumnFilterProps) => {
  const intl = useIntl();
  const count = preFilteredRows.length;

  return (
    <>
      <span className="uib-search__icon" />
      <input
        className="uib-search__input"
        type="search"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={intl.formatMessage({ id: 'components.table.searchEntries' }, { count })}
      />
    </>
  );
};
