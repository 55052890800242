import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useDownloadBusinessReport } from '../../../api/hooks/useReportsApi';
import { ErrorModal } from '../../../components/modal/ErrorModal';
import { useBusinessReportUiStateContext } from '../../../context/BusinessReportUiStateContext';

import { BusinessReportDownloadStartedModal } from './BusinessReportDownloadStartedModal';
import { BusinessFormDataProcessed, BusinessReportModal } from './BusinessReportModal';
import { OnClickReportCard } from './OnClickReportCard';

export function BusinessReportCard() {
  const { setOpenDownloadStartedDialog } = useBusinessReportUiStateContext();
  const [isSelectedBusinessReport, toggleIsSelectedBusinessReport] = useState<boolean>(false);
  const [isErrorModalopen, toggleIsErrorModalOpen] = useState<boolean>(false);

  const intl = useIntl();
  const { mutate: downloadExcel, isError, isLoading } = useDownloadBusinessReport();

  const handleBusinessReportClick = () => {
    toggleIsSelectedBusinessReport(true);
  };

  useEffect(() => {
    if (isError) {
      setOpenDownloadStartedDialog(false);
      toggleIsErrorModalOpen(true);
    }
  }, [isError]);
  function handleDownloadClick(data: BusinessFormDataProcessed) {
    downloadExcel(data);
    setOpenDownloadStartedDialog(true);
  }
  return (
    <>
      <OnClickReportCard
        id={'btnBusinessReport'}
        name={intl.formatMessage({ id: 'reports.businessReport' })}
        onClick={handleBusinessReportClick}
      >
        <i className="uib-icon uib-icon--log-view card-icon" />
      </OnClickReportCard>
      {isSelectedBusinessReport && (
        <BusinessReportModal
          onClick={handleDownloadClick}
          onClose={() => {
            toggleIsSelectedBusinessReport(false);
          }}
        />
      )}
      <BusinessReportDownloadStartedModal isLoading={isLoading} />
      {isErrorModalopen && (
        <ErrorModal
          error={`${intl.formatMessage({ id: 'reports.excelError' })} ${intl.formatMessage({
            id: 'general.contactAdmin',
          })}`}
          isOpen={isErrorModalopen}
          onClose={() => toggleIsErrorModalOpen(false)}
        />
      )}
    </>
  );
}
