import { Button } from '@mui/material';
import { useIntl } from 'react-intl';

interface DownloadButtonProps {
  id: string;
  disabled: boolean;
}

export function DownloadButton({ id, disabled }: DownloadButtonProps) {
  const intl = useIntl();
  return (
    <Button variant={'contained'} type="submit" id={id} disabled={disabled}>
      {intl.formatMessage({ id: 'general.download' })}
    </Button>
  );
}
