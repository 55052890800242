export interface Contractor extends Record<string, unknown> {
  _id: string;
  name: string;
  contactPerson: string;
  contactEmail: string;
}

export function getContractorNames(contractorIds: string[], contractors: Contractor[]): string[] {
  const contractorNames = [];
  for (const contractorId of contractorIds) {
    const contractorName = getContractorName(contractorId, contractors);
    if (contractorName !== '') {
      contractorNames.push(contractorName);
    }
  }
  return contractorNames;
}
function getContractorName(contractorId: string, contractors: Contractor[]): string {
  const contractor = contractors.find(c => c._id === contractorId);
  return contractor?.name ?? '';
}
