import { ReactNode } from 'react';

interface SubHeaderMenuProps {
  firstButtonLabel: string;
  isFirstButtonDisabled?: boolean;
  handleFirstButtonClick?: () => void;
  secondButtonLabel?: string;
  isSecondButtonDisabled?: boolean;
  handleSecondButtonClick?: () => void;
  secondButtonLink?: ReactNode;
  closeMenu: () => void;
}

function SubHeaderMenu({
  firstButtonLabel,
  isFirstButtonDisabled = false,
  handleFirstButtonClick,
  secondButtonLabel,
  isSecondButtonDisabled = false,
  handleSecondButtonClick,
  secondButtonLink,
  closeMenu,
}: SubHeaderMenuProps) {
  return (
    <div className="user-menu">
      <div className="display-button-div">
        <button
          id={'softwareSettingBtn'}
          disabled={isFirstButtonDisabled}
          onClick={() => {
            closeMenu();
            if (handleFirstButtonClick) handleFirstButtonClick();
          }}
        >
          {firstButtonLabel}
        </button>
      </div>
      <div className="display-button-div">
        <button
          id={'userManagementBtn'}
          className={secondButtonLink ? 'button-link' : undefined}
          disabled={isSecondButtonDisabled}
          onClick={() => {
            closeMenu();
            if (handleSecondButtonClick) handleSecondButtonClick();
          }}
        >
          {secondButtonLink ? secondButtonLink : secondButtonLabel}
        </button>
      </div>
    </div>
  );
}

export { SubHeaderMenu };
