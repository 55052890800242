import { useIntl } from 'react-intl';

import { FormTextField } from '../../../library/form/FormTextField';

export function SystemGdcTenantIdInput() {
  const intl = useIntl();
  return (
    <FormTextField
      id={'SystemGdcTenantIdInput'}
      label={intl.formatMessage({ id: 'systems.gdcTenantId' })}
      required={false}
      disabled={false}
      name={'gdcTenantId'}
    />
  );
}
