import { useIntl } from 'react-intl';

import { useRenters } from '../../../api/hooks/useRentingApi';
import { getRenterName, Renter } from '../../../types/renter/Renter';
import {
  AutoCompleteOption,
  FormMultiAutocomplete,
} from '../../library/form/FormMultiAutocomplete';

export function MultiRentersDropDown() {
  const intl = useIntl();
  const { renters } = useRenters();
  const renterDropDownItems = getOptions(renters);
  const label = intl.formatMessage({ id: 'renters.self' });
  return (
    <FormMultiAutocomplete
      id={'renterDropdown'}
      label={label}
      name={'renterIds'}
      options={renterDropDownItems}
    />
  );
}

function getOptions(renters: Renter[]): AutoCompleteOption[] {
  const result = renters.map(renter => ({
    id: renter._id,
    label: getRenterName(renter),
  }));
  return result;
}
