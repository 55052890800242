import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { MultiValue } from 'react-select';
import { CellProps, Row } from 'react-table';

import { MultiSelectColumnFilter } from '../../components/table/MultiselectColumnFilter';
import { EditButton } from '../../components/table/table-buttons/EditButton';
import { VerticalTable } from '../../components/table/VerticalTable';
import { OptionItem, Stock } from '../../types/types';
import { bindestrich } from '../../utils/bindestrich';

interface StockTableProps {
  itemsStock: Stock[];
  onSelectContractStockForEditing: Dispatch<SetStateAction<Stock | undefined>>;
}

function StockTable({ itemsStock, onSelectContractStockForEditing }: StockTableProps) {
  const intl = useIntl();

  function filterSeveralValues(
    rows: Row<Stock>[],
    id: string,
    filterValue: MultiValue<OptionItem>
  ) {
    const values: string[] = [];
    filterValue.forEach((value: OptionItem) => {
      values.push(value.label);
    });

    if (!values.length) return rows;

    return rows.filter(row => {
      const rowValue = row.values[id];
      return values.includes(rowValue);
    });
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'stockPage.itemType' }),
      accessor: 'itemType.name',
    },
    {
      Header: intl.formatMessage({ id: 'stockPage.stationLocation' }),
      accessor: 'station.name',
      id: 'stationLocation',
      Filter: MultiSelectColumnFilter,
      filter: filterSeveralValues,
    },
    {
      Header: intl.formatMessage({ id: 'stockPage.rented' }),
      id: 'rented',
      accessor: (row: Stock) => {
        const data = row as Stock;
        return data.itemType.isConsumable ? '-' : data.stock?.rented || 0;
      },
      disableFilters: true,
      addSum: true,
    },
    {
      Header: intl.formatMessage({ id: 'stockPage.overdue' }),
      id: 'overdue',
      accessor: (row: Stock) => {
        const data = row as Stock;
        return data.itemType.isConsumable ? '-' : data.stock?.overdue || 0;
      },
      disableFilters: true,
      addSum: true,
    },
    {
      Header: intl.formatMessage({ id: 'stockPage.available' }),
      id: 'available',
      accessor: (row: Stock) => {
        const data = row as Stock;
        return data.stock?.available || 0;
      },
      disableFilters: true,
      addSum: true,
    },
    {
      Header: intl.formatMessage({ id: 'stockPage.requiredService' }),
      id: 'requiredService',
      accessor: (row: Stock) => {
        const data = row as Stock;
        return data.itemType.isConsumable ? '-' : data.stock?.requiresService || 0;
      },
      disableFilters: true,
      applyStyle: true,
      addSum: true,
    },
    {
      Header: intl.formatMessage({ id: 'stockPage.contractStock' }),
      id: 'stock.contractStock',
      accessor: (row: Stock) => {
        const data = row as Stock;
        return bindestrich(data.contractStock?.stock);
      },
      disableFilters: true,
      applyStyle: true,
      addSum: true,
    },
    {
      Header: intl.formatMessage({ id: 'stockPage.alertStock' }),
      id: 'stock.alertStock',
      accessor: (row: Stock) => {
        const data = row as Stock;

        if (!data.contractStock?.alertStock || data.itemType.isConsumable) {
          return '-';
        }
        return data.contractStock?.alertStock;
      },
      disableFilters: true,
      applyStyle: true,
    },
    {
      Header: intl.formatMessage({ id: 'general.actions' }),
      id: 'actions',
      Cell: ({ row }: CellProps<Stock>) => {
        const stock = row.original;
        return (
          <EditButton
            onClick={() => {
              onSelectContractStockForEditing(stock);
            }}
          />
        );
      },
    },
  ];

  const sortBy = [
    {
      id: 'location',
      desc: false,
    },
  ];

  const cellProps = (row: Stock, applyStyle = false) => {
    const data = row as Stock;

    const markRed =
      applyStyle &&
      data.contractStock &&
      data.contractStock?.alertStock &&
      data.stock &&
      !data.itemType.isConsumable &&
      data.stock.requiresService >= data.contractStock?.alertStock;
    if (!markRed) {
      return {};
    }
    return {
      style: {
        backgroundColor: `rgb(255, 179, 179)`,
      },
    };
  };
  return (
    <VerticalTable
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      columns={columns}
      data={itemsStock}
      getCellProps={cellProps}
      sortBy={sortBy}
      isScrollableInX={true}
    />
  );
}
export { StockTable };
