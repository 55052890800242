import { IconButton, Stack } from '@mui/material';
import { ReactNode } from 'react';

interface ActionTableButtonProps {
  id: string;
  onClick: () => void;
  icon: ReactNode;
}

export function ActionTableButton({ id, onClick, icon }: ActionTableButtonProps) {
  return (
    <Stack direction="row" spacing={2}>
      <IconButton id={id} onClick={onClick}>
        {icon}
      </IconButton>
    </Stack>
  );
}
