import { IntlShape } from 'react-intl';

import {
  ApiError,
  EntriesErrorCodes,
  ErrorDetails,
  FormatErrorCodes,
  GeneralErrorCodes,
  HeadersErrorCodes,
} from '../../types/types';

const joinArrayEntries = (originalArray: string[]) => {
  return originalArray.length > 1 ? originalArray.slice(1).join(', ') : [];
};

export const handleUploadError = (intl: IntlShape, rentersUploadError: ApiError) => {
  if (rentersUploadError.code in GeneralErrorCodes) {
    return [
      intl.formatMessage({
        id: `renters.uploadDataFailed.${rentersUploadError.code}`,
      }),
    ];
  }
  if (rentersUploadError.details?.length) {
    return rentersUploadError.details.map((detail: ErrorDetails) => {
      if (!detail.parameters) {
        return;
      }
      if (detail.code in EntriesErrorCodes || detail.code in HeadersErrorCodes) {
        return intl.formatMessage(
          {
            id: `renters.uploadDataFailed.${detail.code}`,
          },
          {
            line: detail.parameters[0],
            parameter: joinArrayEntries(detail.parameters),
          }
        );
      }
      if (detail.code in FormatErrorCodes) {
        return intl.formatMessage(
          {
            id: `renters.uploadDataFailed.${detail.code}`,
          },
          {
            parameter: detail.parameters[0],
          }
        );
      }
    });
  }
};
