import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/tokenContext';
import { EditStockFormData, StockFormData } from '../../modules/stock/manage/StockForm';
import { Stock } from '../../types/types';

const STOCK_ROUTE = 'items/stock';
const CONTRACT_STOCK_ROUTE = 'contract-stocks';

function useStock() {
  const client = useClient<Stock[]>();

  const result = useQuery('itemsStock', () => client(STOCK_ROUTE));
  return { ...result, itemsStock: result.data?.data ?? [] };
}

function useCreateContractStock() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: StockFormData) =>
      client(`${CONTRACT_STOCK_ROUTE}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('itemsStock');
      },
    }
  );
}

function useEditContractStock(stockId: string) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: EditStockFormData) =>
      client(`${CONTRACT_STOCK_ROUTE}/${stockId}`, {
        method: 'PUT',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('itemsStock');
      },
    }
  );
}

export { useStock, useCreateContractStock, useEditContractStock };
