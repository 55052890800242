import { IntervalServiceType, Qualification } from '../types';

export interface ItemType extends Record<string, unknown> {
  _id: string;
  name: string;
  imageUrl: string;
  chargingDurationInHours: number;
  lockerSize: string;
  lockerFeature: string;
  xDockTestable: boolean;
  isConsumable: boolean;
  xDockTestWorkflowPolicy: XDockTestWorkflowPolicy;
  xDockTestExpirationTime: number;
  xDockAlertingTime: number;
  xDockTestExpirationTimeInStorage: number;
  gdcCalibration: boolean;
  gdcCalibrationInterval: number;
  gdcCalibrationBlockPeriod: number;
  shortName?: string;
  rentalCategory: string;
  rentalErpId?: string;
  mainErpId?: string;
  manufacturer: string;
  qualificationNames: string[];
}
export function getItemTypeNames(itemTypeIds: string[], itemTypes: ItemType[]): string[] {
  const itemTypeNames: string[] = [];
  itemTypeIds.forEach(itemTypeId => {
    const itemTypeName = getItemTypeName(itemTypeId, itemTypes);
    if (itemTypeName !== '') {
      itemTypeNames.push(itemTypeName);
    }
  });
  return itemTypeNames;
}
export function getItemTypeName(itemTypeId: string, itemTypes: ItemType[]): string {
  return itemTypes.find(it => it._id === itemTypeId)?.name ?? '';
}
export enum XDockTestWorkflowPolicy {
  INTERVAL = 'Interval',
  PER_RENT = 'PerRent',
}
export interface DetailedItemType extends ItemType {
  serviceTypes: IntervalServiceType[];
  qualifications: Qualification[];
}
export interface GdcCalibrationResult extends ItemType {
  tenantId: string;
  draegerSerialNo: string;
  testType: string;
  testDateTime: string;
  testResult: string;
}
