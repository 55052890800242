export enum IconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
  INLINE = 'inline',
}

export enum OdxIconSet {
  CORE = 'core',
  SAFETY = 'safety',
  MEDICAL = 'medical',
}
