import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useServiceTypes,
  useCreateServiceType,
  useEditServiceType,
  useServiceTypeReferences,
  useDeleteServiceType,
} from '../../api/hooks/useServiceApi';
import { TablePageLayout } from '../../components/table/TablePageLayout';
import { ServiceType } from '../../types/types';
import { generateEditRequest, cleanFormData } from '../../utils/formData';

import { DraftServiceTypeModal } from './manage/DraftServiceTypeModal';
import { ServiceTypeFormData } from './manage/ServiceTypeForm';
import { ServiceTypesTable } from './ServiceTypesTable';

function ServiceTypesPage() {
  const intl = useIntl();

  const [isCreatingServiceType, toggleIsCreatingServiceType] = useState<boolean>(false);
  const [selectedServiceTypeForEditing, setSelectedServiceTypeForEditing] = useState<
    ServiceType | undefined
  >();
  const [selectedServiceTypeForDeletion, setSelectedServiceTypeForDeletion] = useState<
    ServiceType | undefined
  >();
  const [editServiceTypeError, setEditServiceTypeError] = useState<unknown>();
  const [deleteServiceTypeError, setDeleteServiceTypeError] = useState<unknown>();

  const {
    isLoading,
    isError,
    error: getServiceTypesError,
    serviceTypes,
    remove,
  } = useServiceTypes();
  const {
    mutateAsync: createServiceType,
    isError: isCreateServiceTypeError,
    error: createServiceTypeError,
    reset: resetCreateServiceType,
  } = useCreateServiceType();
  const {
    mutateAsync: editServiceType,
    isError: isEditServiceTypeError,
    error: editServiceTypeErrorResponse,
    reset: resetEditServiceType,
  } = useEditServiceType();
  const {
    isError: isGetServiceTypeReferencesError,
    serviceTypeReferences,
    refetch: getServiceTypeReferences,
    remove: removeServiceTypeReferences,
  } = useServiceTypeReferences(selectedServiceTypeForDeletion?._id ?? '');

  const {
    mutateAsync: deleteServiceType,
    isError: isDeleteServiceTypeError,
    reset: resetDeleteServiceType,
  } = useDeleteServiceType(selectedServiceTypeForDeletion?._id ?? '');

  const onCloseDraftServiceTypeModal = () => {
    toggleIsCreatingServiceType(false);
    setSelectedServiceTypeForEditing(undefined);
  };

  const onCloseDelete = () => {
    removeServiceTypeReferences();
    setDeleteServiceTypeError(undefined);
    resetDeleteServiceType();
    setSelectedServiceTypeForDeletion(undefined);
  };

  const afterConfirmDelete = () => {
    removeServiceTypeReferences();
    resetDeleteServiceType();
    setSelectedServiceTypeForDeletion(undefined);
  };

  const onSubmitServiceTypeForm = async (data: ServiceTypeFormData) => {
    if (isCreatingServiceType) onSubmitCreateServiceType(data);
    if (selectedServiceTypeForEditing) onSubmitEditServiceType(data);
  };

  const onSubmitCreateServiceType = async (data: ServiceTypeFormData) => {
    const cleanedData = cleanFormData(data) as Partial<ServiceTypeFormData>;
    createServiceType(cleanedData).finally(() => {
      toggleIsCreatingServiceType(false);
    });
  };

  const onSubmitEditServiceType = async (data: ServiceTypeFormData) => {
    const editRequest = generateEditRequest(data);
    if (editRequest) {
      editServiceType(editRequest).finally(() => {
        // Always set to null as error case will trigger new modal
        setSelectedServiceTypeForEditing(undefined);
      });
    } else {
      setEditServiceTypeError(intl.formatMessage({ id: 'items.edit.error' }));
      setSelectedServiceTypeForEditing(undefined);
    }
  };

  useEffect(() => {
    if (isEditServiceTypeError) {
      setEditServiceTypeError(editServiceTypeErrorResponse);
    }
  }, [isEditServiceTypeError]);

  useEffect(() => {
    if (selectedServiceTypeForDeletion) {
      getServiceTypeReferences();
    }
  }, [selectedServiceTypeForDeletion]);

  useEffect(() => {
    if (isGetServiceTypeReferencesError) {
      setDeleteServiceTypeError(
        intl.formatMessage({ id: 'service-types.delete.references.error' })
      );
      setSelectedServiceTypeForDeletion(undefined);
    }
  }, [isGetServiceTypeReferencesError]);

  useEffect(() => {
    if (isDeleteServiceTypeError) {
      setDeleteServiceTypeError(intl.formatMessage({ id: 'service-types.delete.error' }));
    }
  }, [isDeleteServiceTypeError]);

  const deleteServiceTypeReferenceWarning = serviceTypeReferences
    ? intl.formatMessage(
        { id: 'service-types.delete.references' },
        {
          itemReferences: serviceTypeReferences.itemReferences.length,
          itemTypeReferences: serviceTypeReferences.itemTypeReferences.length,
        }
      )
    : undefined;

  const onDeleteServiceType = async () => {
    if (selectedServiceTypeForDeletion) {
      deleteServiceType().finally(() => {
        afterConfirmDelete();
      });
    } else {
      afterConfirmDelete();
      setDeleteServiceTypeError(intl.formatMessage({ id: 'service-types.delete.error' }));
    }
  };

  return (
    <>
      <TablePageLayout
        isContentLoading={isLoading}
        isContentError={isError}
        contentError={getServiceTypesError}
        pageName={intl.formatMessage({ id: 'service-types.self' })}
        pageNameSingular={intl.formatMessage({ id: 'service-types.singular' })}
        table={
          <ServiceTypesTable
            serviceTypes={serviceTypes}
            onSelectServiceTypeForEditing={setSelectedServiceTypeForEditing}
            onSelectServiceTypeForDeletion={setSelectedServiceTypeForDeletion}
          />
        }
        hasDraftModal={true}
        openCreateModal={() => toggleIsCreatingServiceType(true)}
        isDraftModalOpen={isCreatingServiceType || !!selectedServiceTypeForEditing}
        draftModal={
          <DraftServiceTypeModal
            serviceType={selectedServiceTypeForEditing}
            onClose={onCloseDraftServiceTypeModal}
            onSubmit={onSubmitServiceTypeForm}
          />
        }
        isCreateModalError={isCreateServiceTypeError}
        createModalError={createServiceTypeError}
        isEditModalError={editServiceTypeError !== undefined}
        editModalError={editServiceTypeError}
        isDeleteModalOpen={!!serviceTypeReferences}
        isDeleteModalError={!!deleteServiceTypeError}
        deleteModalError={deleteServiceTypeError}
        deleteModalAdditionalMessage={deleteServiceTypeReferenceWarning}
        confirmDeleteMessage={
          <p>
            {intl.formatMessage(
              { id: 'service-types.delete.confirm' },
              {
                serviceTypeName: selectedServiceTypeForDeletion?.name,
              }
            )}
          </p>
        }
        onConfirmDelete={onDeleteServiceType}
        onCloseDelete={onCloseDelete}
        cleanup={remove}
        cleanupErrors={() => {
          resetCreateServiceType();
          resetEditServiceType();
          resetDeleteServiceType();
          setEditServiceTypeError(undefined);
          removeServiceTypeReferences();
          setDeleteServiceTypeError(undefined);
        }}
      />
    </>
  );
}

export { ServiceTypesPage };
