import { useIntl } from 'react-intl';

import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { Dialog } from '../../library/dialog/Dialog';

import { EditSystemForm } from './EditSystemForm';

export function EditSystemDialog() {
  const intl = useIntl();
  const { entityToEdit } = useCrudPageUiStateContext();
  return (
    <>
      {entityToEdit && (
        <Dialog
          width="md"
          id={'EditSystemDialog'}
          isOpen={!!entityToEdit}
          title={intl.formatMessage({ id: 'systems.edit' })}
          content={<EditSystemForm />}
        />
      )}
    </>
  );
}
