import { ReactNode, createContext, useContext, useState } from 'react';

interface ServiceForecastUiStateContextApi {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  downloadStarted: boolean;
  setDownloadStarted: (value: boolean) => void;
}

const ServiceForecastUiStateContext = createContext<ServiceForecastUiStateContextApi | undefined>(
  undefined
);

interface ServiceForecastUiStateProviderProps {
  children: ReactNode;
}

function ServiceForecastUiStateProvider({ children }: ServiceForecastUiStateProviderProps) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [downloadStarted, setDownloadStarted] = useState<boolean>(false);

  const value: ServiceForecastUiStateContextApi = {
    openDialog: openDialog,
    setOpenDialog: setOpenDialog,
    downloadStarted: downloadStarted,
    setDownloadStarted: setDownloadStarted,
  };
  return (
    <ServiceForecastUiStateContext.Provider value={value}>
      {children}
    </ServiceForecastUiStateContext.Provider>
  );
}

function useServiceForecastUiStateContext() {
  const context = useContext(ServiceForecastUiStateContext);
  if (context === undefined) {
    throw new Error(
      'useServiceForecastUiStateContext must be used within an ServiceForecastUiStateProvider'
    );
  }
  return context;
}

export { ServiceForecastUiStateProvider, useServiceForecastUiStateContext };
