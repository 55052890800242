import { useIntl } from 'react-intl';

import { useLockerFeatures } from '../../../../../api/hooks/useLockerFeaturesApi';
import { LoadingIndicator } from '../../../../../components/library/loading/LoadingIndicator';
import { Modal } from '../../../../../components/modal/Modal';
import { Locker, OptionItem, LockerFeature } from '../../../../../types/types';

import { LockerFormData, LockerForm } from './LockerForm';

export interface LockerFormModalProps {
  locker: Locker;
  onClose: () => void;
  onSubmit: (data: LockerFormData) => void;
}

function lockerFeatureToOptionItem(feature: LockerFeature): OptionItem {
  return {
    value: feature._id,
    label: feature.description,
  };
}

function DraftLockerModal({ onClose, onSubmit, locker }: LockerFormModalProps) {
  const intl = useIntl();
  const { lockerFeatures, isLoading: isLoadingLockerFeatures } = useLockerFeatures();

  const header = <h1 className="uib-modal__title">{intl.formatMessage({ id: 'lockers.edit' })}</h1>;
  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      additionalClassNames="overflow-visible"
      content={
        isLoadingLockerFeatures ? (
          <LoadingIndicator />
        ) : (
          <LockerForm
            onCancel={onClose}
            onSubmit={onSubmit}
            lockerFeatures={lockerFeatures.map(lockerFeatureToOptionItem)}
            locker={locker}
          />
        )
      }
    />
  );
}

export { DraftLockerModal };
