import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';

import { ButtonVariant } from '../../types/components/Button';
import Button from '../odx/Button';

export interface ModalProps {
  isOpen: boolean;
  // header should be a h1 with className of uib-modal__title
  header?: ReactNode;
  // content should be enclosed in a div with className of uib-modal__content
  content?: ReactNode;
  closeButtonLabel?: string;
  modalHasClosed?: () => void;
  actionButton?: ReactNode;
  hasForm?: boolean;
  hasButtons?: boolean;
  additionalClassNames?: string;
}

ReactModal.setAppElement('#root');

function Modal({
  isOpen,
  header,
  content,
  closeButtonLabel,
  modalHasClosed,
  actionButton,
  hasForm = false,
  hasButtons = true,
  additionalClassNames = '',
}: ModalProps) {
  const intl = useIntl();

  function handleCloseModal() {
    if (modalHasClosed) {
      modalHasClosed();
    }
  }

  closeButtonLabel = closeButtonLabel
    ? closeButtonLabel
    : intl.formatMessage({ id: 'general.close' });

  return (
    <ReactModal
      isOpen={isOpen}
      className={`uib-modal__inner ${additionalClassNames}`}
      overlayClassName="uib-modal"
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
    >
      {header ? <div className="uib-modal__header">{header}</div> : null}
      {hasForm ? (
        content
      ) : (
        <>
          <div className="uib-modal__content">{content}</div>
          {hasButtons && (
            <div className="uib-modal__footer">
              <div className="uib-button-group uib-button-group--right-to-left seperate-buttons">
                {actionButton}
                <Button id={'abortButton'} variant={ButtonVariant.GHOST} onClick={handleCloseModal}>
                  {closeButtonLabel}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </ReactModal>
  );
}

export { Modal };
