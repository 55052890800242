import { useIntl } from 'react-intl';

import { useAllServiceTypes } from '../../../../api/hooks/useServiceApi';
import { LoadingIndicator } from '../../../../components/library/loading/LoadingIndicator';
import { Modal } from '../../../../components/modal/Modal';
import { BasicServiceType, OptionItem } from '../../../../types/types';
import { globalServiceTypes } from '../../../../utils/globalServices';

import { ServiceItemsForm, ServiceItemsFormData } from './ServiceItemsForm';

export interface ServiceItemsModalProps {
  onClose: () => void;
  onSubmit: (data: ServiceItemsFormData) => void;
}

function ServiceItemsModal({ onClose, onSubmit }: ServiceItemsModalProps) {
  const intl = useIntl();
  const { allServiceTypes, isLoading: isLoadingServiceTypes } = useAllServiceTypes();

  const serviceTypeToOptionItem = (serviceType: BasicServiceType): OptionItem => {
    return {
      value: serviceType._id ? serviceType._id : serviceType.name,
      label: globalServiceTypes.includes(serviceType.name)
        ? intl.formatMessage({
            id: `items.serviceTypes.${serviceType.name.replace(/\s/g, '')}`,
          })
        : serviceType.name,
    };
  };

  const header = (
    <h1 className="uib-modal__title">{intl.formatMessage({ id: 'items.updateServices' })}</h1>
  );
  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      modalHasClosed={onClose}
      content={
        isLoadingServiceTypes ? (
          <LoadingIndicator />
        ) : (
          <ServiceItemsForm
            onCancel={onClose}
            onSubmit={onSubmit}
            serviceTypes={allServiceTypes.map(serviceTypeToOptionItem)}
          />
        )
      }
    />
  );
}

export { ServiceItemsModal };
