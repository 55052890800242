import { useIntl } from 'react-intl';

import { Contractor } from '../../../types/contractors/Contractor';
import { AutoCompleteOption, FormAutocomplete } from '../../library/form/FormAutocomplete';

export interface ContractorsDropDownProps {
  contractors: Contractor[];
}

export function ContractorDropDown({ contractors }: ContractorsDropDownProps) {
  const intl = useIntl();
  const contractorDropDownItems = getOptions(contractors);
  const label = intl.formatMessage({ id: 'contractors.singular' });
  return (
    <FormAutocomplete
      id={'contractorDropdown'}
      label={label}
      name={'contractorId'}
      options={contractorDropDownItems}
      required={true}
      disableClearable={true}
    />
  );
}

function getOptions(contractors: Contractor[]): AutoCompleteOption[] {
  const result = contractors.map(contractor => ({
    id: contractor._id,
    label: contractor.name,
  }));
  return result;
}
