import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useDownloadServiceForeCast } from '../../../../api/hooks/useReportsApi';
import { CancelButton } from '../../../../components/library/buttons/CancelButton';
import { DownloadButton } from '../../../../components/library/buttons/DownloadButton';
import { Form } from '../../../../components/library/form/Form';
import { DatePicker } from '../../../../components/library/inputs/DatePicker';
import { LoadingIndicator } from '../../../../components/library/loading/LoadingIndicator';
import { useServiceForecastUiStateContext } from '../../../../context/ServiceForecastUiStateContext';

import { ServiceForeCastExplanationText } from './ServiceForeCastExplanationText';

export interface ServiceForeCastParameter {
  endDate: string;
}
export function ServiceForeCastForm() {
  const { mutate: downloadExcel, isLoading } = useDownloadServiceForeCast();
  const handleSubmit = (param: ServiceForeCastParameter) => {
    downloadExcel(param.endDate);
  };
  return (
    <div style={{ marginTop: '10px' }}>
      {isLoading && <LoadingIndicator />}
      <Form
        initialFormValues={{ endDate: '' }}
        onSubmit={handleSubmit}
        buttons={<ServiceForeCastFormButtons />}
      >
        <ServiceForeCastEndDatePicker />
        <ServiceForeCastExplanationText />
      </Form>
    </div>
  );
}
function ServiceForeCastEndDatePicker() {
  const intl = useIntl();
  const { getValues, setValue } = useFormContext();
  const formFieldName = 'endDate';
  return (
    <DatePicker
      label={intl.formatMessage({ id: 'reports.endDate' })}
      date={getValues(formFieldName)}
      required={true}
      onChange={date => {
        setValue(formFieldName, date, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }}
    />
  );
}
function ServiceForeCastFormButtons() {
  return (
    <>
      <ServiceForeCastDialogCloseButton />
      <ServiceForeCastDownloadButton />
    </>
  );
}
function ServiceForeCastDialogCloseButton() {
  const { setOpenDialog } = useServiceForecastUiStateContext();
  const { reset: resetForm } = useFormContext();
  return (
    <CancelButton
      onClick={() => {
        setOpenDialog(false);
        resetForm();
      }}
    />
  );
}
function ServiceForeCastDownloadButton() {
  const {
    formState: { isSubmitting, isDirty, isValid },
  } = useFormContext();
  return (
    <DownloadButton
      id={'ServiceForeCastDownload'}
      disabled={isSubmitting || !isDirty || !isValid}
    ></DownloadButton>
  );
}
