import SwaggerUI from 'swagger-ui-react';

import 'swagger-ui-react/swagger-ui.css';
import { useGetShopApiDocs } from '../../api/hooks/useDocsApi';

export function ShopApiDocsPage() {
  const { spec } = useGetShopApiDocs();
  return (
    <>
      <SwaggerUI spec={spec} />;
    </>
  );
}
