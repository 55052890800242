import { EditOperation, EditRequest, OptionItem, ApiError, ErrorDetails } from '../types/types';

function cleanFormData(formData: any): Partial<any> {
  return pickBy(formData, input => {
    if (typeof input === 'undefined') {
      return false;
    } else if (typeof input === 'string') {
      return input.length > 0;
    }
    return true;
  });
}
function pickBy<T>(object: { [key: string]: T }, predicate: (value: T) => boolean): { [key: string]: T } {  
    const result: { [key: string]: T } = {};  
    for (const key in object) {  
        if (Object.prototype.hasOwnProperty.call(object, key) && predicate(object[key])) {  
            result[key] = object[key];  
        }  
    }  
    return result;  
}  

function getDirtyFormValues(
  data: Record<string, any>,
  dirtyFields: Record<string, boolean | boolean[] | Record<string, boolean>[]>
) {
  const dataFields = Object.keys(data);
  dataFields.forEach(field => {
    if (!dirtyFields[field]) delete data[field];
  });
  return data;
}

function generateEditRequest(data: Record<string, any>) {
  const dataFields = Object.keys(data);
  const editRequest: EditRequest = {
    _id: '',
    patchFields: [],
  };
  dataFields.forEach(field => {
    if (field === '_id') {
      editRequest._id = data[field];
    } else if (data[field] === '') {
      editRequest.patchFields.push({
        operation: EditOperation.remove,
        field: field,
      });
    } else if (
      typeof data[field] === 'number' ||
      typeof data[field] === 'boolean' ||
      typeof data[field] === 'string' ||
      (typeof data[field] === 'object' && data[field].length)
    ) {
      editRequest.patchFields.push({
        operation: EditOperation.replace,
        field: field,
        value: data[field],
      });
    }
  });
  if (editRequest._id === '' || editRequest.patchFields.length < 1) return false;
  return editRequest;
}

const getValueFromOptions = (options: OptionItem[], label: string) => {
  return options.find(key => key.label === label)?.value || label;
};

const sortLabelsFromOptions = (options: OptionItem[]): OptionItem[] => {
  return options.sort(function (a, b) {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
    return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
  });
};

const validateEmailFormat = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const compressImage = async (imageFile: File): Promise<{ name: string; content: string }> => {  
  const name = imageFile.name;  
  return new Promise((resolve, reject) => {  
  const reader = new FileReader();  
  reader.readAsDataURL(imageFile);  
  reader.onload = event => {  
    const img = new Image();  
    img.src = event.target?.result as string;  
    img.onload = () => {  
      const elem = document.createElement('canvas');  
      const width = 500;
      const height = img.height * (width / img.width);
      elem.width = width;  
      elem.height = height;  
      const ctx = elem.getContext('2d');  
      if (!ctx) {  
        reject(new Error('Unable to get canvas context'));  
        return;  
      }  
      ctx.drawImage(img, 0, 0, width, height);  
      const dataUrl = ctx.canvas.toDataURL();  
      const content = dataUrl.replace(/^data:image\/\w+;base64,/, '');  
      resolve({ name, content });  
    };  
    img.onerror = error => reject(new Error(`Error loading image: ${error}`));  
  };  
  reader.onerror = error => reject(new Error(`Error reading file: ${error}`));  
});   
};  

const validateErrorStructure = (apiResponse: any): apiResponse is ApiError => {
  return (
    apiResponse !== null &&
    typeof apiResponse === 'object' &&
    'status' in apiResponse &&
    'message' in apiResponse &&
    'code' in apiResponse &&
    typeof apiResponse.status === 'number' &&
    typeof apiResponse.message === 'string' &&
    typeof apiResponse.code === 'string' &&
    validateErrorDetails(apiResponse.details)
  );
};

const validateErrorDetails = (details: any): details is ErrorDetails[] => {
  if (!details || !details.length) return true;

  return (
    details !== null &&
    typeof details === 'object' &&
    details.every((detail: any) => {
      return (
        'code' in detail &&
        'message' in detail &&
        typeof detail.code === 'string' &&
        typeof detail.message === 'string' &&
        validateErrorParams(detail.params)
      );
    })
  );
};

const validateErrorParams = (params: any): params is string[] => {
  if (!params || !params.length) return true;

  return params.every((param: any) => typeof param === 'string');
};

export {
  cleanFormData,
  getDirtyFormValues,
  generateEditRequest,
  getValueFromOptions,
  sortLabelsFromOptions,
  validateEmailFormat,
  compressImage,
  validateErrorStructure,
};
