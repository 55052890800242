import { ReactNode, createContext, useContext } from 'react';

import { useContractors } from '../../../api/hooks/useContractorApi';
import { useQualifications } from '../../../api/hooks/useQualificationApi';
import { useRenters } from '../../../api/hooks/useRentingApi';
import { Contractor } from '../../../types/contractors/Contractor';
import { Renter } from '../../../types/renter/Renter';
import { Qualification } from '../../../types/types';

interface RentersPageContextApi {
  renters: Renter[];
  rentersIsLoading: boolean;
  qualifications: Qualification[];
  qualificationsIsLoading: boolean;
  contractors: Contractor[];
  contractorsIsLoading: boolean;
}

const RentersPageContext = createContext<RentersPageContextApi | undefined>(undefined);

interface RentersPageProviderProps {
  children: ReactNode;
}

function RentersPageProvider({ children }: RentersPageProviderProps) {
  const { renters, isLoading: rentersIsLoading } = useRenters();
  const { qualifications, isLoading: qualificationsIsLoading } = useQualifications();
  const { contractors, isLoading: contractorsIsLoading } = useContractors();
  const value: RentersPageContextApi = {
    renters,
    rentersIsLoading,
    qualifications,
    qualificationsIsLoading,
    contractors,
    contractorsIsLoading,
  };
  return <RentersPageContext.Provider value={value}>{children}</RentersPageContext.Provider>;
}

function useRentersPageContext() {
  const context = useContext(RentersPageContext);
  if (context === undefined) {
    throw new Error('useRentersPageContext must be used within an RentersPageProvider');
  }
  return context;
}

export { RentersPageProvider, useRentersPageContext };
