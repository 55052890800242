import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode } from 'react';

export interface SimpleTableProps {
  rows: SimpleTableRow[];
  columns: SimpleTableColumn[];
  style?: any;
  headless?: boolean;
}
export interface SimpleTableRow {
  id: string;
  [key: string]: any | undefined;
}
export interface SimpleTableColumn {
  id: string;
  label?: string;
  renderRow?: (rowValue: any | undefined) => ReactNode;
}
export default function SimpleTable({ rows, columns, style, headless = false }: SimpleTableProps) {
  return (
    <TableContainer component={Paper} style={style}>
      <Table>
        {!headless && (
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={`header_${column.id}`}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rows.map(row => (
            <TableRow key={`row_${row.id}`}>
              {columns.map(column => (
                <TableCell key={`cell_${column.id}`} component="th" scope="row">
                  {column.renderRow ? column.renderRow(row[column.id]) : row[column.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
