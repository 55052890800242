import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { CellProps, Column } from 'react-table';

import Icon from '../../components/library/icons/Icon';
import Button from '../../components/odx/Button';
import { DeleteButton } from '../../components/table/table-buttons/DeleteButton';
import { EditButton } from '../../components/table/table-buttons/EditButton';
import { TableButtons } from '../../components/table/table-buttons/TableButtons';
import { VerticalTable } from '../../components/table/VerticalTable';
import { ButtonVariant } from '../../types/components/Button';
import { Connectivity, Station } from '../../types/types';
import { bindestrich } from '../../utils/bindestrich';

interface StationsTableProps {
  stations: Station[];
  onClickStation?: (station: Station) => void;
  onPairIotAdapter: (station: Station) => void;
  onPairTerminal: (status: boolean, station: Station) => void;
  onSelectStationForEditing: Dispatch<SetStateAction<Station | undefined>>;
  onSelectStationForDeletion: Dispatch<SetStateAction<Station | undefined>>;
}

function StationsTable({
  stations,
  onClickStation,
  onPairIotAdapter,
  onPairTerminal,
  onSelectStationForEditing,
  onSelectStationForDeletion,
}: StationsTableProps) {
  const intl = useIntl();

  const isStationPairedToIotAdapter = (station: Station) => station.iotAdapterId !== null;

  const iotPairingButton = (row: Station) => {
    const isPaired = isStationPairedToIotAdapter(row);
    const variant = isPaired ? ButtonVariant.DANGER : ButtonVariant.PRIMARY;
    return (
      <Button
        variant={variant}
        onClick={e => {
          e.stopPropagation();
          onPairIotAdapter(row);
        }}
        id={'iotPairing-' + row._id}
        additionalClasses={'min-w-100'}
      >
        {isPaired
          ? intl.formatMessage({ id: 'stations.unpair' })
          : intl.formatMessage({ id: 'stations.pair' })}
      </Button>
    );
  };

  function getIconForTerminalStatus(station: Station) {
    switch (station.stationHealth?.terminalModuleState) {
      case Connectivity.ONLINE:
        return <Icon iconName={'cloud-ok'} />;
      default:
        return <Icon iconName={'cloud-error'} />;
    }
  }

  function getButtonVariant(isDisabledButton: boolean, isUnpairButton: boolean): ButtonVariant {
    if (isDisabledButton) {
      return ButtonVariant.SECONDARY;
    } else if (isUnpairButton) {
      return ButtonVariant.DANGER;
    } else {
      return ButtonVariant.PRIMARY;
    }
  }

  const terminalCell = (row: Station) => {
    const isDisabledButton = !isStationPairedToIotAdapter(row);
    const isUnpairButton = !isDisabledButton && !!row.terminalId;
    const variant = getButtonVariant(isDisabledButton, isUnpairButton);

    return (
      <div className={'align-multi-items'}>
        <Button
          variant={variant}
          onClick={e => {
            e.stopPropagation();
            onPairTerminal(isUnpairButton, row);
          }}
          id={'terminalPairing-' + row._id}
          disabled={isDisabledButton}
          additionalClasses={'min-w-100'}
        >
          {isUnpairButton
            ? intl.formatMessage({ id: 'stations.unpair' })
            : intl.formatMessage({ id: 'stations.pair' })}
        </Button>
        {getIconForTerminalStatus(row)}
      </div>
    );
  };

  const columns: Column[] = [
    {
      Header: intl.formatMessage({ id: 'stations.location' }),
      accessor: 'location',
    },
    {
      Header: intl.formatMessage({ id: 'stations.supervisors' }),
      accessor: row => {
        const data = row as Station;
        return data.supervisorEmails.join(', ');
      },
    },
    {
      Header: intl.formatMessage({ id: 'stations.iotAdapterId' }),
      accessor: row => {
        const data = row as Station;
        return data.iotAdapterId === null
          ? intl.formatMessage({ id: 'stations.unpairedIndication' })
          : data.iotAdapterId;
      },
    },
    {
      Header: intl.formatMessage({ id: 'stations.pairWithIot' }),
      id: 'iot-device-pairing',
      Cell: ({ row: { original } }: CellProps<Station>) => iotPairingButton(original),
    },
    {
      Header: intl.formatMessage({ id: 'stations.terminalSerialNo' }),
      id: 'terminal-serial-no',
      accessor: row => {
        const data = row as Station;
        return bindestrich(data.terminalSerialNo);
      },
    },
    {
      Header: intl.formatMessage({ id: 'stations.pairWithTerminal' }),
      id: 'terminal-pairing',
      Cell: ({ row: { original } }: CellProps<Station>) => terminalCell(original),
    },
    {
      Header: intl.formatMessage({ id: 'general.actions' }),
      id: 'actions',
      Cell: ({ row }: CellProps<Station>) => {
        const station = row.original;
        return (
          <TableButtons>
            <EditButton
              onClick={e => {
                e.stopPropagation();
                onSelectStationForEditing(station);
              }}
            />
            <DeleteButton
              onClick={e => {
                e.stopPropagation();
                onSelectStationForDeletion(station);
              }}
            />
          </TableButtons>
        );
      },
    },
  ];

  const sortBy = [
    {
      id: 'location',
      desc: false,
    },
  ];

  return (
    <VerticalTable
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      columns={columns}
      data={stations}
      sortBy={sortBy}
      onClickRow={row => onClickStation?.(row as unknown as Station)}
      isScrollableInX={true}
    />
  );
}

export { StationsTable };
