import { useFormContext } from 'react-hook-form';

import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { CancelButton } from '../../library/buttons/CancelButton';
import { FormSubmitButton } from '../../library/buttons/FormSubmitButton';

export function CreateEntityFormButtons() {
  return (
    <>
      <CreateEntityDialogCloseButton />
      <CreateEntityFormSubmitButton />
    </>
  );
}
function CreateEntityDialogCloseButton() {
  const { setOpenCreateDialog } = useCrudPageUiStateContext();
  const { reset } = useFormContext();
  return (
    <CancelButton
      onClick={() => {
        setOpenCreateDialog(false);
        reset();
      }}
    />
  );
}

function CreateEntityFormSubmitButton() {
  return <FormSubmitButton id={'createEntityFormSubmitBtn'} />;
}
