import { useIntl } from 'react-intl';

import { useItemDetails } from '../../../api/hooks/useItemApi';
import Icon from '../../../components/library/icons/Icon';
import { DetailView } from '../../../components/table/DetailView';
import { DetailedItem, ItemServiceDetails } from '../../../types/types';
import { bindestrich } from '../../../utils/bindestrich';
import { globalServiceTypes } from '../../../utils/globalServices';

import { ItemDetailCard } from './ItemDetailCard';

interface ItemDetailViewProps {
  itemId: string;
  onClose: () => void;
}

function ItemDetailView({ onClose, itemId }: ItemDetailViewProps) {
  const intl = useIntl();

  const { detailedItem, isLoading, remove } = useItemDetails(itemId);

  const itemTypeHasServices =
    detailedItem !== null &&
    !!detailedItem.serviceDetails &&
    detailedItem.serviceDetails.length > 0;

  const mainColumns = [
    {
      Header: intl.formatMessage({ id: 'items.draegerSerialNo' }),
      id: 'draegerSerialNo',
      accessor: (row: DetailedItem) => {
        return bindestrich(row.draegerSerialNo);
      },
    },
    {
      Header: intl.formatMessage({ id: 'items.eID' }),
      id: 'itemEId',
      accessor: (row: DetailedItem) => {
        return bindestrich(row.itemBarcode);
      },
    },
    {
      Header: intl.formatMessage({ id: 'items.customId' }),
      id: 'customId',
      accessor: (row: DetailedItem) => {
        return bindestrich(row.customId);
      },
    },
  ];

  const serviceTypesColumns = [
    {
      Header: intl.formatMessage({ id: 'service-types.singular' }),
      accessor: (row: ItemServiceDetails) => {
        const serviceName = row.serviceTypeName;
        return globalServiceTypes.includes(serviceName)
          ? intl.formatMessage({
              id: `items.serviceTypes.${serviceName.replace(/\s/g, '')}`,
            })
          : serviceName;
      },
    },
    {
      Header: intl.formatMessage({
        id: 'service-types.services.nextServiceDate',
      }),
      id: 'nextServiceDate',
      accessor: (row: ItemServiceDetails) => {
        if (row.isBlockAfterUse) {
          return (
            <span>
              <i className="uib-icon uib-icon--reload-required" />
            </span>
          );
        }
        if (row.itemNeedsServicingNow) {
          return 'EXPIRED';
        }
        return bindestrich(row.nextServiceDate);
      },
    },
    {
      Header: intl.formatMessage({
        id: 'service-types.services.valid',
      }),
      id: 'isItemFineForUse',
      accessor: (row: ItemServiceDetails) => {
        if (row.itemNeedsServicingNow) {
          return <Icon iconName={'blocked'} />;
        }
        return <Icon iconName={'check'} additionalClasses={'odx-blue-text'} />;
      },
    },
  ];

  return (
    <DetailView
      onClose={onClose}
      cleanup={remove}
      isDetailDataLoading={isLoading}
      detailData={detailedItem}
      header={intl.formatMessage({ id: 'items.detailView' })}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      mainColumns={mainColumns}
      aside={<ItemDetailCard item={detailedItem} />}
      hasSecondSection={itemTypeHasServices}
      secondSectionHeader={intl.formatMessage({
        id: 'service-types.services',
      })}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      secondaryColumns={serviceTypesColumns}
      secondaryData={detailedItem?.serviceDetails}
      detailDataError={intl.formatMessage({
        id: 'items.detailView.error',
      })}
    />
  );
}

export { ItemDetailView };
