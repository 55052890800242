import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button } from '@mui/material';

interface ImportEntitiesButtonProps {
  id: string;
  onClick: () => void;
}
export function ImportIconButton({ id, onClick }: ImportEntitiesButtonProps) {
  return (
    <Button id={id} variant="contained" onClick={onClick}>
      <FileUploadIcon />
    </Button>
  );
}
