import { useIntl } from 'react-intl';

function useTimePeriod() {
  const intl = useIntl();

  function yearsMonthsWeeks(years: number, months: number, weeks: number) {
    const arrayToJoin = [];
    if (years > 1) {
      arrayToJoin.push(`${years} ${intl.formatMessage({ id: 'general.years' })}`);
    } else if (years > 0) {
      arrayToJoin.push(`${years} ${intl.formatMessage({ id: 'general.year' })}`);
    }
    if (months > 1) {
      arrayToJoin.push(`${months} ${intl.formatMessage({ id: 'general.months' })}`);
    } else if (months > 0) {
      arrayToJoin.push(`${months} ${intl.formatMessage({ id: 'general.month' })}`);
    }
    if (weeks > 1) {
      arrayToJoin.push(`${weeks} ${intl.formatMessage({ id: 'general.weeks' })}`);
    } else if (weeks > 0) {
      arrayToJoin.push(`${weeks} ${intl.formatMessage({ id: 'general.week' })}`);
    }
    return arrayToJoin.join(', ');
  }

  return { yearsMonthsWeeks };
}

export { useTimePeriod };
