import { useIntl } from 'react-intl';

import { FormDropDown } from '../../../library/form/FormDropdown';

export function RenterStatusDropdown() {
  const intl = useIntl();
  const options = [
    { value: 'Pending', label: intl.formatMessage({ id: 'renters.statusPending' }) },
    { value: 'Confirmed', label: intl.formatMessage({ id: 'renters.statusConfirmed' }) },
  ];
  return (
    <FormDropDown
      id={'RenterStatusDropdown'}
      label={intl.formatMessage({ id: 'renters.status' })}
      name={'status'}
      dropDownItems={options}
    />
  );
}
