import { useIntl } from 'react-intl';

import { FormMultiEmailInput } from '../../../library/form/FormMultiEmailInput';

export function SystemAdminEmails() {
  const intl = useIntl();
  const thresholdPlaceholder = intl.formatMessage({
    id: 'systems.adminMails',
  });
  return (
    <FormMultiEmailInput
      required={true}
      id={'SystemAdminEmails'}
      label={thresholdPlaceholder}
      name={'adminEmails'}
    />
  );
}
