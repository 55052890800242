import { useIntl } from 'react-intl';

import { ErrorBoxLowerDescription } from './ErrorBoxLowerDescription';
import { Modal } from './Modal';

interface ErrorModalProps {
  isOpen: boolean;
  errorDescription: string;
  isErrorBoxLowerDescription?: boolean;
  content: string[];
  onClose?: () => void;
}

export const ErrorBoxModal = ({
  isOpen,
  errorDescription,
  isErrorBoxLowerDescription = false,
  content,
  onClose,
}: ErrorModalProps) => {
  const intl = useIntl();

  const getContent = () => {
    return (
      <div>
        {errorDescription}
        <div className="form-error border-box-form">
          {content?.map((dataError: string, index: number) => <pre key={index}> {dataError} </pre>)}
        </div>
        {isErrorBoxLowerDescription && <ErrorBoxLowerDescription />}
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      header={
        <h1 className="uib-modal__title">{intl.formatMessage({ id: 'general.simpleError' })}</h1>
      }
      content={getContent()}
      modalHasClosed={onClose}
      hasButtons={true}
    />
  );
};
