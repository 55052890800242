import { MouseEventHandler } from 'react';

import Icon from '../../library/icons/Icon';

interface DeleteButtonProps {
  onClick: MouseEventHandler;
  disabled?: boolean;
}

function DeleteButton({ onClick, disabled = false }: DeleteButtonProps) {
  return (
    <button onClick={e => onClick(e)} type="button" className="uib-iconbutton" disabled={disabled}>
      <Icon id={'deleteButtonTable'} iconName={'delete'} />
    </button>
  );
}

export { DeleteButton };
