import { AlertRuleTemplate } from '../../../types/alert-rules/AlertRuleTemplate';

export function getRequiredAndDisabled(
  alertRuleTemplates: AlertRuleTemplate[],
  alertRuleType: string,
  fieldName: string
): { required: boolean; disabled: boolean } {
  const alertRuleTemplate = alertRuleTemplates.find(t => t.alertRuleType === alertRuleType);
  const alertRuleTemplateField = alertRuleTemplate?.fields.find(f => f.name === fieldName);
  const isDisabled = alertRuleTemplateField ? false : true;
  const isRequired = alertRuleTemplateField?.isRequired ?? false;
  return { required: isRequired, disabled: isDisabled };
}
