import { useIntl } from 'react-intl';

import { Stock } from '../../types/types';
import { BarChartWithLine } from '../library/charts/BarChartWithLine';

export interface ContractStockChartsProps {
  itemStock: Stock[];
}
export default function ContractStockCharts({ itemStock }: ContractStockChartsProps) {
  const datas = transformToChartData(itemStock);
  return (
    <>
      {datas.map(data => {
        return <ItemStockChart key={data.itemTypeName} data={data} />;
      })}
    </>
  );
}
function transformToChartData(itemStock: Stock[]) {
  return itemStock
    .map(it => {
      return {
        contractStock: it.contractStock?.stock ?? 0,
        itemTypeName: it.itemType.name,
        stock: it.stock?.available ?? 0,
        maxStockValue: getMaxStockValue(it),
      };
    })
    .filter(it => it.contractStock !== 0 || it.stock !== 0)
    .sort((a, b) => {
      if (b.contractStock - a.contractStock === 0) {
        return b.stock - a.stock;
      }
      return b.contractStock - a.contractStock;
    });
}
function getMaxStockValue(stock: Stock) {
  const totalItems =
    (stock.stock?.available ?? 0) +
    (stock.stock?.overdue ?? 0) +
    (stock.stock?.rented ?? 0) +
    (stock.stock?.requiresService ?? 0);
  const contractStock = stock.contractStock?.stock ?? 0;
  return contractStock > totalItems ? contractStock * 1.5 : totalItems;
}
interface ItemStockChartData {
  contractStock: number;
  itemTypeName: string;
  stock: number;
  maxStockValue: number;
}
interface ItemStockChartProps {
  data: ItemStockChartData;
}
function ItemStockChart({ data }: ItemStockChartProps) {
  const intl = useIntl();
  const contractStockLabel = intl.formatMessage({
    id: 'stockPage.contractStock',
  });
  const toolTipLabel = intl.formatMessage({ id: 'dashboard.contractStockToolTip' });
  return (
    <BarChartWithLine
      value={data.stock}
      maxValue={data.maxStockValue}
      linePos={data.contractStock}
      lineLabel={contractStockLabel}
      toolTipLabel={toolTipLabel}
      chartLabel={data.itemTypeName}
    />
  );
}
