import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Slide, SlideProps, Snackbar } from '@mui/material';
import { useIntl } from 'react-intl';

export interface NotificationProps {
  showNotification: boolean;
  setShowNotification: (showNotification: boolean) => void;
  message: string;
  type?: 'error' | 'success' | 'info' | 'warning';
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

function Notification({ showNotification, message, setShowNotification, type }: NotificationProps) {
  const intl = useIntl();

  const handleClose = () => {
    setShowNotification(false);
  };

  const action = (
    <>
      <IconButton
        component={'button'}
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          handleClose();
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar open={showNotification} TransitionComponent={SlideTransition}>
      <Alert severity={type || 'error'} action={action} variant="filled" sx={{ width: '100%' }}>
        {intl.formatMessage({ id: message })}
      </Alert>
    </Snackbar>
  );
}

export { Notification };
