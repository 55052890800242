import { useIntl } from 'react-intl';

import { Dialog } from '../../../../components/library/dialog/Dialog';
import { useServiceForecastUiStateContext } from '../../../../context/ServiceForecastUiStateContext';

import { ServiceForeCastForm } from './ServiceForeCastForm';

export function ServiceForeCastDialog() {
  const intl = useIntl();
  const { openDialog: openDownloadStartedDialog } = useServiceForecastUiStateContext();
  return (
    <Dialog
      id={'serviceForeCastDialog'}
      title={intl.formatMessage({ id: 'reports.serviceForecast.dialogTitle' })}
      isOpen={openDownloadStartedDialog}
      content={<ServiceForeCastForm />}
    />
  );
}
