import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { EditOperation, EditRequest, User, UserGlobalRole } from '../../types/types';

interface PatchUserDialogProps {
  setSelectedUserForPatch: React.Dispatch<React.SetStateAction<User | null | undefined>>;
  selectedUserForPatch: User | undefined | null;
  onSubmit: (data: EditRequest) => Promise<void>;
}

function PatchUserDialog({
  setSelectedUserForPatch,
  selectedUserForPatch,
  onSubmit,
}: PatchUserDialogProps) {
  const intl = useIntl();

  const [globalRole, setGlobalRole] = React.useState<string>(
    selectedUserForPatch?.globalRole || ''
  );

  const handleGlobalRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGlobalRole(event.target.value as string);
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ globalRole: string }>({
    mode: 'onChange',
  });

  return (
    <Dialog
      id={'CreateDialog'}
      open={!!selectedUserForPatch}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{intl.formatMessage({ id: 'users.edit' })}</DialogTitle>
      <DialogContent>
        <Grid minWidth={'500px'}>
          <form
            id={'patchUserForm'}
            noValidate
            autoComplete={'false'}
            onSubmit={handleSubmit(data => {
              if (selectedUserForPatch) {
                const editRequest: EditRequest = {
                  _id: selectedUserForPatch._id,
                  patchFields: [
                    {
                      operation: EditOperation.replace,
                      field: 'globalRole',
                      value: data.globalRole,
                    },
                  ],
                };

                onSubmit(editRequest);
              }
            })}
          >
            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel id="edit-global-role-label">
                {intl.formatMessage({ id: 'users.globalRole' })}
              </InputLabel>
              <Select
                label={intl.formatMessage({ id: 'users.globalRole' })}
                labelId="edit-global-role-label"
                id="globalRole"
                value={globalRole}
                {...register('globalRole', {
                  required: true,
                  onChange: handleGlobalRoleChange,
                })}
              >
                {Object.entries(UserGlobalRole).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id={'close'}
          onClick={() => {
            setSelectedUserForPatch(null);
            reset();
          }}
        >
          {intl.formatMessage({ id: 'general.cancel' })}
        </Button>
        <Button
          form="patchUserForm"
          id={'confirm'}
          type={'submit'}
          variant={'contained'}
          color={'primary'}
          disabled={!isValid}
        >
          {intl.formatMessage({ id: 'general.save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { PatchUserDialog };
