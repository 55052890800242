import { DataTableRow } from '../../components/library/table/DataTable';

export interface AlertRuleRow extends DataTableRow {
  id: string;
  alertRuleType: string;
  name: string;
  threshold: number;
  unit: string;
  receiverEmails: string[];
  receiverRoles: string[];
  itemType: string;
  station: string;
}

export enum AlertRuleRowFieldName {
  AlertRuleType = 'alertRuleType',
  Name = 'name',
  Threshold = 'threshold',
  Unit = 'unit',
  ReceiverEmails = 'receiverEmails',
  ReceiverRoles = 'receiverRoles',
  ItemType = 'itemType',
  Station = 'station',
}
