import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Modal } from '../../../components/modal/Modal';
import { ServiceType } from '../../../types/types';

import { ServiceTypeForm, ServiceTypeFormData } from './ServiceTypeForm';

export interface ServiceTypeFormModalProps {
  serviceType?: ServiceType;
  onClose: () => void;
  onSubmit: (data: ServiceTypeFormData) => void;
}

function DraftServiceTypeModal({ serviceType, onClose, onSubmit }: ServiceTypeFormModalProps) {
  const intl = useIntl();
  const isCreateModal = !serviceType;
  const [isBlockPeriodError, toggleIsBlockPeriodError] = useState<boolean>(false);
  const [isIntervalInDaysError, toggleIntervalInDaysError] = useState<boolean>(false);

  const header = (
    <div>
      <h1 className="uib-modal__title">
        {intl.formatMessage({
          id: `service-types.${isCreateModal ? 'create' : 'edit'}`,
        })}
      </h1>

      {isIntervalInDaysError && (
        <div className="draft-modal-error">
          <span>
            {intl.formatMessage({
              id: 'service-types.intervalInDays.error',
            })}
          </span>
        </div>
      )}

      {isBlockPeriodError && !isIntervalInDaysError && (
        <div className="draft-modal-error">
          <span>
            {intl.formatMessage({
              id: 'service-types.blockPeriodInDaysBeforeIntervalEnds.error',
            })}
          </span>
        </div>
      )}
    </div>
  );
  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      modalHasClosed={onClose}
      content={
        <ServiceTypeForm
          serviceType={serviceType}
          onCancel={onClose}
          onSubmit={onSubmit}
          toggleBlockPeriodError={toggleIsBlockPeriodError}
          toggleIntervalInDaysError={toggleIntervalInDaysError}
        />
      }
    />
  );
}

export { DraftServiceTypeModal };
