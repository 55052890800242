import { usePutQuantityRestriction } from '../../../api/hooks/useQuantityRestrictionsApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { QuantityRestrictionParameter } from '../../../types/quantity-restrictions/CreateQuantityRestrictionParameter';
import { QuantityRestriction } from '../../../types/quantity-restrictions/QuantityRestriction';
import { QuantityRestrictionFormValues } from '../../../types/quantity-restrictions/QuantityRestrictionFormValues';
import { ApiError } from '../../../types/types';
import { EditEntityDialogCloseButton } from '../../common/crud/EditEntityDialogCloseButton';
import { FormSubmitButton } from '../../library/buttons/FormSubmitButton';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';
import { QuantityRestrictionForm } from '../form/QuantityRestrictionForm';

export function EditQuantityRestrictionForm() {
  const { setNotificationMessage } = useNotificationMessageContext();
  const { entityToEdit: quantityRestrictionToEdit } =
    useCrudPageUiStateContext<QuantityRestriction>();
  const {
    mutateAsync: editQuantityRestriction,
    reset: resetEditQuantityRestriction,
    isLoading,
  } = usePutQuantityRestriction(quantityRestrictionToEdit?.id ?? '');
  const { setEntityToEdit: setQuantityRestrictionToEdit } = useCrudPageUiStateContext();
  const formValues = getInitialFormValues(quantityRestrictionToEdit);
  const buttons = CreateQuantityRestrictionFormButtons();
  function handleSubmit(quantityRestrictionParam: QuantityRestrictionParameter) {
    editQuantityRestriction(quantityRestrictionParam, {
      onSuccess: () => {
        resetEditQuantityRestriction();
        setNotificationMessage('quantity-restrictions.edited', NotificationType.Success);
        setQuantityRestrictionToEdit(undefined);
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          const errorData = error.response.data as ApiError;
          if (errorData.code) {
            setNotificationMessage(
              `quantity-restrictions.${errorData.code}`,
              NotificationType.Error
            );
          } else {
            setNotificationMessage(
              'quantity-restrictions.generic-edit-error',
              NotificationType.Error
            );
          }
        }
      },
    });
  }
  return (
    <>
      {isLoading && <LoadingIndicator />}
      <QuantityRestrictionForm
        initialFormValues={formValues}
        handleSubmit={handleSubmit}
        buttons={buttons}
      />
    </>
  );
}
function CreateQuantityRestrictionFormButtons() {
  return (
    <>
      <EditEntityDialogCloseButton />
      <FormSubmitButton />
    </>
  );
}
function getInitialFormValues(qr: QuantityRestriction | undefined): QuantityRestrictionFormValues {
  return {
    quantity: qr?.quantity,
    renterIds: qr?.renterIds ?? [],
    contractorIds: qr?.contractorIds ?? [],
    itemTypeIds: qr?.itemTypeIds ?? [],
  };
}
