import { AxiosResponse } from 'axios';
import { UseMutateFunction } from 'react-query';

import Icon from '../../../../components/library/icons/Icon';

interface OpenLockerButtonProps {
  lockerId?: string;
  stationId?: string;
  isLoading: boolean;
  disabled?: boolean;
  onClick: UseMutateFunction<AxiosResponse<any>, unknown, OpenLockerData, unknown>;
}

export interface OpenLockerData {
  lockerId: string | undefined;
  stationId: string | undefined;
}

function OpenLockerButton({
  lockerId,
  stationId,
  isLoading,
  onClick,
  disabled,
}: OpenLockerButtonProps) {
  const openLocker = () => {
    const openLockerData = {
      lockerId,
      stationId,
    };

    if (!isLoading) {
      onClick(openLockerData);
    }
  };

  return (
    <>
      <button
        onClick={e => {
          e.stopPropagation();
          openLocker();
        }}
        disabled={disabled}
        style={{ cursor: 'pointer' }}
        className={'uib-iconbutton'}
      >
        <Icon id={'openLockerButtonTable'} iconName={'logout'} />
      </button>
    </>
  );
}

export { OpenLockerButton };
