import { ReactNode } from 'react';

interface PageHeaderProps {
  children?: ReactNode;
}
/**
 * @deprecated Use components/app/PageHeader isntead
 */
export function TablePageHeader(props: PageHeaderProps) {
  return (
    <div className="uib-area-header">
      <p className="uib-area-header__headline">{props.children}</p>
    </div>
  );
}
