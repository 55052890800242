import { useIntl } from 'react-intl';

import { FormTextField } from '../../../library/form/FormTextField';

export function RenterLastNameInput() {
  const intl = useIntl();
  return (
    <FormTextField
      id={'RenterLastNameInput'}
      label={intl.formatMessage({ id: 'general.lastName' })}
      required={true}
      disabled={false}
      name={'lastName'}
    />
  );
}
