import { useIntl } from 'react-intl';

import { Modal } from '../../../../components/modal/Modal';
import { Contractor } from '../../../../types/contractors/Contractor';

import { ContractorFormData, ContractorForm } from './ContractorForm';

export interface ContractorFormModalProps {
  contractor?: Contractor;
  onClose: () => void;
  onSubmit: (data: ContractorFormData) => void;
}

function DraftContractorModal({ contractor, onClose, onSubmit }: ContractorFormModalProps) {
  const intl = useIntl();
  const isCreateModal = !contractor;
  const header = (
    <h1 className="uib-modal__title">
      {intl.formatMessage({
        id: `contractors.${isCreateModal ? 'create' : 'edit'}`,
      })}
    </h1>
  );
  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      content={<ContractorForm contractor={contractor} onCancel={onClose} onSubmit={onSubmit} />}
      modalHasClosed={onClose}
    />
  );
}

export { DraftContractorModal };
