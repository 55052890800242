import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { components, default as ReactSelect } from 'react-select';
import { FilterProps } from 'react-table';

import { OptionItem } from '../../types/types';
import { sortLabelsFromOptions } from '../../utils/formData';
import { DropdownIndicator } from '../form/Dropdown';
function optionToOptionItem(option: string): OptionItem {
  return {
    value: `${option}_id`,
    label: option,
  };
}
const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const MultiValue = ({ index, getValue }: any) => {
  const containerValue = getValue().map((value: OptionItem) => value.label);

  if (index) {
    return <></>;
  }

  return (
    <div
      style={{
        maxWidth: '80%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        cursor: 'pointer',
      }}
    >
      {containerValue.join(', ')}
    </div>
  );
};
export const MultiSelectColumnFilter = ({
  column: { setFilter, preFilteredRows, id },
}: FilterProps<OptionItem>) => {
  const intl = useIntl();

  const [optionSelected, setOptionSelected] = useState<OptionItem[]>();
  const optionItems = useMemo(() => {
    const options: string[] = [];
    preFilteredRows.forEach(row => {
      const rowValue =
        id !== 'status'
          ? row.values[id]
          : intl.formatMessage({
              id: `rental-robot-systems.${row.values[id].toLowerCase()}`,
            });

      if (!options.includes(rowValue)) {
        options.push(rowValue);
      }
    });
    return sortLabelsFromOptions(options.map((option: string) => optionToOptionItem(option)));
  }, [id, preFilteredRows]);
  return (
    <ReactSelect
      placeholder={intl.formatMessage({
        id: `multiSelect.select`,
      })}
      isClearable={false}
      classNamePrefix="multiselect-filter"
      className="multiselect-filter"
      options={optionItems}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option,
        MultiValue,
        DropdownIndicator,
      }}
      onChange={e => {
        setFilter(e);
        setOptionSelected(e as OptionItem[]);
      }}
      value={optionSelected}
    />
  );
};
