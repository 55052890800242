import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useIntl } from 'react-intl';

import { useAppContext } from '../../../context/appContext';
import { languageOptions } from '../../../context/languageContext';
import { toOptionItem } from '../../form/Dropdown';
import { Modal } from '../../modal/Modal';

import { SettingsForm } from './SettingsForm';

// We will be able to get rid of this when we update to at least "Typescript 5.1"
// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
  function supportedValuesOf(input: 'timeZone'): string[];
}

function SettingsModal() {
  const intl = useIntl();
  const { closeSettingsModal } = useAppContext();

  const getOffset = (timeZone: string) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const offset = dayjs().tz(timeZone).utcOffset() / 60;

    return offset < 0 ? String(offset) : `+${String(offset)}`;
  };

  const sortByUTCOffset = (a: string, b: string) => {
    const [ahh] = a.split('UTC ')[1].split(')');
    const [bhh] = b.split('UTC ')[1].split(')');

    return Number(ahh) * 60 - Number(bhh) * 60;
  };

  const timeZonesWithUTCOffset = Intl.supportedValuesOf('timeZone').map(
    (timeZone: string) => `(UTC ${getOffset(timeZone)}) ${timeZone}`
  );

  const timeZonesAsOptionItems = [...timeZonesWithUTCOffset]
    .sort(sortByUTCOffset)
    .map((timeZonesOption: string) => {
      return toOptionItem(timeZonesOption.split(') ')[1], timeZonesOption);
    });

  const languagesAsOptionItems = languageOptions.map(languageOption =>
    toOptionItem(languageOption.value, languageOption.label)
  );

  const header = <h1 className="uib-modal__title">{intl.formatMessage({ id: 'app.settings' })}</h1>;

  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      content={
        <SettingsForm
          languagesAsOptionItems={languagesAsOptionItems}
          timeZonesAsOptionItems={timeZonesAsOptionItems}
        />
      }
      additionalClassNames="overflow-visible"
      modalHasClosed={closeSettingsModal}
    />
  );
}

export { SettingsModal };
