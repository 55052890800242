import { ApiError, ErrorDetails, ItemMultiDeleteErrorCodes } from '../../types/types';

export const handleDeleteError = (rentersUploadError: ApiError) => {
  if (rentersUploadError.details?.length) {
    return rentersUploadError.details.map((detail: ErrorDetails) => {
      if (detail.code in ItemMultiDeleteErrorCodes && detail.parameters?.length === 2) {
        return `${detail.parameters[0]} ${detail.parameters[1]}`;
      }
      return '';
    });
  }
};
