import { useFormContext } from 'react-hook-form';

import { useGetAlertRuleTemplates } from '../../../../api/hooks/useAlertRulesApi';
import { FormStationDropDown } from '../../../common/inputs/FormStationDropdown';
import { getRequiredAndDisabled } from '../../logic/AlertRuleTemplateEvaluator';

export function AlertRuleStationDropdown() {
  const { alertRuleTemplates } = useGetAlertRuleTemplates();
  const { watch } = useFormContext();
  const alertRuleType = watch('alertRuleType');
  const stationFieldName = 'stationId';
  const { required, disabled } = getRequiredAndDisabled(
    alertRuleTemplates,
    alertRuleType,
    stationFieldName
  );
  return <>{!disabled && <FormStationDropDown required={required} />}</>;
}
