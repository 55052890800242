import { LicenseInfo } from '@mui/x-license';
import React from 'react';
import { createRoot } from 'react-dom/client';

import './scss/index.scss';
import { App } from './components/app/App';
import { AppProviders } from './context';

LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_KEY || '');

const container = document.getElementById('root');

if (container !== null) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <AppProviders>
        <App />
      </AppProviders>
    </React.StrictMode>
  );
} else {
  console.error("No element with id 'root' found");
}
