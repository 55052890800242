import { useIntl } from 'react-intl';

import { useLockerFeatures } from '../../../../api/hooks/useLockerFeaturesApi';
import { useQualifications } from '../../../../api/hooks/useQualificationApi';
import { useItemTypeDetails } from '../../../../api/hooks/useRentingApi';
import { useServiceTypes } from '../../../../api/hooks/useServiceApi';
import { useLockerSizeOptions } from '../../../../api/hooks/useStationApi';
import { extractValuesFromOptionItems } from '../../../../components/form/Dropdown';
import { LoadingIndicator } from '../../../../components/library/loading/LoadingIndicator';
import { Modal } from '../../../../components/modal/Modal';
import { ItemType } from '../../../../types/item-types/ItemType';
import {
  CabinetType,
  LockerFeature,
  Qualification,
  IntervalServiceType,
  OptionItem,
  Manufacturer,
} from '../../../../types/types';
import { compressImage } from '../../../../utils/formData';

import { Image, ItemTypeForm, ItemTypeFormData, RawItemTypeData } from './ItemTypeForm';

export interface CreateItemTypeModalProps {
  onClose: () => void;
  submitFormData: (data: ItemTypeFormData) => void;
  itemType?: ItemType;
}

function lockerFeatureToOptionItem(feature: LockerFeature): OptionItem {
  return {
    value: feature._id,
    label: feature.description,
  };
}

function qualificationsToOptionItem(qualification: Qualification): OptionItem {
  return {
    value: qualification._id,
    label: qualification.name,
  };
}

function cabinetTypeToOptionItem(cabinetType: CabinetType): OptionItem {
  return {
    value: cabinetType.name,
    label: cabinetType.displayNameForItem,
  };
}

function serviceTypeToOptionItem(serviceType: IntervalServiceType): OptionItem {
  return {
    value: serviceType._id,
    label: serviceType.name,
  };
}

function lockerFeatureIdForProcessData(lockerFeatureId: string | null, isEditModal: boolean) {
  return lockerFeatureId && lockerFeatureId === 'none' && isEditModal ? '' : lockerFeatureId;
}

function getIdsForProcessData(optionItem: OptionItem[]) {
  if (optionItem) {
    return optionItem.length ? extractValuesFromOptionItems(optionItem) : '';
  }
  return optionItem;
}

function DraftItemTypeModal({ itemType, onClose, submitFormData }: CreateItemTypeModalProps) {
  const isEditModal = !!itemType;
  const intl = useIntl();

  const { lockerSizeOptions, isLoading: isLoadingCabinetTypes } = useLockerSizeOptions();
  const { lockerFeatures, isLoading: isLoadingLockerFeatures } = useLockerFeatures();
  const { qualifications, isLoading: isLoadingQualifications } = useQualifications();
  const { serviceTypes, isLoading: isLoadingServiceTypes } = useServiceTypes();
  const {
    detailedItemType,
    isLoading: isLoadingItemTypeDetails,
    remove,
  } = useItemTypeDetails(itemType?._id);

  const rentalCategories = [
    {
      value: 'DraegerRentalEquipment',
      label: intl.formatMessage({
        id: 'item-types.category.DraegerRentalEquipment',
      }),
    },
    {
      value: 'CustomerEquipment',
      label: intl.formatMessage({
        id: 'item-types.category.CustomerEquipment',
      }),
    },
  ];
  const manufacturerCategories = [
    {
      value: Manufacturer.DRAEGER,
      label: intl.formatMessage({ id: 'item-types.manufacturer.Draeger' }),
    },
    {
      value: Manufacturer.OTHER,
      label: intl.formatMessage({ id: 'item-types.manufacturer.Other' }),
    },
  ];

  const processFormData = async (formData: Record<keyof RawItemTypeData, any>) => {
    let image: Image | null = null;
    if (formData.images && formData.images.length === 1) {
      image = await compressImage(formData.images[0]);
    }
    let processedData: ItemTypeFormData = {
      _id: itemType?._id,
      name: formData.name,
      lockerSize: formData.lockerSize,
      lockerFeatureId: lockerFeatureIdForProcessData(formData.lockerFeatureId, isEditModal),
      qualificationIds: getIdsForProcessData(formData.qualifications),
      chargingDurationInHours: formData.isConsumable
        ? 0
        : formData.chargingDurationInHours && parseInt(formData.chargingDurationInHours),
      xDockTestable: formData.xDockTestable,
      isConsumable: formData.isConsumable,
      xDockAlertingTime: formData.xDockAlertingTime,
      xDockTestWorkflowPolicy: formData.xDockTestWorkflowPolicy,
      xDockTestExpirationTime:
        formData.xDockTestExpirationTime && parseInt(formData.xDockTestExpirationTime),
      xDockTestExpirationTimeInStorage:
        formData.xDockTestExpirationTimeInStorage &&
        parseInt(formData.xDockTestExpirationTimeInStorage),
      gdcCalibration: formData.gdcCalibration,
      gdcCalibrationInterval: formData.gdcCalibrationInterval,
      gdcCalibrationBlockPeriod: formData.gdcCalibrationBlockPeriod,
      serviceTypeIds: getIdsForProcessData(formData.serviceTypes),
      shortName: formData.shortName,
      rentalCategory: formData.rentalCategory,
      rentalErpId: formData.rentalErpId,
      mainErpId: formData.mainErpId,
      manufacturer: formData.isConsumable ? Manufacturer.OTHER : formData.manufacturer,
    };

    processedData = !isEditModal
      ? { ...processedData, ...{ image } }
      : isEditModal && image
        ? {
            ...processedData,
            ...{ imageName: image.name, imageContent: image.content },
          }
        : { ...processedData };

    submitFormData(processedData);
  };

  const header = (
    <h1 className="uib-modal__title">
      {isEditModal
        ? intl.formatMessage({ id: 'item-types.edit' })
        : intl.formatMessage({ id: 'item-types.create' })}
    </h1>
  );

  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      modalHasClosed={onClose}
      content={
        isLoadingCabinetTypes ||
        isLoadingServiceTypes ||
        isLoadingQualifications ||
        isLoadingItemTypeDetails ||
        isLoadingLockerFeatures ? (
          <LoadingIndicator />
        ) : (
          <ItemTypeForm
            onCancel={onClose}
            onSubmit={processFormData}
            cleanup={remove}
            lockerFeatures={lockerFeatures.map(lockerFeatureToOptionItem)}
            lockerSizes={lockerSizeOptions.map(cabinetTypeToOptionItem).reverse()}
            qualifications={qualifications.map(qualificationsToOptionItem)}
            serviceTypes={serviceTypes.map(serviceTypeToOptionItem)}
            rentalCategories={rentalCategories}
            manufacturerCategories={manufacturerCategories}
            selectedServiceTypes={
              detailedItemType?.serviceTypes
                ? detailedItemType?.serviceTypes.map(serviceTypeToOptionItem)
                : undefined
            }
            selectedQualifications={
              detailedItemType?.qualifications
                ? detailedItemType?.qualifications.map(qualificationsToOptionItem)
                : undefined
            }
            itemType={itemType}
          />
        )
      }
    />
  );
}

export { DraftItemTypeModal };
