import { Box, MenuItem, Select } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { DropDownItem } from '../inputs/DropDown';

import { FormNumberInput } from './FormNumberInput';

interface NumberAndUnitInputProps {
  numberId: string;
  numberName: string;
  numberPlaceHolder: string;

  unitId: string;
  unitName: string;
  unitDropDownItems: DropDownItem[];

  required?: boolean;
  disabled?: boolean;
}
export function FormNumberAndUnitInput({
  numberId,
  numberName,
  numberPlaceHolder,
  required,
  disabled,
  unitId,
  unitName,
  unitDropDownItems,
}: NumberAndUnitInputProps) {
  const { getValues, setValue } = useFormContext();
  return (
    <Box sx={{ display: 'inline-block', my: 1 }}>
      <FormNumberInput
        id={numberId}
        name={numberName}
        placeholder={numberPlaceHolder}
        required={required}
        disabled={disabled}
      />
      <Select
        id={unitId}
        sx={{ marginLeft: 1 }}
        defaultValue={getValues(unitName)}
        onChange={event => {
          setValue(unitName, event.target.value, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }}
        required={required}
        disabled={disabled}
      >
        {unitDropDownItems.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
