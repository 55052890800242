import { useIntl } from 'react-intl';

import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { Renter } from '../../../types/renter/Renter';
import { Dialog } from '../../library/dialog/Dialog';

import { PatchRenterForm } from './PatchRenterForm';

export function PatchRenterDialog() {
  const intl = useIntl();
  const { entityToEdit: renterToEdit } = useCrudPageUiStateContext<Renter>();
  return (
    <>
      {renterToEdit && (
        <Dialog
          width="md"
          id={'EditQuantityRestrictionDialog'}
          isOpen={!!renterToEdit}
          title={intl.formatMessage({ id: 'renters.edit' })}
          content={<PatchRenterForm />}
        />
      )}
    </>
  );
}
