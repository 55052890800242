import { Station } from '../../../types/types';

import { IotPairingModal } from './IotPairingModal';
import { IotUnpairingModal } from './IotUnpairingModal';

interface IotModalProps {
  station: Station;
  unpairIotAdapter: (stationId: string) => void;
  onClose: () => void;
}

function IotModal({ station, onClose, unpairIotAdapter }: IotModalProps) {
  const isPairing = station?.iotAdapterId === null;

  return isPairing ? (
    <IotPairingModal station={station} onClose={onClose} />
  ) : (
    <IotUnpairingModal unpairIotAdapter={unpairIotAdapter} station={station} onClose={onClose} />
  );
}

export { IotModal };
