import { AuthenticationResult, InteractionType } from '@azure/msal-browser';
import { useAccount, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import { LoadingIndicator } from '../../components/library/loading/LoadingIndicator';
import { ErrorModal } from '../../components/modal/ErrorModal';
import { client } from '../../shared/api-client';
import { useConfigContext } from '../../shared/configContext';
import { useRRSystemContext } from '../rrSystemContext';
interface TokenContextApi {
  setAccessToken: (accessToken: string) => void;
  accessToken: string;
}

const TokenContext = createContext<TokenContextApi | undefined>(undefined); // Provider will be set on successful login
TokenContext.displayName = 'TokenContext';

interface TokenProviderProps {
  children: ReactNode;
}

function TokenProvider({ children }: TokenProviderProps) {
  const { error } = useMsalAuthentication(InteractionType.Redirect);
  const { instance, accounts } = useMsal();
  const { config } = useConfigContext();
  const account = useAccount(accounts[0] || {});
  const [accessToken, setAccessToken] = useState('');
  const [isRefreshPending, setRefreshPending] = useState(false);
  const [tokenExpiresOn, setTokenExpiresOn] = useState(new Date());

  const calcTokenLifetimeInMin = useCallback(() => {
    return (tokenExpiresOn.getTime() - new Date().getTime()) / 1000 / 60;
  }, [tokenExpiresOn]);

  const handleRefreshAccessToken = useCallback(() => {
    if (account) {
      const tokenLifetimeInMin = calcTokenLifetimeInMin();
      const forceRefresh = tokenLifetimeInMin < 30;

      if (forceRefresh) {
        setRefreshPending(true);
      }

      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: [config.b2cClientId ?? ''],
          forceRefresh,
        })
        .then((response: AuthenticationResult) => {
          setAccessToken(response.accessToken);
          if (response.expiresOn) {
            setTokenExpiresOn(response.expiresOn);
          }
        })
        .catch(error => {
          console.error(error);

          if (error.errorMessage.includes('AADB2C90077')) {
            instance.loginRedirect({
              scopes: [''],
              authority: config.b2cPwdReset,
            });
          } else {
            instance.loginRedirect({
              scopes: [''],
              authority: config.b2cAuthority,
            });
          }
        })
        .finally(() => {
          if (forceRefresh) {
            setRefreshPending(false);
          }
        });
    }
  }, [account, instance]);

  useEffect(() => {
    handleRefreshAccessToken();
  }, [handleRefreshAccessToken]);

  const value: TokenContextApi = {
    accessToken,
    setAccessToken,
  };

  const [isModalopen, setIsModalOpen] = useState<boolean>(false);

  if (error) {
    console.error(error);
    if (error.errorMessage.includes('AADB2C90118')) {
      instance.loginRedirect({
        scopes: [''],
        authority: config.b2cPwdReset,
      });
    }

    return (
      <ErrorModal
        error={error.message}
        isOpen={isModalopen}
        onClose={() => setIsModalOpen(false)}
      />
    );
  }

  if (accessToken && !isRefreshPending) {
    return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>;
  }

  return <LoadingIndicator />;
}

const useTokenContext = (): TokenContextApi => {
  const context = useContext(TokenContext);
  if (context === undefined) {
    throw new Error('useTokenContext must be used within a TokenProvider');
  }
  return context;
};

function useClient<T>() {
  const { config: appconfig } = useConfigContext();

  const { rrSystem } = useRRSystemContext();
  const rrSystemId = rrSystem ? rrSystem._id : '';

  const { accessToken } = useTokenContext();

  return useCallback(
    (endpoint, config?) => {
      return client<T>(endpoint, {
        ...config,
        apiUrl: `${appconfig?.REACT_APP_BACKOFFICE_API_URL}`,
        rrSystemId,
        token: accessToken,
      });
    },
    [accessToken]
  );
}

export { TokenProvider, TokenContext, useTokenContext, useClient };
