import { Grid as MuiGrid } from '@mui/material';
import { ReactNode } from 'react';

export interface GridItemProps {
  children?: ReactNode;
  style?: any;
  xs?: any;
}
export function GridItem({ children, style, xs = 'auto' }: GridItemProps) {
  return (
    <MuiGrid item xs={xs} style={style}>
      {children}
    </MuiGrid>
  );
}
