import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Card } from '../../../components/library/containers/Card';

interface PageLinkReportCardProps {
  id: string;
  name: string;
  children: ReactNode;
  link: string;
}
export function PageLinkReportCard({ id, name, link, children }: PageLinkReportCardProps) {
  return (
    <div className="uib-board__box">
      <Link to={link}>
        <Card cardName={name}>
          <button id={id} className="uib-iconbutton card-button">
            {children}
          </button>
        </Card>
      </Link>
    </div>
  );
}
