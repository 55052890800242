import { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useCreateContractStock,
  useEditContractStock,
  useStock,
} from '../../api/hooks/useStockApi';
import { TablePageLayout } from '../../components/table/TablePageLayout';
import { Stock } from '../../types/types';

import { DraftStockModal } from './manage/DraftStockModal';
import { EditStockFormData, StockFormData } from './manage/StockForm';
import { StockDashBoardWithNoStockItems } from './StockDashBoardWithNoStockItems';
import { StockTable } from './StockTable';

function StockPage() {
  const intl = useIntl();
  const [selectedContractStockForEditing, setSelectedContractStockForEditing] = useState<
    Stock | undefined
  >();
  const [isCreatingContractStock, toggleIsCreatingContractStock] = useState<boolean>(false);

  const {
    isLoading,
    isError: isGetStationsError,
    error: getStationsError,
    itemsStock,
    remove,
  } = useStock();

  const {
    mutateAsync: createContractStock,
    isError: isCreateContractStockError,
    error: createContractStockErrorResponse,
    reset: resetCreateContractStock,
  } = useCreateContractStock();

  const {
    mutateAsync: editContractStock,
    isError: isEditContractStockError,
    error: editContractStockErrorResponse,
    reset: resetEditContractStock,
  } = useEditContractStock(selectedContractStockForEditing?.contractStock?.id ?? '');

  const onSubmitContractStockForm = async (
    data: Record<string, any>,
    isConsumableItemTypeSelected: boolean
  ) => {
    if (isConsumableItemTypeSelected || !data.alertStock) delete data.alertStock;

    if (!data.stock) delete data.stock;

    if (isCreatingContractStock || !selectedContractStockForEditing?.contractStock?.id) {
      createContractStock(data as StockFormData).finally(() => {
        toggleIsCreatingContractStock(false);
        setSelectedContractStockForEditing(undefined);
      });
      return;
    }

    delete data.itemTypeId;
    delete data.stationId;

    editContractStock(data as EditStockFormData).finally(() => {
      setSelectedContractStockForEditing(undefined);
    });
  };

  const onCloseDraftStockModal = () => {
    toggleIsCreatingContractStock(false);
    setSelectedContractStockForEditing(undefined);
  };

  return (
    <>
      <TablePageLayout
        isContentLoading={isLoading}
        isContentError={isGetStationsError}
        contentError={getStationsError}
        pageName={intl.formatMessage({ id: 'stockPage.self' })}
        pageNameSingular={intl.formatMessage({ id: 'stockPage.self' })}
        table={
          itemsStock.length ? (
            <StockTable
              itemsStock={itemsStock}
              onSelectContractStockForEditing={setSelectedContractStockForEditing}
            />
          ) : (
            <StockDashBoardWithNoStockItems />
          )
        }
        hasDraftModal={true}
        openCreateModal={() => toggleIsCreatingContractStock(true)}
        isDraftModalOpen={isCreatingContractStock || !!selectedContractStockForEditing}
        draftModal={
          <DraftStockModal
            selectedStock={selectedContractStockForEditing}
            onClose={onCloseDraftStockModal}
            submitFormData={onSubmitContractStockForm}
            itemsStock={itemsStock}
          />
        }
        isCreateModalError={isCreateContractStockError}
        createModalError={createContractStockErrorResponse}
        isEditModalError={isEditContractStockError}
        editModalError={editContractStockErrorResponse}
        cleanup={remove}
        cleanupErrors={() => {
          resetCreateContractStock();
          resetEditContractStock();
        }}
      />
    </>
  );
}

export { StockPage };
