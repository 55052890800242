import { useIntl } from 'react-intl';

import { useItemTypes } from '../../../api/hooks/useRentingApi';
import { ItemType } from '../../../types/item-types/ItemType';
import { FormMultiDropDown } from '../../library/form/FormMultiDropDown';
import { DropDownItem } from '../../library/inputs/DropDown';

interface MultiItemTypeDropDownProps {
  required?: boolean;
}
export function MultiItemTypeDropDown({ required }: MultiItemTypeDropDownProps) {
  const intl = useIntl();
  const { itemTypes } = useItemTypes();
  const options = getItemTypeDropDownItems(itemTypes);
  return (
    <FormMultiDropDown
      id={'itemTypeMultiDropDown'}
      label={intl.formatMessage({ id: 'item-types.self' })}
      name={'itemTypeIds'}
      options={options}
      required={required}
    />
  );
}
function getItemTypeDropDownItems(itemTypes: ItemType[]): DropDownItem[] {
  const result = itemTypes.map(itemType => ({
    value: itemType._id,
    label: itemType.name,
  }));
  return result;
}
