import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { odxErrorColor, odxPrimaryColor } from '../../../../context/themeContext';
import { hasPeriodOfValidity, Qualification } from '../../../../types/types';
import { MultiQualificationsDropDown } from '../../../common/inputs/MultiQualificationsDropdown';
import { apiFormat, DatePicker } from '../../../library/inputs/DatePicker';
import SimpleTable, { SimpleTableColumn } from '../../../library/table/SimpleTable';
import { useRentersPageContext } from '../../contexts/RentersPageContext';

interface RenterQualificationDropdownProps {
  qualifications: Qualification[];
}
export function RenterQualificationDropdown() {
  const { qualifications } = useRentersPageContext();
  return (
    <>
      <MultiQualificationsDropDown qualifications={qualifications} />
      <RenterQualificationsTable qualifications={qualifications} />
    </>
  );
}
function RenterQualificationsTable({ qualifications }: RenterQualificationDropdownProps) {
  const { getValues: getFormValues, watch } = useFormContext();
  const selectedQualificationIds = getFormValues('qualificationOptions') as string[];
  const selectedQualifcationsChanged = watch('qualificationOptions');
  useEffect(() => {}, [selectedQualifcationsChanged]);
  return (
    <SimpleTable
      headless
      columns={getColumns(qualifications)}
      rows={getRows(qualifications, selectedQualificationIds)}
    />
  );
}
function getColumns(qualifications: Qualification[]): SimpleTableColumn[] {
  return [
    { id: 'name' },
    {
      id: 'dateType',
      renderRow: hasPeriodOfValidity => {
        return <QualificationDateTypeLabel hasPeriodOfValidity={hasPeriodOfValidity as boolean} />;
      },
    },
    {
      id: 'date',
      renderRow: value => {
        return (
          <QualificationDateInputField
            index={value.index as number}
            qualificationId={value.id as string}
            qualifications={qualifications}
          />
        );
      },
    },
  ];
}
interface QualificationDateInputFieldProps {
  index: number;
  qualificationId: string;
  qualifications: Qualification[];
}
function QualificationDateInputField({
  qualifications,
  qualificationId,
  index,
}: QualificationDateInputFieldProps) {
  const intl = useIntl();
  const label = intl.formatMessage({ id: 'general.date' });
  const { setValue: setFormValue, getValues: getFormValue } = useFormContext();
  const qualification = qualifications.find(q => q._id === qualificationId);
  const qualificationDateFormField = hasPeriodOfValidity(qualification)
    ? `qualifications.${index}.issueDate`
    : `qualifications.${index}.expiryDate`;
  const formValue = getFormValue(qualificationDateFormField);
  const today = dayjs().format(apiFormat);
  if (!formValue) {
    setQualDateFormValues(today);
  }
  return (
    <DatePicker
      label={label}
      date={formValue ?? today}
      onChange={date => {
        setQualDateFormValues(date);
      }}
    />
  );

  function setQualDateFormValues(date: string) {
    const qualificationIdFormField = `qualifications.${index}._id`;
    setFormValue(qualificationDateFormField, date, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setFormValue(qualificationIdFormField, qualificationId);
  }
}
interface QualificationDateTypeLabelProps {
  hasPeriodOfValidity: boolean;
}
function QualificationDateTypeLabel({ hasPeriodOfValidity }: QualificationDateTypeLabelProps) {
  return <>{hasPeriodOfValidity ? <IssueDateLabel /> : <ExpiryDateLabel />}</>;
}
function IssueDateLabel() {
  const intl = useIntl();
  return (
    <p style={{ color: odxPrimaryColor, width: '200px' }}>
      {intl.formatMessage({ id: 'qualifications.issued' })}
    </p>
  );
}
function ExpiryDateLabel() {
  const intl = useIntl();
  return (
    <p style={{ color: odxErrorColor, width: '200px' }}>
      {intl.formatMessage({ id: 'qualifications.expires' })}
    </p>
  );
}

function getRows(qualifications: Qualification[], selectedQualificationIds: string[]) {
  return qualifications
    .filter(qualification => selectedQualificationIds.includes(qualification._id))
    .map((qualification, index) => ({
      id: qualification._id,
      name: qualification.name,
      date: { id: qualification._id, index: index },
      dateType: hasPeriodOfValidity(qualification),
    }));
}
