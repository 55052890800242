import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { useUserDetails } from '../../api/hooks/useUserApi';
import { User } from '../../types/types';

interface DetailUserDialogProps {
  selectedUserForDetailView: User | undefined | null;
  setSelectedUserForDetailView: React.Dispatch<React.SetStateAction<User | null | undefined>>;
  userId: string;
}

function DetailUserDialog({
  selectedUserForDetailView,
  setSelectedUserForDetailView,
  userId,
}: DetailUserDialogProps) {
  const intl = useIntl();

  const { userDetails, isLoading, remove } = useUserDetails(userId);

  return (
    <>
      <Dialog
        id={'DetailDialog'}
        open={!!selectedUserForDetailView}
        keepMounted
        onClose={() => {
          setSelectedUserForDetailView(undefined);
        }}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle>{intl.formatMessage({ id: 'users.detailView' })}</DialogTitle>
        <DialogContent>
          {!isLoading ? (
            <DialogContentText id="alert-dialog-slide-description">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{intl.formatMessage({ id: 'general.firstName' })}</TableCell>
                      <TableCell align="right">
                        {intl.formatMessage({ id: 'general.lastName' })}
                      </TableCell>
                      <TableCell align="right">
                        {intl.formatMessage({ id: 'general.email' })}
                      </TableCell>
                      <TableCell align="right">
                        {intl.formatMessage({ id: 'users.globalRole' })}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={'test'}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {userDetails?.firstName}
                      </TableCell>
                      <TableCell align="right">{userDetails?.lastName}</TableCell>
                      <TableCell align="right">{userDetails?.email}</TableCell>
                      <TableCell align="right">{userDetails?.globalRole}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="h6" gutterBottom mt={'15px'}>
                {intl.formatMessage({ id: 'users.roles' })}
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {intl.formatMessage({
                          id: 'rental-robot-systems.system',
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {intl.formatMessage({ id: 'users.role' })}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userDetails?.roles.map(row => (
                      <TableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell align="left">{row.rrSystemName}</TableCell>
                        <TableCell align="right">{row.role}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          ) : (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            id={'closeBtn'}
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              setSelectedUserForDetailView(null);
              remove();
            }}
          >
            {intl.formatMessage({ id: 'general.close' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { DetailUserDialog };
