import { IntlShape, useIntl } from 'react-intl';

import { FormMultiDropDown } from '../../../library/form/FormMultiDropDown';
import { DropDownItem } from '../../../library/inputs/DropDown';

export enum AlertRuleReceiverRole {
  RrSystemAdmins = 'RRSystemAdmins',
  RenterSupervisors = 'RenterSupervisors',
  StationSupervisors = 'StationSupervisors',
  Renter = 'Renter',
}
export function AlertRuleReceiverRolesInput() {
  const intl = useIntl();
  return (
    <FormMultiDropDown
      id={'AlertRuleReceiverRolesInput'}
      label={intl.formatMessage({ id: 'alert-rules.receiverRoles' })}
      name={'receiverRoles'}
      options={AlertRuleRolesOptions(intl)}
    />
  );
}

function AlertRuleRolesOptions(intl: IntlShape): DropDownItem[] {
  return [
    {
      label: intl.formatMessage({
        id: `alert-rules.${AlertRuleReceiverRole.RrSystemAdmins}`,
      }),
      value: AlertRuleReceiverRole.RrSystemAdmins,
    },
    {
      label: intl.formatMessage({
        id: `alert-rules.${AlertRuleReceiverRole.RenterSupervisors}`,
      }),
      value: AlertRuleReceiverRole.RenterSupervisors,
    },
    {
      label: intl.formatMessage({
        id: `alert-rules.${AlertRuleReceiverRole.StationSupervisors}`,
      }),
      value: AlertRuleReceiverRole.StationSupervisors,
    },
    {
      label: intl.formatMessage({
        id: `alert-rules.${AlertRuleReceiverRole.Renter}`,
      }),
      value: AlertRuleReceiverRole.Renter,
    },
  ];
}
