import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useCreateStation,
  useDeleteStation,
  useEditStation,
  usePairTerminal,
  useStations,
  useUnpairIotAdapter,
  useUnpairTerminal,
} from '../../api/hooks/useStationApi';
import { ErrorModal } from '../../components/modal/ErrorModal';
import { TablePageLayout } from '../../components/table/TablePageLayout';
import { useRRSystemContext } from '../../context/rrSystemContext';
import { Station } from '../../types/types';

import { CabinetsPage } from './cabinets/CabinetsPage';
import { DraftStationModal } from './manage/DraftStationModal';
import { StationFormData } from './manage/StationForm';
import { IotModal } from './pairing/IotModal';
import { TerminalPairingModal } from './pairing/TerminalPairingModal';
import { StationsTable } from './StationsTable';

function StationsPage() {
  const intl = useIntl();

  const [isModalopen, setIsModalOpen] = useState<boolean>(false);

  const { clickedStation, setClickedStation } = useRRSystemContext();
  const [stationBeingConnected, setStationBeingConnected] = useState<Station | undefined>(
    undefined
  );
  const [iotModalOpen, toggleIotModal] = useState<boolean>(false);
  const [terminalModalOpen, toggleTerminalModal] = useState<boolean>(false);
  const [terminalPairingCode, setTerminalPairingCode] = useState<string>('');
  const [isCreatingStation, toggleIsCreatingStation] = useState<boolean>(false);
  const [selectedStationForEditing, setSelectedStationForEditing] = useState<Station | undefined>();
  const [selectedStationForDeletion, setSelectedStationForDeletion] = useState<
    Station | undefined
  >();

  const {
    isLoading,
    isError: isGetStationsError,
    error: getStationsError,
    refetch: refetchStations,
    stations,
    remove,
  } = useStations();
  const {
    mutateAsync: createStation,
    isError: isCreateStationError,
    error: createStationError,
    reset: resetCreateStation,
  } = useCreateStation();
  const {
    mutateAsync: editStation,
    isError: isEditStationError,
    error: editStationError,
    reset: resetEditStation,
  } = useEditStation();
  const {
    mutateAsync: deleteStation,
    isError: isDeleteStationError,
    error: deleteStationError,
    reset: resetDeleteStation,
  } = useDeleteStation(selectedStationForDeletion?._id ?? '');
  const { mutate: unpairStationFromIotAdapter, isError: isUnpairStationFromIotAdapterError } =
    useUnpairIotAdapter();
  const {
    mutate: generatePairingCode,
    isSuccess: isGeneratePairingCodeSuccess,
    data: generatePairingCodeData,
  } = usePairTerminal();
  const { mutate: unpairStationFromTerminal, isError: isUnpairStationFromTerminalError } =
    useUnpairTerminal();

  const { stationId } = useParams<{ stationId: string }>();

  const navigate = useNavigate();

  const selectStation = (station: Station) => {
    setClickedStation(station);
    const url = '/stations/' + station._id;
    navigate(url);
  };

  useEffect(() => {
    if (clickedStation)
      setClickedStation(stations.find((station: { _id: string }) => station._id === stationId));
  }, [stationId]);

  const initiateOrEndIotPairing = (station: Station) => {
    setStationBeingConnected(station);
    toggleIotModal(true);
  };

  const pairStationTerminal = (alreadyPaired: boolean, station: Station) => {
    setStationBeingConnected(station);
    if (alreadyPaired) {
      return toggleTerminalModal(true);
    }
    generatePairingCode(station._id);
  };

  useEffect(() => {
    if (isGeneratePairingCodeSuccess && generatePairingCodeData) {
      // @ts-ignore
      setTerminalPairingCode(generatePairingCodeData.data.oneTimePairingCode);
      toggleTerminalModal(true);
    }
  }, [isGeneratePairingCodeSuccess]);

  const onCloseStationForm = () => {
    toggleIsCreatingStation(false);
    setSelectedStationForEditing(undefined);
  };

  const onSubmitStationForm = (data: StationFormData) => {
    if (isCreatingStation) onSubmitCreateStation(data);
    if (selectedStationForEditing) onSubmitEditStation(data);
  };

  const onSubmitCreateStation = async (data: StationFormData) => {
    createStation(data).finally(() => {
      // always close the modal as error case will trigger a new error modal
      toggleIsCreatingStation(false);
    });
  };

  const onSubmitEditStation = async (data: StationFormData) => {
    editStation(data).finally(() =>
      // always close the modal as error case will trigger a new error modal
      setSelectedStationForEditing(undefined)
    );
  };

  const onConfirmDeleteStation = () => {
    deleteStation().finally(() => {
      setSelectedStationForDeletion(undefined);
    });
  };

  if (clickedStation) {
    return <CabinetsPage station={clickedStation} />;
  }

  return (
    <>
      <TablePageLayout
        isContentLoading={isLoading}
        isContentError={isGetStationsError}
        contentError={getStationsError}
        pageName={intl.formatMessage({ id: 'stations.self' })}
        pageNameSingular={intl.formatMessage({ id: 'stations.singular' })}
        table={
          <StationsTable
            stations={stations}
            onClickStation={selectStation}
            onPairIotAdapter={initiateOrEndIotPairing}
            onPairTerminal={pairStationTerminal}
            onSelectStationForEditing={setSelectedStationForEditing}
            onSelectStationForDeletion={setSelectedStationForDeletion}
          />
        }
        hasDraftModal={true}
        openCreateModal={() => toggleIsCreatingStation(true)}
        isDraftModalOpen={isCreatingStation || !!selectedStationForEditing}
        draftModal={
          <DraftStationModal
            station={selectedStationForEditing}
            onClose={onCloseStationForm}
            onSubmit={onSubmitStationForm}
          />
        }
        isCreateModalError={isCreateStationError}
        createModalError={createStationError}
        isEditModalError={isEditStationError}
        editModalError={editStationError}
        isDeleteModalOpen={!!selectedStationForDeletion}
        onConfirmDelete={onConfirmDeleteStation}
        onCloseDelete={setSelectedStationForDeletion}
        isDeleteModalError={isDeleteStationError}
        deleteModalError={deleteStationError}
        confirmDeleteMessage={
          <p>
            {intl.formatMessage(
              { id: 'stations.delete.confirm' },
              {
                stationName: selectedStationForDeletion?.location,
              }
            )}
          </p>
        }
        cleanup={remove}
        cleanupErrors={() => {
          resetCreateStation();
          resetEditStation();
          resetDeleteStation();
        }}
      />
      {iotModalOpen && stationBeingConnected && (
        <IotModal
          station={stationBeingConnected}
          onClose={() => toggleIotModal(false)}
          unpairIotAdapter={(stationId: string) => unpairStationFromIotAdapter(stationId)}
        />
      )}
      {terminalModalOpen && stationBeingConnected && (
        <TerminalPairingModal
          station={stationBeingConnected}
          onClose={() => {
            refetchStations();
            toggleTerminalModal(false);
          }}
          terminalPairingCode={
            terminalPairingCode.slice(0, 4) +
            ' ' +
            terminalPairingCode.slice(4, 8) +
            ' ' +
            terminalPairingCode.slice(8, 12)
          }
          unpairTerminal={(stationId: string) => unpairStationFromTerminal(stationId)}
        />
      )}
      {isUnpairStationFromIotAdapterError && (
        <ErrorModal
          error={intl.formatMessage({ id: 'stations.unpairingError' })}
          isOpen={isModalopen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {isUnpairStationFromTerminalError && (
        <ErrorModal
          error={intl.formatMessage({ id: 'stations.unpairingError' })}
          isOpen={isModalopen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
}

export { StationsPage };
