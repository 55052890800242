import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useAuthContext } from '../../context/auth/authContext';
import { useClient } from '../../context/auth/tokenContext';
import { StationCountByCountry } from '../../types/rr-systems/StationCountByCountry';
import { System } from '../../types/systems/System';
import { SystemFormValues } from '../../types/systems/SystemFormValues';
import { SystemParameter } from '../../types/systems/SystemParameter';

export const RR_SYSTEMS_ROUTE = 'rr-systems';
export const STATIONS_BY_COUNTRY_QUERY_KEY = 'stations-by-country';

export function useSystems() {
  const client = useClient<System[]>();
  const result = useQuery(RR_SYSTEMS_ROUTE, () => client(`${RR_SYSTEMS_ROUTE}`));
  const systems =
    result.data?.data.map(system => ({
      ...system,
      id: system._id,
    })) ?? [];

  return {
    ...result,
    systems: systems,
  };
}


export function useCreateSystem() {
  const client = useClient();
  const queryClient = useQueryClient();
  const {userEmail} = useAuthContext();
  return useMutation(
    (data: SystemFormValues) =>
      client(`${RR_SYSTEMS_ROUTE}?email=${userEmail}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RR_SYSTEMS_ROUTE);
      },
    }
  );
}

export function usePutSystem(id : string) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: SystemParameter) =>
      client(`${RR_SYSTEMS_ROUTE}/${id}?rr_system_id=${id}`, {
        method: 'PUT',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RR_SYSTEMS_ROUTE);
      },
    }
  );
}


export function useStationCountByCountry() {
  const client = useClient<StationCountByCountry[]>();

  const result = useQuery(STATIONS_BY_COUNTRY_QUERY_KEY, () =>
    client(`${RR_SYSTEMS_ROUTE}/stations-by-country`)
  );

  return {
    ...result,
    stationCountsByCountry: result.data?.data ?? [],
  };
}





