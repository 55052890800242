import { Button } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';

interface ChooseFileButtonProps {
  handleFiles: (files: FileList) => void;
  handleError: (errorLabel: string) => void;
  fileType?: string;
}
export function ChooseFileButton({
  handleFiles,
  handleError,
  fileType = '.xlsx',
}: ChooseFileButtonProps) {
  const intl = useIntl();
  const [fileName, setFileName] = useState<string>();
  return (
    <div style={{ textAlign: 'center' }}>
      <Button component="label" variant={'contained'}>
        {!!fileName && truncate(fileName)}
        {!fileName && intl.formatMessage({ id: 'general.chooseFile' })}
        <input
          type="file"
          accept={fileType}
          hidden
          onChange={e => {
            if (e.target.files) {
              const validationResult = validateFile(e.target.files);
              if (validationResult === true) {
                setFileName(e.target.files[0].name);
                handleFiles(e.target.files);
              } else {
                handleError(validationResult as string);
              }
            }
          }}
        />
      </Button>
      <p style={{ opacity: '0.8', fontSize: '13px' }}>
        {intl.formatMessage({ id: `general.only${fileType}.files` })}
      </p>
    </div>
  );

  function validateFile(input: FileList): string | boolean {
    if (input.length != 1) return 'general.fileCount.error';
    const file = input[0];
    const imageSizeInMbs = file.size / 1024 / 1024;
    const imageNameLength = file.name.length;
    if (!file.name.endsWith(fileType)) {
      return 'general.fileType.error';
    }
    if (imageSizeInMbs > 10) {
      return 'general.fileSize.error';
    }
    if (imageNameLength > 150) {
      return 'general.fileNameSize.error';
    }
    return true;
  }
}

function truncate(fileName: string): string {
  if (fileName.length > 25) {
    return fileName.substring(0, 25) + '...';
  } else {
    return fileName;
  }
}
