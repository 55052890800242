import { CircularProgress } from '@mui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Paper } from '../containers/Paper';

import { ChartCardTitle } from './ChartCardTitle';

interface ChartCardProps {
  title: string;
  content: ReactNode;
  isLoading: boolean;
  isEmpty?: boolean;
}
export function ChartCard({ title, content, isLoading, isEmpty = false }: ChartCardProps) {
  return (
    <Paper>
      <ChartCardTitle title={title} />
      {isLoading && <LoadingState />}
      {!isLoading && !isEmpty && content}
      {!isLoading && isEmpty && <EmptyState />}
    </Paper>
  );
}
function LoadingState() {
  return (
    <CenteredContainer height="350px">
      <CircularProgress />
    </CenteredContainer>
  );
}
function EmptyState() {
  const intl = useIntl();
  return (
    <CenteredContainer height={'50px'}>
      {intl.formatMessage({ id: 'dashboard.defaultEmptyMessage' })}
    </CenteredContainer>
  );
}
interface CenteredContainerProps {
  children: ReactNode;
  height: string;
}
function CenteredContainer({ children, height }: CenteredContainerProps) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        height: height,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );
}
