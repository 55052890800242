import { useIntl } from 'react-intl';

function StockDashBoardWithNoStockItems() {
  const intl = useIntl();
  return (
    <div className="no-stock-container">
      <i className="uib-icon uib-icon--table" />
      <span className="no-stock-bold-text">
        {intl.formatMessage({
          id: 'stockTable.toSeeStock',
        })}
      </span>
      <ol>
        <li>
          {intl.formatMessage({
            id: 'stockTable.createItemStepOne',
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: 'stockTable.createItemStepTwo',
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: 'stockTable.createItemStepThree',
          })}
        </li>
      </ol>
    </div>
  );
}

export { StockDashBoardWithNoStockItems };
