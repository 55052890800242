import Select, { components, SingleValue } from 'react-select';

import { OptionItem } from '../../types/types';
import { sortLabelsFromOptions } from '../../utils/formData';

export interface DropdownProps {
  id: string;
  options: OptionItem[];
  disabled?: boolean;
  value?: string;
  handleChange: (optionItem: SingleValue<OptionItem>) => void;
}

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="uib-icon uib-icon--arrow-scroll-down" />
    </components.DropdownIndicator>
  );
};

function toOptionItem(value: string, label?: string): OptionItem {
  return {
    value,
    label: label ?? value,
  };
}

function extractValuesFromOptionItems(optionItems: OptionItem[]): string[] {
  if (optionItems === undefined) {
    return [];
  }
  return optionItems.map(x => x.value);
}

const selectedOption = (sortedOptions: OptionItem[], value: string | undefined) => {
  return sortedOptions.find(option => option.value === value);
};

function Dropdown({ id, options, disabled = false, value, handleChange }: DropdownProps) {
  const maxMenuHeight = 175;

  return (
    <Select
      id={id}
      options={sortLabelsFromOptions(options)}
      className="dropdown"
      classNamePrefix="dropdown"
      components={{ DropdownIndicator }}
      isDisabled={disabled}
      maxMenuHeight={maxMenuHeight}
      value={selectedOption(options, value)}
      onChange={handleChange}
    />
  );
}
export { Dropdown, toOptionItem, extractValuesFromOptionItems, selectedOption };
