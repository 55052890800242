import { ReactNode } from 'react';

import { Tooltip } from '../general/Tooltip';

interface LabeledInputProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  type?: 'text' | 'email' | 'tel' | 'number' | 'date';
  min?: string;
  max?: string;
  size?: number;
  defaultValue?: string;
  props?: unknown;
  className?: string;
  inputSiblings?: ReactNode;
  subContent?: ReactNode;
  tooltipMessage?: string;
  labelAdditionalClassName?: string;
  feedbackIcon?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  dataTestid?: string;
}

function LabeledInput({
  id,
  label,
  placeholder,
  type = 'text',
  required = true,
  disabled = false,
  hidden = false,
  min,
  max,
  props,
  defaultValue = undefined,
  className,
  inputSiblings,
  subContent,
  tooltipMessage = '',
  labelAdditionalClassName = '',
  feedbackIcon,
  onChange,
  dataTestid,
}: LabeledInputProps) {
  const feedbackIconClassName = feedbackIcon ? `feedbackIcon` : '';
  return (
    <div className={hidden ? 'displayNone' : ''}>
      <div
        className={`uib-form__item ${className} ${required ? `uib-form-field--required` : null}`}
      >
        <label htmlFor={id} className={`uib-form__label ${labelAdditionalClassName}`}>
          {label}
          {tooltipMessage && <Tooltip tooltipMessage={tooltipMessage} />}
        </label>
        <div className={`uib-textfield ${feedbackIconClassName}`} onChange={onChange}>
          <input
            data-testid={dataTestid}
            defaultValue={defaultValue}
            disabled={disabled}
            id={id}
            placeholder={placeholder}
            className={`uib-textfield__input ${feedbackIconClassName}`}
            type={type}
            min={min}
            max={max}
            {...(typeof props === 'object' ? props : {})}
          />
          {inputSiblings}
        </div>
      </div>
      {subContent}
    </div>
  );
}

function VerticalLabelledInput({
  id,
  label,
  placeholder,
  type = 'number',
  disabled = false,
  min,
  max,
  size,
  props,
  defaultValue = undefined,
}: LabeledInputProps) {
  return (
    <div className="vertically-labelled-input">
      <label>{label}</label>
      <input
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        className="uib-textfield__input"
        placeholder={placeholder}
        type={type}
        min={min}
        max={max}
        size={size}
        {...(typeof props === 'object' ? props : {})}
      />
    </div>
  );
}
export { LabeledInput, VerticalLabelledInput };
