import { useIntl } from 'react-intl';

import { useItemTypeDetails } from '../../../api/hooks/useRentingApi';
import Icon from '../../../components/library/icons/Icon';
import { DetailView } from '../../../components/table/DetailView';
import { DetailedItemType } from '../../../types/item-types/ItemType';
import { IntervalServiceType } from '../../../types/types';
import { bindestrich } from '../../../utils/bindestrich';

import { ItemTypeDetailCard } from './ItemTypeDetailCard';

interface ItemTypeDetailViewProps {
  itemTypeId: string;
  onClose: () => void;
}

function ItemTypeDetailView({ onClose, itemTypeId }: ItemTypeDetailViewProps) {
  const intl = useIntl();

  const { detailedItemType, isLoading, remove } = useItemTypeDetails(itemTypeId);

  const hasServiceTypes = detailedItemType !== null && detailedItemType.serviceTypes !== undefined;

  const mainColumns = [
    {
      Header: intl.formatMessage({ id: 'item-types.itemTypeName' }),
      accessor: 'name',
    },
    {
      Header: intl.formatMessage({ id: 'item-types.shortNameTerminal' }),
      id: 'shortName',
      accessor: (row: DetailedItemType) => {
        return bindestrich(row.shortName);
      },
    },
    {
      Header: intl.formatMessage({ id: 'lockers.lockerSize' }),
      accessor: 'lockerSize',
    },
    {
      Header: intl.formatMessage({ id: 'lockers.lockerFeature' }),
      id: 'lockerFeature',
      accessor: (row: DetailedItemType) => {
        return bindestrich(row.lockerFeature);
      },
    },
    {
      Header: intl.formatMessage({ id: 'manufacturer' }),
      id: 'manufacturer',
      accessor: (row: DetailedItemType) => {
        return intl.formatMessage({
          id: `item-types.manufacturer.${row.manufacturer}`,
        });
      },
    },
  ];

  const chargingQualificationColumn = [
    {
      Header: intl.formatMessage({ id: 'item-types.chargingDuration' }),
      id: 'chargingDurationInHours',
      accessor: (row: DetailedItemType) => {
        const data = row as DetailedItemType;
        return bindestrich(data.chargingDurationInHours);
      },
    },
    {
      Header: intl.formatMessage({ id: 'qualifications.self' }),
      id: 'qualificationsEnumeration',
      accessor: (row: DetailedItemType) => {
        if (!detailedItemType?.qualifications) {
          return '-';
        }
        const qualificationsEnumeration = row.qualifications.map(q => q.name);
        return qualificationsEnumeration.join(', ');
      },
    },
    {
      Header: intl.formatMessage({ id: 'item-types.rentalCategory' }),
      id: 'rentalCategory',
      accessor: (row: DetailedItemType) => {
        return intl.formatMessage({
          id: `item-types.category.${row.rentalCategory}`,
        });
      },
    },
    {
      Header: intl.formatMessage({ id: 'item-types.header.consumable' }),
      id: 'consumable',
      accessor: (row: DetailedItemType) => {
        if (row.isConsumable) {
          return <Icon iconName={'check'} additionalClasses={'odx-blue-text'} />;
        }
        return <Icon iconName={'blocked'} />;
      },
    },
  ];

  const serviceTypesColumns = [
    {
      Header: intl.formatMessage({ id: 'service-types.serviceName' }),
      accessor: 'name',
    },
    {
      Header: intl.formatMessage({ id: 'service-types.intervalInDays' }),
      id: 'serviceInterval',
      accessor: (row: IntervalServiceType) => {
        const data = row;
        if (data.isBlockAfterUse) {
          return (
            <span>
              <i className="uib-icon uib-icon--reload-required" />
            </span>
          );
        }
        return bindestrich(data.intervalInDays);
      },
    },
  ];

  return (
    <DetailView
      onClose={onClose}
      cleanup={remove}
      isDetailDataLoading={isLoading}
      detailData={detailedItemType}
      hiddenColumns={detailedItemType?.isConsumable ? ['qualificationsEnumeration'] : undefined}
      header={intl.formatMessage({ id: 'item-types.detailView' })}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      mainColumns={mainColumns}
      aside={<ItemTypeDetailCard itemType={detailedItemType} />}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      additionalTableLayer={chargingQualificationColumn}
      hasSecondSection={hasServiceTypes}
      secondSectionHeader={intl.formatMessage({ id: 'service-types.self' })}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      secondaryColumns={serviceTypesColumns}
      secondaryData={detailedItemType?.serviceTypes}
      detailDataError={intl.formatMessage({
        id: 'item-types.detailView.error',
      })}
    />
  );
}

export { ItemTypeDetailView };
