import { useIntl } from 'react-intl';

import { FormNumberInput } from '../../../library/form/FormNumberInput';

export function QuantityInput() {
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    id: 'quantity-restrictions.quantity',
  });
  return (
    <FormNumberInput
      id={'quantityInput'}
      name={'quantity'}
      placeholder={placeholder}
      fullWidth={true}
      required={true}
      style={{ marginTop: '5px', marginBottom: '5px' }}
    />
  );
}
