import { useIntl } from 'react-intl';

import { Grid } from '../../components/library/containers/Grid';
import { BusinessReportUiStateProvider } from '../../context/BusinessReportUiStateContext';

import { BusinessReportCard } from './components/BusinessReportCard';
import { PageLinkReportCard } from './components/PageLinkReportCard';
import { ReportsPageHeader } from './components/ReportsPageHeader';
import { ServiceForeCast } from './components/service-forecast/ServiceForeCast';

export function ReportsPage() {
  return (
    <>
      <ReportsPageHeader />
      <Grid style={{ marginLeft: '0px', gap: '10px', width: '100%' }} container>
        <RentingHistoryCard />
        <ServiceHistoryCard />
        <BusinessReportUiStateProvider>
          <BusinessReportCard />
        </BusinessReportUiStateProvider>
        <ServiceForeCast />
      </Grid>
    </>
  );
}

function RentingHistoryCard() {
  const intl = useIntl();
  return (
    <PageLinkReportCard
      id={'btnReportHistory'}
      name={intl.formatMessage({ id: 'reports.rentalHistory' })}
      link={'/reports/rental-history'}
    >
      <i className="uib-icon uib-icon--history card-icon" />
    </PageLinkReportCard>
  );
}

function ServiceHistoryCard() {
  const intl = useIntl();
  return (
    <PageLinkReportCard
      id={'btnServiceHistory'}
      name={intl.formatMessage({ id: 'reports.serviceHistory' })}
      link={'/reports/service-history'}
    >
      <i className="uib-icon uib-icon--log-service card-icon" />
    </PageLinkReportCard>
  );
}
