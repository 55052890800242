import { useIntl } from 'react-intl';

import { RequestErrorResponse } from '../../../../components/modal/ErrorModal';

function useServiceItemsErrorHandling() {
  const intl = useIntl();

  function getValidationErrorMessage(error: unknown, serviceTypeName: string | undefined) {
    const {
      response: { data },
    } = error as RequestErrorResponse;
    let errorMessage;
    const responseCode = data.codes[0];
    const itemEId = data.messages[1];
    switch (responseCode) {
      case '1001':
        errorMessage = intl.formatMessage({ id: 'items.updateServices.error.1001' }, { itemEId });
        break;
      case '1002':
        errorMessage = intl.formatMessage({ id: 'items.updateServices.error.1002' }, { itemEId });
        break;
      case '1003':
        errorMessage = intl.formatMessage({ id: 'items.updateServices.error.1003' }, { itemEId });
        break;
      case '1004':
        errorMessage = intl.formatMessage(
          { id: 'items.updateServices.error.1004' },
          {
            itemEId,
            serviceTypeName: serviceTypeName ? serviceTypeName : 'selected',
          }
        );
        break;
      case '1005':
        errorMessage = intl.formatMessage({ id: 'items.updateServices.error.1005' }, { itemEId });
        break;
      case '1006':
        errorMessage = intl.formatMessage({ id: 'items.updateServices.error.1006' }, { itemEId });
        break;
      default:
        errorMessage = `${intl.formatMessage({
          id: 'general.unknownError',
        })} ${intl.formatMessage({ id: 'general.contactAdmin' })}`;
        break;
    }
    return errorMessage;
  }

  return { getValidationErrorMessage };
}

export { useServiceItemsErrorHandling };
