import { useCreateQuantityRestriction } from '../../../api/hooks/useQuantityRestrictionsApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { QuantityRestrictionParameter } from '../../../types/quantity-restrictions/CreateQuantityRestrictionParameter';
import { QuantityRestrictionFormValues } from '../../../types/quantity-restrictions/QuantityRestrictionFormValues';
import { ApiError } from '../../../types/types';
import { CreateEntityFormButtons } from '../../common/crud/CreateEntityFormButtons';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';
import { QuantityRestrictionForm } from '../form/QuantityRestrictionForm';

export function CreateQuantityRestrictionForm() {
  const { setNotificationMessage } = useNotificationMessageContext();
  const {
    mutateAsync: createQuantityRestriction,
    reset: resetCreateQuantityRestriction,
    isLoading,
  } = useCreateQuantityRestriction();
  const { setOpenCreateDialog } = useCrudPageUiStateContext();
  function handleSubmit(QuantityRestrictionParam: QuantityRestrictionParameter) {
    createQuantityRestriction(QuantityRestrictionParam, {
      onSuccess: () => {
        resetCreateQuantityRestriction();
        setNotificationMessage('quantity-restrictions.saved', NotificationType.Success);
        setOpenCreateDialog(false);
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          const errorData = error.response.data as ApiError;
          if (errorData.code) {
            setNotificationMessage(
              `quantity-restrictions.${errorData.code}`,
              NotificationType.Error
            );
          } else {
            setNotificationMessage(
              'quantity-restrictions.generic-create-error',
              NotificationType.Error
            );
          }
        }
      },
    });
  }
  return (
    <>
      {isLoading && <LoadingIndicator />}
      <QuantityRestrictionForm
        initialFormValues={initialCreateQuantityRestrictionFormValues()}
        handleSubmit={handleSubmit}
        buttons={<CreateEntityFormButtons />}
      />
    </>
  );
}
function initialCreateQuantityRestrictionFormValues(): QuantityRestrictionFormValues {
  return {
    renterIds: [],
    contractorIds: [],
    itemTypeIds: [],
  };
}
