import { useEffect } from 'react';

import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { AlertSnackbar } from '../../library/snackbar/AlertSnackbar';

function SimpleNotification() {
  const { notificationMessage, notificationType, setNotificationMessage } =
    useNotificationMessageContext();
  const onClose = () => {
    setNotificationMessage(undefined, undefined);
  };
  const showNotification = notificationMessage ? true : false;
  const severity = notificationType || NotificationType.Error;
  useEffect(() => {
    if (showNotification) {
      const timer = setTimeout(onClose, 5000);
      return () => clearTimeout(timer);
    }
  }, [showNotification, onClose]);
  return (
    <>
      {showNotification && (
        <AlertSnackbar
          severity={severity}
          message={notificationMessage as string}
          onClose={onClose}
        />
      )}
    </>
  );
}

export { SimpleNotification };
