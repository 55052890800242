import { useIntl } from 'react-intl';

import { useSystems } from '../../../api/hooks/useSystemsApi';
import { Modal } from '../../../components/modal/Modal';
import { System } from '../../../types/systems/System';

import { CreateUserFormData, CreateUserForm } from './CreateUserForm';

export interface UserFormModalProps {
  isUserBySystemModal: boolean;
  onClose: () => void;
  onSubmit: (data: CreateUserFormData) => void;
}

function CreateUserModal({ isUserBySystemModal, onClose, onSubmit }: UserFormModalProps) {
  const { systems } = useSystems();
  const intl = useIntl();
  const header = (
    <h1 className="uib-modal__title">
      {intl.formatMessage({
        id: isUserBySystemModal ? 'users.addUser' : 'users.create',
      })}
    </h1>
  );

  function toOptionItem(rrSystem: System) {
    return {
      value: rrSystem._id,
      label: rrSystem.client,
    };
  }

  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      content={
        <CreateUserForm
          onCancel={onClose}
          onSubmit={onSubmit}
          rrSystems={systems}
          rrSystemOptions={systems.map(toOptionItem)}
          isUserBySystemForm={isUserBySystemModal}
        />
      }
      modalHasClosed={onClose}
      additionalClassNames="overflow-visible"
    />
  );
}

export { CreateUserModal };
