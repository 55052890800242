import { useIntl } from 'react-intl';

import { useRentalHistory, useGetRentalHistoryExcel } from '../../api/hooks/useReportsApi';

import { HistoryPageLayout } from './components/HistoryPageLayout';
import { RentalHistoryTable } from './RentalHistoryTable';

function RentalHistory() {
  const intl = useIntl();
  const {
    isLoading: isRentalHistoryLoading,
    isError: isRentalHistoryError,
    rentalHistory,
    remove: removeRentalHistory,
  } = useRentalHistory();
  const {
    rentingHistoryExcel,
    headers,
    isLoading: isExcelLoading,
    isSuccess: isExcelSuccess,
    isError: isExcelError,
    refetch,
    remove: removeExcel,
  } = useGetRentalHistoryExcel();

  return (
    <HistoryPageLayout
      pageName={intl.formatMessage({ id: 'reports.rentalHistory' })}
      pageNameSingular={intl.formatMessage({ id: 'reports.rentalHistory' })}
      historyTable={<RentalHistoryTable rentalHistory={rentalHistory} />}
      isHistoryLoading={isRentalHistoryLoading}
      isHistoryError={isRentalHistoryError}
      historyExcel={rentingHistoryExcel}
      excelHeaders={headers}
      isExcelLoading={isExcelLoading}
      isExcelSuccess={isExcelSuccess}
      isExcelError={isExcelError}
      fetchExcel={refetch}
      removeExcel={removeExcel}
      cleanup={removeRentalHistory}
    />
  );
}

export { RentalHistory };
