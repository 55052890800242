import { useEffect, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useUploadRenters } from '../../../api/hooks/useRentingApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { CancelButton } from '../../library/buttons/CancelButton';
import { ChooseFileButton } from '../../library/buttons/ChooseFileButton';
import { UploadFileButton } from '../../library/buttons/UploadFileButton';
import { Grid } from '../../library/containers/Grid';
import { Dialog } from '../../library/dialog/Dialog';
import { Form } from '../../library/form/Form';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';

import { renderUploadError } from './ErrorHandler';
import { RenterTemplateDownloadLink } from './RenterTemplateDownloadLink';
import { UploadErrorList } from './UploadErrorList';

export function ImportRentersDialog() {
  const intl = useIntl();
  const { openImportDialog } = useCrudPageUiStateContext();

  return (
    <>
      {openImportDialog && (
        <Dialog
          id={'ImportRenterDialog'}
          title={intl.formatMessage({ id: 'renters.uploadHeader' })}
          isOpen={openImportDialog}
          content={<ImportRentersDialogContent />}
        />
      )}
    </>
  );
}
function ImportRentersDialogContent() {
  const intl = useIntl();
  const { setOpenImportDialog } = useCrudPageUiStateContext();
  const {
    mutateAsync: uploadRenters,
    status: uploadRentersStatus,
    error: uploadError,
    isLoading: uploadIsLoading,
  } = useUploadRenters();
  const { setNotificationMessage } = useNotificationMessageContext();
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  useEffect(() => {
    if (uploadRentersStatus === 'success') {
      setNotificationMessage('general.xlsx.upload.sucess', NotificationType.Success);
      setOpenImportDialog(false);
    } else if (uploadRentersStatus === 'error') {
      const errors = renderUploadError(uploadError, intl);
      setUploadErrors(errors);
    }
  }, [uploadRentersStatus]);
  return (
    <>
      {uploadIsLoading && <LoadingIndicator />}
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ChooseRentersFileButton />
          </Grid>
          <Grid item xs={12}>
            <UploadErrorList apiErrors={uploadErrors} />
          </Grid>
          <Grid item xs={12}>
            <ImportRentersButtons />
          </Grid>
        </Grid>
      </Form>
    </>
  );
  function handleSubmit(formValues: FieldValues) {
    const formData = new FormData();
    formData.append('file', formValues.file, formValues.file.name);
    uploadRenters(formData).catch(() => {
      setNotificationMessage('general.xlsx.upload.error', NotificationType.Error);
    });
  }
}

function ImportRentersButtons() {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <RenterTemplateDownloadLink />
      <div style={{ display: 'flex', gap: '10px' }}>
        <CloseImportDialogButton />
        <UploadRentersFileButton />
      </div>
    </div>
  );
}
function UploadRentersFileButton() {
  return <UploadFileButton />;
}
function CloseImportDialogButton() {
  const { setOpenImportDialog } = useCrudPageUiStateContext();
  const { reset } = useFormContext();
  return (
    <CancelButton
      onClick={() => {
        setOpenImportDialog(false);
        reset();
      }}
    />
  );
}
function ChooseRentersFileButton() {
  const { setValue, setError } = useFormContext();
  const intl = useIntl();
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {intl.formatMessage({ id: 'renters.uploadData' })}
      <ChooseFileButton
        handleFiles={files => {
          setValue('file', files[0], { shouldDirty: true, shouldValidate: true });
        }}
        handleError={label => {
          setError('selectedFile', {
            type: 'filetype',
            message: intl.formatMessage({ id: label }),
          });
        }}
      />
    </div>
  );
}
