import { useIntl } from 'react-intl';

import { Modal } from '../../../components/modal/Modal';
import Button from '../../../components/odx/Button';
import { ButtonVariant } from '../../../types/components/Button';
import { Station } from '../../../types/types';

interface TerminalPairingModalProps {
  station: Station;
  onClose: () => void;
  terminalPairingCode: string;
  unpairTerminal: (stationId: string) => void;
}

function TerminalPairingModal({
  station,
  onClose,
  terminalPairingCode,
  unpairTerminal,
}: TerminalPairingModalProps) {
  const intl = useIntl();
  const isPairing = !station.terminalId;

  function modalHeader(stationLocation: string) {
    if (isPairing) {
      return (
        <h1 className="uib-modal__title">
          {intl.formatMessage({ id: 'stations.terminal.pairing.Header' }, { stationLocation })}
        </h1>
      );
    }
    return (
      <h1 className="uib-modal__title">
        {intl.formatMessage({ id: 'stations.terminal.unpairing.Header' })}
      </h1>
    );
  }

  function modalContent() {
    if (isPairing) {
      return (
        <div>
          <p
            onClick={() => {
              navigator.clipboard.writeText(terminalPairingCode);
            }}
          >
            {intl.formatMessage({ id: 'stations.terminal.pairingCode' }, { terminalPairingCode })}
          </p>
          <br />
          <p>{intl.formatMessage({ id: 'stations.terminal.pairingCode.time' })}</p>
        </div>
      );
    }
    return (
      <div>
        <p>{intl.formatMessage({ id: 'stations.terminal.unpairingConfirm' })}</p>
      </div>
    );
  }

  function getActionButton() {
    return (
      <Button
        onClick={() => {
          onClose();
          unpairTerminal(station._id);
        }}
        variant={ButtonVariant.DANGER}
        id={'confirmTerminalUnpair'}
      >
        {intl.formatMessage({ id: 'general.yes' })}
      </Button>
    );
  }

  return (
    <Modal
      isOpen={true}
      header={modalHeader(station.location)}
      content={modalContent()}
      actionButton={!isPairing && getActionButton()}
      closeButtonLabel={
        isPairing
          ? intl.formatMessage({ id: 'general.close' })
          : intl.formatMessage({ id: 'general.no' })
      }
      modalHasClosed={() => onClose()}
    />
  );
}

export { TerminalPairingModal };
