import { DataTableRow } from '../../components/library/table/DataTable';

export interface SystemRow extends DataTableRow {
  id: string;
  client: string;
  country: string;
  city: string;
  site: string;
  notificationLanguage: string;
  adminEmails: string[];
  status: string;
  gdcTenantId: string;
}

export enum SystemRowFieldName {
  client = 'client',
  country = 'country',
  city = 'city',
  site = 'site',
  notificationLanguage = 'notificationLanguage',
  adminEmails = 'adminEmails',
  status = 'status',
  gdcTenantId = 'gdcTenantId',
}
