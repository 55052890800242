import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { PageHeader } from '../common/PageHeader';

export interface SystemsPageHeaderProps {
  children?: ReactNode;
}

export function SystemsPageHeader({ children }: SystemsPageHeaderProps) {
  const intl = useIntl();
  return <PageHeader breadCrumbs={PageBreadCrumbs(intl)}>{children}</PageHeader>;
}
function PageBreadCrumbs(intl: IntlShape) {
  return [
    {
      label: intl.formatMessage({ id: 'systems.self' }),
      route: '#',
    },
  ];
}
