import { MenuItem, Select } from '@mui/material';

interface DropDownProps {
  id: string;
  dropDownItems: DropDownItem[];
  value?: string;
  onChange: (value: string) => void;
  style?: any;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}
export interface DropDownItem {
  value: string;
  label: string;
}

export function DropDown({
  id,
  dropDownItems,
  value,
  onChange,
  style,
  required,
  disabled,
  fullWidth,
}: DropDownProps) {
  return (
    <>
      <Select
        id={id}
        fullWidth={fullWidth}
        value={value}
        onChange={(event: any) => {
          onChange(event.target.value);
        }}
        required={required}
        disabled={disabled}
        style={style}
      >
        {dropDownItems.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
