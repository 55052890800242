import { useIntl } from 'react-intl';

import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { Dialog } from '../../library/dialog/Dialog';

import { CreateAlertRuleForm } from './CreateAlertRuleForm';

function CreateAlertRuleDialog() {
  const intl = useIntl();
  const { openCreateDialog } = useCrudPageUiStateContext();
  return (
    <>
      {openCreateDialog && (
        <Dialog
          id={'CreateAlertRuleDialog'}
          isOpen={openCreateDialog}
          title={intl.formatMessage({ id: 'alert-rules.create' })}
          content={<CreateAlertRuleForm />}
        />
      )}
    </>
  );
}

export { CreateAlertRuleDialog };
