import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { MultiDeleteIconButton } from '../../library/buttons/MultiDeleteIconButton';

export function DeleteEntitiesButton() {
  const { setOpenDeleteDialog, entitieIdsToDelete: entitiesTodelete } = useCrudPageUiStateContext();
  return (
    <MultiDeleteIconButton
      id={'deleteEntitiesButton'}
      onClick={() => {
        setOpenDeleteDialog(true);
      }}
      disabled={entitiesTodelete && entitiesTodelete.length !== 0 ? false : true}
    />
  );
}
