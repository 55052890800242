import { Alert, Slide, SlideProps, Snackbar } from '@mui/material';
import { useIntl } from 'react-intl';

import { NotificationType } from '../../../context/notificationMessageContext';
import { CloseIconButton } from '../buttons/CloseIconButton';

interface AlertSnackbarProps {
  severity: NotificationType;
  onClose: () => void;
  message: string;
}

function AlertSnackbar({ severity, onClose, message }: AlertSnackbarProps) {
  const intl = useIntl();
  return (
    <Snackbar open={true} TransitionComponent={SlideTransition}>
      <Alert
        severity={severity}
        action={<CloseIconButton onClick={onClose} />}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {intl.formatMessage({ id: message })}
      </Alert>
    </Snackbar>
  );
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

export { AlertSnackbar };
