import { Dispatch } from 'react';
import { useIntl } from 'react-intl';

import { OptionItem } from '../../types/types';
import { UploadForm, UploadFormData } from '../form/UploadForm';
import { Tooltip } from '../general/Tooltip';
import Icon from '../library/icons/Icon';
import { LoadingIndicator } from '../library/loading/LoadingIndicator';
import Button from '../odx/Button';

import { Modal } from './Modal';

export interface UploadModalProps {
  entityToUpload: string;
  dropDownOptions?: OptionItem[];
  dropDownLabel?: string;
  dropDownId?: string;
  uploadDropdownDefaultValue?: string;
  setUploadDropdownDefaultValue?: Dispatch<React.SetStateAction<string | undefined>>;
  isLoadingDropdownOptions?: boolean;
  uploadStatus: 'error' | 'idle' | 'loading' | 'success';
  uploadDataErrors?: Record<string, any>;
  uploadedAmount?: string;
  submitFormData: (data: UploadFormData) => void;
  onClose: () => void;
  resetUpload: () => void;
  getUploadTemplate: () => void;
  setSelectedDropdownIdForUpload?: Dispatch<React.SetStateAction<string | undefined>>;
}

function UploadModal({
  entityToUpload,
  dropDownOptions,
  dropDownLabel,
  dropDownId,
  uploadDropdownDefaultValue,
  setUploadDropdownDefaultValue,
  isLoadingDropdownOptions,
  uploadStatus,
  uploadDataErrors,
  uploadedAmount,
  submitFormData,
  onClose,
  resetUpload,
  getUploadTemplate,
  setSelectedDropdownIdForUpload,
}: UploadModalProps) {
  const intl = useIntl();

  function getModalContent() {
    switch (uploadStatus) {
      case 'loading':
        return {
          header: (
            <h1 className="uib-modal__title">
              {intl.formatMessage({ id: `${entityToUpload}.uploadHeader` })}
            </h1>
          ),
          component: <LoadingIndicator />,
        };
      case 'success':
        return {
          header: (
            <>
              <h1 className="uib-modal__title">
                <div className="icon-modal-tittle">
                  <Icon iconName={'check'} additionalClasses={'odx-blue-text'} />
                  <p>{intl.formatMessage({ id: 'general.uploadSuccessHeader' })}</p>
                </div>
              </h1>
            </>
          ),
          component: (
            <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                  }}
                >
                  {intl.formatMessage(
                    {
                      id: `${entityToUpload}.uploadSuccessMessage`,
                    },
                    {
                      uploadedAmount: uploadedAmount,
                      entityUploaded: entityToUpload,
                    }
                  )}
                </span>
                <Tooltip
                  tooltipMessage={intl.formatMessage({
                    id: `${entityToUpload}.uploadSuccessTooltip`,
                  })}
                  toolTipPos="down"
                />
              </div>
              <div className="uib-button-group default-button-group">
                <div className="buttons-main-cluster">
                  <Button
                    type="reset"
                    id={'abortButton'}
                    onClick={onClose}
                    additionalClasses="uib-modal__footer"
                  >
                    {intl.formatMessage({ id: `general.close` })}
                  </Button>
                </div>
              </div>
            </>
          ),
        };
      default:
        return {
          header: (
            <h1 className="uib-modal__title">
              {intl.formatMessage({ id: `${entityToUpload}.uploadHeader` })}
            </h1>
          ),
          component: isLoadingDropdownOptions ? (
            <LoadingIndicator />
          ) : (
            <UploadForm
              entityToUpload={entityToUpload}
              onCancel={onClose}
              onSubmit={submitFormData}
              uploadDataErrors={uploadDataErrors}
              resetUpload={resetUpload}
              dropDownOptions={dropDownOptions}
              dropDownLabel={dropDownLabel}
              dropDownId={dropDownId}
              uploadDropdownDefaultValue={uploadDropdownDefaultValue}
              setUploadDropdownDefaultValue={setUploadDropdownDefaultValue}
              getUploadTemplate={getUploadTemplate}
              setSelectedDropdownIdForUpload={setSelectedDropdownIdForUpload}
            />
          ),
        };
    }
  }

  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={getModalContent().header}
      modalHasClosed={onClose}
      content={getModalContent().component}
      additionalClassNames="overflow-visible"
    />
  );
}

export { UploadModal };
