import { Button } from '@mui/material';
import { useIntl } from 'react-intl';

interface CancelButtonProps {
  onClick: () => void;
}

export function CancelButton({ onClick }: CancelButtonProps) {
  const intl = useIntl();
  return (
    <Button id={'closeBtn'} onClick={onClick}>
      {intl.formatMessage({ id: 'general.cancel' })}
    </Button>
  );
}
