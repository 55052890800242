import { useIntl } from 'react-intl';

import { FormTextField } from '../../../library/form/FormTextField';

export function SystemCountryInput() {
  const intl = useIntl();
  return (
    <FormTextField
      id={'SystemCountryInput'}
      label={intl.formatMessage({ id: 'systems.country' })}
      required={true}
      disabled={false}
      name={'country'}
    />
  );
}
