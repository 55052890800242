import saveAs from 'file-saver';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useUploadTemplate } from '../../../api/hooks/useRentingApi';
import { odxAirBlue } from '../../../context/themeContext';
import { useRequestHeaders } from '../../../hooks/useRequestHeaders';
import { DownloadIcon } from '../../library/icons/DownloadIcon';

export function RenterTemplateDownloadLink() {
  const intl = useIntl();
  const {
    headers,
    isSuccess,
    refetch: getUploadTemplate,
    uploadTemplate,
    remove: removeUploadTemplate,
  } = useUploadTemplate();
  const { contentDispositionFileName } = useRequestHeaders();
  useEffect(() => {
    if (uploadTemplate) {
      const fileName = contentDispositionFileName(headers);
      saveAs(new Blob([uploadTemplate]), fileName);
    }
    return () => {
      removeUploadTemplate();
    };
  }, [isSuccess]);
  return (
    <>
      <div
        style={{
          color: odxAirBlue,
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          getUploadTemplate();
        }}
      >
        <DownloadIcon />
        {intl.formatMessage({ id: 'general.downloadTemplate' })}
      </div>
    </>
  );
}
