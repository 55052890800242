import { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useCreateItemType,
  useDeleteItemType,
  useItemTypes,
  useEditItemType,
} from '../../../api/hooks/useRentingApi';
import { TablePageLayout } from '../../../components/table/TablePageLayout';
import { ItemType } from '../../../types/item-types/ItemType';
import { generateEditRequest } from '../../../utils/formData';

import { ItemTypeDetailView } from './ItemTypeDetailView';
import { ItemTypesTable } from './ItemTypesTable';
import { DraftItemTypeModal } from './manage/DraftItemTypeModal';
import { ItemTypeFormData } from './manage/ItemTypeForm';

function ItemTypesPage() {
  const intl = useIntl();

  const [isCreatingItemType, toggleIsCreatingItemType] = useState<boolean>(false);
  const [selectedItemTypeForDetailView, setSelectedItemTypeForDetailView] = useState<
    ItemType | undefined
  >();
  const [selectedItemTypeForEditing, setSelectedItemTypeForEditing] = useState<
    ItemType | undefined
  >();
  const [editRequestError, setEditRequestError] = useState<unknown>(undefined);
  const [selectedItemTypeForDeletion, setSelectedItemTypeForDeletion] = useState<
    ItemType | undefined
  >();
  const { isLoading, isError, error: getItemTypesError, itemTypes, remove } = useItemTypes();
  const {
    mutateAsync: createItemType,
    isError: isCreateItemTypeError,
    error: createItemTypeErrorResponse,
    reset: resetCreateItemType,
  } = useCreateItemType();
  const {
    mutateAsync: editItemType,
    isError: isEditItemTypeError,
    error: editItemTypeErrorResponse,
    reset: resetEditItemType,
  } = useEditItemType();
  const {
    mutateAsync: deleteItemType,
    isError: isDeleteItemTypeError,
    error: deleteItemTypeError,
    reset: resetDeleteItemType,
  } = useDeleteItemType(selectedItemTypeForDeletion?._id ?? '');

  const onCloseDraftItemTypeModal = () => {
    toggleIsCreatingItemType(false);
    setSelectedItemTypeForEditing(undefined);
  };

  const onSubmitItemTypeForm = (data: ItemTypeFormData) => {
    if (isCreatingItemType) onSubmitCreateItemType(data);
    if (selectedItemTypeForEditing) onSubmitEditItemType(data);
  };

  const onSubmitCreateItemType = async (data: ItemTypeFormData) => {
    createItemType(data).finally(() => {
      toggleIsCreatingItemType(false);
    });
  };

  const onSubmitEditItemType = async (data: ItemTypeFormData) => {
    const editRequest = generateEditRequest(data);

    if (editRequest) {
      editItemType(editRequest).finally(() => {
        setSelectedItemTypeForEditing(undefined);
      });
    } else {
      setEditRequestError(intl.formatMessage({ id: 'items.edit.error' }));
      setSelectedItemTypeForEditing(undefined);
    }
  };

  const onConfirmDeleteItemType = () => {
    deleteItemType().finally(() => setSelectedItemTypeForDeletion(undefined));
  };

  return (
    <TablePageLayout
      isContentLoading={isLoading}
      isContentError={isError}
      contentError={getItemTypesError}
      pageName={intl.formatMessage({ id: 'item-types.self' })}
      pageNameSingular={intl.formatMessage({ id: 'item-types.singular' })}
      table={
        <ItemTypesTable
          itemTypes={itemTypes}
          onSelectItemTypeForEditing={setSelectedItemTypeForEditing}
          onSelectItemTypeForDetailView={setSelectedItemTypeForDetailView}
          onSelectItemTypeForDeletion={setSelectedItemTypeForDeletion}
        />
      }
      hasDraftModal={true}
      openCreateModal={() => toggleIsCreatingItemType(true)}
      isDraftModalOpen={isCreatingItemType || !!selectedItemTypeForEditing}
      draftModal={
        <DraftItemTypeModal
          itemType={selectedItemTypeForEditing}
          onClose={onCloseDraftItemTypeModal}
          submitFormData={onSubmitItemTypeForm}
        />
      }
      isCreateModalError={isCreateItemTypeError}
      createModalError={createItemTypeErrorResponse}
      isEditModalError={isEditItemTypeError || !!editRequestError}
      editModalError={editItemTypeErrorResponse || editRequestError}
      isDetailViewOpen={!!selectedItemTypeForDetailView}
      detailView={
        selectedItemTypeForDetailView && (
          <ItemTypeDetailView
            itemTypeId={selectedItemTypeForDetailView?._id}
            onClose={() => setSelectedItemTypeForDetailView(undefined)}
          />
        )
      }
      isDeleteModalOpen={!!selectedItemTypeForDeletion}
      onCloseDelete={setSelectedItemTypeForDeletion}
      onConfirmDelete={onConfirmDeleteItemType}
      isDeleteModalError={isDeleteItemTypeError}
      confirmDeleteMessage={
        <p>
          {intl.formatMessage(
            { id: 'item-types.delete.confirm' },
            {
              itemTypeName: selectedItemTypeForDeletion?.name,
            }
          )}
        </p>
      }
      deleteModalError={deleteItemTypeError}
      cleanup={remove}
      cleanupErrors={() => {
        resetCreateItemType();
        resetDeleteItemType();
        resetEditItemType();
      }}
    />
  );
}

export { ItemTypesPage };
