import { useEffect, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';

import { DropdownProps, OptionItem } from '../../types/types';
import { sortLabelsFromOptions } from '../../utils/formData';

import { DropdownIndicator, selectedOption } from './Dropdown';

interface LabeledDropdownProps extends DropdownProps {
  placeholder?: string;
  setValue: UseFormSetValue<any>;
  sortOptions?: boolean;
  onChange?: (value: string) => void;
  selectedValue?: string;
  resetSelectedValue?: boolean;
  dataTestId?: string;
  additionalClassName?: string;
}

function LabeledDropdown({
  options,
  id,
  label,
  required = true,
  placeholder,
  disabled = false,
  setValue,
  sortOptions = true,
  onChange,
  selectedValue,
  dataTestId,
  resetSelectedValue,
  additionalClassName,
}: LabeledDropdownProps) {
  const maxMenuHeight = 175;
  const selectRef = useRef<any>(undefined);

  const [inputValue, setInputValue] = useState<OptionItem | undefined>(() => {
    if (placeholder) return undefined;
    if (!selectedValue) {
      return (
        selectedOption(options, 'none') ||
        (sortOptions ? sortLabelsFromOptions(options)[0] : options[0])
      );
    }
    return selectedOption(options, selectedValue);
  });

  const onDropdownValueChanged = (e: SingleValue<OptionItem> | null) => {
    e && onChange && onChange(e.value);
  };

  useEffect(() => {
    if (selectedValue) {
      setValue(id, selectedValue);
      setInputValue(selectedOption(options, selectedValue));
      return;
    }

    selectRef.current.clearValue();
  }, [selectedValue, resetSelectedValue]);

  return (
    <div
      className={`uib-form__item ${
        required ? `uib-form-field--required` : null
      } ${additionalClassName}`}
    >
      <label htmlFor={id} className="uib-form__label">
        {label}
      </label>
      <div data-testid={dataTestId} className="labeledDropdown">
        <Select
          ref={selectRef}
          placeholder={placeholder}
          id={id}
          options={sortOptions ? sortLabelsFromOptions(options) : options}
          classNamePrefix="labeledDropdown"
          components={{ DropdownIndicator }}
          isDisabled={disabled}
          maxMenuHeight={maxMenuHeight}
          value={inputValue}
          onChange={e => {
            onDropdownValueChanged(e);
            setValue(id, e?.value, { shouldDirty: true, shouldValidate: true });
            setInputValue(selectedOption(options, e?.value));
          }}
        />
      </div>
    </div>
  );
}
export { LabeledDropdown };
