import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DefaultButtonGroup } from '../../../components/form/DefaultButtonGroup';
import { LabeledDropdown } from '../../../components/form/LabeledDropdown';
import { LabeledInput } from '../../../components/form/LabeledInput';
import { MultiSelectDropdown } from '../../../components/form/MultiSelectDropdown';
import { System } from '../../../types/systems/System';
import { OptionItem, UserGlobalRole, UserRole } from '../../../types/types';

export interface UserFormProps {
  onCancel: () => void;
  onSubmit: (data: CreateUserFormData) => void;
  rrSystems: System[];
  rrSystemOptions: OptionItem[];
  isUserBySystemForm: boolean;
}
interface UserRoleInfo {
  _rrSystemId: string;
  role: UserRole;
}

export interface CreateUserFormData {
  email: string;
  rrSystemOptions?: OptionItem[];
  roles?: UserRoleInfo[];
  role: UserRole;
  globalRole: UserGlobalRole[];
}

function CreateUserForm({
  onCancel,
  onSubmit,
  rrSystems,
  rrSystemOptions,
  isUserBySystemForm,
}: UserFormProps) {
  const EMAIL_KEY = 'general.email';
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting, isSubmitSuccessful },
    control,
    reset,
    getValues,
    setValue,
  } = useForm<CreateUserFormData>({
    mode: 'onChange',
  });
  const { remove, replace } = useFieldArray({
    control,
    name: 'roles',
  });

  const selectedRRSystemsCurrentValue = getValues('rrSystemOptions');
  const selectedRolesCurrentValue = getValues('roles');

  const handleRrSystemOptionsOptionsChange = (listRrSystemOptions: OptionItem[]) => {
    if (!selectedRolesCurrentValue) return;
    const alignedRolesList: UserRoleInfo[] = selectedRolesCurrentValue.filter(r =>
      listRrSystemOptions.some(rrSystemOption => rrSystemOption.value === r._rrSystemId)
    );
    remove();
    replace(alignedRolesList);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email: '',
        rrSystemOptions: [],
        roles: [],
        role: UserRole.READONLY,
      });
    }
  }, [isSubmitSuccessful]);

  const rolesOptions: OptionItem[] = [
    {
      value: UserRole.READONLY,
      label: intl.formatMessage({ id: 'users.readOnly' }),
    },
    {
      value: UserRole.ADMIN,
      label: intl.formatMessage({ id: 'users.admin' }),
    },
  ];

  const globalRolesOptions: OptionItem[] = [
    {
      value: UserGlobalRole.NONE,
      label: UserGlobalRole.NONE,
    },
    {
      value: UserGlobalRole.SERVICE_TECHNICIAN,
      label: UserGlobalRole.SERVICE_TECHNICIAN,
    },
    {
      value: UserGlobalRole.GLOBAL_ADMIN,
      label: UserGlobalRole.GLOBAL_ADMIN,
    },
  ];

  function form() {
    return (
      <form
        onSubmit={handleSubmit(data => {
          delete data.rrSystemOptions;
          if (isUserBySystemForm) delete data.roles;
          onSubmit(data);
        })}
      >
        {formMainSection()}
        {selectedRRSystemsCurrentValue &&
          selectedRRSystemsCurrentValue.length > 0 &&
          formSubSection(selectedRRSystemsCurrentValue)}
        {formFooter()}
      </form>
    );
  }

  function formMainSection() {
    return (
      <div className="uib-modal__content modal-form double-col">
        <LabeledInput
          id="email"
          label={intl.formatMessage({ id: EMAIL_KEY })}
          placeholder={intl.formatMessage({ id: EMAIL_KEY })}
          type="email"
          props={{ ...register('email', { required: false }) }}
        />
        {isUserBySystemForm ? (
          <LabeledDropdown
            label={intl.formatMessage({ id: 'users.role' })}
            id="role"
            options={rolesOptions}
            required={true}
            placeholder={intl.formatMessage({
              id: 'general.pleaseChooseEllipsis',
            })}
            setValue={setValue}
            props={{ ...register('role', { required: false }) }}
          />
        ) : (
          <>
            <LabeledDropdown
              setValue={setValue}
              id={'globalRole'}
              options={globalRolesOptions}
              label={intl.formatMessage({ id: 'users.globalRole' })}
              props={{ ...register('globalRole', { required: true }) }}
            />
            <MultiSelectDropdown
              label={intl.formatMessage({ id: 'users.addToRrSystem' })}
              id="rrSystemOptions"
              options={rrSystemOptions}
              required={true}
              showPlaceholder={true}
              control={control}
              props={{
                ...register('rrSystemOptions', {
                  required: false,
                  onChange: e => handleRrSystemOptionsOptionsChange(e.target.value),
                }),
              }}
            />
          </>
        )}
      </div>
    );
  }

  function formSubSection(selectedRRSystemsCurrentValue: OptionItem[]) {
    return (
      <div className="uib-modal__content modal-form form-sub-section">
        <table>
          <tbody>
            {selectedRRSystemsCurrentValue.map((selectedOption, index) => {
              const rrSystem = rrSystems.find(s => s._id === selectedOption.value);
              if (rrSystem) return roleInputSection(rrSystem, index);
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function roleInputSection(rrSystem: System, index: number) {
    return (
      <tr key={index} className="sub-section-row">
        <td className="uib-form__label">
          <label className="uib-form__label" htmlFor={rrSystem._id}>
            {rrSystem.client} {intl.formatMessage({ id: 'users.role' })}
          </label>
          <input
            value={rrSystem._id}
            {...register(`roles.${index}._rrSystemId` as const)}
            style={{ display: 'none' }}
          />
        </td>
        <td>
          <LabeledDropdown
            label=""
            id={`roles.${index}.role`}
            options={rolesOptions}
            placeholder={intl.formatMessage({
              id: 'general.pleaseChooseEllipsis',
            })}
            setValue={setValue}
            additionalClassName="width-100"
            props={{
              ...register(`roles.${index}.role` as const, {
                required: true,
              }),
            }}
          />
        </td>
      </tr>
    );
  }

  function formFooter() {
    return (
      <div className="uib-modal__footer">
        <DefaultButtonGroup
          isSubmitDisabled={isSubmitting || !isDirty || !isValid}
          onCancel={onCancel}
        />
      </div>
    );
  }

  return form();
}
export { CreateUserForm };
