import { ReactNode } from 'react';

interface TableButtonsProps {
  children: ReactNode[];
}

interface TableButtonProps {
  children: ReactNode;
}

function TableButtons({ children }: TableButtonsProps) {
  return (
    <div className={`action-button-group with-${children.length}-buttons`}>
      {children.map((button, index) => {
        return (
          <div key={index} className="action-button-container">
            {button}
          </div>
        );
      })}
    </div>
  );
}

function TableButton({ children }: TableButtonProps) {
  return (
    <div className="action-button-group with-1-button">
      <div className="action-button-container">{children}</div>
    </div>
  );
}

export { TableButtons, TableButton };
