import { useIntl } from 'react-intl';

import { FormTextField } from '../../../library/form/FormTextField';

export function RenterPhoneNumberInput() {
  const intl = useIntl();
  return (
    <FormTextField
      id={'RenterPhoneNumberInput'}
      label={intl.formatMessage({ id: 'renters.phone' })}
      required={false}
      disabled={false}
      name={'phone'}
      type={'phone'}
    />
  );
}
