import debounceFn from 'debounce-fn';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { usePairIotAdapter } from '../../../api/hooks/useStationApi';
import { LoadingIndicator } from '../../../components/library/loading/LoadingIndicator';
import { RequestErrorResponse } from '../../../components/modal/ErrorModal';
import { Modal } from '../../../components/modal/Modal';
import Button from '../../../components/odx/Button';
import { ButtonVariant } from '../../../types/components/Button';
import { Station } from '../../../types/types';

interface IotPairingModalProps {
  station: Station;
  onClose: () => void;
}

enum ModalViewState {
  ENTER_ADAPTER_ID,
  CONFIRM,
  SUCCESS,
}

function IotPairingModal({ station, onClose }: IotPairingModalProps) {
  const intl = useIntl();
  const [viewState, setViewState] = useState<ModalViewState>(ModalViewState.ENTER_ADAPTER_ID);
  const [iotAdapterId, setIotAdapterId] = useState<string>('');
  const {
    mutate,
    isLoading: isMutationLoading,
    isSuccess: isMutationSuccess,
    isError: isMutationError,
    error: errorMutation,
    data: mutationData,
    reset,
  } = usePairIotAdapter();

  const debouncedMutate = useMemo(() => debounceFn(mutate, { wait: 300 }), [mutate]);

  useEffect(() => {
    reset();
  }, []);

  const sendAdapterId = (iotAdapterId: string) => {
    debouncedMutate({ stationId: station._id, iotAdapterId });
  };

  const confirmIotPairing = () => {
    if (viewState === ModalViewState.ENTER_ADAPTER_ID) {
      setViewState(ModalViewState.CONFIRM);
    } else if (ModalViewState.CONFIRM) {
      setViewState(ModalViewState.SUCCESS);
      sendAdapterId(iotAdapterId);
    }
  };

  const getUserFeedback = () => {
    if (isMutationLoading) {
      return <LoadingIndicator />;
    }
    if (isMutationSuccess && mutationData) {
      return (
        mutationData &&
        // @ts-ignore
        mutationData.data &&
        // @ts-ignore
        mutationData.data.messages &&
        // @ts-ignore
        mutationData.data.messages[0]
      );
    }
    if (isMutationError && errorMutation) {
      const { response } = errorMutation as RequestErrorResponse;
      return response && response.data && response.data.messages && response.data.messages[0];
    }
  };

  const getModalContent = (
    stationLocation: string,
    iotAdapterId: string,
    setIotAdapterId: Dispatch<SetStateAction<string>>
  ) => {
    if (viewState === ModalViewState.ENTER_ADAPTER_ID) {
      return (
        <div className="uib-form-field">
          <label className="uib-form-field__label" htmlFor="adapterId">
            {intl.formatMessage({ id: 'stations.iot.adapterId' })}:
          </label>
          <div className="uib-textfield">
            <input
              className="uib-textfield__input"
              type="text"
              placeholder={intl.formatMessage({
                id: 'stations.iot.adapterId.prompt',
              })}
              value={iotAdapterId}
              name="adapterId"
              id="adapterId"
              onChange={e => setIotAdapterId(e.target.value)}
            />
            <span className="uib-textfield__validation-icon" />
          </div>
        </div>
      );
    } else if (viewState === ModalViewState.CONFIRM) {
      return (
        <p>
          {intl.formatMessage(
            { id: 'stations.iot.pairingConfirm' },
            { adapterId: iotAdapterId, stationLocation: stationLocation }
          )}
        </p>
      );
    }
    return <>{getUserFeedback()}</>;
  };

  const isValidIotAdapterId = (): boolean => !!iotAdapterId && iotAdapterId.length > 0;

  const getActionButton = () => {
    const label =
      viewState === ModalViewState.CONFIRM
        ? intl.formatMessage({ id: 'general.yes' })
        : intl.formatMessage({ id: 'stations.pair' });
    return (
      <Button
        id={'confirmIotPairing'}
        variant={ButtonVariant.PRIMARY}
        disabled={!isValidIotAdapterId()}
        onClick={confirmIotPairing}
      >
        {label}
      </Button>
    );
  };

  const isActionButtonRequired = () => viewState !== ModalViewState.SUCCESS;

  return (
    <Modal
      isOpen={true}
      header={
        <h1 className="uib-modal__title">
          {intl.formatMessage({ id: 'stations.iot.pairing.Header' })}
        </h1>
      }
      content={getModalContent(station.location, iotAdapterId, setIotAdapterId)}
      closeButtonLabel={
        viewState === ModalViewState.SUCCESS
          ? intl.formatMessage({ id: 'general.close' })
          : viewState === ModalViewState.CONFIRM
            ? intl.formatMessage({ id: 'general.no' })
            : intl.formatMessage({ id: 'general.cancel' })
      }
      modalHasClosed={onClose}
      actionButton={isActionButtonRequired() && getActionButton()}
    />
  );
}

export { IotPairingModal };
