import { FieldErrors } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { RawItemTypeData } from '../../modules/renting/item-types/manage/ItemTypeForm';
import { ItemType } from '../../types/item-types/ItemType';

interface LabeledInputProps {
  label: string;
  itemType?: ItemType;
  isEditForm: boolean;
  showImage: boolean;
  props?: unknown;
  errors?: FieldErrors<RawItemTypeData>;
  errorMessage?: string;
  imageName?: string | boolean;
}

function LabeledImageInput({
  label,
  itemType,
  isEditForm,
  showImage,
  imageName,
  props,
  errors,
  errorMessage,
}: LabeledInputProps) {
  const intl = useIntl();

  const imageIsUploaded = isEditForm && itemType && itemType.imageUrl && showImage;

  return (
    <div>
      <div className="uib-form__item">
        <label htmlFor="images" className="uib-form__label">
          {label}
        </label>
        <div className={`image-input ${imageIsUploaded && 'direction-column'}`}>
          {imageIsUploaded && (
            <img className="edit-modal-image" alt={itemType.name} src={itemType.imageUrl} />
          )}
          <div className="image-input-button">
            <input
              hidden
              type="file"
              id="images"
              accept=".jpg,.jpeg,.png"
              {...(typeof props === 'object' ? props : {})}
            />
            <label
              htmlFor="images"
              style={{ maxWidth: '200px' }}
              className={'odx-button odx-button--primary'}
            >
              {intl.formatMessage({ id: 'general.chooseFile' })}
            </label>
          </div>
          {imageName && <span className="edit-modal-image-name">{imageName}</span>}
        </div>
      </div>
      {errors && errors.images && <span className="form-error">{errorMessage}</span>}
    </div>
  );
}

export { LabeledImageInput };
