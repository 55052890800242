import axios from 'axios';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

interface DynamicConfig {
  REACT_APP_BACKOFFICE_API_URL?: string;
  REACT_APP_TERMINAL_API_URL?: string;
  ENV?: 'local' | 'dev' | 'prod';
  b2cClientId?: string;
  b2cAuthority?: string;
  b2cPwdReset?: string;
  b2cRedirectUri?: string;
  b2cKnownAuthorities?: string[];
}

interface DynamicConfigContextApi {
  config: DynamicConfig;
  setConfig: (newConfig: DynamicConfig) => void;
}

const globalConfigUrl = `${process.env.REACT_APP_BASE_ROUTE as string}config/config.json`;

const ConfigContext = createContext<DynamicConfigContextApi>({
  config: {},
  setConfig: () => {},
});

interface ConfigProviderProps {
  children: ReactNode;
}

function ConfigProvider({ children }: ConfigProviderProps) {
  const [configState, setConfigState] = useState({});

  const { data, isSuccess, isError, refetch } = useQuery('config', () =>
    axios.get(globalConfigUrl).then(response => {
      return response.data;
    })
  );

  useEffect(() => {
    setConfigState(data);
  }, [data]);

  const value: DynamicConfigContextApi = {
    config: configState,
    setConfig: setConfigState,
  };

  useEffect(() => {
    let retryConfig: NodeJS.Timeout;
    if (isError) {
      retryConfig = setInterval(() => {
        refetch();
      }, 10000);
    }
    return () => clearInterval(retryConfig);
  }, [isError]);

  if (isSuccess && configState) {
    return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
  }
  return <h2>Loading</h2>;
}

function useConfigContext() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
}

export { ConfigProvider, useConfigContext };
