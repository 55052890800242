import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useDashboardContext } from '../../context/DashboardContext';
import { Renter } from '../../types/renter/Renter';
import { ChartCard } from '../library/charts/ChartCard';
import { DataTable } from '../library/table/DataTable';

export function PendingRentersChartCard() {
  const { renters, rentersIsLoading } = useDashboardContext();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'dashboard.pendingRentersTitle' });
  const pendingRenters = renters.filter(r => r.status == 'Pending');
  return (
    <ChartCard
      title={title}
      content={<PendingRentersTable renters={pendingRenters} />}
      isLoading={rentersIsLoading}
      isEmpty={pendingRenters.length == 0}
    />
  );
}
interface PendingRentersTableProps {
  renters: Renter[];
}
function PendingRentersTable({ renters }: PendingRentersTableProps) {
  const rows = transformToPendingRenterRow(renters);
  const intl = useIntl();
  const navigate = useNavigate();
  const columns = [
    {
      field: 'renterName',
      headerName: intl.formatMessage({ id: 'renters.fullName' }),
    },
    {
      field: 'barcode',
      headerName: intl.formatMessage({ id: 'renters.eId' }),
    },
    {
      field: 'contractor',
      headerName: intl.formatMessage({ id: 'renters.contractor' }),
    },
  ];
  function onRowClick() {
    navigate(`/renters?filter=${intl.formatMessage({ id: 'renters.statusPending' })}`);
  }
  return (
    <DataTable
      onRowClick={onRowClick}
      rows={rows}
      columns={columns}
      initialPageSize={5}
      showToolBar={false}
      rowHeight={35}
    />
  );
}
function transformToPendingRenterRow(renters: Renter[]) {
  const rows = renters.map(renter => {
    return {
      id: renter._id,
      renterName: renter.firstName + ' ' + renter.lastName,
      barcode: renter.barcode,
      contractor: renter.contractor.name,
    };
  });
  return rows;
}
