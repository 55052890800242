import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { ActionTableButton } from './ActionTableButton';

interface DeleteActionTableButtonProps {
  id: string;
  onClick: () => void;
}
export function DeleteActionTableButton({ id, onClick }: DeleteActionTableButtonProps) {
  return <ActionTableButton id={id} onClick={onClick} icon={<DeleteOutlineOutlinedIcon />} />;
}
