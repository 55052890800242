import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

export interface CloseIconButtonProps {
  onClick: () => void;
}

function CloseIconButton({ onClick }: CloseIconButtonProps) {
  return (
    <>
      <IconButton
        component={'button'}
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClick}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
}

export { CloseIconButton };
