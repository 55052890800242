import { useDelete } from '../../../api/hooks/useGenericApi';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { ApiError } from '../../../types/types';
import { DeleteActionTableButton } from '../../library/table/action-buttons/DeleteActionTableButton';

export function entityDeleteButtonFactoryMethod(
  rowId: string,
  endpoint: string,
  invalidateQuery: string
) {
  return <DeleteEntityButton rowId={rowId} endpoint={endpoint} invalidateQuery={invalidateQuery} />;
}
interface DeleteEntityButtonProps {
  rowId: string;
  endpoint: string;
  invalidateQuery: string;
}
export function DeleteEntityButton({ rowId, endpoint, invalidateQuery }: DeleteEntityButtonProps) {
  const deleteEntity = useDelete();
  const { setNotificationMessage } = useNotificationMessageContext();
  function handleDeleteEntity(id: string) {
    deleteEntity.mutate(
      { _id: id, endpoint: endpoint, invalidateQuery: invalidateQuery },
      {
        onError: (error: any) => {
          if (error.response && error.response.data) {
            const errorData = error.response.data as ApiError;
            if (errorData.code) {
              setNotificationMessage(errorData.code, NotificationType.Success);
            } else {
              setNotificationMessage(`crud.generic-delete-error`, NotificationType.Error);
            }
          }
        },
        onSuccess: () => {
          setNotificationMessage('crud.deleted', NotificationType.Success);
        },
      }
    );
  }
  return (
    <DeleteActionTableButton
      id={`deleteEntity_${rowId}`}
      onClick={() => handleDeleteEntity(rowId)}
    />
  );
}
