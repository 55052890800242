import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface TimeZoneContextApi {
  timeZone: string;
  setTimeZone: React.Dispatch<React.SetStateAction<string>>;
}

const TimeZoneContext = createContext<TimeZoneContextApi | undefined>(undefined);

interface TimeZoneProviderProps {
  children: ReactNode;
}

function TimeZoneProvider({ children }: TimeZoneProviderProps) {
  const [timeZone, setTimeZone] = useState<string>(
    () =>
      window.localStorage.getItem('timeZone') || Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const value: TimeZoneContextApi = {
    timeZone,
    setTimeZone,
  };

  useEffect(() => {
    timeZone && window.localStorage.setItem('timeZone', timeZone);
  }, [timeZone]);

  return <TimeZoneContext.Provider value={value}>{children}</TimeZoneContext.Provider>;
}

function useTimeZoneContext() {
  const context = useContext(TimeZoneContext);
  if (context === undefined) {
    throw new Error('useTimeZoneContext must be used within a TimeZoneProvider');
  }
  return context;
}

export { TimeZoneProvider, useTimeZoneContext };
