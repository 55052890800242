import { useIntl } from 'react-intl';

export function ServiceForeCastExplanationText() {
  const intl = useIntl();
  const text = intl.formatMessage({
    id: 'reports.serviceForecast.explanationText',
  });
  return (
    <>
      <div style={{ paddingTop: '10px' }}>
        <p>{text}</p>
      </div>
    </>
  );
}
