import { MouseEventHandler } from 'react';

import Icon from '../../library/icons/Icon';

interface EditButtonProps {
  onClick: MouseEventHandler;
  disabled?: boolean;
}

function EditButton({ onClick, disabled = false }: EditButtonProps) {
  return (
    <button onClick={e => onClick(e)} type="button" className="uib-iconbutton" disabled={disabled}>
      <Icon id={'editButtonTable'} iconName={'edit'} />
    </button>
  );
}

export { EditButton };
