import { useIntl } from 'react-intl';

import { DetailedItem, Status } from '../../../types/types';
import { defaultItemTypeImage } from '../../../utils/encodedImages';

interface ItemDetailCardProps {
  item: DetailedItem | null;
}

const maxMediaBreakWidth = 920;

function ItemDetailCard({ item }: ItemDetailCardProps) {
  const intl = useIntl();
  if (!item) return <></>;

  const itemInLockerInfo = () => {
    return (
      <>
        <p>
          <span className="label">{intl.formatMessage({ id: 'stations.location' })}: </span>
          {item.station.location}
        </p>
        <p>
          <span className="label">{intl.formatMessage({ id: 'cabinets.cabinetPos' })}: </span>
          {item.cabinetPosition}
        </p>
        <p>
          <span className="label">{intl.formatMessage({ id: 'lockers.lockerPos' })}: </span>
          {item.lockerPosition}
        </p>
      </>
    );
  };

  const infoToDisplay = () => {
    let relevantData: JSX.Element;
    switch (item.status) {
      case Status.IN_SERVICE:
        relevantData = (
          <p>
            <span>{intl.formatMessage({ id: 'items.status' })}: </span>
            <span>{intl.formatMessage({ id: 'items.status.inService' })}</span>
          </p>
        );
        break;
      case Status.RENTED:
        relevantData = (
          <>
            <p>
              <span className="label">{intl.formatMessage({ id: 'items.status' })}: </span>
              {intl.formatMessage({ id: 'items.status.rented' })}
            </p>
            <p>
              <span className="label">{intl.formatMessage({ id: 'renters.eId' })}</span>:{' '}
              {item.renterEId}
            </p>
          </>
        );
        break;
      case Status.AVAILABLE:
        relevantData = (
          <>
            <p>
              <span className="label">{intl.formatMessage({ id: 'items.status' })}: </span>
              {intl.formatMessage({ id: 'items.status.available' })}
            </p>
            {itemInLockerInfo()}
          </>
        );
        break;
      case Status.CHARGING:
        relevantData = (
          <>
            <p>
              <span className="label">{intl.formatMessage({ id: 'items.status' })}: </span>
              {intl.formatMessage({ id: 'items.status.charging' })}
            </p>
            {itemInLockerInfo()}
          </>
        );
        break;
      case Status.REQUIRES_SERVICE:
        relevantData = (
          <>
            <p>
              <span className="label">{intl.formatMessage({ id: 'items.status' })}: </span>
              {intl.formatMessage({ id: 'items.status.requiredService' })}
            </p>
            {itemInLockerInfo()}
          </>
        );
        break;
      case Status.OVERDUE:
        relevantData = (
          <>
            <p>
              <span className="label">{intl.formatMessage({ id: 'items.status' })}: </span>
              {intl.formatMessage({ id: 'items.status.overdue' })}
            </p>
            {itemInLockerInfo()}
          </>
        );
        break;
      default:
        relevantData = (
          <p>
            <span className="label">{intl.formatMessage({ id: 'items.itemError' })} </span>
            {intl.formatMessage({ id: 'general.contactAdmin' })}
          </p>
        );
    }
    return relevantData;
  };

  const imageUrl = item.itemType.imageUrl ?? defaultItemTypeImage;

  return (
    <div className="info-card">
      <div className="card-content">
        <div className="img-container">
          {window.innerWidth > maxMediaBreakWidth && (
            <img src={imageUrl} alt={item?.itemType?.name ?? 'Default Image'} />
          )}
        </div>
        <h4>{item.itemType.name}</h4>
        {item.hasGdcCalibration && (
          <p>
            {intl.formatMessage({ id: 'general.with' })}{' '}
            <b>{intl.formatMessage({ id: 'item-types.gdcCalibration' })}</b>
          </p>
        )}
        <div className="card-infos additional-margin-top">{infoToDisplay()}</div>
      </div>
    </div>
  );
}

export { ItemDetailCard };
