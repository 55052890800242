import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useCrudPageUiStateContext } from '../../context/CrudPageUiContext';
import { Renter } from '../../types/renter/Renter';
import { CancelButton } from '../library/buttons/CancelButton';
import { Dialog } from '../library/dialog/Dialog';
import { Icon } from '../library/icons/Icon';
import { displayFormat } from '../library/inputs/DatePicker';
import SimpleTable, { SimpleTableRow } from '../library/table/SimpleTable';

export function RenterDetailView() {
  const intl = useIntl();
  const { entityForDetailView } = useCrudPageUiStateContext<Renter>();
  return (
    <>
      {entityForDetailView && (
        <Dialog
          id={'renterDetailView'}
          title={intl.formatMessage({ id: 'renters.detailView' })}
          isOpen={!!entityForDetailView}
          content={<RenterDetailViewContent />}
          buttons={<RenterDeailViewButtons />}
        />
      )}
    </>
  );
}
function RenterDetailViewContent() {
  const { entityForDetailView: renter } = useCrudPageUiStateContext<Renter>();
  const intl = useIntl();
  const renterRow = {
    id: renter?._id ?? '',
    firstName: renter?.firstName ?? '-',
    lastName: renter?.lastName ?? '-',
    barcode: renter?.barcode ?? '-',
    humanRenterId: renter?.humanRenterId ?? '-',
    email: renter?.email ?? '-',
    phone: renter?.phone ?? '-',
    contractor: renter?.contractor.name ?? '-',
  };
  return (
    <>
      <SimpleTable
        columns={[
          { id: 'firstName', label: intl.formatMessage({ id: 'general.firstName' }) },
          { id: 'lastName', label: intl.formatMessage({ id: 'general.lastName' }) },
          { id: 'barcode', label: intl.formatMessage({ id: 'renters.eId' }) },
          { id: 'humanRenterId', label: intl.formatMessage({ id: 'renters.employeeNo' }) },
        ]}
        rows={[renterRow]}
        style={{ marginBottom: '25px' }}
      />
      <SimpleTable
        columns={[
          { id: 'email', label: intl.formatMessage({ id: 'general.email' }) },
          { id: 'phone', label: intl.formatMessage({ id: 'renters.phone' }) },
          { id: 'contractor', label: intl.formatMessage({ id: 'contractors.singular' }) },
        ]}
        rows={[renterRow]}
      />
      <h1 style={{ paddingTop: '40px', paddingBottom: '20px' }}>
        {intl.formatMessage({ id: 'qualifications.self' })}
      </h1>
      <SimpleTable
        columns={[
          { id: 'name', label: intl.formatMessage({ id: 'general.name' }) },
          { id: 'expiryDate', label: intl.formatMessage({ id: 'qualifications.expiryDate' }) },
          {
            id: 'validity',
            label: intl.formatMessage({ id: 'qualifications.validity' }),
            renderRow: rowValue => {
              return renderQualificatioNValidity(Boolean(rowValue));
            },
          },
        ]}
        rows={getQualifivationRows(renter)}
      />
    </>
  );
}
function renderQualificatioNValidity(isValid: boolean): ReactNode {
  if (isValid) return <Icon iconName={'check'} additionalClasses={'odx-blue-text'} />;
  return <Icon iconName={'blocked'} />;
}
function getQualifivationRows(renter: Renter | undefined): SimpleTableRow[] {
  return (
    renter?.qualificationDetails.map(qualfication => {
      return {
        id: qualfication._id,
        name: qualfication.name,
        expiryDate: dayjs(qualfication.expiryDate, 'DD.MM.YYYY').format(displayFormat),
        validity: qualfication.isValid,
      };
    }) ?? []
  );
}
function RenterDeailViewButtons() {
  const { setEntityForDetailView } = useCrudPageUiStateContext();
  return (
    <CancelButton
      onClick={() => {
        setEntityForDetailView(undefined);
      }}
    />
  );
}
