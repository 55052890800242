import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { DetailViewActionTableButton } from '../../library/table/action-buttons/DetailViewActionTableButton';

export function detailViewButtonFactoryMethod(entity: any | undefined) {
  return <>{entity && <DetailViewButton entity={entity} />}</>;
}
interface DetailViewButtonProps {
  entity: any;
}
export function DetailViewButton({ entity }: DetailViewButtonProps) {
  const { setEntityForDetailView } = useCrudPageUiStateContext();
  function handleClick() {
    setEntityForDetailView(entity);
  }
  return <DetailViewActionTableButton id={`detailViewButton_${entity.id}`} onClick={handleClick} />;
}
