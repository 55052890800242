import EditIcon from '@mui/icons-material/Edit';

import { ActionTableButton } from './ActionTableButton';

interface EditActionTableButtonProps {
  id: string;
  onClick: () => void;
}

export function EditActionTableButton({ id, onClick }: EditActionTableButtonProps) {
  return <ActionTableButton id={id} onClick={onClick} icon={<EditIcon />} />;
}
