export interface System {
  _id: string;
  id: string;
  client: string;
  country: string;
  city: string;
  site: string;
  notificationLanguage: string;
  adminEmails: string[];
  status: SystemStatus;
  gdcTenantId: string;
}

export enum SystemStatus {
  LIVE = 'Live',
  NOT_LIVE = 'NotLive',
  UNDEFINED = 'Undefined',
}
