import { useIntl } from 'react-intl';

import { Qualification } from '../../../types/types';
import {
  AutoCompleteOption,
  FormMultiAutocomplete,
} from '../../library/form/FormMultiAutocomplete';

interface MultiQualificationsDropDownProps {
  qualifications: Qualification[];
}
export function MultiQualificationsDropDown({ qualifications }: MultiQualificationsDropDownProps) {
  const intl = useIntl();
  const options = getOptions(qualifications);
  const label = intl.formatMessage({ id: 'qualifications.self' });
  return (
    <FormMultiAutocomplete
      id={'qualifcationsDropdown'}
      label={label}
      name={'qualificationOptions'}
      options={options}
    />
  );
}

function getOptions(qualifications: Qualification[]): AutoCompleteOption[] {
  const result = qualifications.map(qualification => ({
    id: qualification._id,
    label: qualification.name,
  }));
  return result;
}
