import { useIntl } from 'react-intl';

import { useItemTypes } from '../../../../api/hooks/useRentingApi';
import { LoadingIndicator } from '../../../../components/library/loading/LoadingIndicator';
import { Modal } from '../../../../components/modal/Modal';
import { Item } from '../../../../types/types';

import { ItemForm, ItemFormData } from './ItemForm';

export interface DraftItemModalProps {
  onClose: () => void;
  onSubmit: (data: ItemFormData, saveAndNext: boolean) => void;
  item?: Item;
}

function DraftItemModal({ onClose, onSubmit, item }: DraftItemModalProps) {
  const intl = useIntl();
  const { itemTypes, isLoading: isLoadingItemTypes } = useItemTypes();
  const isCreateModal = !item;

  const header = (
    <h1 className="uib-modal__title">
      {intl.formatMessage({
        id: isCreateModal ? 'items.create' : 'items.edit',
      })}
    </h1>
  );
  return (
    <Modal
      isOpen={true}
      hasForm={true}
      header={header}
      modalHasClosed={onClose}
      additionalClassNames="overflow-visible"
      content={
        isLoadingItemTypes ? (
          <LoadingIndicator />
        ) : (
          <ItemForm onCancel={onClose} onSubmit={onSubmit} itemTypes={itemTypes} item={item} />
        )
      }
    />
  );
}

export { DraftItemModal };
