import { useIntl } from 'react-intl';

import { FormDropDown } from '../../../library/form/FormDropdown';

export function SystemNotificationLanguageDropdown() {
  const intl = useIntl();
  const options = [
    { value: 'de', label: intl.formatMessage({ id: 'systems.notificationLanguage.german' }) },
    { value: 'en', label: intl.formatMessage({ id: 'systems.notificationLanguage.english' }) },
  ];
  return (
    <FormDropDown
      id={'SystemNotificationLanguageDropdown'}
      required={true}
      label={intl.formatMessage({ id: 'systems.notificationLanguage' })}
      name={'notificationLanguage'}
      dropDownItems={options}
    />
  );
}
