export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GHOST = 'ghost',
  HIGHLIGHT = 'highlight',
  SUCCESS = 'success',
  CONFIRMATION = 'confirmation',
  DANGER = 'danger',
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}
