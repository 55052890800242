import {
  FormControl,
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
} from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export interface FormMultiAutocompleteProps {
  id: string;
  name: string;
  label: string;
  options: AutoCompleteOption[];
  required?: boolean;
  fullWidth?: boolean;
}
export interface AutoCompleteOption {
  id: string;
  label: string;
}
export function FormMultiAutocomplete({
  id,
  name,
  label,
  options,
  required = false,
  fullWidth = true,
}: FormMultiAutocompleteProps) {
  const { getValues: getFormValues, setValue: setFormValue } = useFormContext();
  const [ownValue, setOwnValue] = useState<AutoCompleteOption[]>(
    toAutoCompleteOptions(getFormValues(name), options)
  );
  const [inputValue, setInputValue] = useState<string>();
  return (
    <FormControl sx={{ my: 1 }} fullWidth={fullWidth}>
      <MuiAutocomplete
        multiple
        autoHighlight
        autoSelect
        id={id}
        value={ownValue}
        onChange={(event, value) => {
          onChange(value);
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        options={options}
        filterSelectedOptions
        renderInput={params => (
          <MuiTextField
            {...params}
            required={required}
            label={label}
            placeholder={label}
            value={inputValue}
            onChange={event => {
              setInputValue(event.target.value);
            }}
          />
        )}
      />
    </FormControl>
  );
  function onChange(newValue: AutoCompleteOption[]) {
    const newFormValue = newValue.map(value => {
      return value.id;
    });
    setFormValue(name, newFormValue, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setOwnValue(newValue);
  }
  function toAutoCompleteOptions(formValues: string[], options: AutoCompleteOption[]) {
    return formValues.map(formValue => {
      return options.find(option => option.id === formValue) as AutoCompleteOption;
    });
  }
  function isOptionEqualToValue(option: AutoCompleteOption, value: AutoCompleteOption) {
    return option.id === value.id;
  }
}
