import {
  FormControl,
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
} from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export interface FormAutocompleteProps {
  id: string;
  name: string;
  label: string;
  options: AutoCompleteOption[];
  required?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
  disableClearable?: boolean;
}
export interface AutoCompleteOption {
  id: string;
  label: string;
}
export function FormAutocomplete({
  id,
  name,
  label,
  options,
  required = false,
  fullWidth = true,
  disableClearable = false,
}: FormAutocompleteProps) {
  const { getValues: getFormValues, setValue: setFormValue } = useFormContext();
  const [ownValue, setOwnValue] = useState<AutoCompleteOption | undefined>(
    toAutoCompleteOption(getFormValues(name), options)
  );
  const [inputValue, setInputValue] = useState<string>();
  return (
    <FormControl sx={{ my: 1 }} fullWidth={fullWidth}>
      <MuiAutocomplete
        id={id}
        autoHighlight
        autoSelect
        disableClearable={disableClearable}
        value={ownValue}
        onChange={(event, value) => {
          onChange(value as AutoCompleteOption);
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        options={options}
        filterSelectedOptions
        renderInput={params => (
          <MuiTextField
            {...params}
            required={required}
            label={label}
            placeholder={label}
            value={inputValue}
            onChange={event => {
              setInputValue(event.target.value);
            }}
          />
        )}
      />
    </FormControl>
  );
  function onChange(newValue: AutoCompleteOption) {
    const newFormValue = newValue.id;
    setFormValue(name, newFormValue, { shouldDirty: true, shouldValidate: true });
    setOwnValue(newValue);
  }
  function toAutoCompleteOption(formValue: string, options: AutoCompleteOption[]) {
    return (options.find(option => option.id === formValue) as AutoCompleteOption) ?? null;
  }
  function isOptionEqualToValue(option: AutoCompleteOption, value: AutoCompleteOption) {
    return option.id === value.id;
  }
}
