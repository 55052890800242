import { ReactNode } from 'react';

import { RenterFormValues } from '../../../types/renter/RenterFormValues';
import { RenterParameter } from '../../../types/renter/RenterParameter';
import { ContractorDropDown } from '../../common/inputs/ContractorDropDown';
import { Form } from '../../library/form/Form';
import { useRentersPageContext } from '../contexts/RentersPageContext';

import { RenterAdminToggle } from './inputs/RenterAdminToggle';
import { RenterBarcodeInput } from './inputs/RenterBarcodeInput';
import { RenterEmailInput } from './inputs/RenterEmailInput';
import { RenterEmployeeNumberInput } from './inputs/RenterEmployeeNumberInput';
import { RenterFirstNameInput } from './inputs/RenterFirstNameInput';
import { RenterLastNameInput } from './inputs/RenterLastNameInput';
import { RenterPhoneNumberInput } from './inputs/RenterPhoneNumberInput';
import { RenterQualificationDropdown } from './inputs/RenterQualificationDropdown';
import { RenterStatusDropdown } from './inputs/RenterStatusDropdown';
import { RenterSupervisorsInput } from './inputs/RenterSupervisorsInput';

export interface RenterFormProps {
  initialFormValues: RenterFormValues;
  handleSubmit: (formInputValues: RenterParameter, dirtyFields: string[]) => void;
  buttons: ReactNode;
}
export function RenterForm({ initialFormValues, handleSubmit, buttons }: RenterFormProps) {
  const { contractors } = useRentersPageContext();
  function onSubmit(formInputValues: RenterFormValues, dirtyFields: string[]) {
    handleSubmit(toParam(formInputValues), cleanDirtyFields(dirtyFields));
  }
  return (
    <Form initialFormValues={initialFormValues} onSubmit={onSubmit} buttons={buttons}>
      <RenterFirstNameInput />
      <RenterLastNameInput />
      <RenterBarcodeInput />
      <RenterSupervisorsInput />
      <ContractorDropDown contractors={contractors} />
      <RenterEmailInput />
      <RenterEmployeeNumberInput />
      <RenterPhoneNumberInput />
      <RenterAdminToggle />
      <RenterStatusDropdown />
      <RenterQualificationDropdown />
    </Form>
  );
}

function cleanDirtyFields(dirtyFields: string[]) {
  const cleanedDirtyFields = dirtyFields.filter(f => f !== 'qualificationOptions');
  if (dirtyFields.includes('qualificationOptions') && !dirtyFields.includes('qualifications')) {
    cleanedDirtyFields.push('qualifications');
  }
  return cleanedDirtyFields;
}

function toParam(formValues: RenterFormValues): RenterParameter {
  const cleanedQualifications = formValues.qualifications
    .filter(q => formValues.qualificationOptions.includes(q._id))
    .map(q => {
      return {
        _id: q._id,
        issueDate: q.issueDate != null ? q.issueDate : undefined,
        expiryDate: q.expiryDate != null ? q.expiryDate : undefined,
      };
    });
  return {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    barcode: formValues.barcode,
    humanRenterId: formValues.humanRenterId === '' ? undefined : formValues.humanRenterId,
    contractorId: formValues.contractorId,
    phone: formValues.phone === '' ? undefined : formValues.phone,
    email: formValues.email === '' ? undefined : formValues.email,
    supervisorEmails: formValues.supervisorEmails ?? [],
    qualifications: cleanedQualifications,
    isRenterAdmin: formValues.isRenterAdmin ?? false,
    status: formValues.status,
  };
}
