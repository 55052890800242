import { jwtDecode } from 'jwt-decode';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { useTokenContext } from './tokenContext';

interface AuthContextApi {
  userEmail: string;
  userName: string;
}

const AuthContext = createContext<AuthContextApi | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

interface DecodedJwt {
  emails: string[];
  family_name: string;
  given_name: string;
}

function AuthProvider({ children }: AuthProviderProps) {
  const { accessToken } = useTokenContext();
  const decodedToken: DecodedJwt = jwtDecode(accessToken);

  // We could also get the User OID from the token here (decodedToken.oid) if we ever needed it
  const [userEmail, setUserEmail] = useState<string>(() => {
    return decodedToken ? decodedToken.emails[0] : '';
  });
  const [userName, setUserName] = useState<string>(() => {
    return decodedToken ? `${decodedToken.family_name}, ${decodedToken.given_name}` : '';
  });

  const value: AuthContextApi = {
    userEmail,
    userName,
  };

  useEffect(() => {
    setUserEmail(decodedToken.emails[0]);
    setUserName(`${decodedToken.family_name}, ${decodedToken.given_name}`);
  }, [accessToken]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuthContext };
