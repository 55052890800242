import { FormControlLabel, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface LabeledSwitchProps {
  id: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  size?: 'medium' | 'small';
  label: string;
}

export function FormSwitch({
  id,
  disabled = false,
  size = 'medium',
  required,
  name,
  label,
}: LabeledSwitchProps) {
  const { register, control } = useFormContext();
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ paddingTop: '5px' }}>{label}</span>
        <Controller
          name={name}
          control={control}
          render={({ field: { value } }) => (
            <FormControlLabel
              control={
                <Switch
                  id={id}
                  checked={value}
                  size={size}
                  {...register(name, {
                    required: required,
                    disabled: disabled,
                  })}
                />
              }
              label=""
            />
          )}
        />
      </div>
    </>
  );
}
