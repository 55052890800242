import { AlertRulesPageHeader } from '../../components/alert-rules/AlertRulesPageHeader';
import { AlertRulesTable } from '../../components/alert-rules/AlertRulesTable';
import { CreateAlertRuleDialog } from '../../components/alert-rules/create/CreateAlertRuleDialog';
import { EditAlertRuleDialog } from '../../components/alert-rules/edit/EditAlertRuleDialog';
import { CreateEntityButton } from '../../components/common/crud/CreateEntityButton';
import { SimpleNotification } from '../../components/common/notification/SimpleNotification';
import { CrudPageUiStateProvider } from '../../context/CrudPageUiContext';
import { NotificationMessageProvider } from '../../context/notificationMessageContext';

function AlertRulesPage() {
  return (
    <NotificationMessageProvider>
      <CrudPageUiStateProvider>
        <AlertRulesPageHeader>
          <CreateEntityButton />
        </AlertRulesPageHeader>
        <CreateAlertRuleDialog />
        <AlertRulesTable />
        <EditAlertRuleDialog />
        <SimpleNotification />
      </CrudPageUiStateProvider>
    </NotificationMessageProvider>
  );
}
export { AlertRulesPage };
