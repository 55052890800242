import React from 'react';

import { ButtonSize, ButtonVariant } from '../../types/components/Button';

export interface ButtonProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  type?: string;
  style?: React.CSSProperties;
  additionalClasses?: string;
  children: React.ReactNode;
  id: string;
  disabled?: boolean;
  hasLoadingSpinner?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

/**
 * ODX button component
 *
 * @param {Object} props - The props passed to the component.
 * @param {ButtonVariant} props.variant
 * @param {ButtonSize} props.size
 * @param {string} props.type
 * @param {object} props.style
 * @param {string} props.id
 * @param {boolean} props.disabled
 * @param {boolean} props.hasLoadingSpinner
 * @param {(event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void} props.onClick
 * @param {string} props.additionalClasses
 */
export function Button({
  variant,
  size,
  type,
  style,
  children,
  id,
  disabled,
  hasLoadingSpinner,
  onClick,
  additionalClasses,
}: ButtonProps) {
  const classNames = ['odx-button'];
  if (additionalClasses !== undefined) classNames.push(additionalClasses);
  if (variant) classNames.push(`odx-button--${variant}`);
  if (size) classNames.push(`odx-button--${size}`);

  let styles;
  if (hasLoadingSpinner) {
    const loadingStyles = {
      minHeight: 'inherit',
      position: 'relative',
    };
    styles = { ...style, ...loadingStyles };
  }

  const elementType = type === 'link' ? 'a' : 'button';

  const elementProps = {
    className: classNames.join(' '),
    id: id,
    disabled: disabled,
    onClick: onClick,
    style: styles,
  };

  return React.createElement(elementType, elementProps, children);
}

export default Button;
