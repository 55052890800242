import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { UseMutateFunction } from 'react-query';
import { CellProps } from 'react-table';

import { EditButton } from '../../../../components/table/table-buttons/EditButton';
import { TableButton, TableButtons } from '../../../../components/table/table-buttons/TableButtons';
import { VerticalTable } from '../../../../components/table/VerticalTable';
import { Locker } from '../../../../types/types';
import { bindestrich } from '../../../../utils/bindestrich';

import { OpenLockerButton, OpenLockerData } from './OpenLockerButton';

export interface LockersTableProps {
  lockers: Locker[];
  stationId: string;
  onSelectLockerForEditing: Dispatch<SetStateAction<Locker | undefined>>;
  mutateOpenLocker: UseMutateFunction<AxiosResponse<any>, unknown, OpenLockerData, unknown>;
  isOpenLockerLoading: boolean;
}

function LockersTable({
  lockers,
  stationId,
  onSelectLockerForEditing,
  mutateOpenLocker,
  isOpenLockerLoading,
}: LockersTableProps) {
  const intl = useIntl();

  function defectIcon(isDefective: boolean) {
    const icon = isDefective ? (
      <i className="uib-icon uib-icon--feedback-warning" />
    ) : (
      <p style={{ fontSize: 13, marginLeft: 13 }}>{'-'}</p>
    );
    return <div className="cell-icon-container">{icon}</div>;
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'lockers.lockerPos' }),
      accessor: 'position',
    },
    {
      Header: intl.formatMessage({ id: 'lockers.lockerFeature' }),
      id: 'lockerFeature',
      accessor: (row: Locker) => {
        const data = row as Locker;
        return bindestrich(data.lockerFeature);
      },
    },
    {
      Header: intl.formatMessage({ id: 'lockers.itemType' }),
      accessor: (locker: Locker) => {
        return locker.items
          ? locker.items.map(item => item.itemTypeName).join(', ')
          : bindestrich(locker.itemTypeName);
      },
    },
    {
      Header: intl.formatMessage({ id: 'items.eIDs' }),
      accessor: (row: Locker) => {
        const data = row as Locker;
        return data.items
          ? data.items.map(item => item.itemBarcode).join(', ')
          : bindestrich(data.item?.itemBarcode);
      },
    },
    {
      Header: intl.formatMessage({ id: 'lockers.defect' }),
      accessor: 'lockerDefective',
      Cell: ({ row: { original } }: CellProps<Locker>) => defectIcon(original.isDefective),
    },
    {
      id: 'open-locker',
      className: 'open-locker-cell',
      Cell: ({ row: { original: locker } }: CellProps<Locker>) => {
        const item = locker.item;
        if (!item) {
          return (
            <TableButtons>
              <TableButton>
                <OpenLockerButton
                  onClick={mutateOpenLocker}
                  lockerId={locker._id}
                  stationId={stationId}
                  isLoading={isOpenLockerLoading}
                />
              </TableButton>
              <EditButton
                onClick={e => {
                  onSelectLockerForEditing(locker);
                  e.stopPropagation();
                }}
              />
            </TableButtons>
          );
        }
        return (
          <TableButtons>
            <TableButton>
              <OpenLockerButton
                onClick={mutateOpenLocker}
                lockerId={locker._id}
                stationId={stationId}
                isLoading={isOpenLockerLoading}
              />
            </TableButton>
            <EditButton
              onClick={e => {
                onSelectLockerForEditing(locker);
                e.stopPropagation();
              }}
            />
          </TableButtons>
        );
      },
    },
  ];

  const sortBy = [
    {
      id: 'position',
      desc: false,
    },
  ];
  return (
    <VerticalTable
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      columns={columns}
      data={lockers}
      sortBy={sortBy}
    />
  );
}

export { LockersTable };
