import { useMsal } from '@azure/msal-react';

import { useAuthContext } from '../../../context/auth/authContext';
import { SubHeaderMenu } from '../SubHeaderMenu';

interface UserMenuProps {
  closeMenu: () => void;
}

function UserMenu({ closeMenu }: UserMenuProps) {
  const { instance: msalInstance } = useMsal();
  const { userName } = useAuthContext();

  return (
    <SubHeaderMenu
      firstButtonLabel={userName}
      isFirstButtonDisabled={true}
      secondButtonLabel="Logout"
      handleSecondButtonClick={() => msalInstance.logoutRedirect()}
      closeMenu={closeMenu}
    />
  );
}

export { UserMenu };
