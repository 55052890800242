import { Box } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

interface DatePickerProps {
  label: string;
  required?: boolean;
  date: string; // apiFormat
  onChange: (date: string) => void;
}
export const displayFormat = 'YYYY.MM.DD';
export const apiFormat = 'YYYY-MM-DD';
export function DatePicker({ label, date, onChange, required }: DatePickerProps) {
  return (
    <Box sx={{ width: '100%' }}>
      <MuiDatePicker
        label={label}
        value={dayjs(date)}
        format={displayFormat}
        onChange={value => {
          if (value) {
            onChange(value.format(apiFormat));
          }
        }}
        slotProps={{
          textField: {
            required: required,
          },
        }}
      />
    </Box>
  );
}
