import { useIntl } from 'react-intl';

import { LoadingIndicator } from '../../../components/library/loading/LoadingIndicator';
import { Modal } from '../../../components/modal/Modal';
import { useBusinessReportUiStateContext } from '../../../context/BusinessReportUiStateContext';

interface BusinessReportDownloadStartedModalProps {
  isLoading: boolean;
}
export function BusinessReportDownloadStartedModal({
  isLoading,
}: BusinessReportDownloadStartedModalProps) {
  const { openDownloadStartedDialog, setOpenDownloadStartedDialog } =
    useBusinessReportUiStateContext();
  return (
    <>
      <Modal
        isOpen={openDownloadStartedDialog}
        header={<IntermediateModalHeader />}
        content={<IntermediateModalContent isLoading={isLoading} />}
        modalHasClosed={() => {
          setOpenDownloadStartedDialog(false);
        }}
      />
    </>
  );
}

function IntermediateModalHeader() {
  const intl = useIntl();
  return (
    <h1 className="uib-modal__title">{intl.formatMessage({ id: 'reports.downloadStarted' })}</h1>
  );
}

function IntermediateModalContent({ isLoading }: BusinessReportDownloadStartedModalProps) {
  const intl = useIntl();
  return (
    <div className="uib-modal__content">
      {intl.formatMessage({ id: 'reports.downloadStartedContent' })}
      {isLoading && <LoadingIndicator />}
    </div>
  );
}
