import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter as Router } from 'react-router-dom';

import { ConfigProvider } from '../shared/configContext';

import { AppProvider } from './appContext';
import { AuthProviders } from './auth';
import { LanguageProvider } from './languageContext';
import { RRSystemProvider } from './rrSystemContext';
import { ThemeProvider } from './themeContext';
import { TimeZoneProvider } from './timeZoneContext';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
});

interface AppProvidersProps {
  children: ReactNode;
}

function AppProviders({ children }: AppProvidersProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider>
        <LanguageProvider>
          <ThemeProvider>
            <TimeZoneProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AuthProviders>
                  <RRSystemProvider>
                    <AppProvider>
                      <Router>{children}</Router>
                    </AppProvider>
                  </RRSystemProvider>
                </AuthProviders>
              </LocalizationProvider>
            </TimeZoneProvider>
          </ThemeProvider>
        </LanguageProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export { AppProviders };
