import { useIntl } from 'react-intl';

import { FormMultiEmailInput } from '../../../library/form/FormMultiEmailInput';

export function RenterSupervisorsInput() {
  const intl = useIntl();

  return (
    <FormMultiEmailInput
      id={'renterSupervisorInput'}
      label={intl.formatMessage({ id: 'renters.supervisors' })}
      name={'supervisorEmails'}
      required={true}
    />
  );
}
