function bindestrich(
  value: string | number | boolean | null | undefined | any[],
  replacement: string | number | boolean | null | undefined = undefined
) {
  if (!value || (Array.isArray(value) && value.length <= 0)) {
    return replacement ?? '-';
  } else {
    return value;
  }
}

export { bindestrich };
