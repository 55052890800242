import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/tokenContext';
import { ItemSetTemplate, ItemSetTemplateForm } from '../../types/item-set/item-set-template';

const ITEMS_SET_TEMPLATE_URL = '/item-set-template';
const ITEMS_SET_TEMPLATES_URL = '/item-set-templates';

function useItemSetTemplates() {
  const client = useClient<ItemSetTemplate[]>();
  const result = useQuery('item-set-templates', () => client(ITEMS_SET_TEMPLATES_URL));
  return { ...result, itemSetTemplates: result.data?.data ?? [] };
}

function useCreateItemSetTemplate() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: ItemSetTemplateForm) =>
      client(`${ITEMS_SET_TEMPLATES_URL}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('item-set-templates');
      },
    }
  );
}

function useDeleteItemSetTemplate() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ _id }: { _id: string }) =>
      client(`${ITEMS_SET_TEMPLATE_URL}/${_id}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('item-set-templates');
      },
    }
  );
}

export { useItemSetTemplates, useCreateItemSetTemplate, useDeleteItemSetTemplate };
