import { createContext, ReactNode, useContext, useState } from 'react';

interface AppContextApi {
  isSettingsModalOpen: boolean;
  openSettingsModal: () => void;
  closeSettingsModal: () => void;
}

const AppContext = createContext<AppContextApi | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

function AppProvider({ children }: AppProviderProps) {
  const [isSettingsModalOpen, toggleIsSettingsModalOpen] = useState(false);

  function openSettingsModal() {
    toggleIsSettingsModalOpen(true);
  }

  function closeSettingsModal() {
    toggleIsSettingsModalOpen(false);
  }

  const value: AppContextApi = {
    isSettingsModalOpen,
    openSettingsModal,
    closeSettingsModal,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useAppContext() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return context;
}

export { AppProvider, useAppContext };
