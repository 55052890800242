import { useIntl } from 'react-intl';

import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { Dialog } from '../../library/dialog/Dialog';

import { EditAlertRuleForm } from './EditAlertRuleForm';

export function EditAlertRuleDialog() {
  const intl = useIntl();
  const { entityToEdit: alertRuleToEdit } = useCrudPageUiStateContext();
  return (
    <>
      {alertRuleToEdit && (
        <Dialog
          id={'EditAlertRuleDialog'}
          isOpen={alertRuleToEdit ? true : false}
          title={intl.formatMessage({ id: 'alert-rules.edit' })}
          content={<EditAlertRuleForm />}
        />
      )}
    </>
  );
}
