import { useFormContext } from 'react-hook-form';

import { useGetAlertRuleTemplates } from '../../../../api/hooks/useAlertRulesApi';
import { ItemTypeDropDown } from '../../../common/inputs/ItemTypeDropDown';
import { getRequiredAndDisabled } from '../../logic/AlertRuleTemplateEvaluator';

export function AlertRuleItemTypeDropDown() {
  const { alertRuleTemplates } = useGetAlertRuleTemplates();
  const { watch } = useFormContext();
  const alertRuleType = watch('alertRuleType');
  const itemTypeFieldName = 'itemTypeId';
  const { required, disabled } = getRequiredAndDisabled(
    alertRuleTemplates,
    alertRuleType,
    itemTypeFieldName
  );
  return <>{!disabled && <ItemTypeDropDown required={required} />}</>;
}
