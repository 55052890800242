import { useState } from 'react';

import Button from '../odx/Button';

interface CustomButtonGroupProps {
  handleButtonAction: () => void;
  actionButtonText: string;
  buttonTooltipMessage: string;
  setTimeoutOnMessage: boolean;
  id: string;
}

export const TooltipButton = ({
  handleButtonAction,
  actionButtonText,
  buttonTooltipMessage,
  setTimeoutOnMessage,
  id,
}: CustomButtonGroupProps) => {
  const [isToolTipMessageOpen, setToolTipMessageOpen] = useState<boolean>(false);

  const showTooltip = () => {
    handleButtonAction();
    setToolTipMessageOpen(true);
    if (setTimeoutOnMessage) {
      setTimeout(() => {
        setToolTipMessageOpen(false);
      }, 3000);
    }
  };

  return (
    <>
      {isToolTipMessageOpen && (
        <div className="square-bubble">
          <p>{buttonTooltipMessage}</p>
        </div>
      )}
      <Button id={id} onClick={showTooltip}>
        {actionButtonText}
      </Button>
    </>
  );
};
