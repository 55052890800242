import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';

export interface UploadErrorListProps {
  apiErrors: string[];
}
export function UploadErrorList({ apiErrors }: UploadErrorListProps) {
  const intl = useIntl();
  const {
    formState: { errors: formErrors },
  } = useFormContext();
  const { setNotificationMessage } = useNotificationMessageContext();
  if (apiErrors.length == 0 && !formErrors.selectedFile) return <></>;
  return (
    <>
      <p>
        {intl.formatMessage({
          id: `renters.uploadDataFailed.error`,
        })}
      </p>
      <div
        id="uploadErrorsListContainer"
        title={intl.formatMessage({ id: 'general.copy.tooltip' })}
        onClick={() => copyToClipboard()}
        style={{ cursor: 'pointer', overflow: 'hidden' }}
      >
        <div
          style={{
            paddingLeft: '50px',
            maxHeight: '600px',
            overflowY: 'scroll',
          }}
        >
          <ul style={{ listStyleType: 'circle' }}>
            {formErrors.selectedFile && (
              <li key={'formUploadError'}>{formErrors.selectedFile.message}</li>
            )}
            {apiErrors.map((error, index) => {
              return <li key={`apiUploadError_${index}`}>{error}</li>;
            })}
          </ul>
        </div>
      </div>
    </>
  );
  function copyToClipboard() {
    const textToClipBoard = apiErrors.join('\n');
    navigator.clipboard.writeText(textToClipBoard);
    setNotificationMessage(
      intl.formatMessage({ id: 'general.copied.to.clipboard' }),
      NotificationType.Info
    );
  }
}
