import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { UseMutateFunction } from 'react-query';
import { CellProps } from 'react-table';

import { DeleteButton } from '../../../components/table/table-buttons/DeleteButton';
import { EditButton } from '../../../components/table/table-buttons/EditButton';
import { InfoButton } from '../../../components/table/table-buttons/InfoButton';
import { TableButtons } from '../../../components/table/table-buttons/TableButtons';
import { VerticalTable } from '../../../components/table/VerticalTable';
import { Item, Status } from '../../../types/types';
import { bindestrich } from '../../../utils/bindestrich';
import { globalServiceTypes } from '../../../utils/globalServices';
import { OpenLockerButton, OpenLockerData } from '../../stations/cabinets/lockers/OpenLockerButton';

interface ItemsTableProps {
  items: Item[];
  onSelectItemForEditing: Dispatch<SetStateAction<Item | undefined>>;
  onSelectItemForDetailView: Dispatch<SetStateAction<Item | undefined>>;
  onSelectItemForDeletion: Dispatch<SetStateAction<Item | undefined>>;
  isOpenLockerLoading: boolean;
  mutateOpenLocker: UseMutateFunction<AxiosResponse<any>, unknown, OpenLockerData, unknown>;
  setSelectedItemsForMultiDelete?: React.Dispatch<React.SetStateAction<string[]>>;
  isMultiDeleteModalOpen?: boolean;
  actionButtonsDisabled: boolean;
}

function ItemsTable({
  items,
  onSelectItemForEditing,
  onSelectItemForDetailView,
  onSelectItemForDeletion,
  isOpenLockerLoading,
  mutateOpenLocker,
  setSelectedItemsForMultiDelete,
  isMultiDeleteModalOpen,
  actionButtonsDisabled = false,
}: ItemsTableProps) {
  const intl = useIntl();

  const maxMediaBreakWidth = 920;

  const translateGlobalServices = (serviceNames: string[]) => {
    return serviceNames.map(serviceName =>
      globalServiceTypes.includes(serviceName)
        ? intl.formatMessage({
            id: `items.serviceTypes.${serviceName.replace(/\s/g, '')}`,
          })
        : serviceName
    );
  };

  const columns = [
    {
      Header: intl.formatMessage({ id: 'item-types.singular' }),
      id: 'item-types',
      accessor: 'itemType.name',
    },
    {
      Header: intl.formatMessage({ id: 'items.draegerSerialNo' }),
      id: 'draegerSerialNo',
      accessor: (row: Item) => {
        const data = row as Item;
        return bindestrich(data.draegerSerialNo);
      },
      hideColumn: true,
    },
    {
      Header: intl.formatMessage({ id: 'items.eID' }),
      id: 'eID',
      // accessor: 'itemBarcode',
      accessor: (row: Item) => {
        const data = row as Item;
        return bindestrich(data.itemBarcode);
      },
      hideColumn: true,
    },
    {
      Header: intl.formatMessage({ id: 'items.itemSetId' }),
      id: 'itemSetId',
      accessor: (row: Item) => {
        const data = row as Item;
        return bindestrich(data.itemSet?.id);
      },
      hideColumn: true,
    },
    {
      Header: intl.formatMessage({ id: 'renters.eId' }),
      id: 'renter',
      accessor: (row: Item) => {
        const data = row as Item;
        return bindestrich(data.renter?.barcode);
      },
      hideColumn: true,
    },
    {
      Header: intl.formatMessage({ id: 'stations.location' }),
      id: 'location',
      accessor: (row: Item) => {
        const data = row as Item;
        return bindestrich(data.station?.location);
      },
    },
    {
      Header: intl.formatMessage({ id: 'cabinets.singular' }),
      id: 'cabinet-position',
      accessor: (row: Item) => {
        const data = row as Item;
        return bindestrich(data.cabinetPosition?.toString());
      },
      reducedWidth: true,
      hideColumn: true,
    },
    {
      Header: intl.formatMessage({ id: 'lockers.singular' }),
      id: 'locker-position',
      accessor: (row: Item) => {
        const data = row as Item;
        return bindestrich(data.lockerPosition?.toString());
      },
      reducedWidth: true,
      hideColumn: true,
    },
    {
      Header: intl.formatMessage({ id: 'items.status' }),
      id: 'status',
      accessor: (row: Item) => {
        const data = row as Item;

        switch (data.status) {
          case Status.AVAILABLE:
            return intl.formatMessage({ id: 'items.status.available' });
          case Status.RENTED:
            return intl.formatMessage({ id: 'items.status.rented' });
          case Status.IN_SERVICE:
            return intl.formatMessage({ id: 'items.status.inService' });
          case Status.REQUIRES_SERVICE:
            return intl.formatMessage({ id: 'items.status.requiredService' });
          case Status.CHARGING:
            return intl.formatMessage({ id: 'items.status.charging' });
          case Status.OVERDUE:
            return intl.formatMessage({ id: 'items.status.overdue' });
          default:
            return '';
        }
      },
    },
    {
      Header: intl.formatMessage({ id: 'items.requiredServiceTypes' }),
      id: 'requiredServiceTypes',
      accessor: (row: Item) => {
        const data = row as Item;
        if (!data.serviceNames || data.serviceNames.length === 0) {
          return '-';
        }
        return translateGlobalServices(data.serviceNames).join(', ');
      },
    },
    {
      Header: intl.formatMessage({ id: 'general.actions' }),
      id: 'action-button',
      className: 'open-locker-cell',
      Cell: ({ row }: CellProps<Item>) => {
        const item = row.original;
        const isItemInLocker = item.station && item.cabinetPosition >= 0 && item.lockerPosition > 0;
        return (
          <TableButtons>
            {isItemInLocker && (
              <OpenLockerButton
                onClick={mutateOpenLocker}
                isLoading={isOpenLockerLoading}
                lockerId={item.lockerId}
                stationId={item.station._id}
                disabled={actionButtonsDisabled}
              />
            )}
            <InfoButton
              onClick={() => onSelectItemForDetailView(item)}
              disabled={actionButtonsDisabled}
            />
            <EditButton
              onClick={e => {
                e.stopPropagation();
                onSelectItemForEditing(item);
              }}
              disabled={actionButtonsDisabled}
            />
            {window.innerWidth > maxMediaBreakWidth && (
              <DeleteButton
                onClick={e => {
                  e.stopPropagation();
                  onSelectItemForDeletion(item);
                }}
                disabled={actionButtonsDisabled}
              />
            )}
          </TableButtons>
        );
      },
    },
  ];

  const sortBy = [
    {
      id: 'draegerSerialNo',
      desc: false,
    },
  ];

  return (
    <VerticalTable
      sortBy={sortBy}
      // @ts-ignore : accessor can be string | function according to api doc / seems to be type def error
      columns={columns}
      data={items}
      setMultiSelectedElements={setSelectedItemsForMultiDelete}
      isMultiSelectModalOpen={isMultiDeleteModalOpen}
    />
  );
}

export { ItemsTable };
