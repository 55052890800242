import Box from '@mui/material/Box';
import { ChartsTooltip } from '@mui/x-charts';
import { BarLabel, BarPlot } from '@mui/x-charts/BarChart';
import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { BarSeriesType } from '@mui/x-charts/models';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';

import { chartOdxBlue, chartOdxRed } from '../../../context/themeContext';

export interface BarChartWithLineProps {
  value: number;
  maxValue: number;
  linePos: number;
  lineLabel: string;
  toolTipLabel: string;
  chartLabel: string;
}
export function BarChartWithLine({
  value,
  maxValue,
  linePos,
  lineLabel,
  toolTipLabel,
  chartLabel,
}: BarChartWithLineProps) {
  const fakeYAxis = [''];
  const color = value > linePos ? chartOdxBlue : chartOdxRed;
  const xAxisId = 'x-axis-id';
  const seriesId = 'series-id';
  const configs = {
    series: [
      {
        id: seriesId,
        type: 'bar',
        data: [value],
        layout: 'horizontal',
        color: color,
        label: () => `${toolTipLabel}`,
      },
    ] as BarSeriesType[],
    height: 130,
    yAxis: [
      {
        data: fakeYAxis,
        scaleType: 'band',
      } as const,
    ],
    xAxis: [
      {
        min: 0,
        max: linePos > maxValue - 10 && linePos != 0 ? maxValue + 10 : maxValue,
        id: xAxisId,
        tickMinStep: 1,
        tickMaxStep: 25,
        tickLabelStyle: { fontSize: 14 },
      },
    ],
  };
  return (
    <Box sx={{ width: '100%' }}>
      <ResponsiveChartContainer {...configs}>
        <ChartsTooltip trigger="item" />
        <BarLabel
          color={'white'}
          dataIndex={0}
          seriesId={seriesId}
          isFaded={false}
          isHighlighted={false}
        />
        <BarPlot />
        {linePos != 0 && <ChartsReferenceLine x={linePos} label={lineLabel} labelAlign="middle" />}
        <ChartsXAxis axisId={xAxisId} label={chartLabel} />
      </ResponsiveChartContainer>
    </Box>
  );
}
