import { Paper as MuiPaper } from '@mui/material';
import { ReactNode } from 'react';

export interface PaperProps {
  children?: ReactNode;
  style?: any;
}
export function Paper({
  children,
  style = { padding: '15px', marginBottom: '30px', marginLeft: '15px', marginRight: '15px' },
}: PaperProps) {
  return (
    <MuiPaper variant={'outlined'} style={style}>
      {children}
    </MuiPaper>
  );
}
