import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/tokenContext';
import { ItemFormData } from '../../modules/renting/items/manage/ItemForm';
import {
  ValidateItemServiceData,
  ServiceItemsFormData,
} from '../../modules/renting/items/service-items/ServiceItemsForm';
import { DetailedItem, EditRequest, Item, MessageObject } from '../../types/types';

const ITEMS_URL = '/items';

function useItems() {
  const client = useClient<Item[]>();
  const result = useQuery('items', () => client(ITEMS_URL));
  return { ...result, items: result.data?.data ?? [] };
}

function useItemDetails(itemId: string) {
  const client = useClient<DetailedItem>();
  const result = useQuery('item-details', () => client(`${ITEMS_URL}/${itemId}/details`));
  return { ...result, detailedItem: result.data?.data ?? null };
}

function useCreateItem() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: ItemFormData) =>
      client(`${ITEMS_URL}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('items');
      },
    }
  );
}

function useUploadTemplate(itemTypeId: string | undefined) {
  const client = useClient<BlobPart>();

  const result = useQuery(
    ['upload-template', itemTypeId],
    () => client(`${ITEMS_URL}/${itemTypeId}/xlsx`, { responseType: 'blob' }),
    { enabled: false }
  );
  return {
    ...result,
    uploadTemplate: result.data?.data ?? null,
    headers: result.data?.headers ?? null,
  };
}

function useUploadItems() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (data: { formData: FormData; itemTypeId: string }) =>
      client(`${ITEMS_URL}/${data.itemTypeId}/xlsx`, {
        method: 'POST',
        data: data.formData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('items');
      },
    }
  );
}

function useEditItem() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: EditRequest) =>
      client(`${ITEMS_URL}`, {
        method: 'PATCH',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('items');
      },
    }
  );
}

function useDeleteItem(itemId: string) {
  const client = useClient<MessageObject>();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`${ITEMS_URL}/${itemId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('items');
      },
    }
  );
}

function useDeleteMultiItem() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { itemIds: string[] }) =>
      client(`${ITEMS_URL}/`, {
        method: 'DELETE',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('items');
      },
    }
  );
}

function useValidateItemService() {
  const client = useClient();
  return useMutation((data: ValidateItemServiceData) =>
    client(`${ITEMS_URL}/e-ids/${data.itemEId}`, {
      method: 'POST',
      data: {
        serviceTypeId: data.serviceTypeId,
        name: data.name,
        isGlobalService: data.isGlobalService,
        date: data.date,
      },
    })
  );
}

function useServiceItems() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: ServiceItemsFormData) =>
      client(`${ITEMS_URL}/service-update`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('items');
      },
    }
  );
}

export {
  useItems,
  useItemDetails,
  useCreateItem,
  useUploadTemplate,
  useEditItem,
  useDeleteItem,
  useDeleteMultiItem,
  useValidateItemService,
  useServiceItems,
  useUploadItems,
};
