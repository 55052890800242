interface TooltipProps {
  tooltipMessage: string;
  toolTipPos?: string;
}

function Tooltip({ tooltipMessage, toolTipPos = 'up' }: TooltipProps) {
  return (
    <button
      className={`uib-iconbutton tooltip-button`}
      data-tooltip-length="medium"
      data-tooltip={tooltipMessage}
      data-tooltip-pos={toolTipPos}
      style={{ marginLeft: '200px' }}
      type="button"
    >
      <i className="uib-icon uib-icon--information" />
    </button>
  );
}

export { Tooltip };
