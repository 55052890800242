import { useIntl } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';

import { useAppContext } from '../../../context/appContext';
import { SubHeaderMenu } from '../SubHeaderMenu';

interface SettingsMenuProps {
  closeMenu: () => void;
}

function SettingsMenu({ closeMenu }: SettingsMenuProps) {
  const intl = useIntl();
  const currentRoute = useLocation().pathname;
  const { openSettingsModal } = useAppContext();

  return (
    <SubHeaderMenu
      firstButtonLabel={intl.formatMessage({ id: 'app.settings' })}
      handleFirstButtonClick={openSettingsModal}
      secondButtonLink={
        <NavLink to={currentRoute === '/user-mgmt' ? '/systems' : '/user-mgmt'}>
          <div>
            {intl.formatMessage({
              id: currentRoute === '/user-mgmt' ? 'systems.self' : 'app.users.management',
            })}
          </div>
        </NavLink>
      }
      closeMenu={closeMenu}
    />
  );
}

export { SettingsMenu };
