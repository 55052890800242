import { ReactNode } from 'react';

import { Card } from '../../../components/library/containers/Card';

interface OnClickReportCardProps {
  id: string;
  name: string;
  onClick: () => void;
  children: ReactNode;
}
export function OnClickReportCard({ id, name, onClick, children }: OnClickReportCardProps) {
  return (
    <div className="uib-board__box" onClick={onClick}>
      <Card cardName={name}>
        <button id={id} className="uib-iconbutton card-button">
          {children}
        </button>
      </Card>
    </div>
  );
}
