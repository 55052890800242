import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ReactNode } from 'react';

import { useConfigContext } from '../../shared/configContext';

import { AuthProvider } from './authContext';
import { TokenProvider } from './tokenContext';

interface AuthProvidersProps {
  children: ReactNode;
}

function AuthProviders({ children }: AuthProvidersProps) {
  const { config } = useConfigContext();

  // MSAL configuration
  if (
    !config.b2cClientId ||
    !config.b2cAuthority ||
    !config.b2cRedirectUri ||
    config.b2cKnownAuthorities?.length === 0
  ) {
    throw new Error('Not all config values necessary for the msal setup are provided.');
  }
  const configuration: Configuration = {
    auth: {
      clientId: config.b2cClientId,
      authority: config.b2cAuthority,
      redirectUri: config.b2cRedirectUri,
      knownAuthorities: config.b2cKnownAuthorities,
    },
  };

  const msalInstance = new PublicClientApplication(configuration);

  return (
    <MsalProvider instance={msalInstance}>
      <TokenProvider>
        <AuthProvider>{children}</AuthProvider>
      </TokenProvider>
    </MsalProvider>
  );
}

export { AuthProviders };
