import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useDashboardContext } from '../../context/DashboardContext';
import { Stock } from '../../types/types';
import { StationDropDown } from '../common/inputs/StationDropdown';
import { ChartCard } from '../library/charts/ChartCard';

import ContractStockCharts from './ContractStockCharts';

export function ContractStockChartCard() {
  const intl = useIntl();
  const { itemsStock, stationsIsLoading, itemsStockIsLoading } = useDashboardContext();
  const title = intl.formatMessage({ id: 'stockPage.self' });
  return (
    <ChartCard
      title={title}
      content={<ContractStockByStation />}
      isLoading={itemsStockIsLoading || stationsIsLoading}
      isEmpty={itemsStock.length == 0}
    />
  );
}

function ContractStockByStation() {
  const { itemsStock } = useDashboardContext();
  const [currentStation, setCurrentStation] = useState<string>(
    getMostInterestingStationId(itemsStock)
  );
  useEffect(() => {
    setCurrentStation(getMostInterestingStationId(itemsStock));
  }, [itemsStock]);
  return (
    <div style={{ overflow: 'hidden' }}>
      <StockChartStationDropDown currentStation={currentStation} onChange={setCurrentStation} />
      <div style={{ maxHeight: '350px', overflowY: 'scroll', overflowX: 'hidden' }}>
        <ContractStockCharts itemStock={filterStockByStation(itemsStock, currentStation)} />
      </div>
    </div>
  );
}
interface StockChartStationDropDownProps {
  currentStation: string;
  onChange: (stationId: string) => void;
}
function StockChartStationDropDown({ currentStation, onChange }: StockChartStationDropDownProps) {
  const { stations } = useDashboardContext();
  return (
    <StationDropDown
      currentStation={currentStation}
      onChange={onChange}
      fullWidth={true}
      stations={stations}
    />
  );
}
function filterStockByStation(stock: Stock[], stationId: string) {
  return stock.filter(s => s.station.id === stationId);
}

function getMostInterestingStationId(stockArray: Stock[]): string {
  const groupedByStationId = stockArray.reduce(
    (acc, stock) => {
      const stationId = stock.station.id;
      if (!acc[stationId]) {
        acc[stationId] = [];
      }
      acc[stationId].push(stock);
      return acc;
    },
    {} as Record<string, Stock[]>
  );

  const sortedStationIds = Object.entries(groupedByStationId).sort((a, b) => {
    const [, stocksA] = a;
    const [, stocksB] = b;
    let countA = stocksA.filter(
      stock => stock.stock?.available ?? 0 < (stock.contractStock?.stock ?? Infinity)
    ).length;
    let countB = stocksB.filter(
      stock => stock.stock?.available ?? 0 < (stock.contractStock?.stock ?? Infinity)
    ).length;
    if (countA !== countB) return countB - countA;
    countA = stocksA.filter(stock => stock.contractStock?.stock !== undefined).length;
    countB = stocksB.filter(stock => stock.contractStock?.stock !== undefined).length;
    if (countA !== countB) return countB - countA;
    countA = stocksA.filter(stock => stock.stock?.available ?? 0 > 0).length;
    countB = stocksB.filter(stock => stock.stock?.available ?? 0 > 0).length;
    return countB - countA;
  });
  return sortedStationIds[0][0];
}
