import { useIntl } from 'react-intl';

import { Modal } from './Modal';

interface RequestError {
  status: number;
  data: {
    codes: string[];
    code: string;
    messages: string[];
  };
}

export interface RequestErrorResponse {
  response: RequestError;
}

interface ErrorModalProps {
  isOpen: boolean;
  error: RequestErrorResponse | string | undefined;
  onClose?: () => void;
  dismissable?: boolean;
}

function ErrorModal({ isOpen, error, onClose, dismissable = true }: ErrorModalProps) {
  const intl = useIntl();
  let content = '';
  const generalError = `${intl.formatMessage({
    id: 'general.unknownError',
  })} ${intl.formatMessage({ id: 'general.contactAdmin' })}`;
  if (!error) {
    return null;
  }

  if (typeof error === 'string') {
    content = error;
  } else {
    const {
      response: { data },
    } = error;
    if (data.messages) {
      content = data.messages[0];
    } else if (data.code) {
      content = intl.formatMessage({ id: `general.${data.code}` });
    } else {
      content = generalError;
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      header={
        <h1 className="uib-modal__title">{intl.formatMessage({ id: 'general.simpleError' })}</h1>
      }
      content={content}
      modalHasClosed={onClose}
      hasButtons={dismissable}
    />
  );
}

export { ErrorModal };
