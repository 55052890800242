import { ReactNode, createContext, useContext, useState } from 'react';

export enum NotificationType {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
}
interface NotificationMessageContextApi {
  notificationMessage: string | undefined;
  notificationType: NotificationType | undefined;
  setNotificationMessage: (message: string | undefined, type: NotificationType | undefined) => void;
}

const NotificationMessageContext = createContext<NotificationMessageContextApi | undefined>(
  undefined
);

interface NotificationMessageProviderProps {
  children: ReactNode;
}

function NotificationMessageProvider({ children }: NotificationMessageProviderProps) {
  const [notificationMessage, setNotificationMessage] = useState<string | undefined>(undefined);
  const [notificationType, setNotificationType] = useState<NotificationType | undefined>(undefined);
  const value: NotificationMessageContextApi = {
    notificationMessage: notificationMessage,
    notificationType: notificationType,
    setNotificationMessage: (message, type) => {
      setNotification(message, type);
    },
  };
  const setNotification = (message: string | undefined, type: NotificationType | undefined) => {
    setNotificationMessage(message);
    setNotificationType(type);
  };
  return (
    <NotificationMessageContext.Provider value={value}>
      {children}
    </NotificationMessageContext.Provider>
  );
}

function useNotificationMessageContext() {
  const context = useContext(NotificationMessageContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationMessageContext must be used within a NotificationMessageProvider'
    );
  }
  return context;
}

export { NotificationMessageProvider, useNotificationMessageContext };
