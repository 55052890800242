import { useIntl } from 'react-intl';

import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { QuantityRestriction } from '../../../types/quantity-restrictions/QuantityRestriction';
import { Dialog } from '../../library/dialog/Dialog';

import { EditQuantityRestrictionForm } from './EditQuantityRestrictionForm';

export function EditQuantityRestrictionDialog() {
  const intl = useIntl();
  const { entityToEdit: qrToEdit } = useCrudPageUiStateContext<QuantityRestriction>();
  return (
    <>
      {qrToEdit && (
        <Dialog
          id={'EditQuantityRestrictionDialog'}
          isOpen={qrToEdit ? true : false}
          title={intl.formatMessage({ id: 'quantity-restrictions.edit' })}
          content={<EditQuantityRestrictionForm />}
        />
      )}
    </>
  );
}
