import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { CellProps } from 'react-table';

import Icon from '../../components/library/icons/Icon';
import { DeleteButton } from '../../components/table/table-buttons/DeleteButton';
import { EditButton } from '../../components/table/table-buttons/EditButton';
import { TableButtons } from '../../components/table/table-buttons/TableButtons';
import { VerticalTable } from '../../components/table/VerticalTable';
import { ServiceType } from '../../types/types';
import { bindestrich } from '../../utils/bindestrich';

interface ServiceTypesTableProps {
  serviceTypes: ServiceType[];
  onSelectServiceTypeForEditing: Dispatch<SetStateAction<ServiceType | undefined>>;
  onSelectServiceTypeForDeletion: Dispatch<SetStateAction<ServiceType | undefined>>;
}

function ServiceTypesTable({
  serviceTypes,
  onSelectServiceTypeForDeletion,
  onSelectServiceTypeForEditing,
}: ServiceTypesTableProps) {
  const intl = useIntl();

  function blockAfterUseIcon(serviceType: ServiceType) {
    const icon = serviceType.isBlockAfterUse ? (
      <Icon iconName={'check'} additionalClasses={'odx-blue-text'} />
    ) : (
      <Icon iconName={'blocked'} />
    );
    return <div className="cell-icon-container">{icon}</div>;
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'service-types.name' }),
      accessor: 'name',
    },
    {
      Header: intl.formatMessage({ id: 'service-types.intervalInDays' }),
      Cell: ({ row: { original } }: CellProps<ServiceType>) => {
        return bindestrich(original.intervalInDays);
      },
    },
    {
      Header: intl.formatMessage({
        id: 'service-types.blockPeriodInDaysBeforeIntervalEnds',
      }),
      Cell: ({ row: { original } }: CellProps<ServiceType>) => {
        return bindestrich(original.blockPeriodInDaysBeforeIntervalEnds);
      },
    },
    {
      Header: intl.formatMessage({ id: 'service-types.isBlockAfterUse' }),
      id: 'isBlockAfterUse',
      Cell: ({ row: { original } }: CellProps<ServiceType>) => blockAfterUseIcon(original),
    },
    {
      Header: intl.formatMessage({ id: 'service-types.description' }),
      Cell: ({ row: { original } }: CellProps<ServiceType>) => {
        return bindestrich(original.description);
      },
    },
    {
      Header: intl.formatMessage({ id: 'general.actions' }),
      id: 'actions',
      Cell: ({ row }: CellProps<ServiceType>) => {
        const serviceType = row.original;
        return (
          <TableButtons>
            <EditButton
              onClick={() => {
                onSelectServiceTypeForEditing(serviceType);
              }}
            />
            <div id="Delete Me when you edit and uncomment the edit button" />
            <DeleteButton
              onClick={() => {
                onSelectServiceTypeForDeletion(serviceType);
              }}
            />
          </TableButtons>
        );
      },
    },
  ];
  const sortBy = [
    {
      id: 'name',
      desc: false,
    },
  ];

  return <VerticalTable sortBy={sortBy} columns={columns} data={serviceTypes} />;
}

export { ServiceTypesTable };
