import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { User } from '../../types/types';

interface DeleteUserDialogProps {
  selectedUserForDeletion: User | undefined | null;
  setSelectedUserForDeletion: React.Dispatch<React.SetStateAction<User | null | undefined>>;
  onConfirmDeleteUser: () => void;
}

function DeleteUserDialog({
  selectedUserForDeletion,
  setSelectedUserForDeletion,
  onConfirmDeleteUser,
}: DeleteUserDialogProps) {
  const intl = useIntl();

  const confirmDeleteWithNames = (
    <>
      {intl.formatMessage(
        { id: 'users.delete.confirmation' },
        {
          userFirstName: selectedUserForDeletion?.firstName,
          userLastName: selectedUserForDeletion?.lastName,
        }
      )}
    </>
  );

  return (
    <Dialog
      id={'DeleteUserDialog'}
      open={!!selectedUserForDeletion}
      keepMounted
      onClose={() => {
        setSelectedUserForDeletion(undefined);
      }}
    >
      <DialogTitle>{intl.formatMessage({ id: 'users.deleteDialog' })}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {confirmDeleteWithNames}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id={'closeBtn'}
          onClick={() => {
            setSelectedUserForDeletion(undefined);
          }}
        >
          {intl.formatMessage({ id: 'general.cancel' })}
        </Button>
        <Button
          id={'confirmBtn'}
          onClick={onConfirmDeleteUser}
          variant={'contained'}
          color={'error'}
        >
          {intl.formatMessage({ id: 'general.confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { DeleteUserDialog };
