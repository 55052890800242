import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

interface AddIconButtonProps {
  id: string;
  onClick: () => void;
}
function AddIconButton({ id, onClick }: AddIconButtonProps) {
  return (
    <Button id={id} variant="contained" onClick={onClick}>
      <AddIcon />
    </Button>
  );
}

export { AddIconButton };
