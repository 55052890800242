import { useIntl } from 'react-intl';

import { FormDropDown } from '../../../library/form/FormDropdown';

export function SystemStatusDropdown() {
  const intl = useIntl();
  const options = [
    { value: 'Live', label: intl.formatMessage({ id: 'systems.status.live' }) },
    { value: 'NotLive', label: intl.formatMessage({ id: 'systems.status.notLive' }) },
    { value: 'Undefined', label: intl.formatMessage({ id: 'systems.status.undefined' }) },
  ];
  return (
    <FormDropDown
      id={'SystemStatusDropdown'}
      required={true}
      label={intl.formatMessage({ id: 'systems.status.input' })}
      name={'status'}
      dropDownItems={options}
    />
  );
}
