import React from 'react';

import { IconSize, OdxIconSet } from '../../../types/components/Icon';

export interface IconProps {
  iconName: string;
  id?: string;
  iconSet?: OdxIconSet;
  additionalClasses?: string;
  size?: IconSize;
  inline?: boolean;
  color?: string;
}

/**
 * An icon component to use the ODX icons from Dräger.
 * Get more information about the ODX icons here https://odx.draeger.com/icons
 *
 * @param {Object} props - The props passed to the component.
 * @param {string} props.iconName - Name of icon in ODX
 * @param {string} props.id
 * @param {IconSize} props.size
 * @param {boolean} props.inline
 * @param {OdxIconSet} props.iconSet - Select provided ODX icon-set
 */
export function Icon({
  iconName,
  id,
  iconSet = OdxIconSet.CORE,
  size,
  inline = false,
  additionalClasses,
  color,
}: IconProps) {
  const classNames = ['odx-icon'];
  if (additionalClasses !== undefined) classNames.push(additionalClasses);
  if (size) classNames.push(`odx-icon--${size}`);
  if (inline) classNames.push(`odx-icon--inline`);

  const uniqueClasses = Array.from(new Set(classNames));

  const elementProps = {
    id: id,
    className: uniqueClasses.join(' '),
    'data-icon-set': iconSet,
    'data-icon-name': iconName,
    'data-testid': 'icon-component',
    style: { color: color },
  };

  return React.createElement('i', elementProps, React.createElement('span'));
}

export default Icon;
