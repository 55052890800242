import { DataTableRow } from '../../components/library/table/DataTable';

export interface RenterRow extends DataTableRow {
  id: string;
  fullName: string;
  barcode: string;
  contractor: string;
  supervisorEmails: string[];
  qualificationNames: string[];
  isRenterAdmin: boolean;
  status: string;
}

export enum RenterRowFieldName {
  FullName = 'fullName',
  Barcode = 'barcode',
  Contractor = 'contractor',
  SupervisorEmails = 'supervisorEmails',
  QualificationNames = 'qualificationNames',
  IsRenterAdmin = 'isRenterAdmin',
  Status = 'status',
}
