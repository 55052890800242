import { PieChart as MuiPieChart } from '@mui/x-charts/PieChart';
import { IntlShape, useIntl } from 'react-intl';

import { chartOdxColors } from '../../../context/themeContext';

export interface PieChartProps {
  data: PieChartData[];
  percentages?: boolean;
}
export interface PieChartData {
  id: number;
  value: number;
  label: string;
}
export function PieChart({ data, percentages = false }: PieChartProps) {
  const pieParams = { height: 250 };
  const intl = useIntl();
  const top5Data = transformDataForPieChart(data, intl);
  return (
    <MuiPieChart
      colors={chartOdxColors}
      series={[
        {
          valueFormatter: v => {
            if (percentages) return formatNumber(intl, v.value) + ' %';
            return formatNumber(intl, v.value);
          },
          data: top5Data,
          innerRadius: 30,
          outerRadius: 100,
          paddingAngle: 5,
          cornerRadius: 5,
          startAngle: -180,
          endAngle: 180,
          cx: 170,
          cy: 120,
        },
      ]}
      {...pieParams}
    />
  );
}
function formatNumber(intl: IntlShape, number: number): string {
  return intl.formatNumber(number, {
    maximumFractionDigits: 2,
  });
}
function transformDataForPieChart(data: PieChartData[], intl: IntlShape) {
  const sortedData = [...data].sort((a, b) => b.value - a.value);
  if (sortedData.length <= 6) {
    return sortedData;
  }
  // select top 5 data and accumulates the rest into a "others" data point
  const topData = sortedData.slice(0, 6);
  const otherDataValue = sortedData.slice(6).reduce((acc, curr) => acc + curr.value, 0);
  const otherData: PieChartData = {
    id: 1337,
    value: otherDataValue,
    label: intl.formatMessage({ id: 'general.others' }),
  };
  return [...topData, otherData];
}
