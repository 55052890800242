import { createContext, Dispatch, ReactNode, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { System } from '../types/systems/System';
import { OptionItem, RrSystemStatus, Station } from '../types/types';

interface RRSystemContextApi {
  rrSystem: System | null;
  selectRRSystem: (rrSystem?: System) => void;
  exitRRSystem: () => void;
  setClickedStation: Dispatch<any>;
  clickedStation?: Station;
  rrSystemStatus: OptionItem[];
}

const RRSystemContext = createContext<RRSystemContextApi | undefined>(undefined);

interface RRSystemProviderProps {
  children: ReactNode;
}

function useSessionStorageRRSystem(): [System, Dispatch<System | null>] {
  const rrSystemKey = 'RR_SYSTEM';

  const [rrSystem, setRRSystem] = useState(() => {
    const rrSystemString = sessionStorage.getItem(rrSystemKey);
    return rrSystemString ? JSON.parse(rrSystemString) : null;
  });

  useEffect(() => {
    sessionStorage.setItem(rrSystemKey, rrSystem ? JSON.stringify(rrSystem) : '');
  }, [rrSystem]);

  return [rrSystem, setRRSystem];
}

function RRSystemProvider({ children }: RRSystemProviderProps) {
  const intl = useIntl();
  const [rrSystem, setRRSystem] = useSessionStorageRRSystem();
  const [clickedStation, setClickedStation] = useState<Station | undefined>();

  const rrSystemStatus = [
    {
      value: RrSystemStatus.LIVE,
      label: intl.formatMessage({
        id: 'rental-robot-systems.live',
      }),
    },
    {
      value: RrSystemStatus.NOT_LIVE,
      label: intl.formatMessage({
        id: 'rental-robot-systems.notlive',
      }),
    },
    {
      value: RrSystemStatus.UNDEFINED,
      label: intl.formatMessage({
        id: 'rental-robot-systems.undefined',
      }),
    },
  ];

  const value: RRSystemContextApi = {
    rrSystem,
    clickedStation,
    setClickedStation,
    selectRRSystem: selectedRRSystem => {
      if (!selectedRRSystem) {
        console.error('There was an error while trying to select the RR System.');
      } else {
        if (window.location.href.includes('stations/')) {
          setClickedStation(undefined);
          history.back();
        }
        setRRSystem(selectedRRSystem);
      }
    },
    exitRRSystem: () => {
      setRRSystem(null);
    },
    rrSystemStatus,
  };

  return <RRSystemContext.Provider value={value}>{children}</RRSystemContext.Provider>;
}

function useRRSystemContext() {
  const context = useContext(RRSystemContext);
  if (context === undefined) {
    throw new Error('useRRSystemContext must be used within an RRSystemProvider');
  }
  return context;
}

export { RRSystemProvider, useRRSystemContext };
