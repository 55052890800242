import { useIntl } from 'react-intl';

import { FormSwitch } from '../../../library/form/FormSwitch';

export function RenterAdminToggle() {
  const intl = useIntl();
  return (
    <FormSwitch
      id={'isRenterAdmin'}
      label={intl.formatMessage({ id: 'renters.isRenterAdmin' })}
      name="isRenterAdmin"
    />
  );
}
