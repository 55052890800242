import { IntlShape, useIntl } from 'react-intl';

import { useDashboardContext } from '../../context/DashboardContext';
import { Item } from '../../types/types';
import { ChartCard } from '../library/charts/ChartCard';
import { PieChart, PieChartData } from '../library/charts/PieChart';

export function ItemStatusChartCard() {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'dashboard.itemStatusPieChartTitle' });
  const { items, itemsIsLoading } = useDashboardContext();
  return (
    <ChartCard
      title={title}
      content={<ItemStatusPieChart items={items} />}
      isLoading={itemsIsLoading}
      isEmpty={items.length == 0}
    />
  );
}
interface ItemStatusPieChartProps {
  items: Item[];
}
function ItemStatusPieChart({ items }: ItemStatusPieChartProps) {
  const intl = useIntl();
  const data = toChartData(items, intl);
  return <PieChart data={data} />;
}
function toChartData(items: Item[], intl: IntlShape): PieChartData[] {
  const statusCount: { [key: string]: number } = {};
  for (const item of items) {
    statusCount[item.status] = statusCount[item.status] ? statusCount[item.status] + 1 : 1;
  }
  const chartData: PieChartData[] = Object.keys(statusCount).map((status, id) => {
    return {
      id: id,
      value: statusCount[status],
      label: intl.formatMessage({
        id: `items.status.${mapToStatusLabel(status)}`,
      }),
    };
  });
  return chartData;
}

function mapToStatusLabel(itemStatus: string): string {
  switch (itemStatus) {
    case 'Overdue':
      return 'overdue';
    case 'RequiresService':
      return 'requiredService';
    case 'Available':
      return 'available';
    case 'InService':
      return 'inService';
    case 'Rented':
      return 'rented';
    case 'Charging':
      return 'charging';
    default:
      return itemStatus;
  }
}
